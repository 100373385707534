import React, { useState }from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedIcon from '@mui/icons-material/Feed';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom'; 
import { ToastContainer } from 'react-toastify'; 
import { TodayIs} from 'components/utils/TodayIs';
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages';
import PurchaseSignatureDeliveryFilter from "pages/purchaseSignature/purchaseSignatureFilter/PurchaseSignatureFilter"; 

const initialFiltersPurchaseSignature= {
    "idFrom":"",
    "idTo":"",
    "defaultStoreIdFrom":"",
    "defaultStoreIdTo":"",
    "useraccountIdFrom":"",
    "useraccountIdTo":"",
    "purchasenoteFrom":"",
    "purchasenoteTo":"",
    "datepurchaseFrom":null,
	"datepurchaseTo":null, 
    "vehiclestore_licenseplateFrom":"",
    "vehiclestore_licenseplateTo":"",
    "ori_tras_workplaceFrom":"",
    "ori_tras_workplaceTo":"", 
    "rel_trans_store_idFrom":"",
    "rel_trans_store_idTo":"",
    "ori_tras_store_idFrom":"",
    "ori_tras_store_idTo":"",
    "numberDIFROM":"",
    "numberDITO":"",
    "paymentFromES":"",
    "paymentToES":"",
    "paymentFromEN":"",
    "paymentToEN":"",
  };

const HomePurchaseSignature = () => {
    const {t,i18n} = useTranslation(['listDataPurchases']);
    const state = useSelector((state) => state);
    const enabled = state.loginUser.enabled;
    const roles = state.loginUser.roles;
    const defaultStore = state.loginUser.defaultStore;
    const [applyFiltersPurchaseSignature, setApplyFiltersPurchaseSignature]= useState(initialFiltersPurchaseSignature);
    const [showModalGeneralSettings, setShowModalGeneralSettings] = useState(false);
    const [indexShowSettings,setIndexShowSettings]=useState(0);
    const [showModalPurchasesSignatureSettings, setShowModalPurchasesSignatureSettings] = useState(true); 
    
    const scriptUrl = '/js/sb-admin-2.min.js'; 

    function changeSelectedLanguage(){
        i18n.language==="es"?i18n.changeLanguage("en"):i18n.changeLanguage("es");
        i18n.language==="es"?
        setApplyFiltersPurchaseSignature(applyFiltersPurchaseSignature => ({
            ...applyFiltersPurchaseSignature,
            "paymentFromEN":"",
            "paymentToEN":""
            

          })):
          setApplyFiltersPurchaseSignature(applyFiltersPurchaseSignature => ({
            ...applyFiltersPurchaseSignature,
            "paymentFromES":"",
            "paymentToES":"" 
        }));
    };

    const closeModalPurchaseSignature = () => {    
        setApplyFiltersPurchaseSignature(initialFiltersPurchaseSignature);
        setShowModalGeneralSettings(showModalGeneralSettings);
        setIndexShowSettings(0);
        setIndexShowSettings(indexShowSettings);
        setShowModalPurchasesSignatureSettings(false);
        return () => {};
    };
  
    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer/>
        {enabled===false ? <Navigate to="/"></Navigate>:
        <div id="page-top">
           
            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar toggled sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Sidebar - INFORMATION FILTROS APLICADOS */}
                    <li className="nav-item active">
                        <Link className="nav-link" to={"/purchasesignature"}>
                            <FeedIcon/>
                            <span> {t('Text_Information')}</span>
                        </Link>
                    </li>
                    <div className={applyFiltersPurchaseSignature.idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_From')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.idFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.idFrom}
                    </li>
                    <div className={applyFiltersPurchaseSignature.idTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_To')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.idTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.idTo}
                    </li>
                    
                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Purchases_Store_Master"))&&
                        <>
                            <div className={applyFiltersPurchaseSignature.defaultStoreIdFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                                {t('Text_Filter_Stores_From')}
                            </div>
                            <li className={applyFiltersPurchaseSignature.defaultStoreIdFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersPurchaseSignature.defaultStoreIdFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.defaultStoreIdFrom}
                            </li>
                            <div className={applyFiltersPurchaseSignature.defaultStoreIdTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                                {t('Text_Filter_Stores_To')}
                            </div>
                            <li className={applyFiltersPurchaseSignature.defaultStoreIdTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersPurchaseSignature.defaultStoreIdTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.defaultStoreIdTo}
                            </li>
                        </>
                    }
                    {(roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Purchases_Store_Master"))
                        || roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Purchases_User_Master"))) &&
                        <>
                            <div className={applyFiltersPurchaseSignature.useraccountIdFrom===''?"sidebar-heading":"sidebar-heading text-white"}> 
                                {t('Text_Filter_Useraccount_From')}
                            </div>
                            <li className={applyFiltersPurchaseSignature.useraccountIdFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersPurchaseSignature.useraccountIdFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.useraccountIdFrom}
                            </li>
                            <div className={applyFiltersPurchaseSignature.useraccountIdTo===''?"sidebar-heading":"sidebar-heading text-white"}> 
                                {t('Text_Filter_Useraccount_To')}
                            </div>
                            <li className={applyFiltersPurchaseSignature.useraccountIdTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersPurchaseSignature.useraccountIdTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.useraccountIdTo}
                            </li>
                        </>
                    }

                    <div className={applyFiltersPurchaseSignature.purchasenoteFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_NumberPurchase_From')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.purchasenoteFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.purchasenoteFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.purchasenoteFrom}
                    </li>
                    <div className={applyFiltersPurchaseSignature.purchasenoteTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_NumberPurchase_To')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.purchasenoteTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.purchasenoteTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.purchasenoteTo}
                    </li>

                    <div className={applyFiltersPurchaseSignature.datepurchaseFrom===null || applyFiltersPurchaseSignature.datepurchaseFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_DatePurchase_From')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.datepurchaseFrom===null || applyFiltersPurchaseSignature.datepurchaseFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        {applyFiltersPurchaseSignature.datepurchaseFrom===null || applyFiltersPurchaseSignature.datepurchaseFrom===''? t('Text_Filter_Not_Apply'): new Date(applyFiltersPurchaseSignature.dateContractFrom).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>
                    <div className={applyFiltersPurchaseSignature.datepurchaseTo===null || applyFiltersPurchaseSignature.datepurchaseTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_DatePurchase_To')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.datepurchaseTo===null || applyFiltersPurchaseSignature.datepurchaseTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.datepurchaseTo===null || applyFiltersPurchaseSignature.datepurchaseTo===''? t('Text_Filter_Not_Apply'):new Date(applyFiltersPurchaseSignature.dateContractTo).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>
                    
                    <div className={applyFiltersPurchaseSignature.vehiclestore_licenseplateFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_vehicles_From')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.vehiclestore_licenseplateFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.vehiclestore_licenseplateFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.vehiclestore_licenseplateFrom}
                    </li>
                    <div className={applyFiltersPurchaseSignature.vehiclestore_licenseplateTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_vehicles_To')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.vehiclestore_licenseplateTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.vehiclestore_licenseplateTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.vehiclestore_licenseplateTo}
                    </li>


                    <div className={applyFiltersPurchaseSignature.ori_tras_workplaceFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_workplace_From')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.ori_tras_workplaceFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.ori_tras_workplaceFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.ori_tras_workplaceFrom}
                    </li>
                    <div className={applyFiltersPurchaseSignature.ori_tras_workplaceTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_workplace_To')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.ori_tras_workplaceTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.ori_tras_workplaceTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.ori_tras_workplaceTo}
                    </li>


                    <div className={applyFiltersPurchaseSignature.numberDIFROM===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_FormDI_From')}
                    </div>
                    
                    <li className={applyFiltersPurchaseSignature.numberDIFROM===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.numberDIFROM==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.numberDIFROM}
                    </li>
                    <div className={applyFiltersPurchaseSignature.numberDITO===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_FormDI_To')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.numberDITO===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.numberDITO==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.numberDITO}
                    </li>
  
                    <div className={applyFiltersPurchaseSignature.rel_trans_store_idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_Trans_From')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.rel_trans_store_idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.rel_trans_store_idFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.rel_trans_store_idFrom}
                    </li>
                    <div className={applyFiltersPurchaseSignature.rel_trans_store_idTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_Trans_To')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.rel_trans_store_idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.rel_trans_store_idTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.rel_trans_store_idTo}
                    </li>

                    <div className={applyFiltersPurchaseSignature.ori_tras_store_idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_TradeName_From')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.ori_tras_store_idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.ori_tras_store_idFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.ori_tras_store_idFrom}
                    </li>
                    <div className={applyFiltersPurchaseSignature.ori_tras_store_idTo===''?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_TradeName_To')}
                    </div>
                    <li className={applyFiltersPurchaseSignature.ori_tras_store_idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersPurchaseSignature.ori_tras_store_idTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.ori_tras_store_idTo}
                    </li> 

                    <div className={(applyFiltersPurchaseSignature.paymentFromEN==='' && applyFiltersPurchaseSignature.paymentFromES==='')?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_payment_From')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { i18n.language==="en"?applyFiltersPurchaseSignature.paymentFromEN==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.paymentFromEN:applyFiltersPurchaseSignature.paymentFromES==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.paymentFromES}
                    </li>
                    <div className={(applyFiltersPurchaseSignature.paymentToEN==='' && applyFiltersPurchaseSignature.paymentToES==='')?"sidebar-heading":"sidebar-heading text-white"}>
                        {t('Text_Filter_payment_To')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { i18n.language==="en"?applyFiltersPurchaseSignature.paymentToEN==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.paymentToEN:applyFiltersPurchaseSignature.paymentToES==='' ? t('Text_Filter_Not_Apply'): applyFiltersPurchaseSignature.paymentToES}
                    </li>               

                    <li className="sidebar-heading text-warning">
                    &nbsp;
                    </li>
                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Heading */}
                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings"))&&
                        <li className="nav-item">
                            <Link className="nav-link collapsed"to={"/purchasesignature"} data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                    <Link className="collapse-item" to={"/purchasesignature"} onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                </div>
                            </div>
                        </li>
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to="/home" onClick={closeModalPurchaseSignature} >
                            <LogoutIcon/>
                            <span>{t('Button_Back')}</span>
                        </Link>
                    </li>
 
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>   
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}

                {/* Show Modals */}  
                <PurchaseSignatureDeliveryFilter 
                    applyFiltersPurchaseSignature={applyFiltersPurchaseSignature}
                    setApplyFiltersPurchaseSignature={setApplyFiltersPurchaseSignature}
                    showModalPurchasesSignatureSettings={showModalPurchasesSignatureSettings}  
                    setShowModalPurchasesSignatureSettings={setShowModalPurchasesSignatureSettings} 
                />    
                </div>
                {/* End of Main Content */}

                {/* Footer */}
                <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}
         
      </div>}
      {/* End of Body */}

    </>
  )
};

export default HomePurchaseSignature;
