export const helpHttp = () => {
  
  const customFetch = async (ENDPOINT, url, token, options = {}) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 80000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}`, {
        ...options,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
          ...options.headers,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
  
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }
      return Promise.reject(err);
    }
  };
    
  const customFetch2 = async (ENDPOINT, url, token, options = {}) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}${options}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
          ...options.headers,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
  
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }
      return Promise.reject(err);
    }
  };
  
  const customFetch3 = async (ENDPOINT, url, token, offset, limit, applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 80000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${offset}/${limit}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(applyFilters),
        signal
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }
      return Promise.reject(err);
    }
  };

  const customFetch4 = async (ENDPOINT, url, token, param, offset, limit, applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 80000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param}/${offset}/${limit}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(applyFilters),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
  
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
  
      return Promise.reject(err);
    }
  };  

  const customFetch5 = async (ENDPOINT, url, token, offset, limit) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 80000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${offset}/${limit}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };
  
  const customFetch6 = async (ENDPOINT, url, token, applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 80000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(applyFilters),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };
    
  const customFetch7 = async (ENDPOINT, url, token, param1, param2, param3) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 80000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param1}/${param2}/${param3}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };
   
  const customFetch8 = async (ENDPOINT, url, token, param1, param2) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 80000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param1}/${param2}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };

  const customFetch9 = async (ENDPOINT, url, token, param1, param2, param3, param4) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 80000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param1}/${param2}/${param3}/${param4}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status,
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };
    
  const customPostFetch = async (ENDPOINT, url, token, requestBody) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(requestBody),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };
 
  const customPostFetch2 = async (ENDPOINT, url, token, param, requestBody) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(requestBody),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };
    
  const customPostFetch3 = async (ENDPOINT, url, token, formData) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        body: formData,
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };

  const customPostFetch4 = async (ENDPOINT, url, token, param1, param2, param3,  applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param1}/${param2}/${param3}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(applyFilters),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };

  const customPostFetch5 = async (ENDPOINT, url, token, param) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param}`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };

  const customPostFetch6 = async (ENDPOINT, url, token, offset, limit, param1, param2, param3,applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${offset}/${limit}/${param1}/${param2}/${param3}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(applyFilters),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };

  const customPostFetch7 = async (ENDPOINT, url, token, offset, limit, param1, param2, applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${offset}/${limit}/${param1}/${param2}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(applyFilters),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };

  const customPostFetch8 = async (ENDPOINT, url, token, offset, limit, param1, param2, param3,param4,applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${offset}/${limit}/${param1}/${param2}/${param3}/${param4}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(applyFilters),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };
  
  const customPostFetch9 = async (ENDPOINT, url, token, param1, param2, param3, param4, applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param1}/${param2}/${param3}/${param4}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(applyFilters),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };

  const customPostFetch10 = async (ENDPOINT, url, token, offset, limit, param1, param2, param3, param4, param5,applyFilters) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${offset}/${limit}/${param1}/${param2}/${param3}/${param4}/${param5}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(applyFilters),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };

  const customPostExcel = async (ENDPOINTWEIGHT, url, fileexceltoload) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINTWEIGHT}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(fileexceltoload), // Convierte el cuerpo a JSON
        signal,
      });
      
      clearTimeout(id); // Limpiar el timeout
  
      // Comprobar si la respuesta es exitosa
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse); // Lanzar el error con los detalles
      }  
      return await response.json(); // Devolver la respuesta parseada como JSON
    } catch (err) {
      clearTimeout(id); // Asegurar la limpieza del timeout en caso de error
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err); // Devolver la promesa rechazada con el error
    }
  };  
  

  const customPostTickets = async (ENDPOINTPRINTER, url, respondeData) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINTPRINTER}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(respondeData),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }  
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      return Promise.reject(err);
    }
  };

  const customPostPrinter = async (ENDPOINTWEIGHT, url, configPrinter) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINTWEIGHT}/${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(configPrinter),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }  
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };

  const customPutFetch = async (ENDPOINT, url, token, requestBody) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(requestBody),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };
   
  const customPutFetch2 = async (ENDPOINT, url, token, param, requestBody) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(requestBody),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };

  const customPutFetch3 = async (ENDPOINT, url, token, param1, param2, requestBody) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 100000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param1}/${param2}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(requestBody),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };

  const customPutFetch4 = async (ENDPOINT, url, token, param1, param2) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param1}/${param2}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      }  
      return Promise.reject(err);
    }
  };
  
  const customDelFetch = async (ENDPOINT, url, token, requestBody) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(requestBody),
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
      return Promise.reject(err);
    }
  };
  
  const customDel2Fetch = async (ENDPOINT, url, token, param) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const id = setTimeout(() => abortController.abort(), 40000);
    try {
      const response = await fetch(`${ENDPOINT}/${url}/${param}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token,
        },
        signal,
      });
      clearTimeout(id);
      if (!response.ok) {
        const errorResponse = {
          err: true,
          status: response.status || "00",
          statusText: response.statusText || "Text_IncorrectData",
        };
        return Promise.reject(errorResponse);
      }
      return await response.json();
    } catch (err) {
      clearTimeout(id);
      if (err.name === 'AbortError') {
        console.error('Fetch request was successfully aborted');
      } 
  
      return Promise.reject(err);
    }
  };
    

  const get = (ENDPOINT,url, token, options = {}) => customFetch(ENDPOINT,url, token, options);

  const get2 = (ENDPOINT,url, token, options = {}) => customFetch2(ENDPOINT,url, token, options);

  const get3 = (ENDPOINT,url, token, offset,limit, applyFilters = {}) => customFetch3(ENDPOINT,url, token, offset,limit, applyFilters);

  const get4 = (ENDPOINT,url, token, param, offset,limit, applyFilters = {}) => customFetch4(ENDPOINT,url, token, param, offset,limit, applyFilters);

  const get5 = (ENDPOINT,url, token, offset,limit = {}) => customFetch5(ENDPOINT,url, token, offset,limit);

  const get6 = (ENDPOINT,url, token, applyFilters = {}) => customFetch6(ENDPOINT,url, token, applyFilters);

  const get7 = (ENDPOINT,url, token,  param1, param2,param3) => customFetch7(ENDPOINT,url, token,  param1, param2,param3);

  const get8 = (ENDPOINT,url, token,  param1, param2) => customFetch8(ENDPOINT,url, token,  param1, param2);

  const get9 = (ENDPOINT,url, token,  param1, param2,param3,param4) => customFetch9(ENDPOINT,url, token,  param1, param2,param3,param4);

  const post = (ENDPOINT,url, token, options = {}) => customPostFetch(ENDPOINT,url, token, options);

  const post2 = (ENDPOINT,url, token, param, options = {}) => customPostFetch2(ENDPOINT,url, token, param, options);

  const post3 = (ENDPOINT,url, token, formData = {}) => customPostFetch3(ENDPOINT,url, token, formData);

  const post4 = (ENDPOINT,url, token, param1, param2, param3, applyFilters = {}) => customPostFetch4(ENDPOINT,url, token, param1, param2, param3, applyFilters);
 
  const post5 = (ENDPOINT,url, token, param = {}) => customPostFetch5(ENDPOINT,url, token, param);
 
  const post6 = (ENDPOINT,url, token, offset, limit, param1, param2, param3,applyFilters = {}) => customPostFetch6(ENDPOINT,url, token, offset, limit, param1, param2, param3,applyFilters);
  
  const post7 = (ENDPOINT,url, token, offset, limit, param1, param2, applyFilters = {}) => customPostFetch7(ENDPOINT,url, token, offset, limit, param1, param2,applyFilters);
  
  const post8 = (ENDPOINT,url, token, offset, limit, param1, param2, param3, param4,applyFilters = {}) => customPostFetch8(ENDPOINT,url, token, offset, limit, param1, param2, param3, param4,applyFilters);
  
  const post9 = (ENDPOINT,url, token, param1, param2, param3, param4,applyFilters = {}) => customPostFetch9(ENDPOINT,url, token, param1, param2, param3, param4,applyFilters);
  
  const post10 = (ENDPOINT,url, token, offset, limit, param1, param2, param3, param4, param5,applyFilters = {}) => customPostFetch10(ENDPOINT,url, token, offset, limit, param1, param2, param3, param4, param5,applyFilters);
  
  const sendTickets = (ENDPOINTPRINTER,url, respondeData = {}) => customPostTickets(ENDPOINTPRINTER,url, respondeData);
  
  const postWeigt = (ENDPOINTWEIGHT,url, configPrinter = {}) => customPostPrinter(ENDPOINTWEIGHT,url, configPrinter);
  
  const postFileExcel = (ENDPOINTWEIGHT,url, fileexceltoload = {}) => customPostExcel(ENDPOINTWEIGHT,url, fileexceltoload);
  
  const put = (ENDPOINT,url, token, options = {})=> customPutFetch(ENDPOINT,url, token, options);

  const put2 = (ENDPOINT,url, token, param, options = {}) => customPutFetch2(ENDPOINT,url, token, param, options);

  const put3 = (ENDPOINT,url, token, param1, param2, options = {}) => customPutFetch3(ENDPOINT,url, token, param1, param2, options);

  const put4 = (ENDPOINT,url, token, param1, param2 = {}) => customPutFetch4(ENDPOINT,url, token, param1, param2);
  
  const del = (ENDPOINT,url, token, options = {}) => customDelFetch(ENDPOINT,url, token,options);

  const del2 = (ENDPOINT,url, token, param = {}) => customDel2Fetch(ENDPOINT,url, token,param);

  return {
    get,
    get2,
    get3,
    get4,
    get5,
    get6,    
    get7,
    get8,
    get9,
    post,
    post2,
    post3,
    post4,
    post5,
    post6,
    post7,
    post8,
    post9,
    post10,
    sendTickets,
    postWeigt,
    postFileExcel,
    put,
    put2,
    put3,
    put4,
    del,
    del2
  };
};
