import { combineReducers } from '@reduxjs/toolkit';
import { loginReducer } from "./LoginReducer";
import { errorReducer } from "./ErrorReducer";
import { variablesReducer } from "./VariablesReducer";
import { formatDIReducer } from "./FormatDIReducer"; 
import filtersPurchaseDeliveryReducer from './filtersPurchaseDeliverySlice'; 
import filtersPurchaseSignatureReducer from './filtersPurchaseSignatureSlice'; 
import filtersTicketsReducer from './filtersTicketsSlice.jsx'; 
import filtersGetVehiclesReducer from './filtersVehiclesSlice.jsx'; 

import paginationPurchaseDeliveryReducer from './paginationPurchaseDeliverySlice';
import paginationSignatureReducer from './paginationPurchaseSignatureSlice';
import paginationTicketsReducer from './paginationTicketsSlice';
import paginationVehiclesReducer from './paginationVehiclesSlice';

import loadingReducer from './loadingSlice.jsx';
import userDefaultReducer from './userDefaultSlice.jsx';

const rootReducer = combineReducers({
  loginUser: loginReducer,
  loadError: errorReducer,
  variablesUser: variablesReducer,
  formatDIUser:formatDIReducer, 
  filtersPurchaseDelivery: filtersPurchaseDeliveryReducer,
  filtersPurchaseSignature: filtersPurchaseSignatureReducer,
  filtersTickets: filtersTicketsReducer,
  filtersGetVehicles: filtersGetVehiclesReducer,
  
  pagination: paginationPurchaseDeliveryReducer,
  paginationSignature: paginationSignatureReducer,
  paginationTickets: paginationTicketsReducer,
  paginationVehicles: paginationVehiclesReducer,

  loading: loadingReducer,
  userDefault: userDefaultReducer
  
});

export default rootReducer;
