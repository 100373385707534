import { createSlice } from '@reduxjs/toolkit';

const userDefaultSlice = createSlice({
  name: 'userdefault',
  initialState: {
    isUserdefault: 1, // Valor predeterminado
  },
  reducers: {
    setIsUserdefault: (state, action) => {
      state.isUserdefault = action.payload; // Corrige la propiedad modificada
    },
  },
});

export const { setIsUserdefault } = userDefaultSlice.actions;
export default userDefaultSlice.reducer;
