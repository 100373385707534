import React, {useState, useEffect} from 'react'
import {useTranslation} from "react-i18next"
import Box from '@mui/material/Box'; 
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';  
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import useTheme from '@mui/material/styles/useTheme';  
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import visuallyHidden from '@mui/utils/visuallyHidden'; 
import PropTypes from 'prop-types';

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t, headCellsProvince,showEditButtonProvince,showDeleteButtonProvince } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsProvince.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        {showEditButtonProvince&&
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 14}} >  
            {t('Button_Edit')}
          </Typography>
        </TableCell>}
         {showDeleteButtonProvince&&
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 14}} >  
            {t('Button_Delete')}
          </Typography>
        </TableCell>}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegProvince, numTotRegProvinceByCountry, numTotRegFilter, textOf } = props;
  return (
    <Toolbar>
      <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
        {textHead}
      </Typography>
      <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
        {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} ( {(numTotRegProvinceByCountry).toLocaleString('de-DE')} / {(numTotRegProvince).toLocaleString('de-DE')} )
      </Typography>
    </Toolbar>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataGeneralSettings']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingProvince =(props)=>{
  const { dataProvince, headCellsProvince,numTotRegFilter,titleTable,setOpenCreateDataProvince,setOpenDeleteRowProvince,setOpenEditRowProvince,setRowEditProvince,showEditButtonProvince,showDeleteButtonProvince,showVisibilityButton,setOpenPotalCode,numTotRegProvince,numTotRegProvinceByCountry,activeOffset,rangeOffset,currencyOffset,setCurrencyOffset,minvalue} = props;
  const {t} = useTranslation(['listDataGeneralSettings']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataProvince.length) : 0);
  const [countData,setCountData] = useState(dataProvince.length);
  
  useEffect(
    () => {
      if (dataProvince.length!==countData || dataProvince.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataProvince.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataProvince.length) : 0);  
    },
    [dataProvince,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffset(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRow = (row) => {
    setRowEditProvince(row);
    setOpenDeleteRowProvince(true);
  };

  const openEditRow = (row) => {
    setRowEditProvince(row);
    setOpenCreateDataProvince(true);
    setOpenEditRowProvince(true);
  };

  const openPostalCode = (row) => {
    setRowEditProvince(row);
    setOpenPotalCode(true);
  };
  const listItems= (
  stableSort(dataProvince, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        <>
        <TableCell align="left">
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {row.id}
            </Typography>  
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 14}} >  
              {row.name}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Checkbox checked={row.enabled} disabled/>
          </TableCell>
          { (showVisibilityButton  && row.id!=="" && row.id!==null ) ?
            <TableCell align="center">
              <IconButton  size="large" style={{color:"#4e73df"}} onClick={()=>openPostalCode(row)}>
                <VisibilityIcon />
                <Typography style={{color:"#4e73df", fontSize: 14}} >  
                  &nbsp;({(row.numTotPostalCode).toLocaleString('de-DE')})
                </Typography>
              </IconButton>
              
            </TableCell>:
            <TableCell align="center">
            <IconButton  size="large" style={{color:"#CCC0C0"}} disabled>
              <VisibilityOffIcon />
                <Typography style={{color:"#CCC0C0", fontSize: 14}} >  
                  &nbsp;({(row.numTotPostalCode).toLocaleString('de-DE')})
                </Typography>
            </IconButton>
          </TableCell>}
        </>
        {(showEditButtonProvince  && row.id!=="" && row.id!==null )&&
        <TableCell align="center">
          <IconButton  size="large" style={{color:"#4e73df", fontSize: 14}} onClick={()=>openEditRow(row)}>
            <EditIcon/>
          </IconButton>
        </TableCell>}
        {(showDeleteButtonProvince  && row.id!=="" && row.id!==null )&&
        <TableCell align="center">
          <IconButton  size="large"  style={{ color:row.id>minvalue?"#e74a3b":"#858796", fontSize: 14}} onClick={row.id>minvalue?()=>openModalDeleteRow(row):() => {}}> 
            <DeleteIcon/>
          </IconButton>
        </TableCell>}
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
      <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegProvince={numTotRegProvince} numTotRegProvinceByCountry={numTotRegProvinceByCountry} numTotRegFilter={numTotRegFilter} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} headCellsProvince={headCellsProvince} showEditButtonProvince={showEditButtonProvince} showDeleteButtonProvince={showDeleteButtonProvince}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffset && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffset"
                            name="currencyOffset"
                            value={currencyOffset}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffset
                            }}>
                            {rangeOffset.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={10}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingProvince.propTypes = {
  dataProvince: PropTypes.array.isRequired,
  headCellsProvince: PropTypes.array.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  setOpenCreateDataProvince: PropTypes.func.isRequired,
  setOpenDeleteRowProvince: PropTypes.func.isRequired,
  setOpenEditRowProvince: PropTypes.func.isRequired,   
  setRowEditProvince: PropTypes.func.isRequired,  
  showEditButtonProvince: PropTypes.bool.isRequired,  
  showDeleteButtonProvince: PropTypes.bool.isRequired,  
  showVisibilityButton: PropTypes.bool.isRequired,
  setOpenPotalCode: PropTypes.func.isRequired,   
  numTotRegProvince: PropTypes.number.isRequired,
  numTotRegProvinceByCountry: PropTypes.number.isRequired,
  activeOffset: PropTypes.bool.isRequired,
  rangeOffset: PropTypes.array.isRequired,
  currencyOffset: PropTypes.number.isRequired,
  setCurrencyOffset: PropTypes.func.isRequired,
  minvalue: PropTypes.number.isRequired
};
  
export default TableSortingProvince;