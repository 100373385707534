import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import Box from '@mui/material/Box'; 
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField'; 
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading"; 
import PropTypes from 'prop-types';
import TableSortingContractEmployee from 'pages/contracts/editOrNewContract/SortingTablesContractEmpoyee'; 

const initialFiltersEmployee= {
  "idFrom":"",
  "idTo":"",
  "firstnameFrom":"",
  "firstnameTo":"",
  "lastname1From":"",
  "lastname1To":"",
  "lastname2From":"",
  "lastname2To":"",
  "enabled":true,
  "notenabled":true,
  "languageFromES":"",
  "languageToES":"",
  "languageFromEN":"",
  "languageToEN":"",
  "emailsFrom":"",
  "emailsTo":"",
  "phonesFrom":"",
  "phonesTo":"",
};
 
const initialStateEmployee = [{

  "id" : "",
  "firstname" : "",
  "lastname1" : "",
  "lastname2" : "",
  "fullname" : "", 
  "createdate":"",
  "enabled" : true,
  "birthdate" : null,
  "comments" : "",
  "useraccount_id":0,
  "useraccount_username": "",
  "useraccount_aliasuser": "",
  "useraccount_email": "",
  "documnettype_id":0,
  "documnettype_name": "",
  "numberdocument" : "",
  "numbersecuritysocial" : "",
  "language_id":0,
  "language_code": "",
  "language_namees": "",
  "language_nameen": "", 
  "phones" : [
    {
      "id":"",
      "phone":"",
      "defaultphone":""
    }
  ],
  "emails" : [
    {
      "id":"",
      "email":"",
      "defaultemail":""
    }
  ]  
}];
        

const ContractSearchEmployees = ({ showModalSearchEmployees, setShowModalSearchEmployees,setFormContract}) => {
  const {t,i18n} = useTranslation(['listDataContracts']);
  const state = useSelector((state) => state);
  const {token} = state.loginUser
  const {ENDPOINT,LIMITPAGESIZE} = state.variablesUser;
  const [dataEmployee, setDataEmployee] = useState(initialStateEmployee);
  const [filtersEmployee, setFiltersEmployee]= useState(initialFiltersEmployee);
  const [applyFiltersEmployee, setApplyFiltersEmployee]= useState(initialFiltersEmployee);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegEmployee,setNumTotRegEmployee]= useState(0);
  const [errorsFiltersEmployee, setErrorsFiltersEmployee] = useState({});
  const [inputTextEmployee, setInputTextEmployee] = useState("");
  const [openEditFilterEmployee,setOpenEditFilterEmployee]= useState(false);
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(false);  
  const [isLoadingErrorEmployee, setIsLoadingErrorEmployee] = useState(false);
  const [activeOffsetEmployee, setActiveOffsetEmployee]= useState(false);
  const [currencyOffsetEmployee, setCurrencyOffsetEmployee] = useState(0);
  const [rangeOffsetEmployee, setRangeOffsetEmployee] = useState([{}]); 
  const dispatch = useDispatch(); 
   
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && setShowModalSearchEmployees) {
        setDataEmployee(initialStateEmployee);
        setFiltersEmployee(initialFiltersEmployee);
        setApplyFiltersEmployee(initialFiltersEmployee);
        setNumTotRegFilter(0);
        setNumTotRegEmployee(0);
        setErrorsFiltersEmployee({});
        setInputTextEmployee("");
        setOpenEditFilterEmployee(false);
        setIsLoadingEmployee(false);
        setIsLoadingErrorEmployee(false);
        setActiveOffsetEmployee(false);
        setCurrencyOffsetEmployee(0);
        setRangeOffsetEmployee([{}]);
        setShowModalSearchEmployees(false);
      }
    },
    [ setDataEmployee,
      setFiltersEmployee,
      setApplyFiltersEmployee,
      setNumTotRegFilter,
      setNumTotRegEmployee,
      setErrorsFiltersEmployee,
      setInputTextEmployee,
      setOpenEditFilterEmployee,
      setIsLoadingEmployee,
      setIsLoadingErrorEmployee,
      setActiveOffsetEmployee,
      setCurrencyOffsetEmployee,
      setRangeOffsetEmployee,
      setShowModalSearchEmployees
    ]
  );

  const handleCloseDataEditEmployee = () => {
    setDataEmployee(initialStateEmployee);
    setFiltersEmployee(initialFiltersEmployee);
    setApplyFiltersEmployee(initialFiltersEmployee);
    setNumTotRegFilter(0);
    setNumTotRegEmployee(0);
    setErrorsFiltersEmployee({});
    setInputTextEmployee("");
    setOpenEditFilterEmployee(false);
    setIsLoadingEmployee(false);
    setIsLoadingErrorEmployee(false);
    setActiveOffsetEmployee(false);
    setCurrencyOffsetEmployee(0);
    setRangeOffsetEmployee([{}]);
    setShowModalSearchEmployees(false); 
	};

  const toggleDrawerSearchFormEmployees  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditEmployee();
    }
  };

  const openEditFiltersEmployee = () => {
    setFiltersEmployee(applyFiltersEmployee);
    setOpenEditFilterEmployee(!openEditFilterEmployee);
  };

  const handleSubmitFilterEditStore = () => {
    setErrorsFiltersEmployee(validateFormFilterEmployee());
    if (Object.keys(errorsFiltersEmployee).length === 0) {
      setApplyFiltersEmployee(filtersEmployee);
      setFiltersEmployee(initialFiltersEmployee);
      setErrorsFiltersEmployee({});
      setOpenEditFilterEmployee(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersStore = () => {
    setErrorsFiltersEmployee({});
    setFiltersEmployee(initialFiltersEmployee);
    setApplyFiltersEmployee(initialFiltersEmployee);
    setOpenEditFilterEmployee(!openEditFilterEmployee);
  };

  const handleCancelFiltersEmployee = () => {
    setErrorsFiltersEmployee({});
    setFiltersEmployee(initialFiltersEmployee);
    setOpenEditFilterEmployee(!openEditFilterEmployee);
  };

  const handleChangeEnabledFilter = (e) => {
    setFiltersEmployee(filtersEmployee => ({
      ...filtersEmployee,
      enabled: !filtersEmployee.enabled
    }));
  };

  const handleChangeNotEnabledFilter = (e) => {
    setFiltersEmployee(filtersEmployee => ({
      ...filtersEmployee,
      notenabled: !filtersEmployee.notenabled
    }));
  };

  const handleChangeFilterEditEmployee = (e) => {
    const { name, value } = e.target;
    setFiltersEmployee(filtersEmployee => ({
      ...filtersEmployee,
      [name]: value
    }));
  };

  const handleBlurFilterEditEmployee = (e) => {
    setErrorsFiltersEmployee(validateFormFilterEmployee());
    handleChangeFilterEditEmployee(e);
  };

  const toggleDrawerFilterEditEmployee = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditEmployee();
    }
  }; 

  const validateFormFilterEmployee = () => {

    let errorsFiltersEmployee = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersEmployee.idFrom!=="" & !regexNumber.test(filtersEmployee.idFrom)) {
      errorsFiltersEmployee.idFrom = 'Text_TextNoNumber';
    }
    if (filtersEmployee.idTo!=="" & !regexNumber.test(filtersEmployee.idTo)) {
      errorsFiltersEmployee.idTo = 'Text_TextNoNumber';
    }
    if (filtersEmployee.idFrom!=="" & !errorsFiltersEmployee.idFrom!==undefined){
      if (filtersEmployee.idTo!=="" & !errorsFiltersEmployee.idTo!==undefined){
        if (parseInt(filtersEmployee.idTo)<parseInt(filtersEmployee.idFrom)){
          errorsFiltersEmployee.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersEmployee.firstnameFrom)) {
      errorsFiltersEmployee.firstnameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.firstnameTo)) {
      errorsFiltersEmployee.firstnameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.lastname1From)) {
      errorsFiltersEmployee.lastname1From = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.lastname1To)) {
      errorsFiltersEmployee.lastname1To = 'Text_TextNoValid';
    } 

    if (regexInvalidCharacters.test(filtersEmployee.lastname2From)) {
      errorsFiltersEmployee.lastname2From = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.lastname2To)) {
      errorsFiltersEmployee.lastname2To = 'Text_TextNoValid';
    } 
  
    if (regexInvalidCharacters.test(filtersEmployee.emailsFrom)) {
      errorsFiltersEmployee.emailsFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.emailsTo)) {
      errorsFiltersEmployee.emailsTo = 'Text_TextNoValid';
    } 
  
    if (regexInvalidCharacters.test(filtersEmployee.phonesFrom)) {
      errorsFiltersEmployee.phonesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.phonesTo)) {
      errorsFiltersEmployee.phonesTo = 'Text_TextNoValid';
    } 
  
    if (filtersEmployee.firstnameTo!=="" & filtersEmployee.firstnameTo<filtersEmployee.firstnameFrom){
      errorsFiltersEmployee.firstnameTo = 'Text_TextGreater_firstname';
    }

    if (filtersEmployee.lastname1To!=="" & filtersEmployee.lastname1To<filtersEmployee.lastname1From){
      errorsFiltersEmployee.lastname1To = 'Text_TextGreater_lastname1';
    }

    if (filtersEmployee.lastname2To!=="" & filtersEmployee.lastname2To<filtersEmployee.lastname2From){
      errorsFiltersEmployee.lastname2To = 'Text_TextGreater_lastname2';
    }

    if (filtersEmployee.emailsTo!=="" & filtersEmployee.emailsTo<filtersEmployee.emailsFrom){
      errorsFiltersEmployee.emailsTo = 'Text_TextGreater_emails';
    }

    if (filtersEmployee.phonesTo!=="" & filtersEmployee.phonesTo<filtersEmployee.phonesFrom){
      errorsFiltersEmployee.phonesTo = 'Text_TextGreater_phones';
    }

    return errorsFiltersEmployee;
  }  

  const filteredDataEmployee = dataEmployee.filter((el) => {
    if (inputTextEmployee === '') {
        return el;
    }
    else {
        let dataTranslate=el.id+el.firstname+el.lastname1+el.lastname2+el.fullname+el.numberdocument+el.numbersecuritysocial
        +(el.emails!==undefined && el.emails!==null && el.emails.length>0?el.emails.map(current => {return (current.email )}):"")
        +(el.phones!==undefined && el.phones!==null && el.phones.length>0?el.phones.map(current => {return (current.phone )}):"");
        return dataTranslate.toLowerCase().includes(inputTextEmployee);
    }
  })

  let inputHandlerEmployee = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextEmployee(lowerCase);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    if (numTotRegEmployee > LIMITPAGESIZE) {
      setActiveOffsetEmployee(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegEmployee; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetEmployee(newRangeOffset);
    } else {
      setCurrencyOffsetEmployee(0);
      setActiveOffsetEmployee(false);
      setRangeOffsetEmployee([{}]);
    }
  }, [numTotRegEmployee, LIMITPAGESIZE]);

  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegEmployee = async () => {
      setIsLoadingEmployee(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `employee/getinformationtableemployee`, token);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataEmployee(initialStateEmployee);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setNumTotRegEmployee(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingEmployee(false);
      }
      if (isMounted) {
        setIsLoadingEmployee(false);
      } 
    };

    const fetchnumTotRegFilter = async () => {
      setIsLoadingEmployee(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `employee/getcountfilteremployee`, token, applyFiltersEmployee);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataEmployee(initialStateEmployee);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingEmployee(false);
      }
      if (isMounted) {
        setIsLoadingEmployee(false);
      } 
    };

    const fetchDataEmployee = async () => {
      setIsLoadingEmployee(true);
      try {
        
        const getData = await helpHttp().get3(ENDPOINT, `employee/employees`, token, currencyOffsetEmployee, LIMITPAGESIZE, applyFiltersEmployee);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataEmployee(initialStateEmployee);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setDataEmployee(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingEmployee(false);
      }
      if (isMounted) {
        setIsLoadingEmployee(false);
      }
      
    };

    if (showModalSearchEmployees){
      fetchnumTotRegEmployee();
      fetchnumTotRegFilter();
      fetchDataEmployee();
      return () => { isMounted = false; };
    }
  }, [ENDPOINT,t,token,dispatch,i18n.language,showModalSearchEmployees,LIMITPAGESIZE,applyFiltersEmployee,currencyOffsetEmployee,setIsLoadingEmployee]);


  return(
    <>
      {showModalSearchEmployees && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchFormEmployees(false)}
            onOpen={toggleDrawerSearchFormEmployees(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'1300px', width: '900px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  {isLoadingErrorEmployee?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingEmployee?<CircularProgress color={"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_CONTRACT_Employees_Search')}</h1>
                    <div>
                      <button type="button" className="buttonCreate" onClick={handleCloseDataEditEmployee}>{t('Button_Exit')}</button><> </>
                    </div> 
                </div>  
                <br/>
                <div className="searchStyle">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={inputTextEmployee}
                    placeholder={t('Text_Employee_Search')} 
                    onChange={inputHandlerEmployee}
                    fullWidth
                    label={t('Text_Employee_Search')}>
                  </TextField>
                </div>
                <br/>
                <div id="myDIV">
                  <h1 className="h6NewStyle mb-2">
                    {t('Text_Filter')}&nbsp;
                    <button type="button" className="buttonFilter" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersEmployee.idFrom!=="" || applyFiltersEmployee.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersEmployee.firstnameFrom!=="" || applyFiltersEmployee.firstnameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_CONTRACT_EMPLOYEEE_firstname')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersEmployee.lastname1From!=="" || applyFiltersEmployee.lastname1To!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_CONTRACT_EMPLOYEEE_lastname1')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersEmployee.lastname2From!=="" || applyFiltersEmployee.lastname2To!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_CONTRACT_EMPLOYEEE_lastname2')}</button><> </> 
                    <button type="button" className="buttonFilter" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersEmployee.enabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_Employee_Habilty')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersEmployee.notenabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_Employee_Not_Habilty')}</button><> </>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(
                          applyFiltersEmployee.emailsFrom!==""
                          || applyFiltersEmployee.emailsTo!==""
                          || applyFiltersEmployee.phonesFrom!==""
                          || applyFiltersEmployee.phonesTo!==""
                          )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Others_Filters')}</button><> </> 
                          
                  </h1>
                </div>
                <br/>
                {!isLoadingEmployee ?
                  <>
                    <div className="card shadow mb-4">    
                      <div className="card-body">
                        <TableSortingContractEmployee
                          dataEmployee={filteredDataEmployee}
                          numTotRegFilter={ numTotRegFilter}
                          titleTable={t('Text_Employees_Table')}
                          numTotRegEmployee={numTotRegEmployee}
                          activeOffsetEmployee={activeOffsetEmployee}
                          rangeOffsetEmployee={rangeOffsetEmployee}
                          currencyOffsetEmployee={currencyOffsetEmployee}
                          setCurrencyOffsetEmployee={setCurrencyOffsetEmployee} 
                          setFormContract={setFormContract}
                          handleCloseDataEditEmployee={handleCloseDataEditEmployee}
                        >
                        </TableSortingContractEmployee>
                      </div>             
                    </div>
                    {openEditFilterEmployee &&
                      <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEditEmployee(false)}
                        onOpen={toggleDrawerFilterEditEmployee(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                            <br/>
                              <div id="myDIV">
                                <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                              </div>
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <form className="newfilterformstore" onSubmit={handleCancelFiltersEmployee}>  
                                <div className="form-floating mb-3 px-4 primary">
                                  <div className="form-group">
                                    <TextField
                                      id="idFrom" 
                                      name="idFrom"
                                      value={filtersEmployee.idFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 15 }}
                                      label={errorsFiltersEmployee.idFrom!=null?t(errorsFiltersEmployee.idFrom):t('Text_Filter_Code_From')}
                                      placeholder={t('PlaceHolder_Filter_Code_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditEmployee}
                                      onChange={handleChangeFilterEditEmployee}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="idTo" 
                                      name="idTo"
                                      value={filtersEmployee.idTo}
                                      inputProps={{ style: {width: 400}, maxLength: 15 }}
                                      label={errorsFiltersEmployee.idTo!=null?t(errorsFiltersEmployee.idTo):t('Text_Filter_Code_To')}
                                      placeholder={t('PlaceHolder_Filter_Code_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditEmployee}
                                      onChange={handleChangeFilterEditEmployee}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="firstnameFrom" 
                                      name="firstnameFrom"
                                      value={filtersEmployee.firstnameFrom}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersEmployee.firstnameFrom!=null?t(errorsFiltersEmployee.firstnameFrom):t('Text_Filter_firstname_From')}
                                      placeholder={t('PlaceHolder_Filter_firstname_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditEmployee}
                                      onChange={handleChangeFilterEditEmployee}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="firstnameTo" 
                                      name="firstnameTo"
                                      value={filtersEmployee.firstnameTo}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersEmployee.firstnameTo!=null?t(errorsFiltersEmployee.firstnameTo):t('Text_Filter_firstname_To')}
                                      placeholder={t('PlaceHolder_Filter_firstname_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditEmployee}
                                      onChange={handleChangeFilterEditEmployee}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="lastname1From" 
                                      name="lastname1From"
                                      value={filtersEmployee.lastname1From}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersEmployee.lastname1From!=null?t(errorsFiltersEmployee.lastname1From):t('Text_Filter_lastname1_From')}
                                      placeholder={t('PlaceHolder_Filter_lastname1_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditEmployee}
                                      onChange={handleChangeFilterEditEmployee}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="lastname1To" 
                                      name="lastname1To"
                                      value={filtersEmployee.lastname1To}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersEmployee.lastname1To!=null?t(errorsFiltersEmployee.lastname1To):t('Text_Filter_lastname1_To')}
                                      placeholder={t('PlaceHolder_Filter_lastname1_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditEmployee}
                                      onChange={handleChangeFilterEditEmployee}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="lastname2From" 
                                      name="lastname2From"
                                      value={filtersEmployee.lastname2From}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersEmployee.lastname2From!=null?t(errorsFiltersEmployee.lastname2From):t('Text_Filter_lastname2_From')}
                                      placeholder={t('PlaceHolder_Filter_lastname2_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditEmployee}
                                      onChange={handleChangeFilterEditEmployee}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TextField
                                      id="lastname2To" 
                                      name="lastname2To"
                                      value={filtersEmployee.lastname2To}
                                      inputProps={{ style: {width: 400}, maxLength: 100 }}
                                      label={errorsFiltersEmployee.lastname2To!=null?t(errorsFiltersEmployee.lastname2To):t('Text_Filter_lastname2_To')}
                                      placeholder={t('PlaceHolder_Filter_lastname2_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditEmployee}
                                      onChange={handleChangeFilterEditEmployee}/>
                                    </div>
                                    <div className="form-group">
                                      <TextField
                                        id="emailsFrom" 
                                        name="emailsFrom"
                                        value={filtersEmployee.emailsFrom}
                                        inputProps={{ style: {width: 400}, maxLength: 100 }}
                                        label={errorsFiltersEmployee.emailsFrom!=null?t(errorsFiltersEmployee.emailsFrom):t('Text_Filter_emails_From')}
                                        placeholder={t('PlaceHolder_Filter_emails_From')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditEmployee}
                                        onChange={handleChangeFilterEditEmployee}/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <TextField
                                        id="emailsTo" 
                                        name="emailsTo"
                                        value={filtersEmployee.emailsTo}
                                        inputProps={{ style: {width: 400}, maxLength: 100 }}
                                        label={errorsFiltersEmployee.emailsTo!=null?t(errorsFiltersEmployee.emailsTo):t('Text_Filter_emails_To')}
                                        placeholder={t('PlaceHolder_Filter_emails_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditEmployee}
                                        onChange={handleChangeFilterEditEmployee}/>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                        id="phonesFrom" 
                                        name="phonesFrom"
                                        value={filtersEmployee.phonesFrom}
                                        inputProps={{ style: {width: 400}, maxLength: 30 }}
                                        label={errorsFiltersEmployee.phonesFrom!=null?t(errorsFiltersEmployee.phonesFrom):t('Text_Filter_phones_From')}
                                        placeholder={t('PlaceHolder_Filter_phones_From')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditEmployee}
                                        onChange={handleChangeFilterEditEmployee}/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <TextField
                                        id="phonesTo" 
                                        name="phonesTo"
                                        value={filtersEmployee.phonesTo}
                                        inputProps={{ style: {width: 400}, maxLength: 30 }}
                                        label={errorsFiltersEmployee.phonesTo!=null?t(errorsFiltersEmployee.phonesTo):t('Text_Filter_phones_To')}
                                        placeholder={t('PlaceHolder_Filter_phones_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditEmployee}
                                        onChange={handleChangeFilterEditEmployee}/>
                                        </div>
                                    {i18n.language==="en"?
                                    <>
                                      <div className="form-group">
                                        <Checkbox
                                          id="enabled" 
                                          name="enabled"
                                          checked={filtersEmployee.enabled}
                                          onChange={handleChangeEnabledFilter}
                                          variant="standard"/>
                                        &nbsp;&nbsp;    
                                        <label className="text-primary">{t('Text_Filter_Habilty')}</label>  
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Checkbox
                                          id="notenabled" 
                                          name="notenabled"
                                          checked={filtersEmployee.notenabled}
                                          onChange={handleChangeNotEnabledFilter}
                                          variant="standard"/>
                                        &nbsp;&nbsp;    
                                        <label className="text-primary">{t('Text_Filter_Not_Habilty')}</label>    
                                      </div>
                                    </>  
                                  :
                                    <>
                                      <div className="form-group">  
                                      <Checkbox
                                        id="enabled" 
                                        name="enabled"
                                        checked={filtersEmployee.enabled}
                                        onChange={handleChangeEnabledFilter}
                                        variant="standard"/>
                                      &nbsp;&nbsp;    
                                      <label className="text-primary">{t('Text_Filter_Habilty')}</label>  
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Checkbox
                                        id="notenabled" 
                                        name="notenabled"
                                        checked={filtersEmployee.notenabled}
                                        onChange={handleChangeNotEnabledFilter}
                                        variant="standard"/>
                                      &nbsp;&nbsp;    
                                      <label className="text-primary">{t('Text_Filter_Not_Habilty')}</label>    
                                    </div>
                                  </>
                                }
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditStore}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersStore}>{t('Button_Cancel_Filters')}</button>
                                </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  
                  {/* End Content Row */}
                  </>:
                    <Loading isLoadingData={isLoadingEmployee}/>
                }     
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

ContractSearchEmployees.propTypes = {
  showModalSearchEmployees: PropTypes.bool.isRequired,
  setShowModalSearchEmployees: PropTypes.func.isRequired, 
  setFormContract: PropTypes.func.isRequired
};

export default ContractSearchEmployees;
