import React, { Suspense, useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next" 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingSendEmail  from "pages/recievedemails/SortingTablesReceivedEmail";
import { Loading } from "components/utils/Loading";
import SendEmailEdit from "pages/recievedemails/RecivedEmailEdit";
import PropTypes from 'prop-types';

const initialFilters= {
"idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "dateEmailFrom":null,
  "dateEmailTo":null,
  "store_IdToFrom":"",
  "store_IdToTo":"", 
  "reasonFromES":"",
  "reasonToES":"",
  "reasonFromEN":"",
  "reasonToEN":"",
  "statusFromES":"",
  "statusToES":"",
  "statusFromEN":"",
  "statusToEN":"" 
};

const initialState = [{
  "id":"",
  "defaultStore_id":0, 
  "tradename": "",
  "defaultStore_email": "",
  "defaultStore_documentId": "",
  "useraccount_id":0,
  "username": "",
  "useraccount_email": "",
  "createdate": null,
  "store_IdTo": "",
  "store_NameTo": "",
  "store_EmailTo": "",
  "store_DocumentIdTo": "",
  "subjectEmail":"",
  "contentEmail":"",
  "reason_id":0,
  "reason_namees":"",
  "reason_nameen":"",
  "status_id":0,
  "status_namees":"",
  "status_nameen":"",
  "linesemails":[],
  "updatedFiles":[]
 }];

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:false,
  },
  {
    id: 'createdate',
    numeric: false,
    disablePadding: false,
    label: 'Text_SENDEMAIL_Date',
    canOrder:true,
  },
  {
    id: 'store_EmailTo',
    numeric: false,
    disablePadding: false,
    label: 'Text_SENDEMAIL_Email',
    canOrder:true,
  },
  {
    id: 'subjectEmail',
    numeric: false,
    disablePadding: false,
    label: 'Text_SENDEMAIL_SUBJECT',
    canOrder:true,
  },
  {
    id: 'status_name',
    numeric: false,
    disablePadding: false,
    label: 'Text_SENDEMAIL_STATUS',
    canOrder:true,
  },
  {
    id: 'reason_name',
    numeric: false,
    disablePadding: false,
    label: 'Text_SENDEMAIL_REASON',
    canOrder:true,
  }
];

const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": "",
  "name": ""
}];

const initialTypesToSelect = [{
  "id": "",
  "namees": "",
  "nameen": ""
}];

const ReceivedEmailList = ({setShowPrincipalMenu,showModalSendEmailsList, setShowModalSendEmailsList,setShowModalGeneralSettings}) => {
  const {t, i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);   
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const defaultStore_id = useSelector((state) => state.loginUser.defaultStore.id);  
  const [data, setData] = useState(initialState); 
  const [filters, setFilters]= useState(initialFilters);
  const [applyFilters, setApplyFilters]= useState(initialFilters);
  const [numTotReg,setNumTotReg]= useState(0);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [errorsFilters, setErrorsFilters] = useState({}); 
  const [inputText, setInputText] = useState("");
  const [openDeleteRow,setOpenDeleteRow]= useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const [openEditRow,setOpenEditRow]= useState(false);
  const [openEditFilter,setOpenEditFilter]= useState(false);
  const [rowEdit,setRowEdit]= useState(initialState[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);
  const [storesToSelect,setStoresToSelect]= useState(initialStoresToSelect);
  const [statusToSelect,setStatusToSelect]= useState(initialTypesToSelect);
  const [reasonToSelect,setReasonToSelect]= useState(initialTypesToSelect);
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalSendEmailsList) {
        setData(initialState); 
        setFilters(initialFilters);
        setApplyFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({}); 
        setInputText(""); 
        setOpenDeleteRow(false);
        setIdCodeDelete(DELETE_CODE);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        setOpenEditRow(false);
        setIsLoading(false);
        setIsLoadingError(false); 
        setActiveOffset(false);
        setCurrencyOffset(1);
        setRangeOffset([{}]);
        setStoresToSelect(initialStoresToSelect);
        setStatusToSelect(initialTypesToSelect);
        setReasonToSelect(initialTypesToSelect);
        setShowPrincipalMenu(true);
        setShowModalSendEmailsList(false);
        setShowModalGeneralSettings(false);
      }
    },
    [
      setData, 
      setFilters,
      setApplyFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters, 
      setInputText, 
      setOpenDeleteRow,
      setOpenEditFilter,
      setRowEdit,
      setIdCodeDelete,
      setIsLoading,
      setOpenEditRow,
      setIsLoadingError, 
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      setStoresToSelect,
      setStatusToSelect,
      setReasonToSelect,
      setShowPrincipalMenu,
      showModalSendEmailsList, 
      setShowModalSendEmailsList,
      setShowModalGeneralSettings,
      DELETE_CODE
    ]
  );

  const closeModalEndpointsSettings = () => {    
    setData(initialState); 
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setNumTotReg(0);
    setNumTotRegFilter(0);
    setErrorsFilters({}); 
    setInputText(""); 
    setOpenDeleteRow(false);
    setOpenEditRow(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenEditFilter(false);
    setRowEdit(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false); 
    setActiveOffset(false);
    setCurrencyOffset(1);
    setRangeOffset([{}]);
    setStoresToSelect(initialStoresToSelect);
    setStatusToSelect(initialTypesToSelect);
    setReasonToSelect(initialTypesToSelect);
    setShowPrincipalMenu(true);
    setShowModalSendEmailsList(false);
    setShowModalGeneralSettings(false);
  };
 
  const openEditFilters = () => {
    setFilters(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filters);
      setFilters(initialFilters); 
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };
 
  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const handleBlurSelectDateFrom = (e) => {
    handleChangeSelectDateFrom(e);
    setErrorsFilters(validateFormFilter()); 
  };

  const handleChangeSelectDateFrom = (event) => {
    setFilters(filters => ({
      ...filters,
      dateEmailFrom: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateTo = (e) => {
    handleChangeSelectDateTo(e);
    setErrorsFilters(validateFormFilter()); 
  };

  const handleChangeSelectDateTo = (event) => {
    setFilters(filters => ({
      ...filters,
      dateEmailTo: event!==null?new Date(event):null
    }));
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFilters({});
      setFilters(initialFilters);
      setOpenEditFilter(!openEditFilter);
    }
  };

  const validateFormFilter = () => {
    let errorsFilters = {};
    let regexNumber = /^[0-9]+$/;
    if (filters.idFrom!=="" & !regexNumber.test(filters.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filters.idTo!=="" & !regexNumber.test(filters.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
    if (filters.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filters.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filters.idTo)<parseInt(filters.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    } 
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.store_EmailTo || '');
      let result = dataTranslate.toLowerCase().includes(inputText.toLowerCase());
      return result;
    }
  })

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
 
  const handleCloseDataEdit = () => { 
    setOpenDeleteRow(false); 
    setRowEdit(rowEdit); 
    setIsLoading(false);
    setIsLoadingError(false); 
    toggleDrawerDataEdit(false);
	}; 
 
  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };
  
  const handleCancelDeleteRow = () => {
    setRowEdit(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEdit.id !== '') {
     
        const fetchData = async () => {
          setIsLoading(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `receivedemail/deletereceivedemail`, token, rowEdit);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
            if (isMounted && isLoading!==undefined) {
              setIsLoading(false);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoading(false);
          }
        }; 
        fetchData(); 
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
    handleCloseDataEdit();
    return () => { isMounted = false;};
  };
  
  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegFilter>LIMITPAGESIZE){
      setActiveOffset(true);
      setRangeOffset([
        { value: 0,
          label: " 1 - "+Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ]);
      for (let i = Number(LIMITPAGESIZE); i<numTotRegFilter; i=i+ Number(LIMITPAGESIZE)){
        setRangeOffset(
          current=>[
            ...current,
            ...[
              {
                value: i,
                label: " "+(i+1).toLocaleString('de-DE')+" - "+(i+Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
              }
            ]
          ]
        )
      }
    } else{
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  },[numTotRegFilter,LIMITPAGESIZE]);

  useEffect(() => {
    let isMounted = true; 
    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`receivedemail/storesorderbytradename`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataStatus = async () => {
      try {
        const getData = i18n.language==='es'?
          await helpHttp().get(ENDPOINT,`statustype/simplestatustypeBynamees`,token):
          await helpHttp().get(ENDPOINT,`statustype/simplestatustypeBynameen`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStatusToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    const fetchDataReason = async () => {
      try {
        const getData = i18n.language==='es'?
          await helpHttp().get(ENDPOINT,`reasontype/simplereasontypeBynamees`,token):
          await helpHttp().get(ENDPOINT,`reasontype/simplereasontypeBynameen`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setReasonToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    if (setShowModalSendEmailsList ) { 
      if (roles[0].listmenus.find((key)=>(key.name==='View_EMAILS_ALL_STORES'))){
        fetchDataStore();
      } 
      fetchDataStatus();
      fetchDataReason();
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,i18n,dispatch,roles,setShowModalSendEmailsList,defaultStore_id]);
   
  
  useEffect(() => {
    let ignore = false;  
    const fetchnumTotReg = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `receivedemail/getinformationtablereceivedemail`, token);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotReg(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().post2(ENDPOINT, `receivedemail/getcountfilterreceivedemail`, token, "-1",applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().post4(ENDPOINT, `receivedemail/getreceivedemails`, token, currencyOffset, LIMITPAGESIZE, "-1",applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setData(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (showModalSendEmailsList) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true; };
    }
  }, [ENDPOINT, t, token, dispatch, openDeleteRow, showModalSendEmailsList, LIMITPAGESIZE, applyFilters, currencyOffset]);
  return (
    <>
      {showModalSendEmailsList ? ( 
        <div className="container-fluid">
            {/* Search input */}
            <div id="myDIV">
              <h1 className="h1NewStyle mb-2">
              {isLoadingError?
              <i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>
              :isLoading?
              <CircularProgress color={"primary"}/>:
              <i className="fas fa-solid fa-envelope text-warning"></i>} {t('Text_ENDEMAIL_List')}</h1>
              {isLoading ?
                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                  <div>
                    <button type="button" className="buttonBack" onClick={closeModalEndpointsSettings}>{t('Button_Back')}</button>
                  </div>
              }
            </div>  
          <br/>
          {!isLoading ?
          <>
            <div className="searchStyle">
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={inputText}
                placeholder={t('Text_SENDEMAIL_Search')}
                onChange={inputHandler}
                fullWidth
                label={t('Text_SENDEMAIL_Search')}>
              </TextField>
            </div>
            <br/>
            <div id="myDIV">
              <h1 className="h6NewStyle mb-2">
              {t('Text_Filter')}&nbsp;
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                  {roles[0].listmenus.find((key)=>(key.name==='View_EMAILS_ALL_STORES'))&&
                    <>
                      <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.defaultStoreIdFrom!=="" || applyFilters.defaultStoreIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_ENDEMAIL_Store')}</button><> </>
                    </>
                  }
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.dateEmailFrom!==null || applyFilters.dateEmailTo!==null)?"#32CD32":"#FFFFFF"}}></i> {t('Text_SENDEMAIL_Date')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.store_IdToFrom!=="" || applyFilters.store_IdToTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_SENDEMAIL_Email')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.statusFromES!=="" || applyFilters.statusToES!=="" || applyFilters.statusFromEN!=="" || applyFilters.statusToEN!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_SENDEMAIL_STATUS')}</button><> </> 
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.reasonFromES!=="" || applyFilters.reasonToES!=="" || applyFilters.reasonFromEN!=="" || applyFilters.reasonToEN!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_SENDEMAIL_REASON')}</button><> </> 
                </h1>
            </div>
            <br/>
            <div className="card shadow mb-4">    
              <div className="card-body">
                <TableSortingSendEmail
                  data={filteredData}
                  headCells={headCells}
                  numTotRegFilter={numTotRegFilter}
                  titleTable={t('Text_ENDEMAIL_Table')}
                  setOpenDeleteRow={setOpenDeleteRow}
                  setOpenEditRow={setOpenEditRow}
                  setRowEdit={setRowEdit}
                  showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='Handle_Delete_EMAILS'))?true:false}
                  numTotReg={numTotReg}
                  activeOffset={activeOffset}
                  rangeOffset={rangeOffset}
                  currencyOffset={currencyOffset}
                  setCurrencyOffset={setCurrencyOffset}
                >
                </TableSortingSendEmail>
              </div>             
            </div>
            {openEditFilter &&
              <div>
                <SwipeableDrawer
                  anchor='right'
                  open={true}
                  onClose={toggleDrawerFilterEdit(false)}
                  onOpen={toggleDrawerFilterEdit(true)}>
                  <Container >
                    <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                      <br/>
                      <div id="myDIV">
                        <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                      </div>
                      <hr className="sidebar-divider d-none d-md-block"/>
                      <form className="createfilter" onSubmit={handleCancelFilters}>  
                        <div className="form-floating mb-3 px-4 primary">
                          <div className="form-group">
                            <TextField
                              id="idFrom" 
                              name="idFrom"
                              value={filters.idFrom}
                              inputProps={{ style: {width: 400}, maxLength: 15 }}
                              label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('Text_Filter_Code_From')}
                              placeholder={t('PlaceHolder_Filter_Code_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id="idTo" 
                              name="idTo"
                              value={filters.idTo}
                              inputProps={{ style: {width: 400}, maxLength: 15 }}
                              label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('Text_Filter_Code_To')}
                              placeholder={t('PlaceHolder_Filter_Code_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/
                            >
                          </div>
                          {roles[0].listmenus.find((key)=>(key.name==='View_EMAILS_ALL_STORES'))   &&
                            <>
                              <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                <div className="form-group">
                                  <TextField
                                    id="defaultStoreIdFrom"
                                    name="defaultStoreIdFrom"
                                    value={filters.defaultStoreIdFrom}
                                    inputProps={{ style: {width: 382}, maxLength: 100 }}
                                    label={errorsFilters.defaultStoreIdFrom!=null?t(errorsFilters.defaultStoreIdFrom):t('Text_Filter_Stores_From')}
                                    placeholder={t('PlaceHolder_Filter_Store_From')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {storesToSelect.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.documentid+" - "+option.tradename}
                                      </option>
                                    ))}
                                  </TextField>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                  <TextField
                                    id="defaultStoreIdTo"
                                    name="defaultStoreIdTo"
                                    value={filters.defaultStoreIdTo}  
                                    inputProps={{ style: {width: 382}, maxLength: 100 }}
                                    label={errorsFilters.defaultStoreIdTo!=null?t(errorsFilters.defaultStoreIdTo):t('Text_Filter_Stores_To')}
                                    placeholder={t('PlaceHolder_Filter_Store_to')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                      <option key={0} value=""/>
                                    {storesToSelect.filter(item=>(item.id>=filters.defaultStoreIdFrom)).map(option => (
                                      <option key={option.id} value={option.id}>
                                          {option.documentid+" - "+option.tradename}
                                      </option>
                                    ))}
                                  </TextField>
                                </div>
                              </Suspense>
                            </>
                          }   
                          <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                              <DesktopDatePicker
                                id="dateEmailFrom" 
                                name="dateEmailFrom"
                                value={ filters.dateEmailFrom!== null ? new Date(filters.dateEmailFrom): null}
                                format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                label={errorsFilters.dateEmailFrom!=null?t(errorsFilters.dateEmailFrom):t('Text_Filter_Date_From')}
                                onBlur={handleBlurSelectDateFrom}
                                onChange={handleChangeSelectDateFrom}
                                slotProps={{
                                  textField: {
                                    placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                    sx: {
                                      color: '#1565c0',
                                      maxLength: '100',
                                      width: 430
                                    }
                                  },
                                }}
                              />
                            </LocalizationProvider>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                           <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                              <DesktopDatePicker
                                id="dateEmailTo" 
                                name="dateEmailTo"
                                minDate={filters.dateEmailFrom!== null ? new Date(filters.dateEmailFrom): null}
                                value={ filters.dateEmailTo!== null ? new Date(filters.dateEmailTo): null}
                                format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                label={errorsFilters.dateEmailTo!=null?t(errorsFilters.dateEmailTo):t('Text_Filter_Date_To')}
                                onBlur={handleBlurSelectDateTo}
                                onChange={handleChangeSelectDateTo}
                                slotProps={{
                                  textField: {
                                    placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                    sx: {
                                      color: '#1565c0',
                                      maxLength: '100',
                                      width: 430
                                    
                                    }
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </div>     
                          <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                            <div className="form-group">
                              <TextField
                                id={i18n.language==="es"?"statusFromES":"statusFromEN"}
                                name={i18n.language==="es"?"statusFromES":"statusFromEN"}
                                value={i18n.language==="es"?filters.statusFromES:filters.statusFromEN}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFilters.statusFromES!=null?t(errorsFilters.statusFromES):t('Text_Filter_Status_From'):errorsFilters.statusFromEN!=null?t(errorsFilters.statusFromEN):t('Text_Filter_Status_From')}
                                placeholder={t('PlaceHolder_Filter_Status_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {statusToSelect.map((option) => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id={i18n.language==="es"?"statusToES":"statusToEN"}
                                name={i18n.language==="es"?"statusToES":"statusToEN"}
                                value={i18n.language==="es"?filters.statusToES:filters.statusToEN}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFilters.statusToES!=null?t(errorsFilters.statusToES):t('Text_Filter_Status_To'):errorsFilters.statusToEN!=null?t(errorsFilters.statusToEN):t('Text_Filter_Status_To')}
                                placeholder={t('PlaceHolder_Filter_Status_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {statusToSelect.filter(item=>(i18n.language==="es"?item.namees>=filters.statusFromES:item.nameen>=filters.statusFromEN)).map(option => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                            </div>

                            <div className="form-group">
                              <TextField
                                id={i18n.language==="es"?"reasonFromES":"reasonFromEN"}
                                name={i18n.language==="es"?"reasonFromES":"reasonFromEN"}
                                value={i18n.language==="es"?filters.reasonFromES:filters.reasonFromEN}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFilters.reasonFromES!=null?t(errorsFilters.reasonFromES):t('Text_Filter_Reason_From'):errorsFilters.reasonFromEN!=null?t(errorsFilters.reasonFromEN):t('Text_Filter_Reason_From')}
                                placeholder={t('PlaceHolder_Filter_Reason_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {reasonToSelect.map((option) => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id={i18n.language==="es"?"reasonToES":"reasonToEN"}
                                name={i18n.language==="es"?"reasonToES":"reasonToEN"}
                                value={i18n.language==="es"?filters.reasonToES:filters.reasonToEN}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFilters.reasonToES!=null?t(errorsFilters.reasonToES):t('Text_Filter_Reason_To'):errorsFilters.reasonToEN!=null?t(errorsFilters.reasonToEN):t('Text_Filter_Reason_To')}
                                placeholder={t('PlaceHolder_Filter_Reason_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {reasonToSelect.filter(item=>(i18n.language==="es"?item.namees>=filters.reasonFromES:item.nameen>=filters.reasonFromEN)).map(option => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                            </div>
                            
                          </Suspense>
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                          </div>
                        </div>
                      </form>
                    </Box>
                  </Container>
                </SwipeableDrawer>
              </div>
            }
            <SendEmailEdit
              openEditRow={openEditRow}
              setOpenEditRow={setOpenEditRow}
              rowEdit={rowEdit}>
            </SendEmailEdit>
            <Dialog open={openDeleteRow} onClose={handleCancelDeleteRow}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEdit.id}  - {rowEdit.store_EmailTo} </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('Text_Line1')}<br/>
                    {t('Text_Line2')}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="idCode"
                    label={t('Text_Label')}
                    type="text"
                    fullWidth
                    variant="standard"
                    error
                    defaultValue={idCodeDelete}
                    onChange={handleChangeDeleteRow}/>
                  </DialogContent>
                <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog> 

            {/* End Content Row */}
          </>:
            <Loading isLoadingData={isLoading}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

ReceivedEmailList.propTypes = {
  setShowPrincipalMenu: PropTypes.func.isRequired,
  showModalSendEmailsList: PropTypes.bool.isRequired,
  setShowModalSendEmailsList: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired 
};

export default ReceivedEmailList;