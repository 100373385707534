import React, {useState, useEffect} from 'react';  
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box'; 
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';  
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import useTheme from '@mui/material/styles/useTheme'; 
import visuallyHidden from '@mui/utils/visuallyHidden'; 
import PropTypes from 'prop-types';

const headCellsContractSheeIn = [
  { id: 'registerbook_id', numeric: false, disablePadding: false, label: 'Text_Code', canOrder: true },
  { id: 'registerbook', numeric: false, disablePadding: false, label: 'Text_FORMDI', canOrder: true },
  { id: 'preiddoc_sheet', numeric: false, disablePadding: false, label: 'Text_NUMBER_DI', canOrder: true },
  { id: 'number_dt', numeric: false, disablePadding: false, label: 'Text_NumberNT', canOrder: true },
  { id: 'date_sheet', numeric: false, disablePadding: false, label: 'Text_Date', canOrder: true },
  { id: 'ori_tras_tradename', numeric: false, disablePadding: false, label: 'Text_Ori_Tras_Tradename', canOrder: true },
  { id: 'ori_tras_nima', numeric: false, disablePadding: false, label: 'Text_Ori_Tras_Nima', canOrder: true },
  { id: 'ori_tras_postalcode', numeric: false, disablePadding: false, label: 'Text_Ori_Tras_PostalCode', canOrder: true },
  { id: 'opt_tras_tradename', numeric: false, disablePadding: false, label: 'Text_Opt_Tras_Tradename', canOrder: true },
  { id: 'opt_tras_nima', numeric: false, disablePadding: false, label: 'Text_Opt_Tras_Nima', canOrder: true },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Text_Amount', canOrder: true },
  { id: 'codeler', numeric: false, disablePadding: false, label: 'Text_CodeLer', canOrder: true },
  { id: 'namecodeler', numeric: false, disablePadding: false, label: 'Text_DescriptionCodeLer', canOrder: true },
  { id: 'rel_trans_tradename', numeric: false, disablePadding: false, label: 'Text_Rel_Trans_Tradename', canOrder: true },
  { id: 'rel_trans_nima', numeric: false, disablePadding: false, label: 'Text_Rel_Trans_Nima', canOrder: true },
  { id: 'column1', numeric: false, disablePadding: false, label: 'Text_Column1', canOrder: true },
  { id: 'column2', numeric: false, disablePadding: false, label: 'Text_Column2', canOrder: true }
];

const headCellsContractSheetOut = [
  { id: 'registerbook_id', numeric: false, disablePadding: false, label: 'Text_Code', canOrder: true },
  { id: 'registerbook', numeric: false, disablePadding: false, label: 'Text_FORMDI', canOrder: true },
  { id: 'preiddoc_sheet', numeric: false, disablePadding: false, label: 'Text_NUMBER_DI', canOrder: true },
  { id: 'number_dt', numeric: false, disablePadding: false, label: 'Text_NumberNT', canOrder: true },
  { id: 'date_sheet', numeric: false, disablePadding: false, label: 'Text_Date', canOrder: true },
  { id: 'codeler', numeric: false, disablePadding: false, label: 'Text_CodeLer', canOrder: true },
  { id: 'namecodeler', numeric: false, disablePadding: false, label: 'Text_DescriptionCodeLer', canOrder: true },
  { id: 'dest_tras_tradename', numeric: false, disablePadding: false, label: 'Text_Dest_Tras_Tradename', canOrder: true },
  { id: 'dest_tras_nima', numeric: false, disablePadding: false, label: 'Text_Dest_Tras_Nima', canOrder: true },
  { id: 'dest_tras_inscription', numeric: false, disablePadding: false, label: 'Text_Dest_Tras_PyGR', canOrder: true },
  { id: 'dest_tras_postalcode', numeric: false, disablePadding: false, label: 'Text_Dest_Tras_PostalCode', canOrder: true },
  { id: 'rel_trans_tradename', numeric: false, disablePadding: false, label: 'Text_Rel_Trans_Tradename', canOrder: true },
  { id: 'rel_trans_nima', numeric: false, disablePadding: false, label: 'Text_Rel_Trans_Nima', canOrder: true },
  { id: 'codetreatment', numeric: false, disablePadding: false, label: 'Text_CodeTreatment', canOrder: true },
  { id: 'nametreatment', numeric: false, disablePadding: false, label: 'Text_DescriptionCodeLer', canOrder: true },
  { id: 'codedangerousness', numeric: false, disablePadding: false, label: 'Text_CodeTreatment', canOrder: true },
  { id: 'namedangerousness', numeric: false, disablePadding: false, label: 'Text_DescriptionTreatment', canOrder: true },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Text_Amount', canOrder: true },
  { id: 'column1', numeric: false, disablePadding: false, label: 'Text_Column1', canOrder: true },
  { id: 'column2', numeric: false, disablePadding: false, label: 'Text_Column2', canOrder: true }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,showEditButtonRegisterBook,showDeleteButtonRegisterBook,valueTabs} = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {valueTabs==='sheetin'?
            headCellsContractSheeIn.map((headCell, indexhead) => (
              <TableCell
                key={indexhead}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.canOrder ?(
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography style={{color:"#4e73df", fontSize: 10}} >  
                    {t(headCell.label)}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </Typography>
                </TableSortLabel>):
                  <Typography style={{color:"#4e73df", fontSize: 10}} >  
                    {t(headCell.label)}
                  </Typography>}
              </TableCell>
            )) 
            :
            headCellsContractSheetOut.map((headCell, indexhead) => (
              <TableCell
                key={indexhead}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.canOrder ?(
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography style={{color:"#4e73df", fontSize: 10}} >  
                    {t(headCell.label)}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </Typography>
                </TableSortLabel>):
                  <Typography style={{color:"#4e73df", fontSize: 10}} >  
                    {t(headCell.label)}
                  </Typography>}
              </TableCell>
          ))
        }  
        
       
        {showEditButtonRegisterBook &&
          <TableCell
            key='edit'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 10}} >
              {t('Button_Edit')}
            </Typography>
          </TableCell>
        }
       
        {showDeleteButtonRegisterBook &&
          <TableCell
            key='delete'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 10}} > 
              {t('Button_Delete')}
            </Typography>
          </TableCell>
        } 
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegRegisterBook, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegRegisterBook).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataRegisterBook']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingRegisterBook =(props)=>{
  const { 
    dataRegisterBook, 
    numTotRegFilter,
    titleTable,
    setOpenDeleteRowRegisterBook,
    setOpenEditRowRegisterBook,
    setRowEditRegisterBook,
    showEditButtonRegisterBook,
    showDeleteButtonRegisterBook, 
    numTotRegRegisterBook,
    activeOffsetRegisterBook,
    rangeOffsetRegisterBook,
    currencyOffsetRegisterBook,
    setCurrencyOffsetRegisterBook,
    valueTabs
  } = props;
  const {t,i18n} = useTranslation(['listDataRegisterBook']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataRegisterBook.length) : 0);
  const [countData,setCountData] = useState(dataRegisterBook.length);
  
  useEffect(
    () => {
      if (dataRegisterBook.length!==countData || dataRegisterBook.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataRegisterBook.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataRegisterBook.length) : 0);  
    },
    [dataRegisterBook,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetRegisterBook(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalEditRow = (row) => {
    setRowEditRegisterBook(row);
    setOpenEditRowRegisterBook(true);
  };

  const openModalDeleteRow = (row) => {
    setRowEditRegisterBook(row);
    setOpenDeleteRowRegisterBook(true);
  };

 
  const listItemsSheetIn= (
  stableSort(dataRegisterBook, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 10}} >
            {row.registerbook_id}
          </Typography>  
        </TableCell> 
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.formdi_ownformdi===undefined || row.formdi_ownformdi===null || row.formdi_ownformdi==="" || row.formdi_ownformdi==="0" || row.formdi_ownformdi===0?t('Text_NotJoined'):row.formdi_ownformdi}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.preiddoc_sheet+row.sufiddoc_sheet}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.number_dt}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.date_sheet!==null && row.date_sheet!==""?new Date(row.date_sheet).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US'):""}
           </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.ori_tras_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.ori_tras_nima}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.ori_tras_postalcode}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.opt_tras_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.opt_tras_nima}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.amount}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.codeler}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.namecodeler}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.rel_trans_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.rel_trans_nima}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.column1}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 10}} >
            {row.column2}
          </Typography>
        </TableCell>
        {(showEditButtonRegisterBook && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#4e73df", fontSize: 10}} onClick={()=>openModalEditRow(row)}>
              <EditIcon/>
            </IconButton>
          </TableCell>
        } 
        {(showDeleteButtonRegisterBook && row.id!=="" && row.id!==null) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 10}} onClick={()=>openModalDeleteRow(row)}>
              <DeleteIcon/>
            </IconButton>
          </TableCell>} 
      </TableRow>
    );
  }));

  const listItemsSheetOut= (
    stableSort(dataRegisterBook, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
      return (
        <TableRow hover tabIndex={-1} key={keyData}>
          <TableCell align="left">
            <Typography style={{color:"#4e73df", fontSize: 10}} >
              {row.registerbook_id}
            </Typography>  
          </TableCell> 
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.registerbook}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.preiddoc_sheet+row.sufiddoc_sheet}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.number_dt}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.date_sheet!==""?new Date(row.date_sheet).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US'):""}
             </Typography>
          </TableCell>
         
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.codeler}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.namecodeler}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.dest_tras_tradename}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.dest_tras_nima}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.dest_tras_inscription}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.dest_tras_postalcode}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.rel_trans_tradename}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.rel_trans_nima}
            </Typography>
          </TableCell>

          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.codetreatment}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.nametreatment}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.codedangerousness}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.namedangerousness}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.amount}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.column1}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 10}} >
              {row.column2}
            </Typography>
          </TableCell>
     
          {(showEditButtonRegisterBook && row.id!=="" && row.id!==null ) &&
            <TableCell align="center">
              <IconButton  size="large"  style={{color:"#4e73df", fontSize: 10}} onClick={()=>openModalEditRow(row)}>
                <EditIcon/>
              </IconButton>
            </TableCell>
          } 
          {(showDeleteButtonRegisterBook && row.id!=="" && row.id!==null) &&
            <TableCell align="center">
              <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 10}} onClick={()=>openModalDeleteRow(row)}>
                <DeleteIcon/>
              </IconButton>
            </TableCell>} 
        </TableRow>
      );
    }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegRegisterBook={numTotRegRegisterBook} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} showEditButtonRegisterBook={showEditButtonRegisterBook} showDeleteButtonRegisterBook={showDeleteButtonRegisterBook} valueTabs={valueTabs}/>
              <TableBody>    
                {valueTabs==='sheetin'?listItemsSheetIn:listItemsSheetOut}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={19} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetRegisterBook && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetRegisterBook"
                            name="currencyOffsetRegisterBook"
                            value={currencyOffsetRegisterBook}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetRegisterBook
                            }}>
                            {rangeOffsetRegisterBook.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={19}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingRegisterBook.propTypes = {
  dataRegisterBook: PropTypes.array.isRequired, 
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired, 
  setOpenDeleteRowRegisterBook: PropTypes.func.isRequired, 
  setOpenEditRowRegisterBook: PropTypes.func.isRequired,   
  setRowEditRegisterBook: PropTypes.func.isRequired,     
  showEditButtonRegisterBook: PropTypes.bool.isRequired,   
  showDeleteButtonRegisterBook: PropTypes.bool.isRequired, 
  activeOffsetRegisterBook: PropTypes.bool.isRequired,
  rangeOffsetRegisterBook: PropTypes.array.isRequired,
  currencyOffsetRegisterBook: PropTypes.number.isRequired,
  setCurrencyOffsetRegisterBook: PropTypes.func.isRequired,
  valueTabs: PropTypes.string.isRequired,   
};
  
export default TableSortingRegisterBook;
