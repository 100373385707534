import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';     
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'; 
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import { NumericFormat } from 'react-number-format';
 import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
 import TicketsSearchStores from "pages/tickets/ticketsVehicle/TicketsSearchVehiclesStores";
import TicketsSearchVehiclesVehicles from "pages/tickets/ticketsVehicle/TicketsSearchVehiclesVehicles";

import PropTypes from 'prop-types'; 

const initialVehicles = {
  "id" : "",
  "vehiclestore_id":0,
  "vehiclestore_tara" : 0, 
  "vehiclestore_pma":0,
  "vehiclestore_checkpma" : true,
  "vehiclestore_defaultdriver" : "",
  "vehiclestore_store_id":0,
  "vehiclestore_licenseplate" : "",
  "rel_trans_store_id": 0,
  "rel_trans_documentid" : "",
  "rel_trans_tradename" : ""
};

const TicketsCreateVehicles = ({openEditRowVehicles,setOpenEditRowVehicles,setForm,form,setUnlockData,setUnlockDataChangeState,setEditRowArticles}) => {
  const {t} = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles); 
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const [rowEditVehicles, setRowEditVehiclesVehicles] = useState(initialVehicles);
  const [errorsVehicle, setErrorsVehicle] = useState({});  
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false);  

  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowVehicles) {
        setRowEditVehiclesVehicles(initialVehicles);
        setErrorsVehicle({}); 
        setShowModalSearchStores(false); 
        setShowModalSearchVehicles(false);
        setOpenEditRowVehicles(false); 
      }
    },[
      setRowEditVehiclesVehicles,
      setErrorsVehicle, 
      setShowModalSearchStores,
      setShowModalSearchVehicles,
      openEditRowVehicles,
      setOpenEditRowVehicles]
  );
  const handleCloseDataEditVehicles = () => {
    setRowEditVehiclesVehicles(initialVehicles);
    setErrorsVehicle({}); 
    setShowModalSearchStores(false); 
    setShowModalSearchVehicles(false);
    setOpenEditRowVehicles(false);
	};

  const handleChangeCheckPmaVehicle = () => {
    setRowEditVehiclesVehicles(rowEditVehicles => ({
      ...rowEditVehicles,
      vehiclestore_checkpma: !rowEditVehicles.vehiclestore_checkpma
    }));
  };
  
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000) return true;
    return false;
  };

  const handleBlurNumeric = (value, row) => {
    handleChangeNumeric(value, row);
  };

  const handleChangeNumeric = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" kg","").replaceAll(" €","").replaceAll(" %","")):0;
    setRowEditVehiclesVehicles(rowEditVehicles => ({
      ...rowEditVehicles,
      [name]: newValue
    }));
  };

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrorsVehicle(validateFormVehicles());
  };
  
  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setRowEditVehiclesVehicles(rowEditVehicles => ({
      ...rowEditVehicles,
      [name]: value
    }));
  };

  const deleteDataStoreRelTrans = (event) => {
    setRowEditVehiclesVehicles(rowEditVehicles => ({
      ...rowEditVehicles,
      rel_trans_store_id: 0,
      rel_trans_documentid: "",
      rel_trans_tradename: ""
    }));  
    setErrorsVehicle(validateFormVehicles());
  };

  const deleteDataLicensePlate = (event) => {
    setRowEditVehiclesVehicles(rowEditVehicles => ({
      ...rowEditVehicles, 
      vehiclestore_id:0,
      vehiclestore_tara : 0, 
      vehiclestore_pma:0,
      vehiclestore_checkpma : true,
      vehiclestore_defaultdriver : "",
      vehiclestore_store_id:0,
      vehiclestore_licenseplate : ""

    }));  
    setErrorsVehicle(validateFormVehicles());
  };

  const searchStoreRelTrans = (event) => { 
    setShowModalSearchStores(true);
  };

  const searchLicensePlate = (event) => { 
    setShowModalSearchVehicles(true);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  const toggleDrawerDataEditVehicles = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEditVehicles();
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchExistLicensePlate = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getexistvehicleylicenseplate/`,token,rowEditVehicles.vehiclestore_licenseplate);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==rowEditVehicles.vehiclestore_licenseplate){
            fetchLicensePlate();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchLicensePlate = async () => {
      try {
          const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getvehiclebylicenseplate/`,token,rowEditVehicles.vehiclestore_licenseplate);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!=="" && getData.id!==rowEditVehicles.vehiclestore_id){
                setRowEditVehiclesVehicles(rowEditVehicles => ({
                  ...rowEditVehicles,
                  vehiclestore_id: getData.id,
                  vehiclestore_tara:getData.tara,
                  vehiclestore_pma:getData.pma,
                  vehiclestore_checkpma: getData.checkpma,
                  vehiclestore_defaultdriver: getData.defaultdriver,
                  vehiclestore_store_id: (getData.store_id===undefined 
                                        || getData.store_id===null  
                                        || getData.store_id===""
                                        || getData.store_id==="0"
                                        || getData.store_id===0)?
                                        0:getData.store_id 
              }));
               
              if (rowEditVehicles.rel_trans_documentid===undefined || rowEditVehicles.rel_trans_documentid===null || rowEditVehicles.rel_trans_documentid==="" ){
                if (rowEditVehicles.rel_trans_tradename===undefined || rowEditVehicles.rel_trans_tradename===null || rowEditVehicles.rel_trans_tradename===""){
                  setRowEditVehiclesVehicles(rowEditVehicles => ({
                    ...rowEditVehicles,
                    rel_trans_documentid: getData.store_documentid 
                  }));
                }
              } 
              setRowEditVehiclesVehicles(rowEditVehicles => ({
                ...rowEditVehicles,
              }))
            }
          }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (rowEditVehicles.vehiclestore_licenseplate!==""){
      fetchExistLicensePlate();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,rowEditVehicles.vehiclestore_id,rowEditVehicles.vehiclestore_licenseplate,rowEditVehicles.rel_trans_documentid,rowEditVehicles.rel_trans_tradename,errorsVehicle,rowEditVehicles.vehiclestore_checkpma]);
 

  useEffect(() => {
    if (openEditRowVehicles){
      setRowEditVehiclesVehicles(rowEditVehicles => ({
        ...rowEditVehicles,
        vehiclestore_id:form.vehiclestore_id,
        vehiclestore_tara : form.vehiclestore_tara,
        vehiclestore_pma:form.vehiclestore_pma,
        vehiclestore_checkpma : form.vehiclestore_checkpma,
        vehiclestore_defaultdriver :form.vehiclestore_defaultdriver,
        vehiclestore_store_id:form.vehiclestore_store_id,
        vehiclestore_licenseplate : form.vehiclestore_licenseplate,
        rel_trans_store_id: form.rel_trans_store_id,
        rel_trans_documentid :form.rel_trans_documentid,
        rel_trans_tradename :form.rel_trans_tradename  
      }));
    }
   
  },[form.rel_trans_documentid,form.rel_trans_store_id,form.rel_trans_tradename,
    form.vehiclestore_checkpma,form.vehiclestore_defaultdriver,form.vehiclestore_licenseplate,
    form.vehiclestore_tara,form.vehiclestore_id,form.vehiclestore_pma,form.vehiclestore_store_id,openEditRowVehicles]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentRelTrans = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getstorebynumberdocument/`,token,rowEditVehicles.rel_trans_documentid);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==rowEditVehicles.rel_trans_store_id){
            fetchStoreNumberDocumentRelTrans();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchStoreNumberDocumentRelTrans = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`ticketsgetdata/getstorebynumberdocumentpriority`,token,rowEditVehicles.rel_trans_documentid,6);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setRowEditVehiclesVehicles(rowEditVehicles => ({
                  ...rowEditVehicles,
                  rel_trans_store_id: getData.rel_trans_store_id,
                  rel_trans_tradename: getData.rel_trans_tradename,
                }));
              }  
          }         
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
    };
    if (rowEditVehicles.rel_trans_documentid!==""){
      fetchExistNumberDocumentRelTrans();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,rowEditVehicles.rel_trans_store_id,rowEditVehicles.rel_trans_documentid]);

  
  const validateFormVehicles = () => {
    let errorsVehicle = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
   
    if (!rowEditVehicles.vehiclestore_licenseplate || rowEditVehicles.vehiclestore_licenseplate==="") {
      errorsVehicle.vehiclestore_licenseplate = 'Text_FieldVehicleStoreLicensePlateRequired';
    } else {
      if (regexInvalidCharacters.test(rowEditVehicles.vehiclestore_licenseplate)) {
        errorsVehicle.vehiclestore_licenseplate = 'Text_TextNoValid';
      }    
    }

    if (!rowEditVehicles.rel_trans_documentid || rowEditVehicles.rel_trans_documentid==="") {
      errorsVehicle.rel_trans_documentid = 'Text_FieldRelTrasDocumentIdRequired';
    } else {
      if (regexInvalidCharacters.test(rowEditVehicles.rel_trans_documentid)) {
        errorsVehicle.rel_trans_documentid = 'Text_TextNoValid';
      }  
    }  
    
    if (!rowEditVehicles.rel_trans_tradename || rowEditVehicles.rel_trans_tradename==="") {
      errorsVehicle.rel_trans_tradename = 'Text_FieldRelTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(rowEditVehicles.rel_trans_tradename)) {
        errorsVehicle.rel_trans_tradename = 'Text_TextNoValid';
      }  
    }  
    
    return errorsVehicle;
  };

  const handleSubmitDataEditVehicles = () => {
    setErrorsVehicle(validateFormVehicles());
    const errorsToCheck = validateFormVehicles();
  
    if (Object.keys(errorsToCheck).length === 0 && rowEditVehicles.vehiclestore_licenseplate !== '' && rowEditVehicles.rel_trans_documentid !== '' ) {
      const fetchData = async () => { 
        try {
          const getData =  await helpHttp().post2(ENDPOINT, `ticketscreatevehicle/saveticketcreateVehicle`, token, useraccount_id,rowEditVehicles);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData'))); 
            if (rowEditVehicles.vehiclestore_licenseplate===form.vehiclestore_licenseplate){
              setForm(form => ({
                ...form,
                vehiclestore_id: getData.response.id,
                vehiclestore_tara: getData.response.vehiclestore_tara,
                vehiclestore_pma:  getData.response.vehiclestore_pma,
                vehiclestore_checkpma: getData.response.vehiclestore_checkpma,
                vehiclestore_defaultdriver: getData.response.vehiclestore_defaultdriver,
                vehiclestore_store_id: (getData.response.rel_trans_store_id===undefined 
                                      || getData.response.rel_trans_store_id===null  
                                      || getData.response.rel_trans_store_id===""
                                      || getData.response.rel_trans_store_id==="0"
                                      || getData.response.rel_trans_store_id===0)?
                                      0:getData.response.rel_trans_store_id,
                totalendweight:getData.response.vehiclestore_checkpma?0:getData.response.vehiclestore_tara
              }));
              if (!getData.response.vehiclestore_checkpma){
                setEditRowArticles(editRowArticles => ({
                  ...editRowArticles,
                  secondweight:0,
                  formdi_id:0,
                  ownformdi_id:0
                }));
                setUnlockData(false); 
                setUnlockDataChangeState(false);
              }else{
                setEditRowArticles(editRowArticles => ({
                  ...editRowArticles,
                  secondweight:getData.response.vehiclestore_tara,
                  formdi_id:0,
                  ownformdi_id:0
                }));
                setUnlockData(true);
                setUnlockDataChangeState(true);
              }
              if (form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="" ){
                if (form.rel_trans_tradename===undefined || form.rel_trans_tradename===null || form.rel_trans_tradename===""){
                  setForm(form => ({
                    ...form,
                    rel_trans_documentid: getData.response.rel_trans_documentid 
                  }));
                }
              } 
              setForm(form => ({
                ...form,
              }))

            }
            handleCloseDataEditVehicles();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      }; 
      fetchData();
    } else {
      if (errorsToCheck.rel_trans_documentid) {
        dispatch(warningErrorAction(t(errorsToCheck.rel_trans_documentid)));
      }else{
        if (errorsToCheck.rel_trans_documentid) {
          dispatch(warningErrorAction(t(errorsToCheck.rel_trans_documentid)));
        }else{
          dispatch(warningErrorAction(t('Text_IncorrectData')));
        }  
        
      }
    }
  };
 
  return(
    <>
      {openEditRowVehicles && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerDataEditVehicles(false)}
            onOpen={toggleDrawerDataEditVehicles(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',width: '500px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Button_Add_TARA')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createVehicles" onSubmit={handleSubmitDataEditVehicles}>  
                  <div className="form-floating mb-3 px-4 primary">
                  <label className="text-primary">{t('Text_TICKETS_CIF_TRANS')} :</label>
                    <div className="form-group">
                      <TextField
                        id="rel_trans_documentid" 
                        name="rel_trans_documentid"
                        value={rowEditVehicles.rel_trans_documentid}
                        inputProps={{ style: {width: 300}, maxLength: 50  }}
                        label={errorsVehicle.rel_trans_documentid!=null?t(errorsVehicle.rel_trans_documentid):t('Text_TICKETS_CIF_TRANS')}
                        placeholder={t('Text_TICKETS_CIF_TRANS')}
                        variant="standard"
                        onBlur={handleBlurDataEdit}
                        onChange={handleChangeDataEdit}
                      />
                      &nbsp;&nbsp;
                      <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreRelTrans}>
                        <DeleteIcon/>
                      </IconButton>
                      &nbsp;&nbsp;
                      {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                        <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreRelTrans}>
                          <SearchIcon/>
                        </IconButton>
                      }
                    </div>
                    <label className="text-primary">{t('Text_TICKETS_TRADENAME_TRANS')} :</label>
                    <div className="form-group">
                      <TextField
                        id="rel_trans_tradename" 
                        name="rel_trans_tradename"
                        value={rowEditVehicles.rel_trans_tradename}
                        inputProps={{ style: {width: 450}, maxLength: 50  }}
                        label={errorsVehicle.rel_trans_tradename!=null?t(errorsVehicle.rel_trans_tradename):t('Text_TICKETS_TRADENAME_TRANS')}
                        placeholder={t('Text_TICKETS_TRADENAME_TRANS')}
                        variant="standard"
                        onBlur={handleBlurDataEdit}
                        onChange={handleChangeDataEdit}
                      />
                    </div>
                    <label className="text-primary">{t('Text_TICKETS_LICENSEPLATE')} :</label>
                    <div className="form-group">
                      <TextField
                        id="vehiclestore_licenseplate" 
                        name="vehiclestore_licenseplate"
                        value={rowEditVehicles.vehiclestore_licenseplate}
                        inputProps={{ style: {width: 300}, maxLength: 50  }}
                        label={errorsVehicle.vehiclestore_licenseplate!=null?t(errorsVehicle.vehiclestore_licenseplate):t('Text_TICKETS_LICENSEPLATE')}
                        placeholder={t('Text_TICKETS_LICENSEPLATE')}
                        variant="standard"
                        onBlur={handleBlurDataEdit}
                        onChange={handleChangeDataEdit}
                      />
                      &nbsp;&nbsp;
                      <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataLicensePlate}>
                        <DeleteIcon/>
                      </IconButton>
                      &nbsp;&nbsp;
                      {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                        <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchLicensePlate}>
                          <SearchIcon/>
                        </IconButton>
                      }
                    </div>
                    <label className="text-primary">{t('Text_Vehicle_tara')} :</label>
                    <div className="form-group">
                      <NumericFormat 
                        thousandSeparator={','} 
                        decimalSeparator={'.'} 
                        decimalScale= {2}
                        fixedDecimalScale= {true}
                        allowNegative= {false}
                        isAllowed={withValueCap}
                        id="vehiclestore_tara" 
                        name="vehiclestore_tara"
                        suffix={" kg"}
                        value={rowEditVehicles.vehiclestore_tara}
                        label={errorsVehicle.vehiclestore_tara!=null?t(errorsVehicle.vehiclestore_tara):t('Text_Vehicle_tara')}
                        placeholder={t('Text_Vehicle_tara')}
                        style={{width: 450}}
                        customInput={TextField}
                        onBlur={handleBlurNumeric}
                        onChange={handleChangeNumeric}
                      />
                    </div> 
                    <div className="form-group">
                      <label className="text-primary">{t('Text_Vehicle_NOTCHECK')} :</label>
                      <Checkbox  
                        style={{color:"#4e73df"}} 
                        checked={rowEditVehicles.vehiclestore_checkpma} 
                        onChange={handleChangeCheckPmaVehicle} 
                      />
                    </div>

                    <label className="text-primary">{t('Text_Vehicle_driver')} :</label>
                    <div className="form-group">
                      <TextField
                        id="vehiclestore_defaultdriver" 
                        name="vehiclestore_defaultdriver"
                        value={rowEditVehicles.vehiclestore_defaultdriver}
                        inputProps={{ style: {width: 450}, maxLength: 100  }}
                        label={errorsVehicle.vehiclestore_defaultdriver!=null?t(errorsVehicle.vehiclestore_defaultdriver):t('Text_Vehicle_driver')}
                        placeholder={t('Text_Vehicle_driver')}
                        variant="standard"
                        onBlur={handleBlurDataEdit}
                        onChange={handleChangeDataEdit}
                      />
                    </div>                      
                   
                     
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEditVehicles}>{t('Button_Add_TARA')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEditVehicles}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
          <TicketsSearchStores
            showModalSearchStores={showModalSearchStores}
            setShowModalSearchStores={setShowModalSearchStores}
            setRowEditVehiclesVehicles={setRowEditVehiclesVehicles}
          />
           <TicketsSearchVehiclesVehicles
            showModalSearchVehicles={showModalSearchVehicles}
            setShowModalSearchVehicles={setShowModalSearchVehicles}
            setRowEditVehiclesVehicles={setRowEditVehiclesVehicles}
            rowEditVehicles={rowEditVehicles}
          />
        </div>
      }  
    </>
  )
}

TicketsCreateVehicles.propTypes = {
  openEditRowVehicles: PropTypes.bool.isRequired,
  setOpenEditRowVehicles: PropTypes.func.isRequired,  
  setForm: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setUnlockData: PropTypes.func.isRequired,
  setUnlockDataChangeState: PropTypes.func.isRequired,
  setEditRowArticles: PropTypes.func.isRequired
};

export default TicketsCreateVehicles;