import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"  
import { useDispatch } from "react-redux"; 
import Box from '@mui/material/Box';    
import Container from '@mui/material/Container';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import ScaleIcon from '@mui/icons-material/Scale';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';    
import Typography from '@mui/material/Typography'; 
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { NumericFormat } from 'react-number-format';
import { helpHttp } from "service/HelpHttp";
import "css/generalSettings.css"; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";

import TicketsSearchNextArticles from "pages/tickets/ticketsEdit/invoiceTicketsEdit/InoviceTicketsSearchArticles";
import TicketsSearchNextDI from "pages/tickets/ticketsEdit/invoiceTicketsEdit/InoviceTicketsSearchDI";
import PropTypes from 'prop-types';
   
const initialState = {
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isfinished":false, 
  "numberticket":0, 
  "purchasenote":0, 
  "ownnumbertickes":0, 
  "start_date_ticket": "",
  "end_date_ticket": "", 
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_tara": 0,
  "vehiclestore_pma": 0,
  "vehiclestore_checkpma": false,
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,
  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "rel_trans_grnp": "",

  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "ori_tras_email": "",

  "observations": "",
  "totalstartweight": 0,
  "totalendweight": 0,
  "newweightreading":0,
  "enabled":true,
  "linestickets":[],
  "linesticketsWithOutWeight":[]
};

const initialArticlesToSelect = [{
  "id": 0,
  "indexrowarticle":0, 
  "tickets_id":0,
  "article_id":0,
  "article_code": "",
  "article_namees": "",
  "article_nameen": "",
  "start_date_ticket": "",
  "end_date_ticket": "",
  "firstweight":0,
  "secondweight":0, 
  "estimatedprice":0, 
  "discount":0, 
  "tax":0, 
  "taxincluded":false ,
  "ticketweight":true,
  "formdi_id":0,
  "ownformdi_id":0
}];

const InvoiceNextTickets = ({openEditRowInvoiceTickets,setOpenEditRowInvoiceTickets,id,configPrinter,setRefeshData}) => {
  const { t,i18n } = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const ENDPOINTPRINTER = useSelector((state) => state.loginUser.defaultticketsformat.endpoinrprinter);
  const ENDPOINTWEIGHT = useSelector((state) => state.loginUser.defaultticketsformat.endpointweight);
  const headerPrinter = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinter);
  const numberoftickets = useSelector((state) => state.loginUser.defaultticketsformat.numberoftickets); 
  const printerticket_operatingsystem = useSelector((state) => state.loginUser.defaultticketsformat.operatingsystem); 
  const printerticket_communicationtype = useSelector((state) => state.loginUser.defaultticketsformat.communicationtype);  
  const printerticket_type = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_type);
  const printerticket_interface = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_interface);
  const printerticket_driver = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_driver);
  const [formInvoiceNextTickets, setFormInvoiceNextTickets] = useState(initialState);
  const [editNextArticles, setEditNextArticles] = useState(initialArticlesToSelect); 
  const [showModalSearchNextArticles, setShowModalSearchNextArticles] = useState(false); 
  const [showModalSearchNextDI, setShowModalSearchNextDI] = useState(false); 
  
  const [nextWeight,setNextWeight]=useState(0);   
  const dispatch = useDispatch();

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && openEditRowInvoiceTickets) {  
        setFormInvoiceNextTickets(initialState);
        setFormInvoiceNextTickets(formInvoiceNextTickets => ({
          ...formInvoiceNextTickets,
          id: "",
          linestickets:[],
          linesticketsWithOutWeight:[]
        }));
        setEditNextArticles(initialArticlesToSelect);
        setOpenEditRowInvoiceTickets(false);
        setNextWeight(0);
        setShowModalSearchNextArticles(false);
        setShowModalSearchNextDI(false);
      }
    },
    [ openEditRowInvoiceTickets,
      setFormInvoiceNextTickets,
      setEditNextArticles,
      setNextWeight,
      setShowModalSearchNextArticles,
      setShowModalSearchNextDI,
      setOpenEditRowInvoiceTickets
    ]
  );

  const handleCloseInvoiceNextTickets = () => {  
    setFormInvoiceNextTickets(initialState);
    setFormInvoiceNextTickets(formInvoiceNextTickets => ({
      ...formInvoiceNextTickets,
      id: "",
      linestickets:[],
      linesticketsWithOutWeight:[]
    }));
    setEditNextArticles(initialArticlesToSelect);
    toggleDrawerDataEdit(false);
    setNextWeight(0);
    setOpenEditRowInvoiceTickets(false);
    setShowModalSearchNextArticles(false);
    setShowModalSearchNextDI(false);
	};

  const handleAddNewArticle = () => {
    setShowModalSearchNextArticles(true); 
  }; 

  const handleSelectDI = () => {
    setShowModalSearchNextDI(true); 
  }; 

  const handleDeleteNewArticle = () => {
    setEditNextArticles(editNextArticles => ({
      ...editNextArticles,
      article_id:"",
      article_code:"",
      article_namees:"",
      article_nameen:"",
      estimatedprice: 0 ,
      discount: 0,
      tax: 0 ,
      taxincluded: false,
      ticketweight:true,
      formdi_id:0,
      ownformdi_id:0
    }));
  }; 

  const formatNumber = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) + ' Kg';
  };

  const formatNumberEURO = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' €';
  };

  const formatNumberWithOutDecimal = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 1000000) return true;
    return false;
  };
 
  const handleBlurArticlesAmount = (row, value) => {
    handleChangeArticleAmount(row, value);
  };

  const handleChangeArticleAmount = (row, value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll("kg","").replaceAll("€","").replaceAll("%","")):0;
    var i =  formInvoiceNextTickets.linesticketsWithOutWeight.indexOf( row );
    formInvoiceNextTickets.linesticketsWithOutWeight.map((row,index)=>{
      if ( index!==i ) {
        return row.firstweight
      }else{
        return row.firstweight=newValue
      }  
    })
    setFormInvoiceNextTickets(formInvoiceNextTickets => ({
     ...formInvoiceNextTickets
    }));
  };

  const handleBlurArticlesPrice = (row, value) => {
    handleChangeArticlePrice(row, value);
  };
 
  const handleChangeArticlePrice = (row, value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll("kg","").replaceAll("€","").replaceAll("%","")):0;
    var i =  formInvoiceNextTickets.linesticketsWithOutWeight.indexOf( row );
    formInvoiceNextTickets.linesticketsWithOutWeight.map((row,index)=>{
      if ( index!==i ) {
        return row.estimatedprice
      }else{
        return row.estimatedprice=newValue
      }  
    })
    setFormInvoiceNextTickets(formInvoiceNextTickets => ({
     ...formInvoiceNextTickets
    })) 
  };
 
  const handleChangeArticleWeight = (value) => {
    let newValue= value
    if (isNaN(newValue)){
      newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    }

    setEditNextArticles(editNextArticles => ({
      ...editNextArticles,
      firstweight: newValue
    })); 
    formInvoiceNextTickets.linestickets.map((item,index)=>{
      if ( index===formInvoiceNextTickets.linestickets.length-1 ) {
        return item.secondweight=newValue
      }else{
        return item.secondweight
      }  
    })
    setFormInvoiceNextTickets(formInvoiceNextTickets => ({
      ...formInvoiceNextTickets,
      newweightreading: newValue
    })); 
    setNextWeight(newValue);
    
  };

  const openModalDeleteRowArticle = (row) => {
    let indexDelete=formInvoiceNextTickets.linesticketsWithOutWeight.findIndex(((item) => item.indexrowarticle === row.indexrowarticle));
    if ( indexDelete !== -1 ) {
      formInvoiceNextTickets.linesticketsWithOutWeight.splice( indexDelete, 1 );
    }
    setFormInvoiceNextTickets(formInvoiceNextTickets => ({
      ...formInvoiceNextTickets
    }));
  };

  const handleBlurArticlesWeight = (value, row) => {
    handleChangeArticleWeight(value, row);
  };

  const openExternalWeigh = () => {
    // CÓDIGO LECTURA EXTERNA PESA
    const fetchData = async () => {
      try {
        const getData = await helpHttp().postWeigt(ENDPOINTWEIGHT,`api/config`,configPrinter);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_ERROR_WEIGHT'))); 
          handleChangeArticleWeight(getData.message); 
        } else {

          if (getData.message!==undefined && getData.message!==null & getData.message!==""){
            handleChangeArticleWeight(getData.message); 
          }
        }       
      } catch (error) {
        dispatch(warningErrorAction(t('Text_ERROR_WEIGHT'))); 
        handleChangeArticleWeight(0);  
      }
    };  
    if (configPrinter.printerticket_communicationtype && configPrinter.printerticket_communicationtype === "SOCKET") {
      let isMounted = true;
      // Crear la conexión WebSocket
      const socket = new WebSocket('ws://localhost:8080');
      // Manejar la apertura de la conexión WebSocket
      socket.addEventListener('open', () => { 
        socket.send(JSON.stringify({ type: 'read-scale', content: configPrinter }));
      });
      // Manejar los mensajes recibidos
      socket.addEventListener('message', (event) => {
        if (!isMounted) return;
        try {
          const response = JSON.parse(event.data);
          if (response.status === 'success') { 
            handleChangeArticleWeight(response.message);  
          } else {
            dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));
          }
        } catch (error) { 
          dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));
        }
      }); 
      // Manejar errores en el WebSocket
      socket.addEventListener('error', (error) => { 
        if (isMounted) {
          dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));
        }
      }); 
      // Manejar el cierre de la conexión WebSocket
      socket.addEventListener('close', (event) => { 
        if (!event.wasClean) { 
          if (isMounted) {
            dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));
          }
        }
      });
      // Limpiar el WebSocket cuando el componente se desmonte
      return () => {
        isMounted = false;
        if (socket.readyState === WebSocket.OPEN) {
          socket.close();
        }
      }; 
    } else {
      // Si no es comunicación por socket, realizar la lógica fetch
      fetchData();
    } 
  }; 

  const listItemsOthers= (
    formInvoiceNextTickets.linesticketsWithOutWeight.map((row, keyData) => {
      return (
        <TableRow hover tabIndex={-1} key={keyData}>
          <TableCell align="left">
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {row.article_code}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {i18n.language==="es"?row.article_namees:row.article_nameen}
            </Typography>  
          </TableCell> 
          <TableCell align="center">
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {0}
              fixedDecimalScale= {true}
              allowNegative= {false}
              isAllowed={withValueCap}
              id="firstweight" 
              name="firstweight"
              value={row.firstweight}
              style={{width:118, color:"red", background:"black",fontSize: 20,   paddingLeft:5}} 
              onBlur={(e) => handleBlurArticlesAmount(row,e.target.value)}
              onChange={(e) => handleChangeArticleAmount(row,e.target.value)}
            /> 
          </TableCell>
          <TableCell align="center">
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {2}
              fixedDecimalScale= {true}
              allowNegative= {false}
              isAllowed={withValueCap}
              id="estimatedprice" 
              name="estimatedprice"
              prefix={"€ "}
              value={row.estimatedprice}
              style={{width:118, color:"red", background:"black",fontSize: 20,  paddingLeft:5}} 
              onBlur={(e) => handleBlurArticlesPrice(row,e.target.value)}
              onChange={(e) => handleChangeArticlePrice(row,e.target.value)}
            /> 
          </TableCell>
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalDeleteRowArticle(row)}>
              <DeleteIcon/>
            </IconButton>
          </TableCell>
       </TableRow>
    );
  }));

  const listItems= (
    formInvoiceNextTickets.linestickets.map((row, keyData) => {
      return (
        <TableRow hover tabIndex={-1} key={keyData}>
           <TableCell align="left">
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {row.article_code}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {i18n.language==="es"?row.article_namees:row.article_nameen}
            </Typography>  
          </TableCell> 
          <TableCell align="center">
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {0}
              fixedDecimalScale= {true}
              allowNegative= {false}
              isAllowed={withValueCap}
              id="firstweight" 
              name="firstweight"
              value={row.firstweight}
              style={{width:118, color:"#FF6347", background:"gray",fontSize: 20,  paddingLeft:5}}  
              disabled
            /> 
          </TableCell>
          <TableCell align="center">
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {0}
              fixedDecimalScale= {true}
              allowNegative= {false}
              isAllowed={withValueCap}
              id="secondweight" 
              name="secondweight"
              value={row.secondweight}
              style={{width:118, color:"#FF6347", background:"gray",fontSize: 20,  paddingLeft:5}}  
              disabled
            /> 
          </TableCell>
          <TableCell align="center">
            <Typography style={{color:"#067330", fontSize: 14}} >
              {row.formdi_id===undefined || row.formdi_id==="" || row.formdi_id==="0" || row.formdi_id===0 ?t('Text_NotJoined'):row.ownformdi_id}
            </Typography>
          </TableCell>
      </TableRow>
    );
  }));

  const header = (value) => {
    return (
      <TableHead>
        <TableRow>
          <TableCell  key='code'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_CODE')}
            </Typography>
          </TableCell>
          <TableCell  key='name'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_ARTICLE_TITLE')}
            </Typography>
          </TableCell>
          <TableCell  key='firstweight'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_PREV_WEIGHT')}
            </Typography>
          </TableCell>
          <TableCell  key='secondweight'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_NEXT_WEIGHT')}
            </Typography>
          </TableCell>
          <TableCell  key='formdi_id'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_ARTICLE_DI')}
            </Typography>
          </TableCell>
          {value===1 &&
            <TableCell  key='delete'  align='center' padding='normal' sortDirection={false} >
              <Typography style={{color:"#e74a3b", fontSize: 14}} > 
                {t('Button_Delete')}
              </Typography>
            </TableCell>
          }
        </TableRow>
      </TableHead>
    );
  }

  const headerOther = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell  key='code'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_CODE')}
            </Typography>
          </TableCell>
          <TableCell  key='name'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_ARTICLE_TITLE')}
            </Typography>
          </TableCell>
          <TableCell  key='firstweight'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_ARTICLE_Amount')}
            </Typography>
          </TableCell>
          <TableCell  key='secondweight'  align={'left'}  padding={'normal'}  sortDirection={false}>
              <Typography style={{color:"#4e73df", fontSize: 14}} > 
              {t('Text_TICKETS_ARTICLE_Price')}
            </Typography>
          </TableCell> 
          <TableCell  key='delete'  align='center' padding='normal' sortDirection={false} >
            <Typography style={{color:"#e74a3b", fontSize: 14}} > 
              {t('Button_Delete')}
            </Typography>
          </TableCell>  
        </TableRow>
      </TableHead>
    );
  }

  const handleSubmitDataEditNextTickets = () => {
    if (formInvoiceNextTickets.id !==undefined && formInvoiceNextTickets.id !== '' && formInvoiceNextTickets.id !== '0' && formInvoiceNextTickets.id !== 0) {

      const fetchDataPrintTicket = async (dataToPrint) => {
        let isMounted = true;
        let respondeData={
          "headerPrinter":headerPrinter,
          "numberoftickets":numberoftickets,
          "printerticket_type":printerticket_type,
          "printerticket_interface":printerticket_interface,
          "printerticket_driver":printerticket_driver,
          "printerticket_operatingsystem":printerticket_operatingsystem,
          "start_date_ticket":dataToPrint.start_date_ticket,
          "numberticket":(dataToPrint.numberticket.toString() || ''),
          "ori_tras_tradename": (dataToPrint.ori_tras_tradename || '') ,
          "rel_trans_tradename": (dataToPrint.rel_trans_tradename || ''),
          "vehiclestore_licenseplate":(dataToPrint.vehiclestore_licenseplate || ''), 
          "ori_tras_street":(dataToPrint.ori_tras_street || ''), 
          "linestickets": [],
          "linesticketsWithOutWeight":[]
        } 
        dataToPrint.linestickets.forEach((line) => {
          let formattedFirstWeight = formatNumber(line.firstweight);
          let formattedSecondWeight = line.secondweight!==0?formatNumber(line.secondweight):"";
          let formattedNetoWeight = line.secondweight!==0?formatNumber(Math.abs(line.firstweight - line.secondweight)):"";
          let lineToAdd={
            "article_code":(line.article_code || ''),
            "article_namees":(line.article_namees || ''),
            "start_date_ticket":line.start_date_ticket,
            "end_date_ticket":line.end_date_ticket,
            "firstweight":formattedFirstWeight,
            "secondweight": formattedSecondWeight,
            "totalweight": formattedNetoWeight
          }
          respondeData.linestickets.push(lineToAdd); 
        });
        dataToPrint.linesticketsWithOutWeight.forEach((line) => {
          let formattedFirstWeight = formatNumberWithOutDecimal(line.firstweight);
          let formattedSecondWeight = line.estimatedprice!==0?formatNumberEURO(line.estimatedprice):"";
          let formattedNetoWeight = line.firstweight!==0 && line.estimatedprice!==0?formatNumberEURO(Math.abs(line.firstweight * line.estimatedprice)):"";
          let lineToAdd={
            "article_code":(line.article_code.substring(0, 33) || ''),
            "article_namees":(line.article_namees.substring(0, 33) || ''),
            "firstweight":formattedFirstWeight,
            "secondweight": formattedSecondWeight,
            "totalweight": formattedNetoWeight
          }
          respondeData.linesticketsWithOutWeight.push(lineToAdd); 
        }); 
        if (printerticket_communicationtype && printerticket_communicationtype==="SOCKET"){
          // WebSocket connection
          const socket = new WebSocket('ws://localhost:8080'); 
          socket.addEventListener('open', () => {
            socket.send(JSON.stringify({ type: 'print-ticket', content: respondeData }));
          }); 
          socket.addEventListener('message', (event) => {
            try {
              const response = JSON.parse(event.data);
              if (response.status === 'success') {
                dispatch(successErrorAction(t('Text_PRINTER_SEND')));
              } else {
                if (isMounted) {
                  dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
                }
              }
            } catch (error) {
              if (isMounted) {
                dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
              }
            }
          }); 
          socket.addEventListener('error', (error) => {
            if (isMounted) {
              dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
            }
          }); 
          socket.addEventListener('close', (event) => {
            if (!event.wasClean) {
              if (isMounted) {
                dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
              }
            }
          }); 
        } else{
          try {
            const getData = await helpHttp().sendTickets(ENDPOINTPRINTER, `api/print-ticket`, { respondeData });
            if (getData.message === "OK") {
              dispatch(successErrorAction(t('Text_PRINTER_SEND')));
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
              }
            }
          } catch (error) {
            if (isMounted) {
              dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
            }
          } 
        }  
        return () => { isMounted = false;};
      };  
      const fetchData = async () => { 
        try {
          const getData = await helpHttp().put(ENDPOINT, `tickets/updateticketsandpurchase`, token, formInvoiceNextTickets) ;
          if (getData.status === "OK") {
            if (getData.response ){
              fetchDataPrintTicket(getData.response);
            }
            dispatch(successErrorAction(t('Text_SaveData')));
            setRefeshData(true);
            handleCloseInvoiceNextTickets();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        } 
      };
      const fetchData2 = async () => { 
        try {
          const getData = await helpHttp().put(ENDPOINT, `tickets/updatetickets`, token, formInvoiceNextTickets) ;
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            setRefeshData(true);
            handleCloseInvoiceNextTickets();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        } 
      };
      if (nextWeight===0) {
        dispatch(warningErrorAction(t('Text_FieldNextTotalIsNull')));  
      }else{ 
        if(editNextArticles.article_code!==undefined 
          && editNextArticles.article_code!==null
          && editNextArticles.article_code!==""
         ){
          let newArticleToAdd={
            "id": 0,
            "indexrowarticle":0, 
            "tickets_id":0,
            "article_id":editNextArticles.article_id,
            "article_code": editNextArticles.article_code,
            "article_namees": editNextArticles.article_namees,
            "article_nameen": editNextArticles.article_nameen,
            "start_date_ticket": "",
            "end_date_ticket": "",
            "firstweight":editNextArticles.firstweight,
            "secondweight":0,
            "estimatedprice": editNextArticles.estimatedprice ,
            "discount": editNextArticles.discount ,
            "tax": editNextArticles.tax ,
            "taxincluded": editNextArticles.taxincluded,
            "ticketweight":editNextArticles.ticketweight,
            "formdi_id":editNextArticles.formdi_id,
            "ownformdi_id":editNextArticles.ownformdi_id
          }
          formInvoiceNextTickets.linestickets.push(newArticleToAdd);
          fetchData2();
        }else{
          fetchData();
        } 
      }
    } else {
      dispatch(warningErrorAction(t('Text_IncorrectData')));
    }
  }; 

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseInvoiceNextTickets();
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchDataInvoiceTickets = async () => { 
      try {
        const getData = await helpHttp().get2(ENDPOINT, `tickets/getticketbyid/`, token, id);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setFormInvoiceNextTickets(initialState);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setFormInvoiceNextTickets(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (openEditRowInvoiceTickets && id !== undefined && id !== "" && id !== "0" && id !== 0) {
      if (formInvoiceNextTickets.id === "") {
        fetchDataInvoiceTickets();
      }
    } else {
      setFormInvoiceNextTickets(initialState);
      setFormInvoiceNextTickets(formInvoiceNextTickets => ({
        ...formInvoiceNextTickets, 
        id:"",
        linestickets:[] ,
        linesticketsWithOutWeight:[]
      })); 
    }
    return () => { isMounted = false }; 
  }, [ENDPOINT,dispatch,id,t,token,formInvoiceNextTickets.id,openEditRowInvoiceTickets]); 
   return (
    <div>           
      {(openEditRowInvoiceTickets)&& 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerDataEdit(false)}
            onOpen={toggleDrawerDataEdit(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '900px' }}>
                <form className="createnexttickets" onSubmit={handleSubmitDataEditNextTickets}>  
                  <br/>
                  <div id="myDIV">
                    <h1 className="h1NewStyle px-4 mb-2">{t('Text_TICKETS_EDIT')} {formInvoiceNextTickets.numberticket}</h1>
                  </div>  
                  <hr className="sidebar-divider d-none d-md-block"/>

                  <div className="col-12 mb-2">
                    <div className="card border-info shadow">
                      <div className="card-header 2 bg-primary text-white text-center text-bold">
                        <h4>{t('Text_TICKETS_SCALES')}</h4>
                      </div>
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-3 mb-2">
                              <Typography style={{flex: '1 1 100%',color:"#067330", fontSize: 16}} id="tableTitle" component="div">
                                {t('Text_TICKETS_FIRST_WEIGHTS')}
                              </Typography>
                          </div>
                          <div className="col-3 mb-2">
                              <Typography style={{flex: '1 1 100%',color:"#067330", fontSize: 16}} id="tableTitle" component="div">
                                {t('Text_TICKETS_PREV_WEIGHT')}
                              </Typography>
                          </div>
                          <div className="col-1 mb-2">
                          </div>
                          <div className="col-3 mb-2">
                              <Typography style={{flex: '1 1 100%',color:"#067330", fontSize: 16}} id="tableTitle" component="div">
                                {t('Text_TICKETS_NEXT_WEIGHT')}
                              </Typography>
                          </div>
                          <div className="col-2 mb-2">
                          </div>
                        </div>

                        <div className="row align-items-center">
                          <div className="col-3 mb-2">
                            <NumericFormat 
                              thousandSeparator={','} 
                              decimalSeparator={'.'} 
                              decimalScale= {0}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap}
                              id="totalstartweight" 
                              name="totalstartweight"
                              value={formInvoiceNextTickets.totalstartweight}
                              style={{width:200, color:"#FF6347", background:"gray",fontSize: 28,  paddingLeft:10}} 
                              disabled
                            /> 
                          </div> 
                          <div className="col-3 mb-2">
                            <NumericFormat 
                              thousandSeparator={','} 
                              decimalSeparator={'.'} 
                              decimalScale= {0}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap}
                              id="totalendweight" 
                              name="totalendweight"
                              value={formInvoiceNextTickets.totalendweight}
                              style={{width:200, color:"#FF6347", background:"gray",fontSize: 28, paddingLeft:10}} 
                              disabled
                            /> 
                          </div> 
                          <div className="col-1 mb-2">
                          </div>
                          <div className="col-3 mb-2">
                            <NumericFormat 
                              thousandSeparator={','} 
                              decimalSeparator={'.'} 
                              decimalScale= {0}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap}
                              id="nextWeight" 
                              name="nextWeight"
                              value={nextWeight}
                              style={{width:200, color:"red", background:"black",fontSize: 28, paddingLeft:10}} 
                              onBlur={(e) => handleBlurArticlesWeight(e.target.value)}
                              onChange={(e) => handleChangeArticleWeight(e.target.value)}
                            />  
                          </div>
                          <div className="col-2 mb-2">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <IconButton 
                              style={{color:"#ffffff", background:"#4e73df"}} 
                              aria-label="weight vehicles" 
                              onClick={openExternalWeigh}
                              >
                              <ScaleIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  <br/>  
                  </div>
                  <div className="col-12 mb-2">
                    <div className="card border-info shadow">
                      <div className="card-header 2 bg-primary text-white text-center text-bold">
                        <h4><i className="fas fa-duotone fa-truck text-warning"></i> {t('Text_NEXTTICKETS_TITLE')}</h4>
                      </div>
                      <div className="card-body">
                        <div className="container">
                          
                          <div className="row p-0"> 
                            <div className="form-group"> 
                              <label className="text-primary">{t('Text_TICKETS_LICENSEPLATE')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="vehiclestore_licenseplate" 
                                name="vehiclestore_licenseplate"
                                value={formInvoiceNextTickets.vehiclestore_licenseplate}
                                inputProps={{ style: {width: i18n.language==='es'?161:121}, maxLength: 100 }}
                                style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                variant="standard"
                                disabled
                              /> 
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary">{t('Text_TICKETS_CIF_TRANS')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="rel_trans_documentid" 
                                name="rel_trans_documentid"
                                value={formInvoiceNextTickets.rel_trans_documentid}
                                inputProps={{ style: {width: i18n.language==='es'?161:122}, maxLength: 100 }}
                                style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                variant="standard"
                                disabled
                              />
                             
                            </div>
                          </div>
                          
                          <div className="row p-0"> 
                            <div className="form-group"> 
                              <label className="text-primary">{t('Text_TICKETS_TRADENAME_TRANS')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="rel_trans_tradename" 
                                name="rel_trans_tradename"
                                value={formInvoiceNextTickets.rel_trans_tradename}
                                inputProps={{ style: {width: i18n.language==='es'?641:653}, maxLength: 100 }} 
                                style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                variant="standard"
                                disabled
                              /> 
                            </div>
                          </div>
                          <div className="row p-0"> 
                            <div className="form-group"> 
                              <label className="text-primary">{t('Text_TICKETS_GRNP_TRANS')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="rel_trans_grnp" 
                                name="rel_trans_grnp"
                                value={formInvoiceNextTickets.rel_trans_grnp}
                                inputProps={{ style: {width: i18n.language==='es'?300:300}, maxLength: 100 }} 
                                style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                variant="standard"
                                disabled
                              /> 
                            </div>
                          </div>
 
                          <div className="row p-0"> 
                            <Typography style={{color:"#067330", fontSize: 16}} >  
                                {t('Text_TICKETS_TRADENAME_STORE')} 
                            </Typography>
                          </div>
                          <hr className="sidebar-divider d-none d-md-block"/>
                          
                          <div className="row p-0"> 
                            <div className="form-group">    
                              <label className="text-primary">{t('Text_TICKETS_CIF_STORE')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="ori_tras_documentid" 
                                name="ori_tras_documentid"
                                value={formInvoiceNextTickets.ori_tras_documentid}
                                inputProps={{ style: {width: i18n.language==='es'?200:200}, maxLength: 100 }}
                                style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                variant="standard"
                                disabled
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary">{t('Text_email')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="ori_tras_email" 
                                name="ori_tras_email"
                                value={formInvoiceNextTickets.ori_tras_email}
                                inputProps={{ style: {width: i18n.language==='es'?300:300}, maxLength: 100 }}
                                style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                variant="standard"
                                disabled
                              />
                            </div>
                          </div>  

                          <div className="row p-0"> 
                            <div className="form-group">   
                              <label className="text-primary">{t('Text_TICKETS_NAME_STORE')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_tradename" 
                                  name="ori_tras_tradename"
                                  value={formInvoiceNextTickets.ori_tras_tradename}
                                  inputProps={{ style: {width: i18n.language==='es'?655:673}, maxLength: 100 }} 
                                  style={{ color:"#4e73df", fontSize: 12, textAlign:'right'}} 
                                  variant="standard"
                                  disabled
                                />  
                            </div>                              
                          </div>  

                          <div className="row p-0"> 
                            <div className="form-group">  
                              <Typography style={{flex: '1 1 100%',color:"#067330", fontSize: 16}} id="tableTitle" component="div">
                                {t('Text_NEXTTICKETS_LIST_ARTICLE')} &nbsp;&nbsp;
                              </Typography>
                              <TableContainer component={Paper}>
                                <Table sx={{width: 800 }} aria-labelledby="tableTitle" size='small'>
                                  {header(0)}
                                  <TableBody>
                                    {listItems} 
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div> 
                          {(formInvoiceNextTickets.linesticketsWithOutWeight!==undefined && formInvoiceNextTickets.linesticketsWithOutWeight.length>0) &&
                            <div className="row p-0"> 
                              <div className="form-group">  
                                <Typography style={{flex: '1 1 100%',color:"#067330", fontSize: 16}} id="tableTitle" component="div">
                                  {t('Text_NEXTTICKETS_LIST_ARTICLE_OTHERS')} &nbsp;&nbsp;
                                </Typography>
                                <TableContainer component={Paper}>
                                  <Table sx={{width: 800 }} aria-labelledby="tableTitle" size='small'>
                                    {headerOther()}
                                    <TableBody>
                                      {listItemsOthers} 
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </div>
                          } 
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="col-12 mb-2">
                    <div className="card border-info shadow">
                      <div className="card-header 2 bg-primary text-white text-center text-bold">
                        <h4>{t('Text_NEXTTICKETS_Add_ARTICLE')}</h4>
                      </div>
                      <div className="card-body">
                        <div className="container">
                          <div className="row p-0"> 
                            <div className="form-group">  
                              <Typography style={{flex: '1 1 100%',color:"#067330", fontSize: 16}} id="tableTitle" component="div">
                                {t('Text_TICKETS_SELECT_ARTICLES')} &nbsp;&nbsp;
                                <IconButton size="small"style={{color:"#e74a3b", fontSize: 14}} onClick={handleAddNewArticle}>
                                  <SearchIcon sx={{ color:"#067330"}}/>
                                </IconButton>  
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <IconButton size="small"style={{color:(
                                  editNextArticles.article_code===undefined || editNextArticles.article_code==="" || editNextArticles.article_code==="0" || editNextArticles.article_code===0 )?"#858796":"#067330", fontSize: 14}} disabled={(
                                    editNextArticles.article_code===undefined || editNextArticles.article_code==="" || editNextArticles.article_code==="0" || editNextArticles.article_code===0 )} onClick={handleSelectDI}>
                                  {t('Button_Join')}&nbsp;&nbsp;<JoinInnerIcon sx={{ color:(
                                  editNextArticles.article_code===undefined || editNextArticles.article_code==="" || editNextArticles.article_code==="0" || editNextArticles.article_code===0 )?"#858796":"#067330"}}/>
                                </IconButton>  
                              </Typography> 
                              
                              <TableContainer component={Paper}>
                                <Table sx={{width: 800 }} aria-labelledby="tableTitle" size='small'> 
                                  {header(1)}
                                  <TableBody>
                                    <TableRow hover key={editNextArticles.id}>
                                      <TableCell align="left">
                                        <Typography style={{color:"#4e73df", fontSize: 14}} >
                                          {editNextArticles.article_code}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="left">
                                        <Typography style={{color:"#4e73df", fontSize: 14}} >
                                          {i18n.language==="es"?editNextArticles.article_namees:editNextArticles.article_nameen}
                                        </Typography>  
                                      </TableCell>
                                      <TableCell align="center">
                                        <NumericFormat 
                                          thousandSeparator={','} 
                                          decimalSeparator={'.'} 
                                          decimalScale= {0}
                                          fixedDecimalScale= {true}
                                          allowNegative= {false}
                                          isAllowed={withValueCap}
                                          id="firstweight" 
                                          name="firstweight"
                                          value={editNextArticles.firstweight}
                                          style={{width:118, color:"#FF6347", background:"gray",fontSize: 20,  paddingLeft:5}}  
                                          disabled
                                        /> 
                                      </TableCell>
                                      <TableCell align="center">
                                        <NumericFormat 
                                          thousandSeparator={','} 
                                          decimalSeparator={'.'} 
                                          decimalScale= {0}
                                          fixedDecimalScale= {true}
                                          allowNegative= {false}
                                          isAllowed={withValueCap}
                                          id="secondweight" 
                                          name="secondweight"
                                          value={editNextArticles.secondweight}
                                          style={{width:118, color:"#FF6347", background:"gray",fontSize: 20,  paddingLeft:5}}  
                                          disabled
                                        /> 
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography style={{color:"#067330", fontSize: 14}} >
                                          {editNextArticles.formdi_id===undefined || editNextArticles.formdi_id==="" || editNextArticles.formdi_id==="0" || editNextArticles.formdi_id===0 ?t('Text_NotJoined'):editNextArticles.ownformdi_id}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={handleDeleteNewArticle}>
                                          <DeleteIcon/>
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>  
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="sidebar-divider d-none d-md-block"/>
                  <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                    <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEditNextTickets}>{openEditRowInvoiceTickets?t('Button_Apply'):t('Button_Create')}</button><> </>
                    <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseInvoiceNextTickets}>{t('Button_Cancel')}</button>
                  </div>
                </form>
              </Box>
              <TicketsSearchNextArticles
                showModalSearchNextArticles={showModalSearchNextArticles}
                setShowModalSearchNextArticles={setShowModalSearchNextArticles}
                setEditNextArticles={setEditNextArticles}
                formInvoiceNextTickets={formInvoiceNextTickets}
                setFormInvoiceNextTickets={setFormInvoiceNextTickets}
              /> 
              <TicketsSearchNextDI
                showModalSearchNextDI={showModalSearchNextDI}
                setShowModalSearchNextDI={setShowModalSearchNextDI}
                editNextArticles={editNextArticles}
                setEditNextArticles={setEditNextArticles}
                formInvoiceNextTickets={formInvoiceNextTickets} 
              /> 
            </Container>
          </SwipeableDrawer>
        </div>}
     </div>
  )
}

InvoiceNextTickets.propTypes = {
  openEditRowInvoiceTickets: PropTypes.bool.isRequired,
  setOpenEditRowInvoiceTickets: PropTypes.func.isRequired,
  configPrinter:PropTypes.object.isRequired, 
  setRefeshData: PropTypes.func.isRequired
}; 

export default InvoiceNextTickets;
