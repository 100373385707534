import React, {useState, useEffect} from 'react' 
import PropTypes from 'prop-types'; 
import {useTranslation} from "react-i18next"
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';  
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt'; 
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel'; 
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter'
import useTheme from '@mui/material/styles/useTheme';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { NumericFormat } from 'react-number-format';

const headCellsArticle = [
  {
    id: 'article_code',
    numeric: false,
    disablePadding: false,
    label: 'Text_ARTICLE_Code',
    canOrder:true,
  },
  {
    id: 'article_namees',
    numeric: false,
    disablePadding: false,
    label: 'Text_ARTICLE_Name',
    canOrder:true,
  },
  {
    id: 'estimatedprice',
    numeric: false,
    disablePadding: false,
    label: 'Text_PURCHASEDELIVERY_ARTICLE_PRICE',
    canOrder:true,
  },
  {
    id: 'tax',
    numeric: false,
    disablePadding: false,
    label: 'Text_PURCHASEDELIVERY_ARTICLE_TAX',
    canOrder:true,
  },
  {
    id: 'taxincluded',
    numeric: false,
    disablePadding: false,
    label: 'Text_PURCHASEDELIVERY_ARTICLE_TAX_INCLUDED',
    canOrder:true,
  }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {t('Button_Select')}
          </Typography>
        </TableCell>
        {headCellsArticle.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
       
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegArticle, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegArticle).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataPurchases']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingArticle =(props)=>{
  const { 
    dataArticle,
    numTotRegFilter,
    titleTable, 
    numTotRegArticle,
    activeOffsetArticle,
    rangeOffsetArticle,
    currencyOffsetArticle,
    setCurrencyOffsetArticle, 
    formPurchaseDelivery,
    setFormPurchaseDelivery,
    handleCloseDataEditArticle
  } = props;
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataArticle.length) : 0);
  const [countData,setCountData] = useState(dataArticle.length);
  
  useEffect(
    () => {
      if (dataArticle.length!==countData || dataArticle.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataArticle.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataArticle.length) : 0);  
    },
    [dataArticle,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetArticle(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const selectDefaultArticle = (row) => {
    let articleToAdd={
      "id":"",
      "indexrowarticle":formPurchaseDelivery.linespurchasedelivery.length>0?formPurchaseDelivery.linespurchasedelivery[formPurchaseDelivery.linespurchasedelivery.length-1].indexrowarticle+1:1,
      "purchase_id":formPurchaseDelivery.id,
      "article_id":row.article_id,
      "article_code":row.article_code,
      "article_namees":row.article_namees,
      "article_nameen":row.article_nameen,
      "datepurchase":formPurchaseDelivery.datepurchase,
      "estimatedprice":row.estimatedprice,
      "amount":0,
      "discount":0,
      "tax":row.tax,
      "taxincluded": row.taxincluded,
      "total": 0,
      "ticketweight":row.ticketweight,
      "formdi_id":0,
      "ownformdi_id":0,
      
    }

    formPurchaseDelivery.linespurchasedelivery.push(articleToAdd);
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      totalpurchasedelivery:0,
      totaldiscountpurchasedelivery:0,
      totaltaxespurchasedelivery:0 
    })); 
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery
    }));

    let totalNeto=0; 
    formPurchaseDelivery.linespurchasedelivery.map((item)=>{
      return totalNeto+=item.total;
    });
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      totalNetoPurchasedelivery:totalNeto
     }));
    handleCloseDataEditArticle();
  };
  const listItems= (
  stableSort(dataArticle, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        {(row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#4e73df", fontSize: 16}}  onClick={()=>selectDefaultArticle(row)}>
              <PanToolAltIcon/>
            </IconButton>
          </TableCell>
        }
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 16}} >
            {row.article_code}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16}} >
            {i18n.language==='es'?row.article_namees:row.article_nameen}
          </Typography>  
        </TableCell> 
        <TableCell align="right">
          <Typography style={{color:"#000000", fontSize: 16}} >  
            <NumericFormat
              style={{color:"#4e73df", align:'right', fontSize: 16}}
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {2}
              fixedDecimalScale= {true}
              displayType="text"
              value={row.estimatedprice}
              disabled={true}
            />
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography style={{color:"#000000", fontSize: 16}} >  
            <NumericFormat
              style={{color:"#4e73df", align:'right', fontSize: 16}}
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {2}
              fixedDecimalScale= {true}
              displayType="text"
              value={row.tax}
              disabled={true}
            /> 
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={row.taxincluded} style={{color:"#858796", fontSize: 16}} />
        </TableCell> 
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegArticle={numTotRegArticle} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} />
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetArticle && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetArticle"
                            name="currencyOffsetArticle"
                            value={currencyOffsetArticle}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetArticle
                            }}>
                            {rangeOffsetArticle.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={6}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingArticle.propTypes = {
  dataArticle: PropTypes.array.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  numTotRegArticle: PropTypes.number.isRequired,
  activeOffsetArticle: PropTypes.bool.isRequired,
  rangeOffsetArticle: PropTypes.array.isRequired,
  currencyOffsetArticle: PropTypes.number.isRequired,
  setCurrencyOffsetArticle: PropTypes.func.isRequired,
  formPurchaseDelivery: PropTypes.object.isRequired,
  setFormPurchaseDelivery: PropTypes.func.isRequired,
  handleCloseDataEditArticle: PropTypes.func.isRequired
};
  
export default TableSortingArticle;
