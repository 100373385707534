import React, {  useEffect,useCallback,useState } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Box from '@mui/material/Box';  
import Container from '@mui/material/Container'; 
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import "css/generalSettings.css";  
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingFormatDI from './SortingTablesFormatDI'
import PropTypes from 'prop-types'; 

const headCellsFormatDI = [
  { id: 'name', numeric: true, label: 'Text_FORMAT_DI_DESCRIPTION' },
  { id: 'opt_tras', numeric: false, label: 'Text_FORMAT_DI_OPT_TRAS' },
  { id: 'ori_tras', numeric: false, label: 'Text_FORMAT_DI_ORI_TRAS_PROD' },
  { id: 'aut_tras', numeric: false, label: 'Text_FORMAT_DI_ORI_TRAS_EMPR' },
  { id: 'dest_tras', numeric: false, label: 'Text_FORMAT_DI_DEST_TRAS_PROD' },
  { id: 'aut_dest', numeric: false, label: 'Text_FORMAT_DI_DEST_TRAS_EMPR' },
  { id: 'rel_trans', numeric: false, label: 'Text_FORMAT_DI_Carrier' },
  { id: 'resp_prod', numeric: false, label: 'Text_FORMAT_DI_SRAP' }
];
 

const FormatDetailsDISettings = ({ openCreateFormatDetailsDI,setOpenCreateFormatDetailsDI,rowEdit,setRowEdit,titlees,titleen}) => {
  const { t, i18n } = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const [data, setData] = useState(rowEdit);
  const dispatch = useDispatch();
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openCreateFormatDetailsDI) { 
        setData(rowEdit);
        setOpenCreateFormatDetailsDI(false);
      }
    },
    [setData, rowEdit, openCreateFormatDetailsDI, setOpenCreateFormatDetailsDI]
  );

  const closeModalFormatDI = () => {  
    setData(rowEdit);
    setOpenCreateFormatDetailsDI(false);
  };
    
  const toggleDrawerFormatDI = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      closeModalFormatDI();
      return;
    }
  };

  useEffect(() => {
    if (openCreateFormatDetailsDI) {
      setData(rowEdit);
    }
  }, [rowEdit, openCreateFormatDetailsDI]);
 
  const handleSubmitFormatDI = () => {
     setRowEdit(rowEdit => ({
      ...rowEdit,
      formatDIDTO:{
        id : data.id,
        typesdi_id : data.typesdi_id,

        opt_tras_view :data.opt_tras_view,
        opt_tras_expand :data.opt_tras_expand,
        opt_tras_handle :data.opt_tras_handle,
        opt_tras_view_name :data.opt_tras_view_name,
        opt_tras_view_contactname :data.opt_tras_view_contactname,
        opt_tras_view_address :data.opt_tras_view_address,
        opt_tras_view_country :data.opt_tras_view_country,
        opt_tras_view_community :data.opt_tras_view_community,
        opt_tras_view_phone :data.opt_tras_view_phone,
        opt_tras_view_email :data.opt_tras_view_email,
        opt_tras_view_nima :data.opt_tras_view_nima,
        opt_tras_view_cnae :data.opt_tras_view_cnae,
        opt_tras_view_inscription :data.opt_tras_view_inscription,
        opt_tras_view_opt :data.opt_tras_view_opt,
        opt_tras_view_licenseplate :data.opt_tras_view_licenseplate,
        opt_tras_view_economicactivity:data.opt_tras_view_economicactivity,

        opt_tras_handle_documnettype :data.opt_tras_handle_documnettype,
        opt_tras_handle_documentid :data.opt_tras_handle_documentid,
        opt_tras_handle_tradename :data.opt_tras_handle_tradename,
        opt_tras_handle_name :data.opt_tras_handle_name,
        opt_tras_handle_contactname :data.opt_tras_handle_contactname,
        opt_tras_handle_address :data.opt_tras_handle_address,
        opt_tras_handle_phone :data.opt_tras_handle_phone,
        opt_tras_handle_email :data.opt_tras_handle_email,
        opt_tras_handle_nima :data.opt_tras_handle_nima,
        opt_tras_handle_cnae :data.opt_tras_handle_cnae,
        opt_tras_handle_inscription :data.opt_tras_handle_inscription,
        opt_tras_handle_opt :data.opt_tras_handle_opt,
        opt_tras_handle_licenseplate :data.opt_tras_handle_licenseplate,
        opt_tras_handle_economicactivity:data.opt_tras_handle_economicactivity,

        ori_tras_view :data.ori_tras_view,
        ori_tras_expand :data.ori_tras_expand,
        ori_tras_handle :data.ori_tras_handle,
        ori_tras_view_name :data.ori_tras_view_name,
        ori_tras_view_contactname :data.ori_tras_view_contactname,
        ori_tras_view_address :data.ori_tras_view_address,
        ori_tras_view_country :data.ori_tras_view_country,
        ori_tras_view_community :data.ori_tras_view_community,
        ori_tras_view_phone :data.ori_tras_view_phone,
        ori_tras_view_email :data.ori_tras_view_email,
        ori_tras_view_nima :data.ori_tras_view_nima,
        ori_tras_view_cnae :data.ori_tras_view_cnae,
        ori_tras_view_inscription :data.ori_tras_view_inscription,
        ori_tras_view_opt :data.ori_tras_view_opt,
        ori_tras_view_licenseplate :data.ori_tras_view_licenseplate,
        ori_tras_view_economicactivity :data.ori_tras_view_economicactivity,

        ori_tras_handle_documnettype :data.ori_tras_handle_documnettype,
        ori_tras_handle_documentid :data.ori_tras_handle_documentid,
        ori_tras_handle_tradename :data.ori_tras_handle_tradename,
        ori_tras_handle_name :data.ori_tras_handle_name,
        ori_tras_handle_contactname :data.ori_tras_handle_contactname,
        ori_tras_handle_address :data.ori_tras_handle_address,
        ori_tras_handle_phone :data.ori_tras_handle_phone,
        ori_tras_handle_email :data.ori_tras_handle_email,
        ori_tras_handle_nima :data.ori_tras_handle_nima,
        ori_tras_handle_cnae :data.ori_tras_handle_cnae,
        ori_tras_handle_inscription :data.ori_tras_handle_inscription,
        ori_tras_handle_opt :data.ori_tras_handle_opt,
        ori_tras_handle_licenseplate :data.ori_tras_handle_licenseplate,
        ori_tras_handle_economicactivity :data.ori_tras_handle_economicactivity,

        aut_tras_view :data.aut_tras_view,
        aut_tras_expand :data.aut_tras_expand,
        aut_tras_handle :data.aut_tras_handle,
        aut_tras_view_name :data.aut_tras_view_name,
        aut_tras_view_contactname :data.aut_tras_view_contactname,
        aut_tras_view_address :data.aut_tras_view_address,
        aut_tras_view_country :data.aut_tras_view_country,
        aut_tras_view_community :data.aut_tras_view_community,
        aut_tras_view_phone :data.aut_tras_view_phone,
        aut_tras_view_email :data.aut_tras_view_email,
        aut_tras_view_nima :data.aut_tras_view_nima,
        aut_tras_view_cnae :data.aut_tras_view_cnae,
        aut_tras_view_inscription :data.aut_tras_view_inscription,
        aut_tras_view_opt :data.aut_tras_view_opt,
        aut_tras_view_licenseplate :data.aut_tras_view_licenseplate,
        aut_tras_view_economicactivity :data.aut_tras_view_economicactivity,
        
        aut_tras_handle_documnettype :data.aut_tras_handle_documnettype,
        aut_tras_handle_documentid :data.aut_tras_handle_documentid,
        aut_tras_handle_tradename :data.aut_tras_handle_tradename,
        aut_tras_handle_name :data.aut_tras_handle_name,
        aut_tras_handle_contactname :data.aut_tras_handle_contactname,
        aut_tras_handle_address :data.aut_tras_handle_address,
        aut_tras_handle_phone :data.aut_tras_handle_phone,
        aut_tras_handle_email :data.aut_tras_handle_email,
        aut_tras_handle_nima :data.aut_tras_handle_nima,
        aut_tras_handle_cnae :data.aut_tras_handle_cnae,
        aut_tras_handle_inscription :data.aut_tras_handle_inscription,
        aut_tras_handle_opt :data.aut_tras_handle_opt,
        aut_tras_handle_licenseplate :data.aut_tras_handle_licenseplate,
        aut_tras_handle_economicactivity :data.aut_tras_handle_economicactivity,

        dest_tras_view :data.dest_tras_view,
        dest_tras_expand :data.dest_tras_expand,
        dest_tras_handle :data.dest_tras_handle,
        dest_tras_view_name :data.dest_tras_view_name,
        dest_tras_view_contactname :data.dest_tras_view_contactname,
        dest_tras_view_address :data.dest_tras_view_address,
        dest_tras_view_country :data.dest_tras_view_country,
        dest_tras_view_community :data.dest_tras_view_community,
        dest_tras_view_phone :data.dest_tras_view_phone,
        dest_tras_view_email :data.dest_tras_view_email,
        dest_tras_view_nima :data.dest_tras_view_nima,
        dest_tras_view_cnae :data.dest_tras_view_cnae,
        dest_tras_view_inscription :data.dest_tras_view_inscription,
        dest_tras_view_opt :data.dest_tras_view_opt,
        dest_tras_view_licenseplate :data.dest_tras_view_licenseplate,
        dest_tras_view_economicactivity :data.dest_tras_view_economicactivity,

        dest_tras_handle_documnettype :data.dest_tras_handle_documnettype,
        dest_tras_handle_documentid :data.dest_tras_handle_documentid,
        dest_tras_handle_tradename :data.dest_tras_handle_tradename,
        dest_tras_handle_name :data.dest_tras_handle_name,
        dest_tras_handle_contactname :data.dest_tras_handle_contactname,
        dest_tras_handle_address :data.dest_tras_handle_address,
        dest_tras_handle_phone :data.dest_tras_handle_phone,
        dest_tras_handle_email :data.dest_tras_handle_email,
        dest_tras_handle_nima :data.dest_tras_handle_nima,
        dest_tras_handle_cnae :data.dest_tras_handle_cnae,
        dest_tras_handle_inscription :data.dest_tras_handle_inscription,
        dest_tras_handle_opt :data.dest_tras_handle_opt,
        dest_tras_handle_licenseplate :data.dest_tras_handle_licenseplate,
        dest_tras_handle_economicactivity :data.dest_tras_handle_economicactivity,

        aut_dest_view :data.aut_dest_view,
        aut_dest_expand :data.aut_dest_expand,
        aut_dest_handle :data.aut_dest_handle,
        aut_dest_view_name :data.aut_dest_view_name,
        aut_dest_view_contactname :data.aut_dest_view_contactname,
        aut_dest_view_address :data.aut_dest_view_address,
        aut_dest_view_country :data.aut_dest_view_country,
        aut_dest_view_community :data.aut_dest_view_community,
        aut_dest_view_phone :data.aut_dest_view_phone,
        aut_dest_view_email :data.aut_dest_view_email,
        aut_dest_view_nima :data.aut_dest_view_nima,
        aut_dest_view_cnae :data.aut_dest_view_cnae,
        aut_dest_view_inscription :data.aut_dest_view_inscription,
        aut_dest_view_opt :data.aut_dest_view_opt,
        aut_dest_view_licenseplate :data.aut_dest_view_licenseplate,
        aut_dest_view_economicactivity :data.aut_dest_view_economicactivity,
        
        aut_dest_handle_documnettype :data.aut_dest_handle_documnettype,
        aut_dest_handle_documentid :data.aut_dest_handle_documentid,
        aut_dest_handle_tradename :data.aut_dest_handle_tradename,
        aut_dest_handle_name :data.aut_dest_handle_name,
        aut_dest_handle_contactname :data.aut_dest_handle_contactname,
        aut_dest_handle_address :data.aut_dest_handle_address,
        aut_dest_handle_phone :data.aut_dest_handle_phone,
        aut_dest_handle_email :data.aut_dest_handle_email,
        aut_dest_handle_nima :data.aut_dest_handle_nima,
        aut_dest_handle_cnae :data.aut_dest_handle_cnae,
        aut_dest_handle_inscription :data.aut_dest_handle_inscription,
        aut_dest_handle_opt :data.aut_dest_handle_opt,
        aut_dest_handle_licenseplate :data.aut_dest_handle_licenseplate,
        aut_dest_handle_economicactivity :data.aut_dest_handle_economicactivity,

        rel_trans_view :data.rel_trans_view,
        rel_trans_expand :data.rel_trans_expand,
        rel_trans_handle :data.rel_trans_handle,
        rel_trans_view_name :data.rel_trans_view_name,
        rel_trans_view_contactname :data.rel_trans_view_contactname,
        rel_trans_view_address :data.rel_trans_view_address,
        rel_trans_view_country :data.rel_trans_view_country,
        rel_trans_view_community :data.rel_trans_view_community,
        rel_trans_view_phone :data.rel_trans_view_phone,
        rel_trans_view_email :data.rel_trans_view_email,
        rel_trans_view_nima :data.rel_trans_view_nima,
        rel_trans_view_cnae :data.rel_trans_view_cnae,
        rel_trans_view_inscription :data.rel_trans_view_inscription,
        rel_trans_view_opt :data.rel_trans_view_opt,
        rel_trans_view_licenseplate :data.rel_trans_view_licenseplate,
        rel_trans_view_economicactivity :data.rel_trans_view_economicactivity,
        
        rel_trans_handle_documnettype :data.rel_trans_handle_documnettype,
        rel_trans_handle_documentid :data.rel_trans_handle_documentid,
        rel_trans_handle_tradename :data.rel_trans_handle_tradename,
        rel_trans_handle_name :data.rel_trans_handle_name,
        rel_trans_handle_contactname :data.rel_trans_handle_contactname,
        rel_trans_handle_address :data.rel_trans_handle_address,
        rel_trans_handle_phone :data.rel_trans_handle_phone,
        rel_trans_handle_email :data.rel_trans_handle_email,
        rel_trans_handle_nima :data.rel_trans_handle_nima,
        rel_trans_handle_cnae :data.rel_trans_handle_cnae,
        rel_trans_handle_inscription :data.rel_trans_handle_inscription,
        rel_trans_handle_opt :data.rel_trans_handle_opt,
        rel_trans_handle_licenseplate :data.rel_trans_handle_licenseplate,
        rel_trans_handle_economicactivity :data.rel_trans_handle_economicactivity,

        resp_prod_view :data.resp_prod_view,
        resp_prod_expand :data.resp_prod_expand,
        resp_prod_handle :data.resp_prod_handle,
        resp_prod_view_name :data.resp_prod_view_name,
        resp_prod_view_contactname :data.resp_prod_view_contactname,
        resp_prod_view_address :data.resp_prod_view_address,
        resp_prod_view_country :data.resp_prod_view_country,
        resp_prod_view_community :data.resp_prod_view_community,
        resp_prod_view_phone :data.resp_prod_view_phone,
        resp_prod_view_email :data.resp_prod_view_email,
        resp_prod_view_nima :data.resp_prod_view_nima,
        resp_prod_view_cnae :data.resp_prod_view_cnae,
        resp_prod_view_inscription :data.resp_prod_view_inscription,
        resp_prod_view_opt :data.resp_prod_view_opt,
        resp_prod_view_licenseplate :data.resp_prod_view_licenseplate,
        resp_prod_view_economicactivity :data.resp_prod_view_economicactivity,
        
        resp_prod_handle_documnettype :data.resp_prod_handle_documnettype,
        resp_prod_handle_documentid :data.resp_prod_handle_documentid,
        resp_prod_handle_tradename :data.resp_prod_handle_tradename,
        resp_prod_handle_name :data.resp_prod_handle_name,
        resp_prod_handle_contactname :data.resp_prod_handle_contactname,
        resp_prod_handle_address :data.resp_prod_handle_address,
        resp_prod_handle_phone :data.resp_prod_handle_phone,
        resp_prod_handle_email :data.resp_prod_handle_email,
        resp_prod_handle_nima :data.resp_prod_handle_nima,
        resp_prod_handle_cnae :data.resp_prod_handle_cnae,
        resp_prod_handle_inscription :data.resp_prod_handle_inscription,
        resp_prod_handle_opt :data.resp_prod_handle_opt,
        resp_prod_handle_licenseplate :data.resp_prod_handle_licenseplate,
        resp_prod_handle_economicactivity :data.resp_prod_handle_economicactivity
        
      },
      
    }));
    const fetchData = async () => {
      try {
        const getData = await helpHttp().put(ENDPOINT, `typesdi/updateformatdi`, token, data);
        if (getData.status === "OK") {
          dispatch(successErrorAction(t('Text_SaveData')));
        } else {
          dispatch(warningErrorAction(t(getData.cause)));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_ConnectionFailied')));
      } 
    };

    if (openCreateFormatDetailsDI) {
      fetchData();
      closeModalFormatDI();
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]); 
  return (
    <>
      {openCreateFormatDetailsDI && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerFormatDI(false)}
            onOpen={toggleDrawerFormatDI(true)}
          >
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                    {t('Text_FORMAT_DI_TITTLE')}
                  </h1>
                  <div>
                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_TypesDi_Create'))?
                      <div>
                        <button type="button" className="buttonCreate" onClick={handleSubmitFormatDI}>{t('Button_Update')}</button><> </>
                        <button type="button" className="buttonBack" onClick={closeModalFormatDI}>{t('Button_Back')}</button>
                      </div>:
                      <div>
                        <button type="button" className="buttonBack" onClick={closeModalFormatDI}>{t('Button_Back')}</button>
                      </div>
                    }
                  </div>
                </div>
                <div id="myDIV">  
                  <h1 className="h1NewStyle mb-2">
                    {i18n.language==="es"?titlees:titleen}
                  </h1>  
                </div>  
                <br/>
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createformatdi" onSubmit={closeModalFormatDI}> 
                  <div className="card shadow mb-4">    
                    <div className="card-body">  
                      <TableSortingFormatDI
                        data={data} 
                        setData={setData}
                        headCellsFormatDI={headCellsFormatDI}
                      /> 
                    </div>  
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>    
        </div>
      } 
    </>
  )
}

FormatDetailsDISettings.propTypes = {
  openCreateFormatDetailsDI: PropTypes.bool.isRequired,
  setOpenCreateFormatDetailsDI: PropTypes.func.isRequired,
  rowEdit: PropTypes.object.isRequired,
  setRowEdit: PropTypes.func.isRequired,
  titlees: PropTypes.string.isRequired,
  titleen: PropTypes.string.isRequired
};

export default FormatDetailsDISettings;