import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next" 
import Box from '@mui/material/Box'; 
import CircularProgress from '@mui/material/CircularProgress'; 
import Container from '@mui/material/Container';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import TextField from '@mui/material/TextField';
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingEndpoint  from "pages/endpoints/SortingTablesEndpoint";
import { Loading } from "components/utils/Loading";
import PropTypes from 'prop-types';

const initialFilters= {
 "idFrom":"",
 "idTo":"",
 "documentIdFrom":"",
 "documentIdTo":"",
 "tradenameFrom":"",
 "tradenameTo":"",
 "nameFrom":"",
 "nameTo":"",
 "contacpersonFrom":"",
 "contacpersonTo":"",
 "endpointFrom":"",
 "endpointTo":""
};

const initialState = [{
  "id":"",
   "documentid":"",
   "tradename":"",
   "name":"",
   "contacperson":"",
   "endpoint":"",
 }];

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:false,
  },
  {
    id: 'documentid',
    numeric: false,
    disablePadding: false,
    label: 'Text_documentid',
    canOrder:true,
  },
  {
    id: 'tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_tradename',
    canOrder:true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Text_AliasUser',
    canOrder:true,
  },
  {
    id: 'contacperson',
    numeric: false,
    disablePadding: false,
    label: 'Text_name',
    canOrder:true,
  },
  {
    id: 'endpoint',
    numeric: false,
    disablePadding: false,
    label: 'Text_Endpoint',
    canOrder:true,
  }
];

const EndpointSettings = ({setShowPrincipalMenu,showEndpointSettings, setShowModalEndpointSettings,setShowModalGeneralSettings}) => {
  const {t} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);   
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const [data, setData] = useState(initialState); 
  const [filters, setFilters]= useState(initialFilters);
  const [applyFilters, setApplyFilters]= useState(initialFilters);
  const [numTotReg,setNumTotReg]= useState(0);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [errorsFilters, setErrorsFilters] = useState({});
  const [errors, setErrors] = useState({});
  const [inputText, setInputText] = useState("");
  const [openEditRow,setOpenEditRow]= useState(false);
  const [openEditFilter,setOpenEditFilter]= useState(false);
  const [rowEdit,setRowEdit]= useState(initialState[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showEndpointSettings) {
        setData(initialState); 
        setFilters(initialFilters);
        setApplyFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({});
        setErrors({});
        setInputText(""); 
        setOpenEditRow(false);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        setIsLoading(false);
        setIsLoadingError(false); 
        setActiveOffset(false);
        setCurrencyOffset(1);
        setRangeOffset([{}]);
        setShowPrincipalMenu(true);
        setShowModalEndpointSettings(false);
        setShowModalGeneralSettings(false);
      }
    },
    [
      setData, 
      setFilters,
      setApplyFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters,
      setErrors,
      setInputText, 
      setOpenEditRow,
      setOpenEditFilter,
      setRowEdit,
      setIsLoading,
      setIsLoadingError, 
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      setShowPrincipalMenu,
      showEndpointSettings, 
      setShowModalEndpointSettings,
      setShowModalGeneralSettings
    ]
  );

  const closeModalEndpointsSettings = () => {    
    setData(initialState); 
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setNumTotReg(0);
    setNumTotRegFilter(0);
    setErrorsFilters({});
    setErrors({});
    setInputText(""); 
    setOpenEditRow(false);
    setOpenEditFilter(false);
    setRowEdit(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false); 
    setActiveOffset(false);
    setCurrencyOffset(1);
    setRangeOffset([{}]);
    setShowPrincipalMenu(true);
    setShowModalEndpointSettings(false);
    setShowModalGeneralSettings(false);
  };
 
  const openEditFilters = () => {
    setFilters(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filters);
      setFilters(initialFilters);
      setErrorsFilters({});
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

   
  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFilters({});
      setFilters(initialFilters);
      setOpenEditFilter(!openEditFilter);
    }
  };

  const validateFormFilter = () => {

    let errorsFilters = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    let regexNumber = /^[0-9]+$/;
    if (filters.idFrom!=="" & !regexNumber.test(filters.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filters.idTo!=="" & !regexNumber.test(filters.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
    if (filters.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filters.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filters.idTo)<parseInt(filters.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filters.documentIdFrom)) {
      errorsFilters.documentIdFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.documentIdTo)) {
      errorsFilters.documentIdTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.tradenameFrom)) {
      errorsFilters.tradenameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.tradenameTo)) {
      errorsFilters.tradenameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameFrom)) {
      errorsFilters.nameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.nameTo)) {
      errorsFilters.nameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.contacpersonFrom)) {
      errorsFilters.contacpersonFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.contacpersonTo)) {
      errorsFilters.contacpersonTo = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(filters.endpointFrom)) {
      errorsFilters.endpointFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.endpointTo)) {
      errorsFilters.endpointTo = 'Text_TextNoValid';
    } 
    if (filters.documentIdTo!=="" & filters.documentIdTo<filters.documentIdFrom){
      errorsFilters.documentIdTo = 'Text_TextGreater_documentid';
    }
    if (filters.tradenameTo!=="" & filters.tradenameTo<filters.tradenameFrom){
      errorsFilters.tradenameTo = 'Text_TextGreater_tradename';
    }
    if (filters.nameTo!=="" & filters.nameTo<filters.nameFrom){
      errorsFilters.nameTo = 'Text_TextGreater_name';
    }
    if (filters.contacpersonTo!=="" & filters.contacpersonTo<filters.contacpersonFrom){
      errorsFilters.contacpersonTo = 'Text_TextGreater_contactName';
    }
    if (filters.endpointTo!=="" & filters.endpointTo<filters.endpointFrom){
      errorsFilters.endpointTo = 'Text_TextGreater_endpoint';
    }

 
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
      let dataTranslate=t(el.id+el.documentid+el.tradename+el.name+el.contacperson+el.endpoint) ;
      return dataTranslate.toLowerCase().includes(inputText);
    }
  })

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handleSubmitDataEdit = () => {
    setErrors(validateForm());
    const errorsToCheck = validateForm()
    if ((Object.keys(errorsToCheck).length === 0)) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const getData = await helpHttp().put(ENDPOINT, `endpoint/updateendpoints`, token, rowEdit) ;
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEdit();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      if (errorsToCheck.endpoint) {
        dispatch(warningErrorAction(t(errorsToCheck.endpoint)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };

  const handleCloseDataEdit = () => { 
    setOpenEditRow(false);
    setErrors({});
    setRowEdit(rowEdit); 
    setIsLoading(false);
    setIsLoadingError(false); 
    toggleDrawerDataEdit(false);
	}; 

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrors(validateForm());
  };
  
  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setRowEdit(rowEdit => ({
      ...rowEdit,
      [name]: value
    }));
  };

  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const validateForm = () => {
    let errors = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (regexInvalidCharacters.test(rowEdit.endpoint)) {
      errors.endpoint = 'Text_TextNoValid';
    } 
    
    return errors;
  };
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegFilter>LIMITPAGESIZE){
      setActiveOffset(true);
      setRangeOffset([
        { value: 0,
          label: " 1 - "+Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ]);
      for (let i = Number(LIMITPAGESIZE); i<numTotRegFilter; i=i+ Number(LIMITPAGESIZE)){
        setRangeOffset(
          current=>[
            ...current,
            ...[
              {
                value: i,
                label: " "+(i+1).toLocaleString('de-DE')+" - "+(i+Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
              }
            ]
          ]
        )
      }
    } else{
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  },[numTotRegFilter,LIMITPAGESIZE]);
  
  useEffect(() => {
    let ignore = false;  
    const fetchnumTotReg = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `endpoint/getinformationtableendpoints`, token);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotReg(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `endpoint/getcountfilterendpoints`, token, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT, `endpoint/getendpoints`, token, currencyOffset, LIMITPAGESIZE, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setData(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (showEndpointSettings) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true; };
    }
  }, [ENDPOINT, t, token, dispatch, openEditRow, showEndpointSettings, LIMITPAGESIZE, applyFilters, currencyOffset]);
  return (
    <>
      {showEndpointSettings ? ( 
        <div className="container-fluid">
            {/* Search input */}
            <div id="myDIV">
              <h1 className="h1NewStyle mb-2">
              {isLoadingError?
              <i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>
              :isLoading?
              <CircularProgress color={"primary"}/>:
              <i className="fas fa-solid fa-thumbtack text-warning"></i>} {t('Text_Endpoint_Config')}</h1>
              {isLoading ?
                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                  <div>
                    <button type="button" className="buttonBack" onClick={closeModalEndpointsSettings}>{t('Button_Back')}</button>
                  </div>
              }
          </div>  
          <br/>
          {!isLoading ?
          <>
            <div className="searchStyle">
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={inputText}
                placeholder={t('Text_Endpoint_Search')}
                onChange={inputHandler}
                fullWidth
                label={t('Text_Endpoint_Search')}>
              </TextField>
            </div>
            <br/>
            <div id="myDIV">
              <h1 className="h6NewStyle mb-2">
              {t('Text_Filter')}&nbsp;
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.documentIdFrom!=="" || applyFilters.documentIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_documentid')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.tradenameFrom!=="" || applyFilters.tradenameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_tradename')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.nameFrom!=="" || applyFilters.nameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_name')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.contacpersonFrom!=="" || applyFilters.contacpersonTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_contacperson')}</button><> </> 
                  <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.endpointFrom!=="" || applyFilters.endpointTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Endpoint')}</button><> </>  
                </h1>
            </div>
            <br/>
            <div className="card shadow mb-4">    
              <div className="card-body">
                <TableSortingEndpoint
                  data={filteredData}
                  headCells={headCells}
                  numTotRegFilter={numTotRegFilter}
                  titleTable={t('Text_Endpoint_Table')}
                  setOpenEditRow={setOpenEditRow}
                  setRowEdit={setRowEdit}
                  showEditButton={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Modify_ENDPOINT'))?true:false}
                  numTotReg={numTotReg}
                  activeOffset={activeOffset}
                  rangeOffset={rangeOffset}
                  currencyOffset={currencyOffset}
                  setCurrencyOffset={setCurrencyOffset}
                >
                </TableSortingEndpoint>
              </div>             
            </div>
            {openEditFilter &&
              <div>
                <SwipeableDrawer
                  anchor='right'
                  open={true}
                  onClose={toggleDrawerFilterEdit(false)}
                  onOpen={toggleDrawerFilterEdit(true)}>
                  <Container >
                    <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                      <br/>
                      <div id="myDIV">
                        <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                      </div>
                      <hr className="sidebar-divider d-none d-md-block"/>
                      <form className="createfilter" onSubmit={handleCancelFilters}>  
                        <div className="form-floating mb-3 px-4 primary">
                          <div className="form-group">
                            <TextField
                              id="idFrom" 
                              name="idFrom"
                              value={filters.idFrom}
                              inputProps={{ style: {width: 400}, maxLength: 15 }}
                              label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('Text_Filter_Code_From')}
                              placeholder={t('PlaceHolder_Filter_Code_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id="idTo" 
                              name="idTo"
                              value={filters.idTo}
                              inputProps={{ style: {width: 400}, maxLength: 15 }}
                              label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('Text_Filter_Code_To')}
                              placeholder={t('PlaceHolder_Filter_Code_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEdit}
                              onChange={handleChangeFilterEdit}/>
                          </div>
                          <div className="form-group">
                          <TextField
                            id="documentIdFrom" 
                            name="documentIdFrom"
                            value={filters.documentIdFrom}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.documentIdFrom!=null?t(errorsFilters.documentIdFrom):t('Text_Filter_documentId_From')}
                            placeholder={t('PlaceHolder_Filter_documentid_From')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <TextField
                            id="documentIdTo" 
                            name="documentIdTo"
                            value={filters.documentIdTo}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.documentIdTo!=null?t(errorsFilters.documentIdTo):t('Text_Filter_documentId_To')}
                            placeholder={t('PlaceHolder_Filter_documentid_to')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                          <div className="form-group">
                          <TextField
                            id="tradenameFrom" 
                            name="tradenameFrom"
                            value={filters.tradenameFrom}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.tradenameFrom!=null?t(errorsFilters.tradenameFrom):t('Text_Filter_tradename_From')}
                            placeholder={t('PlaceHolder_Filter_tradename_From')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                          <TextField
                            id="tradenameTo" 
                            name="tradenameTo"
                            value={filters.tradenameTo}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.tradenameTo!=null?t(errorsFilters.tradenameTo):t('Text_Filter_tradename_To')}
                            placeholder={t('PlaceHolder_Filter_tradename_to')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <div className="form-group">
                          <TextField
                            id="nameFrom" 
                            name="nameFrom"
                            value={filters.nameFrom}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.nameFrom!=null?t(errorsFilters.nameFrom):t('Text_Filter_name_From')}
                            placeholder={t('PlaceHolder_Filter_name_From')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                          <TextField
                            id="nameTo" 
                            name="nameTo"
                            value={filters.nameTo}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.nameTo!=null?t(errorsFilters.nameTo):t('Text_Filter_name_To')}
                            placeholder={t('PlaceHolder_Filter_name_To')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <div className="form-group">
                          <TextField
                            id="contacpersonFrom" 
                            name="contacpersonFrom"
                            value={filters.contacpersonFrom}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.contacpersonFrom!=null?t(errorsFilters.contacpersonFrom):t('Text_Filter_contactName_From')}
                            placeholder={t('PlaceHolder_Filter_contactName_From')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <TextField
                            id="contacpersonTo" 
                            name="contacpersonTo"
                            value={filters.contacpersonTo}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.contacpersonTo!=null?t(errorsFilters.contacpersonTo):t('Text_Filter_contactName_To')}
                            placeholder={t('PlaceHolder_Filter_contactName_To')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <div className="form-group">
                          <TextField
                            id="endpointFrom" 
                            name="endpointFrom"
                            value={filters.endpointFrom}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.endpointFrom!=null?t(errorsFilters.endpointFrom):t('PlaceHolder_Filter_Endpoint_From')}
                            placeholder={t('PlaceHolder_Filter_Endpoint_From')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <TextField
                            id="endpointTo" 
                            name="endpointTo"
                            value={filters.endpointTo}
                            inputProps={{ style: {width: 400}, maxLength: 100 }}
                            label={errorsFilters.endpointTo!=null?t(errorsFilters.endpointTo):t('PlaceHolder_Filter_Endpoint_To')}
                            placeholder={t('PlaceHolder_Filter_Endpoint_To')}
                            variant="outlined"
                            onBlur={handleBlurFilterEdit}
                            onChange={handleChangeFilterEdit}/>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
            }
            {( openEditRow) && 
              <div>
                <SwipeableDrawer
                  anchor='right'
                  open={true}
                  onClose={toggleDrawerDataEdit(false)}
                  onOpen={toggleDrawerDataEdit(true)}>
                  <Container >
                    <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                      <br/>
                      <div id="myDIV">
                        <h1 className="h1NewStyle px-4 mb-2">{t('Text_Endpoint_Edit')}</h1>
                      </div>  
                      <hr className="sidebar-divider d-none d-md-block"/>
                      <form className="createendpoin" onSubmit={handleSubmitDataEdit}>  
                        <div className="form-floating mb-3 px-4 primary">
                          <label className="text-primary">{t('Text_Code')} :</label>
                          <div className="form-group">
                            <TextField
                              id="id" 
                              name="id"
                              value={rowEdit.id}
                              inputProps={{ style: {width: 200} }}
                              label={t('Text_Code')}
                              placeholder={t('Text_Code')}
                              variant="standard"
                              disabled/>
                          </div>                        
                          <label className="text-primary">{t('Text_documentid')} :</label>
                          <div className="form-group">
                            <TextField
                              id="documentid" 
                              name="documentid"
                              value={rowEdit.documentid}
                              inputProps={{ style: {width: 450}, maxLength: 100  }}
                              label={t('Text_documentid')}
                              placeholder={t('Text_documentid')}
                              variant="standard" 
                              disabled
                              />
                          </div>
                          
                          <label className="text-primary">{t('Text_tradename')} :</label>
                          <div className="form-group">
                            <TextField
                              id="tradename" 
                              name="tradename"
                              value={rowEdit.tradename}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={t('Text_tradename')}
                              placeholder={t('Text_tradename')}
                              variant="standard"
                              disabled
                            />
                          </div>
                           <label className="text-primary">{t('Text_Name')} :</label>
                          <div className="form-group">
                            <TextField
                              id="name" 
                              name="name"
                              value={rowEdit.name}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={t('Text_name')}
                              placeholder={t('Text_name')}
                              variant="standard"
                              disabled
                            />
                          </div>
                          <label className="text-primary">{t('Text_contacperson')} :</label>
                          <div className="form-group">
                            <TextField
                              id="contacperson" 
                              name="contacperson"
                              value={rowEdit.contacperson}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={t('Text_contacperson')}
                              placeholder={t('Text_contacperson')}
                              variant="standard"
                              disabled
                          />
                          </div>
                          <label className="text-primary">{t('Text_Endpoint')} :</label>
                          <div className="form-group">
                            <TextField
                              id="endpoint" 
                              name="endpoint"
                              value={rowEdit.endpoint}
                              inputProps={{ style: {width: 450}, maxLength: 100 }}
                              label={errors.endpoint!=null?t(errors.endpoint):t('PlaceHolde_Endpoint')}
                              placeholder={t('PlaceHolde_Endpoint')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}/>
                          </div>  
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEdit}>{openEditRow?t('Button_Update'):t('Button_Create')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEdit}>{t('Button_Cancel')}</button>
                          </div>
                        </div>
                      </form>
                    </Box>
                  </Container>
                </SwipeableDrawer>
              </div>
            } 
             
            {/* End Content Row */}
          </>:
            <Loading isLoadingData={isLoading}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

EndpointSettings.propTypes = {
  setShowPrincipalMenu: PropTypes.func.isRequired,
  showEndpointSettings: PropTypes.bool.isRequired,
  setShowModalEndpointSettings: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired 
};

export default EndpointSettings;