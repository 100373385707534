import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem  from "@mui/material/MenuItem"; 
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { useTranslation } from "react-i18next"   
import { NumericFormat } from 'react-number-format';
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingVehicles from "pages/vehicles/SortingTablesVehicles";  
import PropTypes from 'prop-types'; 

const initialFilters= {
  "idFrom":"",
  "idTo":"",
  "licenseplateFrom":"",
  "licenseplateTo":"",
  "taraFrom":"",
  "taraTo":"",
  "pmaFrom":"",
  "pmaTo":"",
  "checkpma":true,
  "notcheckpma":true,
  "defaultdriverFrom":"",
	"defaultdriverTo":"",
  "vehicleStoreFrom":"",
  "vehicleStoreTo":"",
  "enabled":true,
  "notenabled":true 
}; 

const initialState = [{
  "id" : "",
  "licenseplate" : "",
  "defaultvehicle" : false,
  "tara" : 0,
  "pma" : 0,
  "checkpma" : true,
  "defaultdriver" : "",
  "enabled" : true,
  "vehiclestore_id" : "",
  "vehiclestore_tradename" : "",
 }];

const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": ""
}];

const initialVehicleToSelect = [{
  "id": "",
  "vehiclestore_licenseplate": "" 
}];

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'vehiclestore_tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_STORE',
    canOrder:true,
  },
  {
    id: 'licenseplate',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_LICENSEPLATE',
    canOrder:true,
  },
  {
    id: 'defaultdriver',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_DRIVER',
    canOrder:true,
  },
  {
    id: 'tara',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_TARA',
    canOrder:true,
  },
  {
    id: 'pma',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_PMA',
    canOrder:true,
  },
  {
    id: 'checkpma',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_NOTCHECK',
    canOrder:true,
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_ENABLED',
    canOrder:true,
  }
  
];
 
const VehiclesSettings = ({setShowPrincipalMenu, showVehiclesSettings, setShowVehiclesSettings,setShowModalGeneralSettings,showModalTicketsSettings,setShowModalTicketsSettings}) => {
  
  const {t} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const [data, setData] = useState(initialState);
  const [form, setForm] = useState(initialState[0]);
  const [filtersVehicles, setFiltersVehicles] = useState(initialFilters);
  const [applyFilters, setApplyFilters] = useState(initialFilters);
  const [numTotReg, setNumTotReg] = useState(0);
  const [numTotRegFilter, setNumTotRegFilter] = useState(0);
  const [errorsFilters, setErrorsFilters] = useState({});
  const [errors, setErrors] = useState({});
  const [inputText, setInputText] = useState("");
  const [openCreateData, setOpenCreateData] = useState(false);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [openEditFilter, setOpenEditFilter] = useState(false);
  const [rowEdit, setRowEdit] = useState(initialState[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [idCodeDelete, setIdCodeDelete] = useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset] = useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(0);
  const [rangeOffset, setRangeOffset] = useState([{}]); 
  const [tradenameToSelect,setTradenameToSelect]= useState(initialStoresToSelect); 
  const [filteredStoreFrom, setFilteredStoreFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexStoreFrom, setHighlightedIndexStoreFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredStoreTo, setFilteredStoreTo] = useState([]); // Opciones filtradas
  const [highlightedIndexStoreTo, setHighlightedIndexStoreTo] = useState(-1); // Índice de la opción resaltada    
  const [vehiclesToSelect,setVehiclesToSelect]= useState(initialVehicleToSelect); 
  const [filteredVehicleFrom, setFilteredVehicleFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleFrom, setHighlightedIndexVehicleFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleTo, setFilteredVehicleTo] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleTo, setHighlightedIndexVehicleTo] = useState(-1); // Índice de la opción resaltada 
  const [filteredTradename, setFilteredTradename] = useState([]); // Opciones filtradas
  const [highlightedIndexTradename, setHighlightedIndexTradename] = useState(-1); // Índice de la opción resaltada 
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showVehiclesSettings) {
        setData(initialState);
        setForm(initialState[0]);
        setFiltersVehicles(initialFilters);
        setApplyFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({});
        setErrors({});
        setInputText("");
        setOpenCreateData(false);
        setOpenDeleteRow(false);
        setOpenEditRow(false);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        dispatch(setLoading(false));
        setIsLoadingError(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffset(false);
        setCurrencyOffset(0);
        setRangeOffset([{}]);  
        setShowPrincipalMenu(true);
        setShowVehiclesSettings(false);  
        setTradenameToSelect(initialStoresToSelect);
        setFilteredStoreFrom([]);
        setHighlightedIndexStoreFrom(-1); 
        setFilteredStoreTo([]);  
        setHighlightedIndexStoreTo(-1);  
        setVehiclesToSelect(initialVehicleToSelect);
        setFilteredVehicleFrom([]);
        setHighlightedIndexVehicleFrom(-1);  
        setFilteredVehicleTo([]);
        setHighlightedIndexVehicleTo(-1);  
        setFilteredTradename([]);
        setHighlightedIndexTradename(-1);  
        setShowModalTicketsSettings(showModalTicketsSettings);
        setShowModalGeneralSettings(false);
      }
    },
    [ setData,
      setForm,
      setFiltersVehicles,
      setApplyFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters,
      setErrors,
      setInputText,
      setOpenCreateData,
      setOpenDeleteRow,
      setOpenEditRow,
      setOpenEditFilter,
      setRowEdit,
      setIsLoadingError,
      setIdCodeDelete,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,  
      showModalTicketsSettings,
      setShowModalTicketsSettings,
      showVehiclesSettings, 
      setShowPrincipalMenu,
      setShowVehiclesSettings,
      setShowModalGeneralSettings,
      setTradenameToSelect,
      setFilteredStoreFrom,
      setHighlightedIndexStoreFrom, 
      setFilteredStoreTo,
      setHighlightedIndexStoreTo, 
      setVehiclesToSelect,
      setFilteredVehicleFrom,
      setHighlightedIndexVehicleFrom, 
      setFilteredVehicleTo,
      setHighlightedIndexVehicleTo, 
      setFilteredTradename,
      setHighlightedIndexTradename,
      dispatch,
      DELETE_CODE]
  );

  const closeModalUserTypeSettings = () => {    
    setData(initialState);
      setForm(initialState[0]);
      setFiltersVehicles(initialFilters);
      setApplyFilters(initialFilters);
      setNumTotReg(0);
      setNumTotRegFilter(0);
      setErrorsFilters({});
      setErrors({});
      setInputText("");
      setOpenCreateData(false);
      setOpenDeleteRow(false);
      setOpenEditRow(false);
      setOpenEditFilter(false);
      setRowEdit(initialState[0]);
      dispatch(setLoading(false));
      setIsLoadingError(false);
      setIdCodeDelete(DELETE_CODE);
      setActiveOffset(false);
      setCurrencyOffset(0);
      setRangeOffset([{}]);  
      setShowPrincipalMenu(true);
      setShowVehiclesSettings(false);  
      setTradenameToSelect(initialStoresToSelect);
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1); 
      setFilteredStoreTo([]);  
      setHighlightedIndexStoreTo(-1); 
      setVehiclesToSelect(initialVehicleToSelect);
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);  
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);  
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);  
      setShowModalTicketsSettings(showModalTicketsSettings);
      setShowModalGeneralSettings(false);
  };

  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchStoreFrom = (event) => {
    const searchStoreFrom = event.target.value.toUpperCase();
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      vehicleStoreFrom: searchStoreFrom,
    }));
    if (searchStoreFrom.trim() === "") {
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1);
      return;
    } 
    const filtered = tradenameToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchStoreFrom) ||
        normalizedDocumentId.includes(searchStoreFrom)
      );
    });
    setFilteredStoreFrom(filtered);
    setHighlightedIndexStoreFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectStoreFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      vehicleStoreFrom: store.tradename || "",
      vehicleStoreTo: store.tradename || filtersVehicles.vehicleStoreTo
    }));
    setFilteredStoreFrom([]);
    setHighlightedIndexStoreFrom(-1);
  }; 
  const handleKeyDownStoreFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredStoreFrom.length === 0 && filtersVehicles.vehicleStoreFrom.trim() === "") {
        setFilteredStoreFrom(tradenameToSelect);
        setHighlightedIndexStoreFrom(0);
      } else {
        setHighlightedIndexStoreFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredStoreFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexStoreFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexStoreFrom >= 0) {
      if (highlightedIndexStoreFrom >= 0 && highlightedIndexStoreFrom < filteredStoreFrom.length) {
        handleSelectStoreFrom(filteredStoreFrom[highlightedIndexStoreFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = tradenameToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filtersVehicles.vehicleStoreFrom.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFiltersVehicles(filtersVehicles => ({
          ...filtersVehicles,
          vehicleStoreFrom: ""
        }));
      }
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1);
    }
  };
 // Manejo del texto de búsqueda y filtrado dinámico
   const handleSearchStoreTo = (event) => {
     const searchStoreTo = event.target.value.toUpperCase();
     setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      vehicleStoreTo: searchStoreTo 
    }));
    if (searchStoreTo.trim() === "") {
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);
      return;
    } 
     const filtered = tradenameToSelect
     .filter(item=>(item.tradename>=filtersVehicles.vehicleStoreFrom))
     .filter((store) => {
       if (!store || !store.tradename) return false;
       // Comparar después de eliminar puntos y guiones de ambos
       const normalizedTradeName = store.tradename.toUpperCase();
       const normalizedDocumentId = store.documentid.toUpperCase();
       return (
         normalizedTradeName.includes(searchStoreTo) ||
         normalizedDocumentId.includes(searchStoreTo)
       );
     });
     setFilteredStoreTo(filtered);
     setHighlightedIndexStoreTo(0);
   };
   // Manejo de la selección de una tienda
   const handleSelectStoreTo = (store) => {
     if (!store) return; // Validación adicional para evitar errores
     setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      vehicleStoreTo:  store.tradename || ""
    }));
    setFilteredStoreTo([]);
    setHighlightedIndexStoreTo(-1);
   }; 
   const handleKeyDownStoreTo = (event) => {
     if (event.key === "ArrowDown") {
       if (filteredStoreTo.length === 0 && filtersVehicles.vehicleStoreTo.trim() === "") {
         const filtered = tradenameToSelect.filter(item=>(item.tradename>=filtersVehicles.vehicleStoreFrom));
         setFilteredStoreTo(filtered);
         setHighlightedIndexStoreTo(0);
       } else {
         setHighlightedIndexStoreTo((prevIndex) => {
           const nextIndex = prevIndex < filteredStoreTo.length - 1 ? prevIndex + 1 : prevIndex;
           scrollToElement(nextIndex);
           return nextIndex;
         });
       }
     } else if (event.key === "ArrowUp") {
       setHighlightedIndexStoreTo((prevIndex) => {
         const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
         scrollToElement(prevIndexValid);
         return prevIndexValid;
       });
     } else if (event.key === "Enter" && highlightedIndexStoreTo >= 0) {
       if (highlightedIndexStoreTo >= 0 && highlightedIndexStoreTo < filteredStoreTo.length) {
         handleSelectStoreTo(filteredStoreTo[highlightedIndexStoreTo]);
       }
     } else if (event.key === "Escape" || event.key === "Tab") {
       const isValid = tradenameToSelect
       .filter(item=>(item.tradename>=filtersVehicles.vehicleStoreFrom))
       .some((store) => {
        if (!store || !store.tradename) return false; 
          const normalizedTradeName = store.tradename.toUpperCase();
          const normalizedInputTradeName = filtersVehicles.vehicleStoreTo.toUpperCase();
          return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFiltersVehicles(filtersVehicles => ({
          ...filtersVehicles,
          vehicleStoreTo:  ""
        }));
      }
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);
     }
   };  

   // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchVehicleFrom = (event) => {
    const searchVehicleFrom = event.target.value.toUpperCase();
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      licenseplateFrom: searchVehicleFrom,
    }));
    if (searchVehicleFrom.trim() === "") {
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      return;
    } 
    const filtered = vehiclesToSelect.filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleFrom.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return ( normalizedVehicle.includes(searchNormailzedVehicled) );
    });
    setFilteredVehicleFrom(filtered);
    setHighlightedIndexVehicleFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleFrom = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      licenseplateFrom: vehicle.vehiclestore_licenseplate || "",
      licenseplateTo: vehicle.vehiclestore_licenseplate || filtersVehicles.licenseplateTo,
    }));
    setFilteredVehicleFrom([]);
    setHighlightedIndexVehicleFrom(-1);
  }; 
  const handleKeyDownVehicleFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleFrom.length === 0 && filtersVehicles.licenseplateFrom.trim() === "") {
        setFilteredVehicleFrom(vehiclesToSelect);
        setHighlightedIndexVehicleFrom(0);
      } else {
        setHighlightedIndexVehicleFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexVehicleFrom >= 0) {
      if (highlightedIndexVehicleFrom >= 0 && highlightedIndexVehicleFrom < filteredVehicleFrom.length) {
        handleSelectVehicleFrom(filteredVehicleFrom[highlightedIndexVehicleFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = vehiclesToSelect.some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filtersVehicles.licenseplateFrom.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFiltersVehicles(filtersVehicles => ({
          ...filtersVehicles,
          licenseplateFrom: ""
        }));
      }
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
    }
  };
  const handleSearchVehicleTo = (event) => {
    const searchVehicleTo = event.target.value.toUpperCase();
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      licenseplateTo:searchVehicleTo,
    }));
    if (searchVehicleTo.trim() === "") {
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
      return;
    } 
    const filtered = vehiclesToSelect
    .filter(item=>(item.vehiclestore_licenseplate>=filtersVehicles.licenseplateFrom))
    .filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleTo.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return (normalizedVehicle.includes(searchNormailzedVehicled));
    });
    setFilteredVehicleTo(filtered);
    setHighlightedIndexVehicleTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleTo = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      licenseplateTo: vehicle.vehiclestore_licenseplate || ""
    })); 
    setFilteredVehicleTo([]);
    setHighlightedIndexVehicleTo(-1);
  }; 
  const handleKeyDownVehicleTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleTo.length === 0 && filtersVehicles.licenseplateTo.trim() === "") {
        const filtered = vehiclesToSelect.filter(item=>(item.vehiclestore_licenseplate>=filtersVehicles.licenseplateFrom));
        setFilteredVehicleTo(filtered);
        setHighlightedIndexVehicleTo(0);
      } else {
        setHighlightedIndexVehicleTo((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexVehicleTo >= 0) {
      if (highlightedIndexVehicleTo >= 0 && highlightedIndexVehicleTo < filteredVehicleTo.length) {
        handleSelectVehicleTo(filteredVehicleTo[highlightedIndexVehicleTo]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = vehiclesToSelect
      .filter(item=>(item.vehiclestore_licenseplate>=filtersVehicles.licenseplateFrom))
      .some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filtersVehicles.licenseplateTo.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFiltersVehicles(filtersVehicles => ({
          ...filtersVehicles,
           licenseplateTo: ""
        }));   
      }
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
    }
  }; 

  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTradename = (event) => {
    const searchTradename = event.target.value.toUpperCase();
    setForm(form => ({
      ...form,
      vehiclestore_tradename: searchTradename,
    })); 
    if (searchTradename.trim() === "") {
      setForm(form => ({
        ...form,
        vehiclestore_id:"",
        vehiclestore_tradename: "",
      }));
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
      return;
    } 
    const filtered = tradenameToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      return normalizedTradeName.includes(searchTradename);
    });
    setFilteredTradename(filtered);
    setHighlightedIndexTradename(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTradeName = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setForm((form) => ({
      ...form,
      vehiclestore_id: store.id || "",
      vehiclestore_tradename: store.tradename || ""
    }));
    setFilteredTradename([]);
    setHighlightedIndexTradename(-1);
  }; 
  const handleKeyDownTradename = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTradename.length === 0 && form.vehiclestore_tradename.trim() === "") {
        setFilteredTradename(tradenameToSelect);
        setHighlightedIndexTradename(0);
      } else {
        setHighlightedIndexTradename((prevIndex) => {
          const nextIndex = prevIndex < filteredTradename.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTradename((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexTradename >= 0) {
      if (highlightedIndexTradename >= 0 && highlightedIndexTradename < filteredTradename.length) {
        handleSelectTradeName(filteredTradename[highlightedIndexTradename]);
      }
    } else if (event.key === "Escape") {
      const isValid = tradenameToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = form.vehiclestore_tradename.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setForm((form) => ({
          ...form,
          vehiclestore_id: "",
          vehiclestore_tradename: "",
        }));
      } 
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
    }
  };

   // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleBlur = (event) => {
    // Asegúrate de que no se cierra si haces clic en un elemento de la lista
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1); 
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);  
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
    }
  };

  const openCreateOrEditData = () => {
    setInputText("");
    setOpenCreateData(true);
  };

  const openEditFilters = () => {
    setFiltersVehicles(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filtersVehicles);
      setFiltersVehicles(initialFilters);
      setErrorsFilters({});
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFiltersVehicles(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFiltersVehicles(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };
  
  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const handleChangeEnabledFilter = (e) => {
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      enabled: !filtersVehicles.enabled
    }));
  };

  const handleChangeNotEnabledFilter = (e) => {
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      notenabled: !filtersVehicles.notenabled
    }));
  };

  const handleChangeCheckPMAFilter = (e) => {
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      checkpma: !filtersVehicles.checkpma
    }));
  };

  const handleChangeNOTCheckPMAFilter = (e) => {
    setFiltersVehicles(filtersVehicles => ({
      ...filtersVehicles,
      notcheckpma: !filtersVehicles.notcheckpma
    }));
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFilters({});
      setFiltersVehicles(initialFilters);
      setOpenEditFilter(!openEditFilter);
    }
  };

  const validateFormFilter = () => {
    let errorsFilters = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersVehicles.idFrom!=="" & !regexNumber.test(filtersVehicles.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filtersVehicles.idTo!=="" & !regexNumber.test(filtersVehicles.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
    if (filtersVehicles.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filtersVehicles.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filtersVehicles.idTo)<parseInt(filtersVehicles.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (filtersVehicles.taraFrom!=="" & !regexNumber.test(filtersVehicles.taraFrom)) {
      errorsFilters.taraFrom = 'Text_TextNoNumber';
    }
    if (filtersVehicles.taraTo!=="" & !regexNumber.test(filtersVehicles.taraTo)) {
      errorsFilters.taraTo = 'Text_TextNoNumber';
    }
    if (filtersVehicles.taraFrom!=="" & !errorsFilters.taraFrom!==undefined){
      if (filtersVehicles.taraTo!=="" & !errorsFilters.taraTo!==undefined){
        if (parseInt(filtersVehicles.taraTo)<parseInt(filtersVehicles.taraFrom)){
          errorsFilters.taraTo = 'Text_TextGreater_Tara';
        }
      }
    }

    if (filtersVehicles.pmaFrom!=="" & !regexNumber.test(filtersVehicles.pmaFrom)) {
      errorsFilters.pmaFrom = 'Text_TextNoNumber';
    }
    if (filtersVehicles.pmaTo!=="" & !regexNumber.test(filtersVehicles.pmaTo)) {
      errorsFilters.pmaTo = 'Text_TextNoNumber';
    }
    if (filtersVehicles.pmaFrom!=="" & !errorsFilters.pmaFrom!==undefined){
      if (filtersVehicles.pmaTo!=="" & !errorsFilters.pmaTo!==undefined){
        if (parseInt(filtersVehicles.pmaTo)<parseInt(filtersVehicles.pmaFrom)){
          errorsFilters.pmaTo = 'Text_TextGreater_Pma';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersVehicles.licenseplateFrom)) {
      errorsFilters.licenseplateFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicles.licenseplateTo)) {
      errorsFilters.licenseplateTo = 'Text_TextNoValid';
    } 

    if (filtersVehicles.licenseplateTo!=="" & filtersVehicles.licenseplateTo<filtersVehicles.licenseplateFrom){
      errorsFilters.licenseplateTo = 'Text_TextGreater_LicensePlate';
    }

    if (regexInvalidCharacters.test(filtersVehicles.defaultdriverFrom)) {
      errorsFilters.defaultdriverFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicles.defaultdriverTo)) {
      errorsFilters.defaultdriverTo = 'Text_TextNoValid';
    } 

    if (filtersVehicles.defaultdriverTo!=="" & filtersVehicles.defaultdriverTo<filtersVehicles.defaultdriverFrom){
      errorsFilters.defaultdriverTo = 'Text_TextGreater_DefaultDriver';
    }
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.licenseplate || '') +
        String(el.defaultdriver || '') +
        String(el.vehiclestore_tradename || '');
      let result = dataTranslate.toLowerCase().includes(inputText.toLowerCase());
      return result;
    }
  })

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRow = () => {
    setErrors({});
    setRowEdit(initialState[0]);
    setForm(initialState[0]);
    dispatch(setLoading(false)); 
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);

  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    if (e.key !== 'Escape') {
      if (rowEdit.id !== '') {
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT, `vehicles/deletevehicles`, token, rowEdit);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }  
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    } 
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
    handleCloseDataEdit();
  };

  const handleSubmitDataEdit = () => {
    setErrors(validateForm());
    const errorsToCheck = validateForm();
  
    if (Object.keys(errorsToCheck).length === 0 && form.licenseplate !== '' && form.vehiclestore_id !==undefined && form.vehiclestore_id !== '' && form.vehiclestore_id !== '0') {
      const fetchData = async () => {
        dispatch(setLoading(true));
        try {
          const getData = openEditRow
            ? await helpHttp().put(ENDPOINT, `vehicles/updatevehicles`, token, form)
            : await helpHttp().post(ENDPOINT, `vehicles/savevehicles`, token, form);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            dispatch(setLoading(false));
            handleCloseDataEdit();
          } else {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_IncorrectData')));  
        } 
      };
      fetchData();
    } else {
      if (errorsToCheck.licenseplate) {
        dispatch(warningErrorAction(t(errorsToCheck.licenseplate)));
      }else{
        if (errorsToCheck.vehiclestore_id) {
          dispatch(warningErrorAction(t(errorsToCheck.vehiclestore_id)));
        } else{
          dispatch(warningErrorAction(t('Text_IncorrectData')));
        }    
        
      }
    }
  };

  const handleCloseDataEdit = () => {
    setOpenCreateData(false);
    setOpenEditRow(false);
    setErrors({});
    setRowEdit(openEditRow?rowEdit:initialState[0]);
    setForm(initialState[0]);
    dispatch(setLoading(false));
    setIsLoadingError(false);
    toggleDrawerDataEdit(false);
	};

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrors(validateForm());
  };
  
  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value
    }));
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000) return true;
    return false;
  };

  const handleBlurNumeric = (value, row) => {
    handleChangeNumeric(value, row);
  };

  const handleChangeNumeric = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" kg","").replaceAll(" €","").replaceAll(" %","")):0;
    setForm(form => ({
      ...form,
      [name]: newValue
    }));
  };

  const handleChangeCheckPmaVehicle = () => {
    setForm(form => ({
      ...form,
      checkpma: !form.checkpma
    }));
  };

  const handleChangeEnabledVehicle = () => {
    setForm(form => ({
      ...form,
      enabled: !form.enabled
    }));
  };

  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const validateForm = () => {
    let errors = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    if (!form.vehiclestore_id || form.vehiclestore_id==="" || form.vehiclestore_id==="0" || form.vehiclestore_id===0) {
      errors.vehiclestore_id = 'Text_FieldStoreRequired';
    } 
    if (!form.licenseplate || form.licenseplate==="") {
      errors.licenseplate = 'Text_FieldLicensePlateRequired';
    } else {
      if (regexInvalidCharacters.test(form.licenseplate)) {
        errors.licenseplate = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.licenseplate.toLowerCase()===form.licenseplate.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.licenseplate = 'Text_Vehicle_LicensePlateExist';
          }
        } else {
          if (data.find((key=>key.licenseplate.toLowerCase()===form.licenseplate.toLowerCase()))) {
            errors.licenseplate = 'Text_Vehicle_LicensePlateExist';
          }
        }  
      }   
    }
    return errors;
  };
 
  useEffect(() => {
    setForm(openEditRow?rowEdit:initialState[0]);
  },[rowEdit,openEditRow]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);
 
  useEffect(() => {
    let isMounted = true; 
    const fetchDataVehicles = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`vehicles/getlistvehicles`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setVehiclesToSelect(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    const fetchDataTradename = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`vehicles/storesorderbyname`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setTradenameToSelect(getData); 
        }
      } catch (error) { 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };   

    if (showVehiclesSettings ) {  
      fetchDataTradename(); 
      fetchDataVehicles();
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,roles,filtersVehicles,openEditFilter,showVehiclesSettings]);
 
  useEffect(() => {
    let ignore = false;
  
    const fetchnumTotReg = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().get(ENDPOINT, `vehicles/getinformationtablevehicles`, token);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotReg(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
  
    const fetchnumTotRegFilter = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().post(ENDPOINT, `vehicles/getcountfiltervehicles`, token, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
  
    const fetchData = async () => {
     dispatch(setLoading(true));
      try {
        const getData = await helpHttp().get3(ENDPOINT, `vehicles/getvehicles`, token, currencyOffset, LIMITPAGESIZE, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setData(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
  
    if (showVehiclesSettings) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true; };
    }
  }, [ENDPOINT, t, token, dispatch, openCreateData, openDeleteRow, openEditRow, rowEdit, showVehiclesSettings, LIMITPAGESIZE, applyFilters, currencyOffset]);
  
  return (
    <>
      {showVehiclesSettings && ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h1NewStyle mb-2"> 
            {isLoadingError?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={openDeleteRow?"warning":"primary"}/>:<i className="fas fa-solid fa-truck text-warning"></i>} {t('Text_Vehicle_Config')}</h1>
            {isLoadingData ?
              <CircularProgress sx={{ color : "#f6c23e"}}/>:
              <>
                {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Vehicle'))?
                  <div>
                    <button type="button" className="buttonCreate" onClick={openCreateOrEditData}>{t('Button_Create')}</button><> </>
                    <button type="button" className="buttonBack" onClick={closeModalUserTypeSettings}>{t('Button_Back')}</button>
                  </div>:
                  <div>
                    <button type="button" className="buttonBack" onClick={closeModalUserTypeSettings}>{t('Button_Back')}</button>
                  </div>
                }
              </>
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputText}
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              placeholder={t('Text_Vehicle_Search')}
              onChange={inputHandler}
              fullWidth
              label={t('Text_Vehicle_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> &nbsp;&nbsp;{t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.vehicleStoreFrom!=="" || applyFilters.vehicleStoreTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Vehicle_STORE')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.licenseplateFrom!=="" || applyFilters.licenseplateTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Vehicle_LICENSEPLATE')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.taraFrom!=="" || applyFilters.taraTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Vehicle_TARA')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.pmaFrom!=="" || applyFilters.pmaTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Vehicle_PMA')}</button><> </>
                
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.checkpma)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Vehicle_CHECK')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.notcheckpma)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Vehicle_NOTCHECK')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.enabled)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Vehicle_ENABLED')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.notenabled)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Vehicle_NOTENABLED')}</button><> </>
            </h1>
          </div>
          <br/>
          {!isLoadingData ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingVehicles
                    data={filteredData}
                    headCells={headCells}
                    numTotRegFilter={numTotRegFilter}
                    titleTable={t('Text_Vehicle_Table')}
                    setOpenDeleteRow={setOpenDeleteRow}
                    setOpenEditRow={setOpenEditRow}
                    setRowEdit={setRowEdit}
                    showEditButton={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Modify_Vehicle'))?true:false}
                    showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Delete_Vehicle'))?true:false}
                    numTotReg={numTotReg}
                    activeOffset={activeOffset}
                    rangeOffset={rangeOffset}
                    currencyOffset={currencyOffset}
                    setCurrencyOffset={setCurrencyOffset}  
                  >
                  </TableSortingVehicles>
                </div>             
              </div>
              {openEditFilter &&
                <div>
                  <SwipeableDrawer
                    anchor='right'
                    open={true}
                    onClose={toggleDrawerFilterEdit(false)}
                    onOpen={toggleDrawerFilterEdit(true)}>
                    <Container >
                      <Box sx={{ bgcolor: '#ffffff', width: '850' }}>
                        <br/>
                        <div id="myDIV">
                          <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <form className="createfilterpurchasedelivery" onSubmit={handleCancelFilters}>  
                          <div className="form-floating mb-3 px-4 primary">
                            <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="idFrom" 
                                name="idFrom" 
                                value={filtersVehicles.idFrom}
                                label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('Text_Filter_Code_From')}
                                placeholder={t('PlaceHolder_Filter_Code_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit} 
                              /> 
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="idTo" 
                                name="idTo" 
                                value={filtersVehicles.idTo}
                                label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('Text_Filter_Code_To')}
                                placeholder={t('PlaceHolder_Filter_Code_to')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit} 
                              />  
                            </div>

                            <div className="form-group">
                              {/* Campo de texto para escribir y filtrar */}
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="vehicleStoreFrom"
                                  name="vehicleStoreFrom"
                                  value={filtersVehicles.vehicleStoreFrom}
                                  onChange={handleSearchStoreFrom}
                                  onKeyDown={handleKeyDownStoreFrom}
                                  label={errorsFilters.vehicleStoreFrom!=null?t(errorsFilters.vehicleStoreFrom):t('Text_Filter_Store_From')}
                                  placeholder={t('Placeholder_Filter_Store_From')}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  variant="outlined" 
                                />
                                {/* Desplegable dinámico */}
                                {filteredStoreFrom.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredStoreFrom.map((store, index) => (
                                      <MenuItem
                                          key={store.id}
                                          onClick={() => handleSelectStoreFrom(store)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexStoreFrom ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexStoreFrom ? "white" : "black",
                                          }}
                                      >
                                        {store.tradename} - {store.documentid}
                                      </MenuItem>
                                    ))}
                                  </div>
                                )}
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="vehicleStoreTo"
                                  name="vehicleStoreTo"
                                  value={filtersVehicles.vehicleStoreTo}
                                  onChange={handleSearchStoreTo}
                                  onKeyDown={handleKeyDownStoreTo}
                                  label={errorsFilters.vehicleStoreTo!=null?t(errorsFilters.vehicleStoreTo):t('Text_Filter_Store_To')}
                                  placeholder={t('Placeholder_Filter_Store_To')}
                                  variant="outlined"
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                />
                                {/* Desplegable dinámico */}
                                {filteredStoreTo.length > 0 && ( 
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredStoreTo.map((store, index) => (
                                      <MenuItem
                                        key={store.id}
                                          onClick={() => handleSelectStoreTo(store)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexStoreTo ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexStoreTo ? "white" : "black",
                                          }}
                                        >
                                          {store.tradename} - {store.documentid}
                                        </MenuItem>
                                    ))}
                                  </div>
                              )}    
                              </span>
                            </div> 

                            <div className="form-group">
                              {/* Campo de texto para escribir y filtrar */}
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="licenseplateFrom"
                                  name="licenseplateFrom"
                                  value={filtersVehicles.licenseplateFrom}
                                  onChange={handleSearchVehicleFrom}
                                  onKeyDown={handleKeyDownVehicleFrom}
                                  label={errorsFilters.licenseplateFrom!=null?t(errorsFilters.licenseplateFrom):t('Text_Filter_LicensePlate_From')}
                                  placeholder={t('Placeholder_Filter_LicensePlate_From')}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  variant="outlined"
                                />
                                {/* Desplegable dinámico */}
                                {filteredVehicleFrom.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredVehicleFrom.map((vehicle, index) => (
                                      <MenuItem
                                          key={vehicle.id}
                                          onClick={() => handleSelectVehicleFrom(vehicle)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexVehicleFrom ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexVehicleFrom ? "white" : "black",
                                          }}
                                      >
                                        {vehicle.vehiclestore_licenseplate}
                                      </MenuItem>
                                    ))}
                                  </div>
                                )}
                                </span>  
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="licenseplateTo"
                                    name="licenseplateTo"
                                    value={filtersVehicles.licenseplateTo}
                                    onChange={handleSearchVehicleTo}
                                    onKeyDown={handleKeyDownVehicleTo}
                                    label={errorsFilters.licenseplateTo!=null?t(errorsFilters.licenseplateTo):t('Text_Filter_LicensePlate_To')}
                                    placeholder={t('Placeholder_Filter_LicensePlate_To')}
                                    inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    variant="outlined"
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredVehicleTo.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredVehicleTo.map((vehicle, index) => (
                                      <MenuItem
                                        key={vehicle.id}
                                          onClick={() => handleSelectVehicleTo(vehicle)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexVehicleTo ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexVehicleTo ? "white" : "black",
                                          }}
                                        >
                                          {vehicle.vehiclestore_licenseplate}
                                        </MenuItem>
                                    ))}
                                  </div>
                                )}    
                              </span>  
                            </div> 

                            <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="taraFrom" 
                                name="taraFrom" 
                                value={filtersVehicles.taraFrom}
                                label={errorsFilters.taraFrom!=null?t(errorsFilters.taraFrom):t('Text_Filter_Tara_From')}
                                placeholder={t('Placeholder_Filter_Tara_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit} 
                              /> 
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="taraTo" 
                                name="taraTo" 
                                value={filtersVehicles.taraTo}
                                label={errorsFilters.taraTo!=null?t(errorsFilters.taraTo):t('Text_Filter_Tara_To')}
                                placeholder={t('Placeholder_Filter_Tara_To')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit} 
                              />  
                            </div>

                            <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="pmaFrom" 
                                name="pmaFrom" 
                                value={filtersVehicles.pmaFrom}
                                label={errorsFilters.pmaFrom!=null?t(errorsFilters.pmaFrom):t('Text_Filter_Pma_From')}
                                placeholder={t('Placeholder_Filter_Pma_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit} 
                              /> 
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="pmaTo" 
                                name="pmaTo" 
                                value={filtersVehicles.pmaTo}
                                label={errorsFilters.pmaTo!=null?t(errorsFilters.pmaTo):t('Text_Filter_Pma_To')}
                                placeholder={t('Placeholder_Filter_Pma_To')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit} 
                              />  
                            </div>

                            <div className="form-group">
                              <Checkbox
                                id="checkpma" 
                                name="checkpma" 
                                checked={filtersVehicles.checkpma}
                                onChange={handleChangeCheckPMAFilter}
                                variant="standard"
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                              &nbsp;&nbsp;    
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_CHECK')}</label>  
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Checkbox
                                id="notcheckpma" 
                                name="notcheckpma" 
                                checked={filtersVehicles.notcheckpma}
                                onChange={handleChangeNOTCheckPMAFilter}
                                variant="standard"
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                              &nbsp;&nbsp;    
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_NOTCHECK')}</label>    
                            </div>

                            <div className="form-group">
                              <Checkbox
                                id="enabled" 
                                name="enabled" 
                                checked={filtersVehicles.enabled}
                                onChange={handleChangeEnabledFilter}
                                variant="standard"
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                              &nbsp;&nbsp;    
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_ENABLED')}</label>  
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Checkbox
                                id="notenabled" 
                                name="notenabled" 
                                checked={filtersVehicles.notenabled}
                                onChange={handleChangeNotEnabledFilter}
                                variant="standard"
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                              &nbsp;&nbsp;    
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_NOTENABLED')}</label>    
                            </div>
 
                             
                          </div>
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                           </div>
                        </form>
                      </Box>
                    </Container>
                  </SwipeableDrawer>
                </div>
              }  
              
              {(openCreateData || openEditRow) && 
                <div>
                  <SwipeableDrawer
                    anchor='right'
                    open={true}
                    onClose={toggleDrawerDataEdit(false)}
                    onOpen={toggleDrawerDataEdit(true)}>
                    <Container >
                      <Box sx={{ bgcolor: '#ffffff',width: '500px' }}>
                        <br/>
                        <div id="myDIV">
                          <h1 className="h1NewStyle px-4 mb-2">{openEditRow?t('Text_Vehicle_Edit'):t('Text_Vehicle_Create')}</h1>
                        </div>  
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <form className="createVehicles" onSubmit={handleSubmitDataEdit}>  
                          <div className="form-floating mb-3 px-4 primary">
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Code').toUpperCase()} :</label>
                            <div className="form-group">
                              <TextField
                                id="id" 
                                name="id"
                                value={form.id}
                                inputProps={{ style: {width: 250,fontSize: '18px', }, maxLength: 15 }}
                                style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                label={t('PlaceHolder_Vehicles_Code')}
                                placeholder={t('PlaceHolder_Vehicles_Code')}
                                variant="standard"
                                disabled/>
                            </div>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_licenseplate').toUpperCase()} :</label>
                            <div className="form-group">
                              <TextField
                                id="licenseplate" 
                                name="licenseplate"
                                value={form.licenseplate}
                                inputProps={{ style: {width: 450,fontSize: '18px', }, maxLength: 50 }}
                                style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                label={errors.licenseplate!=null?t(errors.licenseplate):t('PlaceHolder_Vehicle_licenseplate').toUpperCase()}
                                placeholder={t('PlaceHolder_Vehicle_licenseplate')}
                                variant="standard"
                                onBlur={handleBlurDataEdit}
                                onChange={handleChangeDataEdit}
                              />
                            </div>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_tara').toUpperCase()} :</label>
                            <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {2}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="tara" 
                                name="tara"
                                suffix={" kg"}
                                value={form.tara}
                                label={errors.tara!=null?t(errors.tara):t('PlaceHolder_Vehicle_tara')}
                                placeholder={t('PlaceHolder_Vehicle_tara')}
                                style={{width: 450, fontSize: 18}}
                                inputProps={{ style: {width: 450,fontSize: '18px' } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurNumeric}
                                onChange={handleChangeNumeric}
                              />
                            </div> 
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_pma').toUpperCase()} :</label>
                            <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {2}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="pma" 
                                name="pma"
                                suffix={" kg"}
                                value={form.pma}
                                label={errors.pma!=null?t(errors.pma):t('PlaceHolder_Vehicle_pma')}
                                placeholder={t('PlaceHolder_Vehicle_pma')}
                                style={{width: 450, fontSize: 18}}
                                inputProps={{ style: {width: 450,fontSize: '18px' } }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurNumeric}
                                onChange={handleChangeNumeric}
                              /> 
                            </div>
                            <div className="form-group">
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_NOTCHECK').toUpperCase()} :</label>
                              <Checkbox  
                                style={{color:"#4e73df"}} 
                                checked={form.checkpma} 
                                onChange={handleChangeCheckPmaVehicle} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_driver').toUpperCase()} :</label>
                            <div className="form-group">
                              <TextField
                                id="defaultdriver" 
                                name="defaultdriver"
                                value={form.defaultdriver}
                                inputProps={{ style: {width: 450,fontSize: '18px', }, maxLength: 100 }}
                                style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                label={errors.defaultdriver!=null?t(errors.defaultdriver):t('PlaceHolder_Vehicle_driver')}
                                placeholder={t('PlaceHolder_Vehicle_driver')}
                                variant="standard"
                                onBlur={handleBlurDataEdit}
                                onChange={handleChangeDataEdit}
                              />
                            </div>                      
                            <div className="form-group">
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Vehicle_enabled').toUpperCase()} :</label>
                              <Checkbox  
                                style={{color:"#4e73df"}} 
                                checked={form.enabled} 
                                onChange={handleChangeEnabledVehicle} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_User_Stores').toUpperCase()} :</label>
                            <div className="form-group"style={{ position: "relative" }}  onBlur={handleBlur} >
                              <TextField
                                id="vehiclestore_tradename"
                                name="vehiclestore_tradename"
                                value={form.vehiclestore_tradename}
                                onChange={handleSearchTradename}
                                onKeyDown={handleKeyDownTradename}
                                label={errors.vehiclestore_tradename!=null?t(errors.vehiclestore_tradename):t('Text_User_Stores')}
                                placeholder={t('Text_User_Select_Stores')}
                                variant="standard"
                                inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              />
                              {/* Desplegable dinámico */}
                              {filteredTradename.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                >
                                  {filteredTradename.map((store, index) => (
                                    <MenuItem
                                        key={store.id}
                                        onClick={() => handleSelectTradeName(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexTradename ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexTradename ? "white" : "black",
                                        }}
                                    >
                                      {store.tradename} - {store.documentid}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>   
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitDataEdit}>{openEditRow?t('Button_Update'):t('Button_Create')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleCloseDataEdit}>{t('Button_Cancel')}</button>
                          </div> 
                        </form>
                      </Box>
                    </Container>
                  </SwipeableDrawer>
                </div>
              }
            </>:
              <LoadingNews/>
          }
          <Dialog open={openDeleteRow} onClose={handleCancelDeleteRow}>
            <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEdit.id}  - {rowEdit.name}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Text_Line1')}<br/>
                {t('Text_Line2')}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="idCode"
                label={t('Text_Label')}
                type="text"
                fullWidth
                variant="standard"
                error
                defaultValue={idCodeDelete}
                onChange={handleChangeDeleteRow}/>
            </DialogContent>
            <DialogActions>
              <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
              <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
            </DialogActions>
          </Dialog>
          {/* End Content Row */}
        </div>
        //* End Page Content *//
      )}
    </>
  )
}
VehiclesSettings.propTypes = {
  setShowPrincipalMenu: PropTypes.func.isRequired,
  showVehiclesSettings: PropTypes.bool.isRequired,
  setShowVehiclesSettings: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired ,
  showModalTicketsSettings: PropTypes.bool.isRequired,
  setShowModalTicketsSettings: PropTypes.func.isRequired
  
};

export default VehiclesSettings;