import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"  
import Box from '@mui/material/Box'; 
import Container from '@mui/material/Container';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import TextField from '@mui/material/TextField';
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';  

const initialProcessType = [{
  "id": "",
  "indexrowprocesstypes":"",
  "processtype_id":"",
  "processtype_code": "",
  "processtype_namees": "",
  "processtype_nameen": ""
}];
  


const ContractCreateProcessTypes = ({openEditRowProcessTypes,setOpenEditRowProcessTypes,newCodeLER,setNewCodeLER}) => {
  const {t,i18n} = useTranslation(['listDataContracts']);
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const [newProcessType,setNewProcessType]= useState(initialProcessType[0]);
  const [processtypeToSelect,setProcesstypeToSelect]= useState(initialProcessType);
  const [errorsProcessType, setErrorsProcessType] = useState({}); 
  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowProcessTypes) {
        setNewProcessType(initialProcessType[0]);
        setErrorsProcessType({});
        setOpenEditRowProcessTypes(false); 
        setProcesstypeToSelect(initialProcessType);
      }
    },[
      setNewProcessType,
      setErrorsProcessType, 
      setProcesstypeToSelect,
      openEditRowProcessTypes,
      setOpenEditRowProcessTypes]
  );

  const handleCloseNewProcessType = () => {
    setNewProcessType(initialProcessType[0]);
    setErrorsProcessType({}); 
    setProcesstypeToSelect(initialProcessType);
    setOpenEditRowProcessTypes(false); 
  };

  const handleChangeNewProcessType = (e) => {
    const { name, value } = e.target;
    setNewProcessType(newProcessType => ({
      ...newProcessType,
      [name]: value,
    }));   
  };
  
  const handleBlurNewProcessType = (e) => {
    handleChangeNewProcessType(e);
    setErrorsProcessType(validateFormProcessType());
  };
   

  const toggleDrawerNewProcessType  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewProcessType();
    }
  };

  const validateFormProcessType = () => {
    let errorsProcessType = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (newProcessType.processtype_code===""){
      errorsProcessType.processtype_code = 'Text_CODELER_code';
    }else {
      if (regexInvalidCharacters.test(newProcessType.code)) {
        errorsProcessType.processtype_code = 'Text_TextNoValid';
      }
    }
  
    if (newProcessType.processtype_namees===""){
        errorsProcessType.processtype_namees = 'Text_PROCESSESTYPE_NOT_SELECTED';
    }else {
      if (regexInvalidCharacters.test(newProcessType.processtype_namees)) {
        errorsProcessType.processtype_namees = 'Text_TextNoValid';
      }
    } 

    if (newProcessType.processtype_nameen===""){
      errorsProcessType.processtype_nameen = 'Text_PROCESSESTYPE_NOT_SELECTED';
    }else {
      if (regexInvalidCharacters.test(newProcessType.processtype_nameen)) {
        errorsProcessType.processtype_nameen = 'Text_TextNoValid';
      }
    }  
    if (newCodeLER.processtypes.find((item) => {return (item.processtype_code.trim()=== newProcessType.processtype_code.trim() ) })){
      errorsProcessType.processtype_code = 'Text_PROCESSESTYPE_SELECTED';
    } 
    
    return errorsProcessType;
  };

  const handleChangeSelectProcessType = (event) => { 
    let foundCode="";
    let foundNamees="";
    let foundNameen="";
    if (event.target.value!==""){
      foundCode=processtypeToSelect.find((key=>key.processtype_id===Number(event.target.value))).processtype_code;
      foundNamees=processtypeToSelect.find((key=>key.processtype_id===Number(event.target.value))).processtype_namees;
      foundNameen=processtypeToSelect.find((key=>key.processtype_id===Number(event.target.value))).processtype_nameen;
    }
    setNewProcessType(newProcessType => ({
        ...newProcessType,
        processtype_id:event.target.value,
        processtype_code:foundCode,
        processtype_namees:foundNamees,
        processtype_nameen:foundNameen
    }));
  };

  const handleBlurSelectProcessType = (e) => {
    handleChangeSelectProcessType(e);
  };


  const handleSubmitNewProcessType = () => {  

    setErrorsProcessType(validateFormProcessType());
    let errorsCheckProcessType=validateFormProcessType();

    if ((Object.keys(errorsCheckProcessType).length === 0) 
        && newProcessType.processtype_id!==''
          && newProcessType.processtype_code!==''  
          && newProcessType.processtype_namees!=='' 
          && newProcessType.processtype_nameen!=='' ) {

      let processTypeToAdd={
        "id":"",
        "indexrowprocesstypes":newCodeLER.processtypes.length>0?newCodeLER.processtypes[newCodeLER.processtypes.length-1].indexrowprocesstypes+1:1, 
        "processtype_id":newProcessType.processtype_id,      
        "processtype_code":newProcessType.processtype_code,
        "processtype_namees":newProcessType.processtype_namees,
        "processtype_nameen":newProcessType.processtype_nameen 
      }
       
      newCodeLER.processtypes.push(processTypeToAdd);
      setNewCodeLER(newCodeLER => ({
        ...newCodeLER,
      }))
      handleCloseNewProcessType();
    } else {
      if (errorsCheckProcessType.processtype_code){
        dispatch(warningErrorAction(t(errorsCheckProcessType.processtype_code)));
      }
      if (errorsCheckProcessType.processtype_namees){
        dispatch(warningErrorAction(t(errorsCheckProcessType.processtype_namees)));
      }
      if (errorsCheckProcessType.processtype_nameen){
        dispatch(warningErrorAction(t(errorsCheckProcessType.processtype_nameen)));
      }
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);


  useEffect(() => {
    let isMounted = true;

    const fetchDataProcessType = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `contract/processtypesorderbycode`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProcesstypeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };


    if (openEditRowProcessTypes){
      fetchDataProcessType(); 
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,openEditRowProcessTypes]);


  return(
    <>
      {openEditRowProcessTypes && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewProcessType(false)}
            onOpen={toggleDrawerNewProcessType(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_PROCESSESTYPE_NEW')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewProcessType}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('PlaceHolder_CONTRACT_PROCESSESTYPE')} :</label>
                    <div className="form-group">
                      <TextField
                          id="processtype_id"
                          name="processtype_id"
                          value={ (newProcessType.processtype_id === undefined || newProcessType.processtype_id ===null || newProcessType.processtype_id ==="")?"":newProcessType.processtype_id}
                          inputProps={{ style: {width: 750}, maxLength: 100 }}
                          variant="outlined"
                          onBlur={handleBlurSelectProcessType}
                          onChange={handleChangeSelectProcessType}
                          select
                          SelectProps={{
                            native: true,
                            value: (newProcessType.processtype_id === undefined || newProcessType.processtype_id ===null || newProcessType.processtype_id ==="")?"": newProcessType.processtype_id
                          }}>
                          <option key={0} value=""/>
                          {processtypeToSelect.map((option) => (
                          <option key={option.processtype_id} value={option.processtype_id}>
                              {i18n.language==="es"?option.processtype_code+" - "+option.processtype_namees.substring(0,120):option.processtype_code+" - "+option.processtype_nameen.substring(0,120)}
                          </option>
                          ))}
                        </TextField>
                    </div>
                    <label className="text-primary">{t('Text_CONTRACT_PROCESSESTYPE')} :</label>
                    <div className="form-group">
                      <TextField
                        id="processtype_code" 
                        name="processtype_code"
                        value={newProcessType.processtype_code}
                        inputProps={{ style: {width: 800}, maxLength: 255 }}
                        label={errorsProcessType.processtype_code!=null?t(errorsProcessType.processtype_code):t('Text_CONTRACT_PROCESSESTYPE')}
                        placeholder={t('Text_CONTRACT_PROCESSESTYPE')}
                        variant="standard"
                        onBlur={handleBlurNewProcessType}
                        onChange={handleChangeNewProcessType}
                        disabled/>
                    </div>
                    <label className="text-primary">{t('Text_CONTRACT_PROCESSESTYPE_TITLEES')} :</label>
                    <div className="form-group">
                      <TextField
                        id="processtype_namees" 
                        name="processtype_namees"
                        value={newProcessType.processtype_namees}
                        inputProps={{ style: {width: 800}, maxLength: 255 }}
                        label={errorsProcessType.processtype_namees!=null?t(errorsProcessType.processtype_namees):t('Text_CONTRACT_PROCESSESTYPE_TITLEES')}
                        placeholder={t('Text_CONTRACT_PROCESSESTYPE_TITLEES')}
                        variant="standard"
                        onBlur={handleBlurNewProcessType}
                        onChange={handleChangeNewProcessType}
                        disabled/>
                    </div>

                    <label className="text-primary">{t('Text_CONTRACT_PROCESSESTYPE_TITLEEN')} :</label>
                    <div className="form-group">
                      <TextField
                        id="processtype_nameen" 
                        name="processtype_nameen"
                        value={newProcessType.processtype_nameen}
                        inputProps={{ style: {width: 1000}, maxLength: 255 }}
                        label={errorsProcessType.processtype_nameen!=null?t(errorsProcessType.processtype_nameen):t('Text_CONTRACT_PROCESSESTYPE_TITLEEN')}
                        placeholder={t('Text_CONTRACT_PROCESSESTYPE_TITLEEN')}
                        variant="standard"
                        onBlur={handleBlurNewProcessType}
                        onChange={handleChangeNewProcessType}
                        disabled/>
                    </div>
                   
      
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewProcessType}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewProcessType}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

ContractCreateProcessTypes.propTypes = {
  openEditRowProcessTypes: PropTypes.bool.isRequired,
  setOpenEditRowProcessTypes: PropTypes.func.isRequired,  
  newCodeLER: PropTypes.object.isRequired,
  setNewCodeLER: PropTypes.func.isRequired, 
};

export default ContractCreateProcessTypes;