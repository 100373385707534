import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import LoginForm from "pages/login/LoginForm";
import NotFound from "pages/404/NotFound"; 
import ErrorBoundary from "./ErrorBoundary"; // Importa el Error Boundary 
import store from "store/Store";
import "./App.css";
import Home from "pages/main/Home";   
import HomeStore from "pages/stores/HomeStore";
import HomeEmployee from 'pages/employees/HomeEmployee';
import HomeStoreEditData from "pages/stores/HomeStoreEditData";
import HomeEmployeeEditData from "pages/employees/HomeEmployeeEditData";
import HomeFormDI from "pages/forms/filterFormDI/HomeFormDI";
import HomeFormEditData from "pages/forms/editOrNewFormDI/HomeFormEditData";
import HomeTypesDIEditData from "pages/typesDIFormat/HomeTypesDIEditData";
import HomeTypesDI from "pages/typesDIFormat/HomeTypesDI";
import HomeContract from "pages/contracts/HomeContract";
import HomeContractEditData from "pages/contracts/editOrNewContract/HomeContractEditData";
import HomeTickets from "pages/tickets/TicketsHome";
import HomeTicketsDetails from "pages/ticketsDetails/TicketsDetailsHome";
import HomeTicketsFormat from "pages/settings/ticketsFormatSettings/HomeTicketsFormat";
import HomeTicketsFormatEditData from "pages/settings/ticketsFormatSettings/HomeTicketsFormatEditData";
import HomeArticle from "pages/articles/HomeArticle";
import HomeArticleEditData from "pages/articles/articleEdit/HomeArticleEditData";
import HomePurchaseDelivery from "pages/purchaseDelivery/HomePurchaseDelivery";
import HomePurchaseDeliveryEditData from "pages/purchaseDelivery/editOrNewPurchaseDelivery/HomePurchaseDeliveryEditData";
import HomeShippng from "pages/shipping/HomeShipping";
import HomeShippingEditData from "pages/shipping/editOrNewShipping/HomeShippingEditData";
import HomeRegisterBook from "pages/registerBook/HomeRegisterBook";
import HomeTicketsEditData from "pages/tickets/ticketsEdit/editTickets/HomeTicketsEditData";
import HomePurchaseSignature from "pages/purchaseSignature/HomePurchaseSignature";
import HomePurchaseSignatureEditData from "pages/purchaseSignature/purchaseSignatureSign/HomePurchaseSignature";

const App =() => {  
  return(
    
    <Provider store={store}>
      <div className="App">
        <ErrorBoundary>  
          <Suspense fallback={null}>
            <section className="App-content">            
              <BrowserRouter>
                <Routes>
                  <Route path="" element={
                    <Suspense fallback={null}>
                      <LoginForm/>
                    </Suspense>
                  } />
                  <Route path="/home" element={
                    <Suspense fallback={null}>
                      <Home/>
                    </Suspense>
                  } />
                  <Route path="/*" element={
                    <Suspense fallback={null}>
                      <NotFound/>    
                    </Suspense>
                  } />
                  <Route path="/store" element={
                    <Suspense fallback={null}>
                      <HomeStore/>
                    </Suspense>
                  } />
                  <Route path="/storedetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeStoreEditData/>
                    </Suspense>
                  } />

                  <Route path="/article" element={
                    <Suspense fallback={null}>
                      <HomeArticle typeList="1"/>
                    </Suspense>
                  } />

                  <Route path="/articlepurchase" element={
                    <Suspense fallback={null}>
                      <HomeArticle typeList="2"/>
                    </Suspense>
                  } />

                  <Route path="/articledetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeArticleEditData typeList="1"/>
                    </Suspense>
                  } />

                  <Route path="/employee" element={
                    <Suspense fallback={null}>
                      <HomeEmployee/>
                    </Suspense>
                  } />
               
                  <Route path="/employeedetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeEmployeeEditData/>
                    </Suspense>
                  } />
                  
                  <Route path="/formdi" element={
                    <Suspense fallback={null}>
                      <HomeFormDI typeList="1"/>
                    </Suspense>
                  } />
                
                  <Route path="/formdiended" element={
                    <Suspense fallback={null}>
                      <HomeFormDI typeList="2"/>
                    </Suspense>
                  } />
                
                  <Route path="/formdetail/:id" element={
                    <Suspense fallback={null}>
                     <HomeFormEditData  typeList="1"/>
                    </Suspense>
                  } />

                  <Route path="/formdetail2/:id" element={
                    <Suspense fallback={null}>
                      <HomeFormEditData  typeList="2"/>
                    </Suspense>
                  } />

                  <Route path="/formatdi" element={
                    <Suspense fallback={null}>
                      <HomeTypesDI/>
                    </Suspense>
                  } />

                  <Route path="/formatdidetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeTypesDIEditData/>
                    </Suspense>
                  } />

                  <Route path="/ticketsformat" element={
                    <Suspense fallback={null}>
                      <HomeTicketsFormat/>
                    </Suspense>
                  } />

                  <Route path="/ticketsformatdetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeTicketsFormatEditData/>
                    </Suspense>
                  } />

                <Route path="/purchasedelivery" element={
                    <Suspense fallback={null}>
                      <HomePurchaseDelivery typeList="1"/>
                    </Suspense>
                  } />
                
                <Route path="/purchasedeliveryended" element={
                    <Suspense fallback={null}>
                      <HomePurchaseDelivery typeList="2"/>
                    </Suspense>
                  } />

                  <Route path="/purchasedeliverybegin" element={
                    <Suspense fallback={null}>
                      <HomePurchaseDelivery typeList="3"/>
                    </Suspense>
                  } />


                  <Route path="/purchasedeliverydetail/:id" element={
                    <Suspense fallback={null}>
                      <HomePurchaseDeliveryEditData typeList="1"/>
                    </Suspense>
                  } />

                  <Route path="/purchasedeliverydetailended/:id" element={
                    <Suspense fallback={null}>
                      <HomePurchaseDeliveryEditData typeList="2"/>
                    </Suspense>
                  } />

                  <Route path="/purchasedeliverydetailbegin/:id" element={
                    <Suspense fallback={null}>
                      <HomePurchaseDeliveryEditData typeList="3"/>
                    </Suspense>
                  } />

                  <Route path="/purchasesignature" element={
                    <Suspense fallback={null}>
                      <HomePurchaseSignature />
                    </Suspense>
                  } />

                  <Route path="/purchasesignaturedetails/:id" element={
                    <Suspense fallback={null}>
                      <HomePurchaseSignatureEditData />
                    </Suspense>
                  } />
  
                  <Route path="/shipping" element={
                    <Suspense fallback={null}>
                      <HomeShippng />
                    </Suspense>
                  } />
                  
                  <Route path="/shippingdetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeShippingEditData/>
                    </Suspense>
                  } />

                  <Route path="/registerbook" element={
                    <Suspense fallback={null}>
                      <HomeRegisterBook />
                    </Suspense>
                  } />

                  <Route path="/contract" element={
                    <Suspense fallback={null}>
                      <HomeContract />
                    </Suspense>
                  } />

                  <Route path="/contractdetail/:id" element={
                    <Suspense fallback={null}>
                      <HomeContractEditData/>
                    </Suspense>
                  } />

                  <Route path="/tickets" element={
                    <Suspense fallback={null}>
                      <HomeTickets/>
                    </Suspense>
                  } /> 

                  <Route path="/ticketsdetails" element={
                    <Suspense fallback={null}>
                      <HomeTicketsDetails/>
                    </Suspense>
                  } /> 

                  <Route path="/ticketseditsdetails/:id" element={
                    <Suspense fallback={null}>
                      <HomeTicketsEditData/>
                    </Suspense>
                  } />  
                </Routes>
              </BrowserRouter>
            </section>
          </Suspense>
        </ErrorBoundary>
      </div>
    </Provider>
  );
};

export default App;