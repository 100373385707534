import React, { useState, useEffect }from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next" 
import { useDispatch } from "react-redux"; 
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; 
import DashboardIcon from '@mui/icons-material/Dashboard'; 
import FeedIcon from '@mui/icons-material/Feed';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { TodayIs } from 'components/utils/TodayIs';  
import { resetVariablesAction } from "actions/VariablesActions"; 
import { resetFormatDIAction } from "actions/FormatDIActions";
import { resetLoginAction } from "actions/LoginActions.jsx";  
import ImportScript from 'components/utils/ImportScript';
import Footer from './Footer';
import Alerts from 'pages/alerts/Alerts.jsx';
import Messages from 'pages/messages/Messages';
import Login from "pages/login/LoginForm";
import GeneralSettings from 'pages/settings/GeneralSettings';
import ColorSettings from "pages/settings/ColorSettings";
import TypesOfTaxes from "pages/settings/TypesOfTaxesSettings"
import CodeLerSettings from "pages/settings/CodeLerSettings";
import OptTypeSettings from "pages/settings/OptTypeSettings.jsx";
import RoadSettings from "pages/settings/RoadSettings";
import DocumentTypeSettings from "pages/settings/DocumentTypeSettings";
import LanguageSettings from "pages/settings/LanguageSettings";
import CountrySettings from "pages/settings/CountrySettings";
import UserSettings from "pages/users/UserSettings";
import RolesUserSettings from "pages/roles/RolesUserSettings";
import VehiclesSettings from "pages/vehicles/VehiclesSettings";
import PrincipalMenuSettings from "pages/settings/InitPrincipalMenus";
import DescriptionSettings from "pages/settings/DescriptionSettings";
import TreatmentTypeSettings from "pages/settings/TreatmentTypeSettings";
import ProcessTypeSettings from "pages/settings/ProcessTypeSettings";
import DangerousnessSettings from "pages/settings/DangerousnessSettings";
import CommunitySettings from "pages/settings/CommunitySettings";
import PaymentTypeSettings from "pages/settings/PaymentTypeSettings";
import ConvertPDFsSettings from "pages/settings/ConvertPDFSettings";
import ArticlesButtonSettings from "pages/settings/articlesButtonSettings/ArticlesButtonsSettings"; 
import EndpointSettings from "pages/endpoints/EndpointSettings";
import ReasonTypeSettings from "pages/settings/ReasonTypeSettings";
import StatusTypeSettings from "pages/settings/StatusTypeSettings";
import ReceivedEmailList from "pages/recievedemails/ReceivedEmailList";

const Home = () => {

    const {t,i18n} = useTranslation(['sidebar']); 
    const dispatch = useDispatch(); 
    const username = useSelector((state) => state.loginUser.username);
    const firstName = useSelector((state) => state.loginUser.firstName);  
    const lastName1 = useSelector((state) => state.loginUser.lastName1);  
    const lastName2 = useSelector((state) => state.loginUser.lastName2);  
    const email = useSelector((state) => state.loginUser.email);  
    const enabled = useSelector((state) => state.loginUser.enabled);  
    const aliasuser = useSelector((state) => state.loginUser.aliasuser);  
    const roles = useSelector((state) => state.loginUser.roles);  
    const language = useSelector((state) => state.loginUser.language);
    const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
    const [showModalGeneralSettings, setShowModalGeneralSettings] = useState(false);
    const [indexShowSettings,setIndexShowSettings]=useState(0);
    const [showModalColorSettings, setShowModalColorSettings] = useState(false);
    const [showRoadSettings, setShowModalRoadSettings] = useState(false); 
    const [showCountrySettings, setShowModalCountrySettings] = useState(false);
    const [showDocumentTypeSettings, setShowModalDocumentTypeSettings] = useState(false);
    const [showCommunitySettings, setShowCommunitySettings] = useState(false);
    const [showLanguageSettings, setShowModalLanguageSettings] = useState(false);
    const [showUserSettings,setShowModalUserSettings] = useState(false);
    const [showRolesUserSettings,setShowModalRolesUserSettings] = useState(false);
    const [showVehiclesSettings,setShowVehiclesSettings] = useState(false);
    const [showModalEmployeeSettings, setShowModalEmployeeSettings] = useState(false);
    const [showModalFormSettings, setShowModalFormSettings] = useState(false); 
    const [showModalTicketsSettings, setShowModalTicketsSettings] = useState(false); 
    const [showModalContractSettings, setShowModalContractSettings] = useState(false); 
    const [showModalStoreSettings, setShowModalStoreSettings] = useState(false);
    const [showModalArticlesSettings, setShowModalArticlesSettings] = useState(false);
    const [showModalTypesOfTaxes, setShowModalTypesOfTaxes] = useState(false);
    const [showModalCodeLerSettings, setShowModalCodeLerSettings] = useState(false);
    const [showModalOptTypeSettings, setShowModalOptTypeSettings] = useState(false);
    const [showModalDescriptionSettings, setShowModalDescriptionSettings] = useState(false);
    const [showModalDangerousnessSettings, setShowModalDangerousnessSettings] = useState(false);
    const [showModalProcessTypeSettings, setShowModalProcessTypeSettings] = useState(false);
    const [showModalTreatmentTypeSettings, setShowModalTreatmentTypeSettings] = useState(false); 
    const [showArticlesButtonSettings, setShowArticlesButtonSettings] = useState(false); 
    const [showModalPurchasesDeliverySettings, setShowModalPurchasesDeliverySettings] = useState(false); 
    const [showModalShippingsSettings, setShowModalShippingsSettings] = useState(false); 
    const [showEndpointSettings, setShowModalEndpointSettings] = useState(false); 
    const [showModalRegisterBookSettings, setShowModalRegisterBookSettings] = useState(false); 
    const [showModalPaymentTypeSettings, setShowModalPaymentTypeSettings] = useState(false); 
    const [showModalStatusTypeSettings, setShowModalStatusTypeSettings] = useState(false);
    const [showModalReasonTypeSettings, setShowModalReasonTypeSettings] = useState(false);
    const [showModalSendEmailsList, setShowModalSendEmailsList] = useState(false);
  
    const [showConvertPDFsSettings, setShowModalConvertPDFsSettings] = useState(false);
    const [showPrincipalMenu, setShowPrincipalMenu] = useState(true);
    const scriptUrl = '/js/sb-admin-2.min.js';
    function changeSelectedLanguage(){
        i18n.language==="es"?i18n.changeLanguage("en"):i18n.changeLanguage("es");
    };
  
    function logOut(){
        setShowModalGeneralSettings(false);
        setIndexShowSettings(0);
        setShowPrincipalMenu(false);
        setShowModalColorSettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalDocumentTypeSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalStatusTypeSettings(false);
        setShowModalReasonTypeSettings(false);
        setShowModalSendEmailsList(false);
        setShowCommunitySettings(false);
        setShowModalLanguageSettings(false);
        setShowModalUserSettings(false);
        setShowModalRolesUserSettings(false);
        setShowVehiclesSettings(false);
        setShowModalTypesOfTaxes(false);
        setShowModalCodeLerSettings(false);
        setShowModalOptTypeSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowArticlesButtonSettings(false);  
        setShowModalConvertPDFsSettings(false);
        setShowModalEmployeeSettings(showModalEmployeeSettings);
        setShowModalFormSettings(showModalFormSettings);
        setShowModalTicketsSettings(showModalTicketsSettings);
        setShowModalStoreSettings(showModalStoreSettings);
        setShowModalArticlesSettings(showModalArticlesSettings);
        setShowModalPurchasesDeliverySettings(showModalPurchasesDeliverySettings);
        setShowModalShippingsSettings(showModalShippingsSettings);
        setShowModalRegisterBookSettings(showModalRegisterBookSettings);
        setShowModalEndpointSettings(showEndpointSettings);
        setShowModalContractSettings(showModalContractSettings);
        dispatch(resetLoginAction());
        dispatch(resetVariablesAction());
        dispatch(resetFormatDIAction());
    };
     
    function openModalUsername(){
        setShowModalGeneralSettings(false);
        setIndexShowSettings(0);
        setShowPrincipalMenu(false);
        setShowModalColorSettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalDocumentTypeSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalStatusTypeSettings(false);
        setShowModalReasonTypeSettings(false);
        setShowModalSendEmailsList(false);
        setShowCommunitySettings(false);
        setShowModalLanguageSettings(false);
        setShowModalUserSettings(!showUserSettings);
        setShowModalRolesUserSettings(false);
        setShowVehiclesSettings(false);
        setShowModalTypesOfTaxes(false);
        setShowModalCodeLerSettings(false);
        setShowModalOptTypeSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowArticlesButtonSettings(false); 
        setShowModalConvertPDFsSettings(false);
        setShowModalEmployeeSettings(showModalEmployeeSettings); 
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalEndpointSettings(false);
        setShowModalStoreSettings(false);
        setShowModalRegisterBookSettings(false);
    }

    function openModalRoleUser(){
        setShowModalGeneralSettings(false);
        setIndexShowSettings(0);
        setShowPrincipalMenu(false);
        setShowModalColorSettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalDocumentTypeSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalStatusTypeSettings(false);
        setShowModalReasonTypeSettings(false);
        setShowModalSendEmailsList(false);
        setShowCommunitySettings(false);
        setShowModalLanguageSettings(false);
        setShowModalUserSettings(false);
        setShowModalRolesUserSettings(true);
        setShowVehiclesSettings(false);
        setShowModalTypesOfTaxes(false);
        setShowModalCodeLerSettings(false);
        setShowModalOptTypeSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowArticlesButtonSettings(false); 
        setShowModalConvertPDFsSettings(false);
        setShowModalEmployeeSettings(false); 
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalEndpointSettings(false);
        setShowModalStoreSettings(false);
        setShowModalRegisterBookSettings(false);
    }

    function openModalEndpoints(){
        setShowModalGeneralSettings(false);
        setIndexShowSettings(0);
        setShowPrincipalMenu(false);
        setShowModalColorSettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalDocumentTypeSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalStatusTypeSettings(false);
        setShowModalReasonTypeSettings(false);
        setShowModalSendEmailsList(false);
        setShowCommunitySettings(false);
        setShowModalLanguageSettings(false);
        setShowModalUserSettings(false);
        setShowModalRolesUserSettings(false);
        setShowVehiclesSettings(false);
        setShowModalTypesOfTaxes(false);
        setShowModalCodeLerSettings(false);
        setShowModalOptTypeSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowArticlesButtonSettings(false); 
        setShowModalConvertPDFsSettings(false);
        setShowModalEmployeeSettings(false); 
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalEndpointSettings(true);
        setShowModalRegisterBookSettings(false);
        setShowModalStoreSettings(false);
    }

    function openModalRecievedEmails(){
        setShowModalGeneralSettings(false);
        setIndexShowSettings(0);
        setShowPrincipalMenu(false);
        setShowModalColorSettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalDocumentTypeSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalStatusTypeSettings(false);
        setShowModalReasonTypeSettings(false);
        setShowModalSendEmailsList(true);
        setShowCommunitySettings(false);
        setShowModalLanguageSettings(false);
        setShowModalUserSettings(false);
        setShowModalRolesUserSettings(false);
        setShowVehiclesSettings(false);
        setShowModalTypesOfTaxes(false);
        setShowModalCodeLerSettings(false);
        setShowModalOptTypeSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowArticlesButtonSettings(false); 
        setShowModalConvertPDFsSettings(false);
        setShowModalEmployeeSettings(false); 
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalEndpointSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalStoreSettings(false);
    }

    function openModalVehicles(){
        setShowModalGeneralSettings(false);
        setIndexShowSettings(0);
        setShowPrincipalMenu(false);
        setShowModalColorSettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalDocumentTypeSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalStatusTypeSettings(false);
        setShowModalReasonTypeSettings(false);
        setShowModalSendEmailsList(false);
        setShowCommunitySettings(false);
        setShowModalLanguageSettings(false);
        setShowModalUserSettings(false);
        setShowModalRolesUserSettings(false);
        setShowVehiclesSettings(true);
        setShowModalTypesOfTaxes(false);
        setShowModalCodeLerSettings(false);
        setShowModalOptTypeSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowArticlesButtonSettings(false); 
        setShowModalConvertPDFsSettings(false);
        setShowModalEmployeeSettings(false); 
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalEndpointSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalStoreSettings(false);
    }

    function openModalGeneralSettings(){
        setShowModalGeneralSettings(!showModalGeneralSettings);
        setIndexShowSettings(0);
        setShowPrincipalMenu(false);
        setShowModalColorSettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalDocumentTypeSettings(false);
        setShowModalPaymentTypeSettings(false);
        setShowModalStatusTypeSettings(false);
        setShowModalReasonTypeSettings(false);
        setShowModalSendEmailsList(false);
        setShowCommunitySettings(false);
        setShowModalLanguageSettings(false);
        setShowModalUserSettings(false);
        setShowModalRolesUserSettings(false);
        setShowVehiclesSettings(false);
        setShowModalTypesOfTaxes(false);
        setShowModalCodeLerSettings(false);
        setShowModalOptTypeSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowArticlesButtonSettings(false); 
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalEndpointSettings(false);
        setShowModalConvertPDFsSettings(false);
        setShowModalFormSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalTicketsSettings(false);
    }
    
    useEffect(
        () => {
            let isMounted = true;
                if (isMounted) {
                    switch (indexShowSettings) {
                        case 1:    
                            return setShowModalColorSettings(true);
                        case 2:    
                            return setShowModalDocumentTypeSettings(true);
                        case 3:    
                            return setShowModalLanguageSettings(true); 
                        case 4:
                            return setShowModalCountrySettings(true);
                        case 5:    
                            return setShowModalRoadSettings(true);   
                        case 6:    
                            return setShowCommunitySettings(true);
                        case 7:    
                            return setShowModalTypesOfTaxes(true);
                        case 8:    
                            return setShowModalCodeLerSettings(true);
                        case 9:    
                            return setShowModalDescriptionSettings(true);
                        case 10:    
                            return setShowModalTreatmentTypeSettings(true);
                        case 11:    
                            return setShowModalProcessTypeSettings(true);
                        case 12:    
                            return setShowModalDangerousnessSettings(true);
                        case 13:  
                            return setShowArticlesButtonSettings(true);
                        case 14:  
                            return setShowModalOptTypeSettings(true);
                        case 15:  
                            return setShowModalPaymentTypeSettings(true);
                        case 16:  
                            return setShowModalStatusTypeSettings(true);
                        case 17:  
                            return setShowModalReasonTypeSettings(true);
                        case 18:  
                            return setShowModalConvertPDFsSettings(true);
                        default:
                            return null;
                }
            }
            return()=>{ isMounted=false;}    
        },
        [indexShowSettings]
      );
   
    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer></ToastContainer>
        {(enabled===false || roles===undefined || roles[0]===undefined || roles[0].listmenus===undefined) ? <Login/>:
        <div id="page-top">

            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Heading */}
                    <div className="sidebar-heading">
                        {t('Text_Interface')}
                    </div>

                    {/* Nav Item - Stores Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Stores")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapseStores"
                            aria-expanded="true" aria-controls="collapseStores">
                                <SettingsIcon/>
                                <span> {t('Menu_Stores')}</span>
                            </Link>
                            <div id="collapseStores" className="collapse" aria-labelledby="headingStores" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Stores')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Stores_Stores")) &&
                                        <Link className="collapse-item"  to="/store">{t('SubMenu_Stores_Stores')} </Link>
                                    }
                                </div>
                            </div>
                        </li>
                    }

                     {/* Nav Item - Employees Collapse Menu */}
                     {roles[0].listmenus.find((key)=>(key.name==="Menu_Employees")) &&
                    
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapseEmployees"
                                aria-expanded="true" aria-controls="collapseEmployees">
                                <SettingsIcon/>
                                <span> {t('Menu_Employees')}</span>
                            </Link>
                            <div id="collapseEmployees" className="collapse" aria-labelledby="headingEmployees"
                            data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Employees')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Employees")) &&
                                        <Link className="collapse-item"  to="/employee">{t('SubMenu_Employees_Employees')} </Link>
                                    }             
                                </div>
                            </div>
                        </li>
                    } 

                    {/* Nav Item - Articles Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Articles")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapsArticles"
                                aria-expanded="true" aria-controls="collapsArticles">
                                <SettingsIcon/>
                                <span> {t('Menu_Articles')}</span>
                            </Link>
                            <div id="collapsArticles" className="collapse" aria-labelledby="headingArticles"
                            data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Articles')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Articles_Articles")) &&
                                        <Link className="collapse-item"  to="/article">{t('SubMenu_Articles_Filters')} </Link>
                                    }             
                                </div>
                            </div>
                        </li>
                    }

                    {/* Nav Item - VEHICLES Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Handle_Store_View_Vehicle")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapsevehicles"
                                aria-expanded="true" aria-controls="collapsevehicles">
                                <SettingsIcon/>
                                <span>{t('Menu_Vehicles')}</span>
                            </Link>
                            <div id="collapsevehicles" className="collapse" aria-labelledby="headingvehicles"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Vehicles')}</h6>
                                    <Link className="collapse-item"  to="/home" onClick={openModalVehicles}>{t('SubMenu_Vehicles_Filters')} </Link> 
                                </div>
                            </div>
                        </li> 
                    }
                    
                    {/* Nav Item - TICKETS DETAILS Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TicketsDetails")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapsTicketsDetails"
                                aria-expanded="true" aria-controls="collapsTicketsDetails">
                                <SettingsIcon/>
                                <span> {t('Menu_TicketsDetails')}</span>
                            </Link>
                            <div id="collapsTicketsDetails" className="collapse" aria-labelledby="headingTicketsDetail"
                            data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_TicketsDetails')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TicketsDetails")) &&
                                        <Link className="collapse-item"  to="/ticketsdetails">{t('SubMenu_TicketsDetails_Filters')} </Link>
                                    }             
                                </div>
                            </div>
                        </li>
                    }

                    {/* Nav Item - PURCHASE NOTES DETAILS Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Purchases")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapsPurchasesDetails"
                                aria-expanded="true" aria-controls="collapsPurchasesDetails">
                                <SettingsIcon/>
                                <span> {t('Menu_Purchases')}</span>
                            </Link>
                            <div id="collapsPurchasesDetails" className="collapse" aria-labelledby="headingPurchasesDetail"
                            data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_PurchasesDetails')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Purchases")) &&
                                        <Link className="collapse-item"  to="/purchasedelivery">{t('SubMenu_PurchasesPendindg_Filters')} </Link>
                                    }  
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Purchases_Edit_Ended")) &&
                                        <Link className="collapse-item"  to="/purchasedeliveryended">{t('SubMenu_PurchasesEnded_Filters')} </Link>
                                    } 
                                     {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Purchases_Signature")) &&
                                        <Link className="collapse-item"  to="/purchasesignature">{t('SubMenu_PurchasesSigned_Filters')} </Link>
                                    }             
                                </div>
                            </div>
                        </li>
                    }

                     {/* Nav Item - SHIPPING BILL Collapse Menu */}
                     {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Shippings")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapseShippings"
                                aria-expanded="true" aria-controls="collapseShippings">
                                <SettingsIcon/>
                                <span> {t('Menu_Shippings')}</span>
                            </Link>
                            <div id="collapseShippings" className="collapse" aria-labelledby="headingShippings"
                            data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Shippings')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Shippings")) &&
                                        <Link className="collapse-item"  to="/shipping">{t('SubMenu_Shippings_Contracts')} </Link>
                                    }             
                                </div>
                            </div>
                        </li>
                    }

                    {/* Nav Item - Contracts Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapseContracts"
                                aria-expanded="true" aria-controls="collapseContracts">
                                <SettingsIcon/>
                                <span> {t('Menu_Contract')}</span>
                            </Link>
                            <div id="collapseContracts" className="collapse" aria-labelledby="headingContracts"
                            data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Contracts')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts")) &&
                                        <Link className="collapse-item"  to="/contract">{t('SubMenu_Contracts_Contracts')} </Link>
                                    }             
                                </div>
                            </div>
                        </li>
                    }

                      {/* Nav Item - RegisterBook Collapse Menu */}
                      {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Register_Book_Store_Master")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapseRegisterBook"
                                aria-expanded="true" aria-controls="collapseRegisterBook">
                                <SettingsIcon/>
                                <span> {t('Menu_RegisterBook')}</span>
                            </Link>
                            <div id="collapseRegisterBook" className="collapse" aria-labelledby="headingContracts"
                            data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_RegisterBook')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Register_Book_Store_Master")) &&
                                        <Link className="collapse-item"  to="/registerbook">{t('SubMenu_RegisterBook')} </Link>
                                    }    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Emails"))&&
                                        <Link className="collapse-item"  to="/home" onClick={openModalRecievedEmails}>{t('SubMenu_Emails_Emails')} </Link>
                                    }         
                                </div>
                            </div>
                        </li>
                    }


                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Sidebar - INFORMATION */}
                    <li className="nav-item active">
                        <Link className="nav-link" to="/home">
                            <FeedIcon/>
                            <span> {t('Text_Information')}</span>
                        </Link>
                    </li>
                    <div className="sidebar-heading">
                        {t('Text_User')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { username==='' ? t('Text_UserEmpty'): username}
                    </li>
                    <div className="sidebar-heading">
                        {t('Text_Alias')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { aliasuser==='' ? t('Text_AliasEmpty'): aliasuser}
                    </li>
                    <div className="sidebar-heading">
                        {t('Text_Name')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { firstName==='' ? t('Text_NameEmpty'): firstName+ ' '+ (lastName1===''?'':lastName1+' ')+ (lastName2===''?'':lastName2+' ')}
                    </li>

                    <div className="sidebar-heading">
                        {t('Text_Email')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { email==='' ? t('Text_EmailEmpty'): email}
                    </li>

                    <div className="sidebar-heading">
                        {t('Text_Role')}
                    </div>
                    {roles==null? t('Text_RoleEmpty'):roles.slice(0, 5).map((post) =>
                        <li className="sidebar-heading text-warning" key={post.id}>
                            {post.name}
                        </li>
                    )}
                    <div className="sidebar-heading">
                        {t('Text_Currentlanguage')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { language.code==='es' ? language.namees: language.nameen}
                    </li>
                    <div className="sidebar-heading">
                        {t('Text_StoreSelected')}
                    </div>
                    <li className="sidebar-heading text-warning">
                        { defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                    </li>
                    <br/>
                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Heading */}
                    <div className="sidebar-heading">
                        {t('Text_Admin')}
                    </div>

                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/home" data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Settings"))&&
                                        <Link className="collapse-item"  to="/home" onClick={openModalGeneralSettings}>{t('SubMenu_Settings_Settings')} </Link> 
                                    }
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TypesDi"))&&
                                        <Link className="collapse-item"  to="/formatdi">{t('SubMenu_Settings_TypesDi')} </Link>
                                    }
                                        
                                        {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TicketsFormat"))&&
                                        <Link className="collapse-item"  to="/ticketsformat" >{t('SubMenu_Settings_TicketsFormat')} </Link>
                                    }

                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Users"))&&
                                        <Link className="collapse-item"  to="/home" onClick={openModalUsername}>{t('SubMenu_Settings_Users')} </Link>
                                    }
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Roles"))&&
                                        <Link className="collapse-item"  to="/home" onClick={openModalRoleUser}>{t('SubMenu_Settings_Roles')} </Link>
                                    }
                                    {roles[0].listmenus.find((key)=>(key.name==="Handle_Store_View_ENDPOINT"))&&
                                        <Link className="collapse-item"  to="/home" onClick={openModalEndpoints}>{t('SubMenu_Settings_Endpoints')} </Link>
                                    }
                                    <Link className="collapse-item" to="/home" onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                    <Link className="collapse-item" to="/home">{t('SubMenu_Settings_ChangePassword')}</Link>
                                </div>
                            </div>
                        </li> 
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to="/"  onClick={logOut}>
                            <LogoutIcon/>
                            <span>{t('Menu_LogOut')}</span>
                        </Link>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block"/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>
 
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>      
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}
                
                
                {/* Show Modals */}
                <PrincipalMenuSettings  
                    showPrincipalMenu={showPrincipalMenu}
                    setIndexShowSettings={setIndexShowSettings}
                    setShowModalGeneralSettings={setShowModalGeneralSettings}
                    setShowPrincipalMenu={setShowPrincipalMenu}
                />
                <GeneralSettings  
                    setShowPrincipalMenu={setShowPrincipalMenu}
                    showModalGeneralSettings={showModalGeneralSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    indexShowSettings={indexShowSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                />
                <ColorSettings  
                    showModalColorSettings={showModalColorSettings}
                    setShowModalColorSettings={setShowModalColorSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={false}
                />
                <TypesOfTaxes  
                    showModalTypesOfTaxes={showModalTypesOfTaxes}
                    setShowModalTypesOfTaxes={setShowModalTypesOfTaxes} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={false}  
                />
                <CodeLerSettings  
                    showModalCodeLerSettings={showModalCodeLerSettings}
                    setShowModalCodeLerSettings={setShowModalCodeLerSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                 <OptTypeSettings  
                    showModalOptTypeSettings={showModalOptTypeSettings}
                    setShowModalOptTypeSettings={setShowModalOptTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <TreatmentTypeSettings  
                    showModalTreatmentTypeSettings={showModalTreatmentTypeSettings}
                    setShowModalTreatmentTypeSettings={setShowModalTreatmentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <ArticlesButtonSettings  
                    showArticlesButtonSettings={showArticlesButtonSettings}
                    setShowArticlesButtonSettings={setShowArticlesButtonSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                /> 
                <ProcessTypeSettings  
                    showModalProcessTypeSettings={showModalProcessTypeSettings}
                    setShowModalProcessTypeSettings={setShowModalProcessTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <DangerousnessSettings  
                    showModalDangerousnessSettings={showModalDangerousnessSettings}
                    setShowModalDangerousnessSettings={setShowModalDangerousnessSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <DescriptionSettings  
                    showModalDescriptionSettings={showModalDescriptionSettings}
                    setShowModalDescriptionSettings={setShowModalDescriptionSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                /> 
                 <PaymentTypeSettings  
                    showModalPaymentTypeSettings={showModalPaymentTypeSettings}
                    setShowModalPaymentTypeSettings={setShowModalPaymentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                /> 
                <CommunitySettings  
                    showCommunitySettings={showCommunitySettings} 
                    setShowCommunitySettings={setShowCommunitySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <DocumentTypeSettings  
                    showDocumentTypeSettings={showDocumentTypeSettings} 
                    setShowModalDocumentTypeSettings={setShowModalDocumentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <LanguageSettings  
                    showLanguageSettings={showLanguageSettings} 
                    setShowModalLanguageSettings={setShowModalLanguageSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                /> 
                <StatusTypeSettings  
                    showModalStatusTypeSettings={showModalStatusTypeSettings} 
                    setShowModalStatusTypeSettings={setShowModalStatusTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings}   
                /> 
                <ReasonTypeSettings  
                    showModalReasonTypeSettings={showModalReasonTypeSettings} 
                    setShowModalReasonTypeSettings={setShowModalReasonTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings}   
                /> 
                <RoadSettings   
                    showRoadSettings={showRoadSettings} 
                    setShowModalRoadSettings={setShowModalRoadSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <CountrySettings  
                    showCountrySettings={showCountrySettings} 
                    setShowModalCountrySettings={setShowModalCountrySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                /> 
                <UserSettings  
                    setShowPrincipalMenu={setShowPrincipalMenu}
                    showUserSettings={showUserSettings} 
                    setShowModalUserSettings={setShowModalUserSettings}
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                />
                <RolesUserSettings  
                    setShowPrincipalMenu={setShowPrincipalMenu}
                    showRolesUserSettings={showRolesUserSettings} 
                    setShowModalRolesUserSettings={setShowModalRolesUserSettings}
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                />
                 <VehiclesSettings  
                    setShowPrincipalMenu={setShowPrincipalMenu}
                    showVehiclesSettings={showVehiclesSettings} 
                    setShowVehiclesSettings={setShowVehiclesSettings}
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    showModalTicketsSettings={showModalTicketsSettings}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                />
                 <EndpointSettings  
                    setShowPrincipalMenu={setShowPrincipalMenu}
                    showEndpointSettings={showEndpointSettings} 
                    setShowModalEndpointSettings={setShowModalEndpointSettings}
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                />
                <ReceivedEmailList  
                    setShowPrincipalMenu={setShowPrincipalMenu}
                    showModalSendEmailsList={showModalSendEmailsList} 
                    setShowModalSendEmailsList={setShowModalSendEmailsList}
                    setShowModalGeneralSettings={setShowModalGeneralSettings}  
                />
                 <ConvertPDFsSettings  
                    showConvertPDFsSettings={showConvertPDFsSettings}
                    setShowModalConvertPDFsSettings={setShowModalConvertPDFsSettings} 
                    setIndexShowSettings={setIndexShowSettings}
                    setShowPrincipalMenu={setShowPrincipalMenu} 
                />
                </div>
                {/* End of Main Content */}

                {/* Footer */}
                    <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}
         


      </div>}
      {/* End of Body */}

    </>
  )
};

export default Home;
