import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  licenseplateFrom:"",
  licenseplateTo:"",
  taraFrom:"",
  taraTo:"",
  pmaFrom:"",
  pmaTo:"",
  checkpma:true,
  notcheckpma:true,
  defaultdriverFrom:"",
	defaultdriverTo:"",
  vehicleStoreFrom:"",
  vehicleStoreTo:"",
  enabled:true,
  notenabled:true 
};

const filtersGetVehiclesSlice = createSlice({
  name: 'filtersGetVehicles',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersGetVehiclesSlice.actions;
export default filtersGetVehiclesSlice.reducer;
