import React, {useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { setFilters, resetFilters} from 'reducers/filtersTicketsSlice';
import { resetPagination } from 'reducers/paginationTicketsSlice';
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem  from "@mui/material/MenuItem"; 
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { NumericFormat } from 'react-number-format'; 
import TableSortingTicketsDetails from "pages/ticketsDetails/SortingTablesTicketsDetails";
import TicketsPDF from "pages/ticketsDetails/generatePDF/TicketsPDF";
import TicketsPrint from "pages/ticketsDetails/generatePDF/TicketsPrint";
import PropTypes from 'prop-types';
 
const initialState = {
  idFrom:"",
  idTo:"",
  defaultStoreIdFrom:"",
  defaultStoreIdTo:"",
  useraccountIdFrom:"",
  useraccountIdTo:"",
  isFinished:false,
  isNotFinished:true,
  numberticketFrom:"",
  numberticketTo:"",
  start_date_ticketFrom:null,
	start_date_ticketTo:null,
  end_date_ticketFrom:null,
  end_date_tickettTo:null,
  vehiclestore_licenseplateFrom:"",
  vehiclestore_licenseplateTo:"",
  rel_trans_store_idFrom:"",
  rel_trans_store_idTo:"",
  ori_tras_store_idFrom:"",
  ori_tras_store_idTo:"",
  numberDIFROM:"",
  numberDITO:"",
};

const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": ""
}];
 
const initialVehicleToSelect = [{
  "id": "",
  "vehiclestore_licenseplate": "" 
}];

const initialStateInvoiceTickets = [{
  "id" : "",
  "defaultStore_id": 0,
  "tradename": "",
  "useraccount_id": 0,
  "username": "",
  "defaultformatnumber": 1,
  "numberticket":0,
  "vehiclestore_licenseplate" : "",
  "start_date_ticket" : "",
  "end_date_ticket" : "",
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalstartweight" : 0,
  "totalendweight" : 0,
  "enabled": true ,
  "formDIAssociates": ""
}];  
 

const TicketsDetailsFilter = ({ showModalTicketsDetails}) => {
  const {t,i18n} = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_TICKETS = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const [dataTicketsDetails, setDataTicketsDetails] = useState(initialStateInvoiceTickets); 
  const filtersTicketsDetails = useSelector((state) => state.filtersTickets);
  const [filterTicket,setFilterTicket]=useState(filtersTicketsDetails);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegTicketsDetails,setNumTotRegTicketsDetails]= useState(0);
  const [errorsFiltersTicketsDetails, setErrorsFiltersTicketsDetails] = useState({});
  const [inputTextTicketsDetails, setInputTextTicketsDetails] = useState(""); 
  const [openDeleteRowTicketsDetails,setOpenDeleteRowTicketsDetails]= useState(false); 
  const [openEditRowTicketsDetails,setOpenEditRowTicketsDetails]= useState(false);
  const [openEditFilterTicketsDetails,setOpenEditFilterTicketsDetails]= useState(false);
  const [rowEditTicketsDetails,setRowEditTicketsDetails]= useState(initialStateInvoiceTickets[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorTicketsDetails, setIsLoadingErrorTicketsDetails] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const [viewPDF,setViewPDF]=useState(false);  
  const [viewPrint,setViewPrint]=useState(false);  
  const dispatch = useDispatch();
  const [activeOffsetTicketsDetails, setActiveOffsetTicketsDetails]= useState(false);
  const [currencyOffsetTicketsDetails, setCurrencyOffsetTicketsDetails] = useState(0);
  const [rangeOffsetTicketsDetails, setRangeOffsetTicketsDetails] = useState([{}]);
  const [vehiclesToSelect,setVehiclesToSelect]= useState(initialVehicleToSelect); 
  const [tradenameToSelect,setTradenameToSelect]= useState(initialStoresToSelect); 
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoresToSelect);   
  const [filteredStoreFrom, setFilteredStoreFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexStoreFrom, setHighlightedIndexStoreFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredStoreTo, setFilteredStoreTo] = useState([]); // Opciones filtradas
  const [highlightedIndexStoreTo, setHighlightedIndexStoreTo] = useState(-1); // Índice de la opción resaltada
  const [filteredTransFrom, setFilteredTransFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexTransFrom, setHighlightedIndexTransFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredTransTo, setFilteredTransTo] = useState([]); // Opciones filtradas
  const [highlightedIndexTransTo, setHighlightedIndexTransTo] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleFrom, setFilteredVehicleFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleFrom, setHighlightedIndexVehicleFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleTo, setFilteredVehicleTo] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleTo, setHighlightedIndexVehicleTo] = useState(-1); // Índice de la opción resaltada 
  const [refeshData,setRefeshData]=useState(false);
  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalTicketsDetails) {
        setDataTicketsDetails(initialStateInvoiceTickets); 
        setNumTotRegFilter(0); 
        setNumTotRegTicketsDetails(0);
        setErrorsFiltersTicketsDetails({});
        setInputTextTicketsDetails("");
        setOpenDeleteRowTicketsDetails(false); 
        setOpenEditRowTicketsDetails(false);
        setOpenEditFilterTicketsDetails(false);
        setRowEditTicketsDetails(initialStateInvoiceTickets[0]);
        dispatch(setLoading(false));
        setIsLoadingErrorTicketsDetails(false);
        setIdCodeDelete(DELETE_CODE);
        setViewPDF(false);
        setViewPrint(false);
        setRefeshData(false);
        setActiveOffsetTicketsDetails(false);
        setCurrencyOffsetTicketsDetails(0);
        setRangeOffsetTicketsDetails([{}]);
        setVehiclesToSelect(initialVehicleToSelect);
        setFilteredVehicleFrom([]);
        setHighlightedIndexVehicleFrom(-1); 
        setFilteredVehicleTo([]);
        setHighlightedIndexVehicleTo(-1);  
        setTrasnporterToSelect(initialStoresToSelect);
        setTradenameToSelect(initialStoresToSelect);
        setFilteredStoreFrom([]);
        setHighlightedIndexStoreFrom(-1); 
        setFilteredStoreTo([]);  
        setHighlightedIndexStoreTo(-1);  
        setFilteredTransFrom([]);
        setHighlightedIndexTransFrom(-1);  
        setFilteredTransTo([]);
        setHighlightedIndexTransTo(-1);  
        setFilteredVehicleFrom([]);
        setHighlightedIndexVehicleFrom(-1);  
        setFilteredVehicleTo([]);
        setHighlightedIndexVehicleTo(-1);   
        dispatch(resetPagination());
        dispatch(resetFilters());
        setFilterTicket(filtersTicketsDetails);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataTicketsDetails,  
      setNumTotRegFilter,
      setFilterTicket,
      filtersTicketsDetails,
      setNumTotRegTicketsDetails,
      setErrorsFiltersTicketsDetails,
      setInputTextTicketsDetails,
      setOpenDeleteRowTicketsDetails, 
      setOpenEditRowTicketsDetails,
      setOpenEditFilterTicketsDetails,
      setRowEditTicketsDetails, 
      setIsLoadingErrorTicketsDetails,
      setIdCodeDelete,
      setViewPDF,
      setViewPrint,
      setRefeshData,
      setActiveOffsetTicketsDetails,
      setCurrencyOffsetTicketsDetails,
      setRangeOffsetTicketsDetails,
      setVehiclesToSelect,
      setTrasnporterToSelect,
      setTradenameToSelect,
      setFilteredStoreFrom,
      setHighlightedIndexStoreFrom, 
      setFilteredStoreTo,
      setHighlightedIndexStoreTo, 
      setFilteredTransFrom,
      setHighlightedIndexTransFrom, 
      setFilteredTransTo,
      setHighlightedIndexTransTo,  
      setFilteredVehicleFrom,
      setHighlightedIndexVehicleFrom, 
      setFilteredVehicleTo,
      setHighlightedIndexVehicleTo,   
      showModalTicketsDetails,  
      navigate,
      dispatch,
      DELETE_CODE]
  );

  const closeModalTicketsDetails = () => {    
    setDataTicketsDetails(initialStateInvoiceTickets); 
    setNumTotRegFilter(0); 
    setNumTotRegTicketsDetails(0);
    setErrorsFiltersTicketsDetails({});
    setInputTextTicketsDetails("");
    setOpenDeleteRowTicketsDetails(false); 
    setOpenEditRowTicketsDetails(false);
    setOpenEditFilterTicketsDetails(false);
    setRowEditTicketsDetails(initialStateInvoiceTickets[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorTicketsDetails(false);
    setIdCodeDelete(DELETE_CODE);
    setViewPDF(false);
    setViewPrint(false);
    setRefeshData(false);
    setActiveOffsetTicketsDetails(false);
    setCurrencyOffsetTicketsDetails(0);
    setRangeOffsetTicketsDetails([{}]); 
    setVehiclesToSelect(initialVehicleToSelect);
    setTrasnporterToSelect(initialStoresToSelect);
    setTradenameToSelect(initialStoresToSelect); 
    setFilteredStoreFrom([]);
    setHighlightedIndexStoreFrom(-1); 
    setFilteredStoreTo([]);  
    setHighlightedIndexStoreTo(-1);  
    setFilteredTransFrom([]);
    setHighlightedIndexTransFrom(-1);  
    setFilteredTransTo([]);
    setHighlightedIndexTransTo(-1);  
    setFilteredVehicleFrom([]);
    setHighlightedIndexVehicleFrom(-1);  
    setFilteredVehicleTo([]);
    setHighlightedIndexVehicleTo(-1);   
    dispatch(resetPagination());
    dispatch(resetFilters());
    setFilterTicket(filtersTicketsDetails);
    navigate("/home");
    return () => {};
  };
 
  const openEditFiltersTicketsDetails  = () => {
    setOpenEditFilterTicketsDetails(!openEditFilterTicketsDetails);
  };

  const handleSubmitFilterEditTicketsDetails = () => {
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails()); 
    if (Object.keys(errorsFiltersTicketsDetails).length === 0) {
      setErrorsFiltersTicketsDetails({});
      dispatch(resetPagination());
      dispatch(setFilters(filterTicket));  
      setOpenEditFilterTicketsDetails(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    } 
  }

  const handleDeleteFiltersTicketsDetails = () => {
    setErrorsFiltersTicketsDetails({});
    dispatch(resetFilters());
    dispatch(resetPagination());
    setFilterTicket(initialState);
    setOpenEditFilterTicketsDetails(!openEditFilterTicketsDetails);
  };

  const handleCancelFiltersTicketsDetails = () => {
    setErrorsFiltersTicketsDetails({});
    setOpenEditFilterTicketsDetails(!openEditFilterTicketsDetails);
  };
  
  const handleChangeFilterEditTicketsDetails = (e) => {
    const { name, value } = e.target;
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      [name]: value
    }));
  };

  const handleBlurSelectStartDateTicketsDetailsFrom = (e) => {
    handleChangeSelectStartDateTicketsDetailsFrom(e);
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100000000) return true;
    return false;
  };

  const handleChangeSelectStartDateTicketsDetailsFrom = (event) => {
    const newDate = event ? new Date(event) : null;
    if (newDate && isNaN(newDate.getTime())) { 
      return; // Salir si la fecha es inválida
    }
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      start_date_ticketFrom: newDate,
      start_date_ticketTo:null
    }));
  };
 
  const handleBlurSelectStartDateTicketsDetailsTo = (e) => {
    handleChangeSelectStartDateTicketsDetailsTo(e);
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
  };

  const handleChangeSelectStartDateTicketsDetailsTo= (event) => {
    const newDate = event ? new Date(event) : null;
    if (newDate && isNaN(newDate.getTime())) { 
      return; // Salir si la fecha es inválida
    }
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      start_date_ticketTo: newDate 
    }));
  };
 
  const handleBlurSelectEndDateTicketsDetailsFrom = (e) => {
    handleChangeSelectEndDateTicketsDetailsFrom(e);
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
  };
 
  const handleChangeSelectEndDateTicketsDetailsFrom = (event) => {
    const newDate = event ? new Date(event) : null;
    if (newDate && isNaN(newDate.getTime())) { 
      return; // Salir si la fecha es inválida
    }
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      end_date_ticketFrom: newDate,
      end_date_tickettTo:null
    })); 
  };
 
  const handleBlurSelectEndDateTicketsDetailsTo = (e) => {
    handleChangeSelectEndDateTicketsDetailsTo(e);
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
  };

  const handleChangeSelectEndDateTicketsDetailsTo = (event) => {
    const newDate = event ? new Date(event) : null;
    if (newDate && isNaN(newDate.getTime())) { 
      return; // Salir si la fecha es inválida
    }
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      end_date_tickettTo: newDate 
    }));  
  }; 

  const handleBlurFilterEditTicketsDetails = (e) => {
    setErrorsFiltersTicketsDetails(validateFormFilterTicketsDetails());
    handleChangeFilterEditTicketsDetails(e);
  };

  const toggleDrawerFilterEditTicketsDetails = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditTicketsDetails();
    }
  };

  const validateFormFilterTicketsDetails = () => {

    let errorsFiltersTicketsDetails = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filterTicket.idFrom!=="" & !regexNumber.test(filterTicket.idFrom)) {
      errorsFiltersTicketsDetails.idFrom = 'Text_TextNoNumber';
    }
    if (filterTicket.idTo!=="" & !regexNumber.test(filterTicket.idTo)) {
      errorsFiltersTicketsDetails.idTo = 'Text_TextNoNumber';
    }
    if (filterTicket.idFrom!=="" & !errorsFiltersTicketsDetails.idFrom!==undefined){
      if (filterTicket.idTo!=="" & !errorsFiltersTicketsDetails.idTo!==undefined){
        if (parseInt(filterTicket.idTo)<parseInt(filterTicket.idFrom)){
          errorsFiltersTicketsDetails.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filterTicket.numberDIFROM!=="" & !regexNumber.test(filterTicket.numberDIFROM)) {
      errorsFiltersTicketsDetails.numberDIFROM = 'Text_TextNoNumber';
    }
    if (filterTicket.numberDITO!=="" & !regexNumber.test(filterTicket.numberDITO)) {
      errorsFiltersTicketsDetails.numberDITO = 'Text_TextNoNumber';
    }
    if (filterTicket.numberDIFROM!=="" & !errorsFiltersTicketsDetails.numberDIFROM!==undefined){
      if (filterTicket.numberDITO!=="" & !errorsFiltersTicketsDetails.numberDITO!==undefined){
        if (parseInt(filterTicket.numberDITO)<parseInt(filterTicket.numberDIFROM)){
          errorsFiltersTicketsDetails.numberDITO = 'Text_TextGreater_FormDI';
        }
      }
    }

    if (filterTicket.numberticketFrom!=="" & !regexNumber.test(filterTicket.numberticketFrom)) {
      errorsFiltersTicketsDetails.numberticketFrom = 'Text_TextNoNumber';
    }
    if (filterTicket.numberticketTo!=="" & !regexNumber.test(filterTicket.numberticketTo)) {
      errorsFiltersTicketsDetails.numberticketTo = 'Text_TextNoNumber';
    }
    if (filterTicket.numberticketFrom!=="" & !errorsFiltersTicketsDetails.numberticketFrom!==undefined){
      if (filterTicket.numberticketTo!=="" & !errorsFiltersTicketsDetails.numberticketTo!==undefined){
        if (parseInt(filterTicket.numberticketTo)<parseInt(filterTicket.numberticketFrom)){
          errorsFiltersTicketsDetails.numberticketTo = 'Text_TextGreater_Id';
        }
      }
    }
    
    if (regexInvalidCharacters.test(filterTicket.vehiclestore_licenseplateFrom)) {
      errorsFiltersTicketsDetails.vehiclestore_licenseplateFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filterTicket.vehiclestore_licenseplateTo)) {
      errorsFiltersTicketsDetails.vehiclestore_licenseplateTo = 'Text_TextNoValid';
    }
   
    if (filterTicket.defaultStoreIdTo!=="" & filterTicket.defaultStoreIdTo<filterTicket.defaultStoreIdFrom){
      errorsFiltersTicketsDetails.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filterTicket.useraccountIdTo!=="" & filterTicket.useraccountIdTo<filterTicket.useraccountIdFrom){
      errorsFiltersTicketsDetails.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }
 
    return errorsFiltersTicketsDetails;
  }  

  const filteredDataTicketsDetails = dataTicketsDetails.filter((el) => {
    if (inputTextTicketsDetails === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.numberticket || '') +
        String(el.vehiclestore_licenseplate || '') +
        String(el.rel_trans_tradename || '') +
        String(el.ori_tras_tradename || '');
      let result = dataTranslate.toLowerCase().includes(inputTextTicketsDetails.toLowerCase());
      return result;
    }
  })

  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchStoreFrom = (event) => {
    const searchStoreFrom = event.target.value.toUpperCase();
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      ori_tras_store_idFrom: searchStoreFrom,
    }));  
    if (searchStoreFrom.trim() === "") {
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1);
      return;
    } 
    const filtered = tradenameToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchStoreFrom) ||
        normalizedDocumentId.includes(searchStoreFrom)
      );
    });
    setFilteredStoreFrom(filtered);
    setHighlightedIndexStoreFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectStoreFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      ori_tras_store_idFrom: store.tradename || "",
      ori_tras_store_idTo: store.tradename || filterTicket.ori_tras_store_idTo
    }));  
    setFilteredStoreFrom([]);
    setHighlightedIndexStoreFrom(-1);
  }; 
  const handleKeyDownStoreFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredStoreFrom.length === 0 && filterTicket.ori_tras_store_idFrom.trim() === "") {
        setFilteredStoreFrom(tradenameToSelect);
        setHighlightedIndexStoreFrom(0);
      } else {
        setHighlightedIndexStoreFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredStoreFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexStoreFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexStoreFrom >= 0) {
      if (highlightedIndexStoreFrom >= 0 && highlightedIndexStoreFrom < filteredStoreFrom.length) {
        handleSelectStoreFrom(filteredStoreFrom[highlightedIndexStoreFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = tradenameToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterTicket.ori_tras_store_idFrom.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterTicket(filterTicket => ({
          ...filterTicket,
          ori_tras_store_idFrom: ""
        }));
      }
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1);
    }
  };
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchStoreTo = (event) => {
    const searchStoreTo = event.target.value.toUpperCase();
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      ori_tras_store_idTo: searchStoreTo
    })); 
    if (searchStoreTo.trim() === "") {
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);
      return;
    } 
    const filtered = tradenameToSelect
    .filter(item=>(item.tradename>=filterTicket.ori_tras_store_idFrom))
    .filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchStoreTo) ||
        normalizedDocumentId.includes(searchStoreTo)
      );
    });
    setFilteredStoreTo(filtered);
    setHighlightedIndexStoreTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectStoreTo = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterTicket(filterTicket => ({
      ...filterTicket,
       ori_tras_store_idTo:  store.tradename || ""
    })); 
    setFilteredStoreTo([]);
    setHighlightedIndexStoreTo(-1);
  }; 
  const handleKeyDownStoreTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredStoreTo.length === 0 && filterTicket.ori_tras_store_idTo.trim() === "") {
        const filtered = tradenameToSelect.filter(item=>(item.tradename>=filterTicket.ori_tras_store_idFrom));
        setFilteredStoreTo(filtered);
        setHighlightedIndexStoreTo(0);
      } else {
        setHighlightedIndexStoreTo((prevIndex) => {
          const nextIndex = prevIndex < filteredStoreTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexStoreTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexStoreTo >= 0) {
      if (highlightedIndexStoreTo >= 0 && highlightedIndexStoreTo < filteredStoreTo.length) {
        handleSelectStoreTo(filteredStoreTo[highlightedIndexStoreTo]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = tradenameToSelect
      .filter(item=>(item.tradename>=filterTicket.ori_tras_store_idFrom))
      .some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterTicket.ori_tras_store_idTo.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterTicket(filterTicket => ({
          ...filterTicket,
           ori_tras_store_idTo:  ""
        }));   
      }
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);
    }
  };  
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTransFrom = (event) => {
    const searchTransFrom = event.target.value.toUpperCase();
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      rel_trans_store_idFrom:  searchTransFrom
    }));    
    if (searchTransFrom.trim() === "") {
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1);
      return;
    } 
    const filtered = trasnporterToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchTransFrom) ||
        normalizedDocumentId.includes(searchTransFrom)
      );
    });
    setFilteredTransFrom(filtered);
    setHighlightedIndexTransFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTransFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      rel_trans_store_idFrom: store.tradename || "",
      rel_trans_store_idTo: store.tradename || filterTicket.rel_trans_store_idTo,
    }));   
    setFilteredTransFrom([]);
    setHighlightedIndexTransFrom(-1);
  }; 
  const handleKeyDownTransFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTransFrom.length === 0 && filterTicket.rel_trans_store_idFrom.trim() === "") {
        setFilteredTransFrom(trasnporterToSelect);
        setHighlightedIndexTransFrom(0);
      } else {
        setHighlightedIndexTransFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredTransFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTransFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexTransFrom >= 0) {
      if (highlightedIndexTransFrom >= 0 && highlightedIndexTransFrom < filteredTransFrom.length) {
        handleSelectTransFrom(filteredTransFrom[highlightedIndexTransFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = trasnporterToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterTicket.rel_trans_store_idFrom.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterTicket(filterTicket => ({
          ...filterTicket,
          rel_trans_store_idFrom: "" 
        }));  
      }
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1);
    }
  };
  const handleSearchTransTo = (event) => {
    const searchTransTo = event.target.value.toUpperCase();
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      rel_trans_store_idTo:searchTransTo,
    }));   
    if (searchTransTo.trim() === "") {
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1);
      return;
    } 
    const filtered = trasnporterToSelect
    .filter(item=>(item.tradename>=filterTicket.rel_trans_store_idFrom))
    .filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchTransTo) ||
        normalizedDocumentId.includes(searchTransTo)
      );
    });
    setFilteredTransTo(filtered);
    setHighlightedIndexTransTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTransTo = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      rel_trans_store_idTo:store.tradename || ""
    }));  
    setFilteredTransTo([]);
    setHighlightedIndexTransTo(-1);
  }; 
  const handleKeyDownTransTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTransTo.length === 0 && filterTicket.rel_trans_store_idTo.trim() === "") {
        const filtered = trasnporterToSelect.filter(item=>(item.tradename>=filterTicket.rel_trans_store_idFrom));
        setFilteredTransTo(filtered);
        setHighlightedIndexTransTo(0);
      } else {
        setHighlightedIndexTransTo((prevIndex) => {
          const nextIndex = prevIndex < filteredTransTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTransTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexTransTo >= 0) {
      if (highlightedIndexTransTo >= 0 && highlightedIndexTransTo < filteredTransTo.length) {
        handleSelectTransTo(filteredTransTo[highlightedIndexTransTo]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = trasnporterToSelect
      .filter(item=>(item.tradename>=filterTicket.rel_trans_store_idFrom))
      .some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterTicket.rel_trans_store_idTo.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterTicket(filterTicket => ({
          ...filterTicket,
          rel_trans_store_idTo: ""
        }));  
      }
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1);
    }
  };  
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchVehicleFrom = (event) => {
    const searchVehicleFrom = event.target.value.toUpperCase();
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      vehiclestore_licenseplateFrom: searchVehicleFrom,
    }));   
    if (searchVehicleFrom.trim() === "") {
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      return;
    } 
    const filtered = vehiclesToSelect.filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleFrom.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return ( normalizedVehicle.includes(searchNormailzedVehicled) );
    });
    setFilteredVehicleFrom(filtered);
    setHighlightedIndexVehicleFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleFrom = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      vehiclestore_licenseplateFrom: vehicle.vehiclestore_licenseplate || "",
      vehiclestore_licenseplateTo: vehicle.vehiclestore_licenseplate || filterTicket.vehiclestore_licenseplateTo,
    })); 
    setFilteredVehicleFrom([]);
    setHighlightedIndexVehicleFrom(-1);
  }; 
  const handleKeyDownVehicleFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleFrom.length === 0 && filterTicket.vehiclestore_licenseplateFrom.trim() === "") {
        setFilteredVehicleFrom(vehiclesToSelect);
        setHighlightedIndexVehicleFrom(0);
      } else {
        setHighlightedIndexVehicleFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexVehicleFrom >= 0) {
      if (highlightedIndexVehicleFrom >= 0 && highlightedIndexVehicleFrom < filteredVehicleFrom.length) {
        handleSelectVehicleFrom(filteredVehicleFrom[highlightedIndexVehicleFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = vehiclesToSelect.some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filterTicket.vehiclestore_licenseplateFrom.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFilterTicket(filterTicket => ({
          ...filterTicket,
          vehiclestore_licenseplateFrom: ""
        }));
      }
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
    }
  };
  const handleSearchVehicleTo = (event) => {
    const searchVehicleTo = event.target.value.toUpperCase();
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      vehiclestore_licenseplateTo:searchVehicleTo,
    })); 
    if (searchVehicleTo.trim() === "") {
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
      return;
    } 
    const filtered = vehiclesToSelect
    .filter(item=>(item.vehiclestore_licenseplate>=filterTicket.vehiclestore_licenseplateFrom))
    .filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleTo.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return (normalizedVehicle.includes(searchNormailzedVehicled));
    });
    setFilteredVehicleTo(filtered);
    setHighlightedIndexVehicleTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleTo = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFilterTicket(filterTicket => ({
      ...filterTicket,
      vehiclestore_licenseplateTo: vehicle.vehiclestore_licenseplate || ""
    })); 
    setFilteredVehicleTo([]);
    setHighlightedIndexVehicleTo(-1);
  }; 
  const handleKeyDownVehicleTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleTo.length === 0 && filterTicket.vehiclestore_licenseplateTo.trim() === "") {
        const filtered = vehiclesToSelect.filter(item=>(item.vehiclestore_licenseplate>=filterTicket.vehiclestore_licenseplateFrom));
        setFilteredVehicleTo(filtered);
        setHighlightedIndexVehicleTo(0);
      } else {
        setHighlightedIndexVehicleTo((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexVehicleTo >= 0) {
      if (highlightedIndexVehicleTo >= 0 && highlightedIndexVehicleTo < filteredVehicleTo.length) {
        handleSelectVehicleTo(filteredVehicleTo[highlightedIndexVehicleTo]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = vehiclesToSelect
      .filter(item=>(item.vehiclestore_licenseplate>=filterTicket.vehiclestore_licenseplateFrom))
      .some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filterTicket.vehiclestore_licenseplateTo.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFilterTicket(filterTicket => ({
          ...filterTicket,
          vehiclestore_licenseplateTo: ""
        }));  
      }
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
    }
  };  

  // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleBlur = (event) => {
    // Asegúrate de que no se cierra si haces clic en un elemento de la lista
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1); 
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1); 
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1); 
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1); 
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
    }
  };

  let inputHandlerTicketsDetails = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextTicketsDetails(lowerCase);
  }; 
  const handleChangeDeleteRowTicketsDetails = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };  
  const handleCancelDeleteRowTicketsDetails = () => {
    setRowEditTicketsDetails(initialStateInvoiceTickets[0]);
    setIsLoadingErrorTicketsDetails(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowTicketsDetails(false);
  }; 
  const handleCloseDeleteRowTicketsDetails = (e) => {
    if (idCodeDelete !== DELETE_CODE_TICKETS) {
      dispatch(warningErrorAction(t('Text_InvalidCode_RESET')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditTicketsDetails.id!=='') {      
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT,`ticketsdetails/deleteresettickets`,token,rowEditTicketsDetails);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode_RESET')));
                dispatch(successErrorAction(t('Text_DeleteField_RESET')));
                setRefeshData(true);
                handleCloseDataEditTicketsDetails();
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            dispatch(setLoading(false));
          } 
          if (isMounted && isLoadingData!==undefined) {
            dispatch(setLoading(false));
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowTicketsDetails(false);
    setIsLoadingErrorTicketsDetails(false);
    handleCloseDataEditTicketsDetails(); 
  };
 
  const handleCloseDataEditTicketsDetails = () => { 
    setOpenEditRowTicketsDetails(false);
    setRowEditTicketsDetails(openEditRowTicketsDetails?rowEditTicketsDetails:initialStateInvoiceTickets[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorTicketsDetails(false);
    toggleDrawerFilterEditTicketsDetails(false);  
	}; 
  
  useEffect(() => {
    if (numTotRegFilter > Number(LIMITPAGESIZE)) {
      setActiveOffsetTicketsDetails(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetTicketsDetails(newRangeOffset);
    } else {
      setCurrencyOffsetTicketsDetails(0);
      setActiveOffsetTicketsDetails(false);
      setRangeOffsetTicketsDetails([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE,filtersTicketsDetails]);
   
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataTransporter = async () => {
      try {
        dispatch(setLoading(true));
        const getData = await helpHttp().get(ENDPOINT, `ticketsdetails/getlisttransportertickets`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTrasnporterToSelect(getData);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataTradename = async () => {
      try {
        dispatch(setLoading(true));
        const getData = await helpHttp().get(ENDPOINT,`ticketsdetails/getlisttradenametickets`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTradenameToSelect(getData);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    if (showModalTicketsDetails ) { 
      fetchDataTransporter(); 
      fetchDataTradename(); 
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,openEditFilterTicketsDetails,showModalTicketsDetails,defaultStore.id,useraccount_id]);
   
  useEffect(() => {
    let isMounted = true; 
    const fetchDataVehicles = async () => {
      try {
        const getData = await helpHttp().get8(ENDPOINT,`ticketsdetails/getlistvehiclestickets`,token,
          filterTicket.rel_trans_store_idFrom!==undefined && filterTicket.rel_trans_store_idFrom!==null && filterTicket.rel_trans_store_idFrom.replace(/[.\-/\\]/g, "").trim()!==""?filterTicket.rel_trans_store_idFrom.replace(/[.\-/\\]/g, "").trim():"-1",
          filterTicket.rel_trans_store_idTo!==undefined && filterTicket.rel_trans_store_idTo!==null && filterTicket.rel_trans_store_idTo.replace(/[.\-/\\]/g, "").trim()!==""?filterTicket.rel_trans_store_idTo.replace(/[.\-/\\]/g, "").trim():"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setVehiclesToSelect(getData); 
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
  
    if (showModalTicketsDetails ) { 
        fetchDataVehicles();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,filterTicket,openEditFilterTicketsDetails,showModalTicketsDetails]);
    

  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegTicketsDetails = async () => {
      dispatch(setLoading(true));
      try {
        const getData  = await helpHttp().get(ENDPOINT,`ticketsdetails/getinformationtickets`,token);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataTicketsDetails(initialStateInvoiceTickets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegTicketsDetails(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }   
    };

    const fetchnumTotRegFilter = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().post4(ENDPOINT,`ticketsdetails/getcountfilterticket`,token,"-1","-1",true,filtersTicketsDetails);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataTicketsDetails(initialStateInvoiceTickets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    }; 

    const fetchDataTicketsDetails = async () => {
      dispatch(setLoading(true));
      try { 
        const getData = await helpHttp().post7(ENDPOINT,`ticketsdetails/gettickets`,token,currencyOffsetTicketsDetails,LIMITPAGESIZE,"-1","-1",filtersTicketsDetails);
        if (getData.err) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setDataTicketsDetails(getData);
          dispatch(setLoading(false));
        }
      
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalTicketsDetails || refeshData){
      dispatch(setLoading(true));
      fetchnumTotRegTicketsDetails();
      fetchnumTotRegFilter(); 
      fetchDataTicketsDetails();
      setRefeshData(false); 
    }    
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,LIMITPAGESIZE,showModalTicketsDetails,currencyOffsetTicketsDetails,defaultStore.id,useraccount_id ,openDeleteRowTicketsDetails,setOpenDeleteRowTicketsDetails,setOpenEditRowTicketsDetails,openEditRowTicketsDetails,rowEditTicketsDetails,defaultStore,refeshData,filtersTicketsDetails ]);

  return (
    <>
      {showModalTicketsDetails ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={"hANewStyle mb-2"}>
              {isLoadingErrorTicketsDetails?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={openDeleteRowTicketsDetails?"warning":"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_TICKETS_Table')}
            </h1>
            <div>
              <button type="button" className="buttonBack" onClick={closeModalTicketsDetails}>{t('Button_Back')}</button><> </>
            </div> 
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              value={inputTextTicketsDetails}
              placeholder={t('Text_TICKETS_Search')} 
              onChange={inputHandlerTicketsDetails}
              fullWidth
              label={t('Text_TICKETS_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyleHigh mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(filtersTicketsDetails.numberticketFrom!=="" || filtersTicketsDetails.numberticketTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_NumberTicket')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(filtersTicketsDetails.start_date_ticketFrom!==null || filtersTicketsDetails.start_date_ticketTo!==null || filtersTicketsDetails.end_date_ticketFrom!==null || filtersTicketsDetails.end_date_tickettTo!==null )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Dates')}</button><> </>  
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(filtersTicketsDetails.ori_tras_store_idFrom!=="" || filtersTicketsDetails.ori_tras_store_idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_TICKETS_TRADENAME_STORE')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(filtersTicketsDetails.rel_trans_store_idFrom!=="" || filtersTicketsDetails.rel_trans_store_idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('TRANSPORTISTA')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(filtersTicketsDetails.vehiclestore_licenseplateFrom!=="" || filtersTicketsDetails.vehiclestore_licenseplateTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_FORM_LICENSEPLATE')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersTicketsDetails }><i className="fas fa-solid fa-circle" style={{color:(filtersTicketsDetails.numberDIFROM!=="" || filtersTicketsDetails.numberDITO!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_FormDI')}</button><> </>   
            </h1>
          </div>
          <br/>
          {!isLoadingData && dataTicketsDetails?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingTicketsDetails
                    dataListTicketsDetails={filteredDataTicketsDetails} 
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={t('Text_TICKETS_Table')}
                    setOpenDeleteRowTicketsDetails={setOpenDeleteRowTicketsDetails} 
                    setRowEditTicketsDetails={setRowEditTicketsDetails}
                    showEditButtonTicketsDetails={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Tickets_Edit'))?true:true}
                    showPrintButtonTicketsDetails={roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsDetails_Print'))?true:true}
                    showDeleteButtonTicketsDetails={roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsDetails_Delete'))?true:false}
                    numTotRegTicketsDetails={numTotRegTicketsDetails}
                    activeOffsetTicketsDetails={activeOffsetTicketsDetails}
                    rangeOffsetTicketsDetails={rangeOffsetTicketsDetails}
                    currencyOffsetTicketsDetails={currencyOffsetTicketsDetails}
                    setCurrencyOffsetTicketsDetails={setCurrencyOffsetTicketsDetails}
                    setViewPDF={setViewPDF} 
                    setViewPrint={setViewPrint}
                    >
                  </TableSortingTicketsDetails>
                </div>             
              </div>
              {openEditFilterTicketsDetails &&
                <div>
                <SwipeableDrawer
                  anchor='right'
                  open={true}
                  onClose={toggleDrawerFilterEditTicketsDetails(false)}
                  onOpen={toggleDrawerFilterEditTicketsDetails(true)}>
                  <Container >
                    <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                      <br/>
                        <div id="myDIV">
                          <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <form className="createfiltformdi" onSubmit={handleCancelFiltersTicketsDetails}>  
                          <div className="form-floating mb-3 px-4 primary">
                            <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="numberticketFrom" 
                                name="numberticketFrom" 
                                value={filterTicket.numberticketFrom}
                                label={errorsFiltersTicketsDetails.numberticketFrom!=null?t(errorsFiltersTicketsDetails.numberticketFrom):t('Text_Filter_NumberTicket_From')}
                                placeholder={t('PlaceHolder_Filter_NumberTicket_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails} 
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="numberticketTo" 
                                name="numberticketTo" 
                                value={filterTicket.numberticketTo}
                                label={errorsFiltersTicketsDetails.numberticketTo!=null?t(errorsFiltersTicketsDetails.numberticketTo):t('Text_Filter_NumberTicket_To')}
                                placeholder={t('PlaceHolder_Filter_NumberTicket_to')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails} 
                              /> 
                            </div>   
                            <div className="form-group">
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="start_date_ticketFrom" 
                                  name="start_date_ticketFrom"
                                  value={ filterTicket.start_date_ticketFrom!== null ? new Date(filterTicket.start_date_ticketFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersTicketsDetails.start_date_ticketFrom!=null?t(errorsFiltersTicketsDetails.start_date_ticketFrom):t('Text_Filter_FirstWeight_From')}
                                  onBlur={handleBlurSelectStartDateTicketsDetailsFrom}
                                  onChange={handleChangeSelectStartDateTicketsDetailsFrom}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px'
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="start_date_ticketTo" 
                                  name="start_date_ticketTo"
                                  minDate={filterTicket.start_date_ticketFrom!== null ? new Date(filterTicket.start_date_ticketFrom): null}
                                  value={ filterTicket.start_date_ticketTo!== null ? new Date(filterTicket.start_date_ticketTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersTicketsDetails.start_date_ticketTo!=null?t(errorsFiltersTicketsDetails.start_date_ticketTo):t('Text_Filter_FirstWeight_To')}
                                  onBlur={handleBlurSelectStartDateTicketsDetailsTo}
                                  onChange={handleChangeSelectStartDateTicketsDetailsTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430, 
                                        fontSize: '18px' 
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="form-group">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  id="end_date_ticketFrom" 
                                  name="end_date_ticketFrom"
                                  value={ filterTicket.end_date_ticketFrom!== null ? new Date(filterTicket.end_date_ticketFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersTicketsDetails.end_date_ticketFrom!=null?t(errorsFiltersTicketsDetails.end_date_ticketFrom):t('Text_Filter_EndWeight_From')}
                                  onBlur={handleBlurSelectEndDateTicketsDetailsFrom}
                                  onChange={handleChangeSelectEndDateTicketsDetailsFrom}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px'
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  id="end_date_tickettTo" 
                                  name="end_date_tickettTo"
                                  minDate={filterTicket.end_date_ticketFrom!== null ? new Date(filterTicket.end_date_ticketFrom): null}
                                  value={ filterTicket.end_date_tickettTo!== null ? new Date(filterTicket.end_date_tickettTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersTicketsDetails.end_date_tickettTo!=null?t(errorsFiltersTicketsDetails.end_date_tickettTo):t('Text_Filter_EndWeight_To')}
                                  onBlur={handleBlurSelectEndDateTicketsDetailsTo}
                                  onChange={handleChangeSelectEndDateTicketsDetailsTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px' 
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </div>    

                            <div className="form-group">
                              {/* Campo de texto para escribir y filtrar */}
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="ori_tras_store_idFrom"
                                  name="ori_tras_store_idFrom"
                                  value={filterTicket.ori_tras_store_idFrom}
                                  onChange={handleSearchStoreFrom}
                                  onKeyDown={handleKeyDownStoreFrom}
                                  label={errorsFiltersTicketsDetails.ori_tras_store_idFrom!=null?t(errorsFiltersTicketsDetails.ori_tras_store_idFrom):t('Text_Filter_TradeName_From')}
                                  placeholder={t('PlaceHolder_Filter_TradeName_From')}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  variant="outlined" 
                                />
                                {/* Desplegable dinámico */}
                                {filteredStoreFrom.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredStoreFrom.map((store, index) => (
                                      <MenuItem
                                          key={store.id}
                                          onClick={() => handleSelectStoreFrom(store)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexStoreFrom ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexStoreFrom ? "white" : "black",
                                          }}
                                      >
                                        {store.documentid} - {store.tradename}
                                      </MenuItem>
                                    ))}
                                  </div>
                                )}
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="ori_tras_store_idTo"
                                  name="ori_tras_store_idTo"
                                  value={filterTicket.ori_tras_store_idTo}
                                  onChange={handleSearchStoreTo}
                                  onKeyDown={handleKeyDownStoreTo}
                                  label={errorsFiltersTicketsDetails.ori_tras_store_idTo!=null?t(errorsFiltersTicketsDetails.ori_tras_store_idTo):t('Text_Filter_TradeName_To')}
                                  placeholder={t('PlaceHolder_Filter_TradeName_To')}
                                  variant="outlined"
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                />
                                {/* Desplegable dinámico */}
                                {filteredStoreTo.length > 0 && ( 
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredStoreTo.map((store, index) => (
                                      <MenuItem
                                        key={store.id}
                                          onClick={() => handleSelectStoreTo(store)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexStoreTo ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexStoreTo ? "white" : "black",
                                          }}
                                        >
                                          {store.documentid} - {store.tradename}
                                        </MenuItem>
                                    ))}
                                  </div>
                              )}    
                              </span>
                            </div> 
                            <div className="form-group">
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              {/* Campo de texto para escribir y filtrar */}
                                <TextField
                                  id="rel_trans_store_idFrom"
                                  name="rel_trans_store_idFrom"
                                  value={filterTicket.rel_trans_store_idFrom}
                                  onChange={handleSearchTransFrom}
                                  onKeyDown={handleKeyDownTransFrom}
                                  label={errorsFiltersTicketsDetails.rel_trans_store_idFrom!=null?t(errorsFiltersTicketsDetails.rel_trans_store_idFrom):t('Text_Filter_Trans_From')}
                                  placeholder={t('PlaceHolder_Filter_Trans_From')}
                                  variant="outlined"
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                />
                                {/* Desplegable dinámico */}
                                {filteredTransFrom.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredTransFrom.map((store, index) => (
                                      <MenuItem
                                          key={store.id}
                                          onClick={() => handleSelectTransFrom(store)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexTransFrom ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexTransFrom ? "white" : "black",
                                          }}
                                      >
                                        {store.documentid} - {store.tradename}
                                      </MenuItem>
                                    ))}
                                  </div>
                                )}
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="rel_trans_store_idTo"
                                    name="rel_trans_store_idTo"
                                    value={filterTicket.rel_trans_store_idTo}
                                    onChange={handleSearchTransTo}
                                    onKeyDown={handleKeyDownTransTo}
                                    label={errorsFiltersTicketsDetails.rel_trans_store_idTo!=null?t(errorsFiltersTicketsDetails.rel_trans_store_idTo):t('Text_Filter_Trans_To')}
                                    placeholder={t('PlaceHolder_Filter_Trans_To')}
                                    inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    variant="outlined"
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredTransTo.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "100%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                    >
                                    {filteredTransTo.map((store, index) => (
                                      <MenuItem
                                        key={store.id}
                                          onClick={() => handleSelectTransTo(store)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexTransTo ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexTransTo ? "white" : "black",
                                          }}
                                        >
                                          {store.documentid} - {store.tradename}
                                        </MenuItem>
                                    ))}
                                  </div>
                                )}
                              </span>  
                            </div> 

                            <div className="form-group">
                              {/* Campo de texto para escribir y filtrar */}
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="vehiclestore_licenseplateFrom"
                                  name="vehiclestore_licenseplateFrom"
                                  value={filterTicket.vehiclestore_licenseplateFrom}
                                  onChange={handleSearchVehicleFrom}
                                  onKeyDown={handleKeyDownVehicleFrom}
                                  label={errorsFiltersTicketsDetails.vehiclestore_licenseplateFrom!=null?t(errorsFiltersTicketsDetails.vehiclestore_licenseplateFrom):t('Text_Filter_LicensePlate_From')}
                                  placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  variant="outlined"
                                />
                                {/* Desplegable dinámico */}
                                {filteredVehicleFrom.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredVehicleFrom.map((vehicle, index) => (
                                      <MenuItem
                                          key={vehicle.id}
                                          onClick={() => handleSelectVehicleFrom(vehicle)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexVehicleFrom ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexVehicleFrom ? "white" : "black",
                                          }}
                                      >
                                        {vehicle.vehiclestore_licenseplate}
                                      </MenuItem>
                                    ))}
                                  </div>
                                )}
                                </span>  
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="vehiclestore_licenseplateTo"
                                    name="vehiclestore_licenseplateTo"
                                    value={filterTicket.vehiclestore_licenseplateTo}
                                    onChange={handleSearchVehicleTo}
                                    onKeyDown={handleKeyDownVehicleTo}
                                    label={errorsFiltersTicketsDetails.vehiclestore_licenseplateTo!=null?t(errorsFiltersTicketsDetails.vehiclestore_licenseplateTo):t('Text_Filter_LicensePlate_To')}
                                    placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                                    inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    variant="outlined"
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredVehicleTo.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                    {filteredVehicleTo.map((vehicle, index) => (
                                      <MenuItem
                                        key={vehicle.id}
                                          onClick={() => handleSelectVehicleTo(vehicle)}
                                          style={{
                                              cursor: "pointer",
                                              padding: "8px",
                                              borderBottom: "1px solid #eee",
                                              fontSize: '18px',
                                              backgroundColor:
                                              index === highlightedIndexVehicleTo ? "#4e73df" : "transparent",
                                              color: index === highlightedIndexVehicleTo ? "white" : "black",
                                          }}
                                        >
                                          {vehicle.vehiclestore_licenseplate}
                                        </MenuItem>
                                    ))}
                                  </div>
                                )}    
                              </span>  
                            </div> 
                            <div className="form-group">
                              <TextField
                                id="numberDIFROM" 
                                name="numberDIFROM"
                                value={filterTicket.numberDIFROM}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                label={errorsFiltersTicketsDetails.numberDIFROM!=null?t(errorsFiltersTicketsDetails.numberDIFROM):t('Text_Filter_FormDI_From')}
                                placeholder={t('PlaceHolder_Filter_FormDI_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="numberDITO" 
                                name="numberDITO"
                                value={filterTicket.numberDITO}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                label={errorsFiltersTicketsDetails.numberDITO!=null?t(errorsFiltersTicketsDetails.numberDITO):t('Text_Filter_FormDI_To')}
                                placeholder={t('PlaceHolder_Filter_FormDI_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditTicketsDetails}
                                onChange={handleChangeFilterEditTicketsDetails}
                              />
                            </div>
                          </div> 
                          <hr className="sidebar-divider d-none d-md-block"/>
                          <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditTicketsDetails}>{t('Button_Apply')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersTicketsDetails}>{t('Button_Cancel_Filters')}</button>
                          </div>
                        </form>
                      </Box>
                    </Container>
                  </SwipeableDrawer>
                </div>
              }
              <TicketsPrint 
                rowEditTicketsDetails={rowEditTicketsDetails} 
                viewPrint={viewPrint} 
                setViewPrint={setViewPrint}
              />
                <TicketsPDF 
                rowEditTicketsDetails={rowEditTicketsDetails} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />
              <Dialog open={openDeleteRowTicketsDetails} onClose={handleCancelDeleteRowTicketsDetails}>
                <DialogTitle style={{color:"#e74a3b"}}>{rowEditTicketsDetails.enabled?t('Text_Delete'):t('Text_UnDelete')} {rowEditTicketsDetails.id}  - {rowEditTicketsDetails.numberticket} - {rowEditTicketsDetails.vehiclestore_licenseplate} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {rowEditTicketsDetails.enabled?
                      <>
                        {t('Text_Line1')}<br/>
                        {t('Text_Line2')}
                      </>:
                      <>
                       {t('Text_Line3')}<br/>
                       {t('Text_Line4')}
                       </>
                      }
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowTicketsDetails}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowTicketsDetails}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowTicketsDetails}>{rowEditTicketsDetails.enabled?t('Button_Delete'):t('Button_UnDelete')}</Button>
                </DialogActions>
              </Dialog> 
              {/* End Content Row */}
          </>:
            <LoadingNews/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

TicketsDetailsFilter.propTypes = { 
  showModalTicketsDetails: PropTypes.bool.isRequired 
};

export default TicketsDetailsFilter;

