import React, {  Suspense,useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box';  
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container'; 
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading"; 
import PropTypes from 'prop-types';  
import TableSortingPurchaseDelivery from "pages/purchaseSignature/purchaseSignatureFilter/SortingTablesPurchaseSignature"; 
import PurchaseSignaturePDF from "pages/purchaseSignature/generatePDF/PurchaseSignaturePDF";

const initialFiltersPurchaseSignature= {
  "idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "useraccountIdFrom":"",
  "useraccountIdTo":"",
  "purchasenoteFrom":"",
  "purchasenoteTo":"",
  "datepurchaseFrom":null,
	"datepurchaseTo":null, 
  "vehiclestore_licenseplateFrom":"",
  "vehiclestore_licenseplateTo":"",
  "rel_trans_store_idFrom":"",
  "rel_trans_store_idTo":"",
  "ori_tras_store_idFrom":"",
  "ori_tras_store_idTo":"",
  "ori_tras_workplaceFrom":"",
  "ori_tras_workplaceTo":"",
  "numberDIFROM":"",
  "numberDITO":"",
  "paymentFromES":"",
  "paymentToES":"",
  "paymentFromEN":"",
  "paymentToEN":"",
  
};
 
const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": ""
}];

const initialPaymentToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": "",
}];

const initialUserAccountToSelect = [{
  "id": "",
  "username":"",
  "firstName": "",
  "lastName1": "",
  "lastName2": "",
  "email": "",
  "aliasuser": ""
}];

const initialStatePurchseSignature = [{
  "id" : "",
  "defaultStore_id": 0,
  "tradename": "",
  "useraccount_id": 0,
  "username": "",
  "defaultformatnumber": 1,
  "isPending": true,
  "isFinished" : false,
  "purchasenote":0, 
  "ownnumberpurchase":"", 
  "numberticket":0, 
  "vehiclestore_licenseplate" : "",
  "workplace": "",
  "datepurchase" : null, 
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalpurchase" : 0 ,
  "enabled":"",
  "formDIAssociates": "",
  "payment_id" : 0 ,
  "payment_code": "",
  "payment_namees": "",
  "payment_nameen": ""
}];
            
const PurchaseSignatureFilter = ({ applyFiltersPurchaseSignature,setApplyFiltersPurchaseSignature,showModalPurchasesSignatureSettings,setShowModalPurchasesSignatureSettings}) => {
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const [dataPurchaseSignature, setDataPurchaseSignature] = useState(initialStatePurchseSignature); 
  const [formPurchaseSignature, setFormPurchaseSignature] = useState(initialStatePurchseSignature[0]);
  const [filtersPurchaseSignature, setFiltersPurchaseSignature]= useState(applyFiltersPurchaseSignature);
  const [numTotRegFilterSignature,setNumTotRegFilterSignature]= useState(0);
  const [numTotRegPurchaseSignature,setNumTotRegPurchaseSignature]= useState(0);
  const [errorsFiltersPurchaseSignature, setErrorsFiltersPurchaseSignature] = useState({});
  const [inputTextPurchaseSignature, setInputTextPurchaseSignature] = useState("");  
  const [openEditFilterPurchaseSignature,setOpenEditFilterPurchaseSignature]= useState(false);
  const [openEditRowPurchaseSignature,setOpenEditRowPurchaseSignature]= useState(false);
  const [rowEditPurchaseSignature,setRowEditPurchaseSignature]= useState(initialStatePurchseSignature[0]);
  const [isLoadingPurchaseSignature, setIsLoadingPurchaseSignature] = useState(true);
  const [refeshData,setRefeshData]=useState(false);
  const [viewPDF,setViewPDF]=useState(false); 
  const dispatch = useDispatch();
  const [activeOffsetPurchaseSignature, setActiveOffsetPurchaseSignature]= useState(false);
  const [currencyOffsetPurchaseSignature, setCurrencyOffsetPurchaseSignature] = useState(0);
  const [rangeOffsetPurchaseSignature, setRangeOffsetPurchaseSignature] = useState([{}]);
  const [storesToSelect,setStoresToSelect]= useState(initialStoresToSelect);
  const [userAccountsToSelect,setUserAccountsToSelect]= useState(initialUserAccountToSelect); 
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoresToSelect); 
  const [tradenameToSelect,setTradenameToSelect]= useState(initialStoresToSelect); 
  const [paymenetTypeSelect,setPaymenetTypeSelect]= useState(initialPaymentToSelect); 

  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalPurchasesSignatureSettings) {
        setDataPurchaseSignature(initialStatePurchseSignature); 
        setFormPurchaseSignature(formPurchaseSignature);
        setFormPurchaseSignature(initialStatePurchseSignature[0]);
        setFiltersPurchaseSignature(initialFiltersPurchaseSignature);
        setNumTotRegFilterSignature(0);
        setNumTotRegPurchaseSignature(0);
        setErrorsFiltersPurchaseSignature({});
        setInputTextPurchaseSignature("");   
        setOpenEditFilterPurchaseSignature(false);
        setOpenEditRowPurchaseSignature(false);
        setRowEditPurchaseSignature(initialStatePurchseSignature[0]);
        setIsLoadingPurchaseSignature(false);    
        setRefeshData(false);
        setViewPDF(false);
        setActiveOffsetPurchaseSignature(false);
        setCurrencyOffsetPurchaseSignature(0);
        setRangeOffsetPurchaseSignature([{}]);
        setStoresToSelect(initialStoresToSelect);
        setUserAccountsToSelect(initialUserAccountToSelect); 
        setTrasnporterToSelect(initialStoresToSelect);
        setShowModalPurchasesSignatureSettings(false);
        setTradenameToSelect(initialStoresToSelect);
        setPaymenetTypeSelect(initialPaymentToSelect);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataPurchaseSignature,  
      formPurchaseSignature,
      setFormPurchaseSignature,
      setFiltersPurchaseSignature,
      setNumTotRegFilterSignature,
      setNumTotRegPurchaseSignature,
      setErrorsFiltersPurchaseSignature,
      setInputTextPurchaseSignature,   
      setOpenEditFilterPurchaseSignature,
      setOpenEditRowPurchaseSignature,
      setRowEditPurchaseSignature,
      setIsLoadingPurchaseSignature,    
      setRefeshData,
      setViewPDF,
      setActiveOffsetPurchaseSignature,
      setCurrencyOffsetPurchaseSignature,
      setRangeOffsetPurchaseSignature,
      setStoresToSelect,
      setUserAccountsToSelect, 
      setTrasnporterToSelect,
      setShowModalPurchasesSignatureSettings,
      setTradenameToSelect,
      setPaymenetTypeSelect,
      showModalPurchasesSignatureSettings, 
      navigate]
  );

  const closeModalPurchaseSignature = () => {    
    setDataPurchaseSignature(initialStatePurchseSignature); 
    setFormPurchaseSignature(initialStatePurchseSignature[0]);
    setFiltersPurchaseSignature(initialFiltersPurchaseSignature);
    setNumTotRegFilterSignature(0);
    setNumTotRegPurchaseSignature(0);
    setErrorsFiltersPurchaseSignature({});
    setInputTextPurchaseSignature("");   
    setOpenEditFilterPurchaseSignature(false);
    setOpenEditRowPurchaseSignature(false);
    setRowEditPurchaseSignature(initialStatePurchseSignature[0]);
    setIsLoadingPurchaseSignature(false);    
    setRefeshData(false);
    setViewPDF(false);
    setActiveOffsetPurchaseSignature(false);
    setCurrencyOffsetPurchaseSignature(0);
    setRangeOffsetPurchaseSignature([{}]);
    setStoresToSelect(initialStoresToSelect);
    setUserAccountsToSelect(initialUserAccountToSelect); 
    setTrasnporterToSelect(initialStoresToSelect);
    setShowModalPurchasesSignatureSettings(false);
    setTradenameToSelect(initialStoresToSelect);
    setPaymenetTypeSelect(initialPaymentToSelect);
    navigate("/home");
    return () => {};
  };
 
  const openEditFiltersPurchaseSignature = () => {
      setFiltersPurchaseSignature(applyFiltersPurchaseSignature);
      setOpenEditFilterPurchaseSignature(!openEditFilterPurchaseSignature);
  };

  const handleSubmitFilterEditPurchaseSignature = () => {
    setErrorsFiltersPurchaseSignature(validateFormFilterPurchaseSignature());
    if (Object.keys(errorsFiltersPurchaseSignature).length === 0) {
      setApplyFiltersPurchaseSignature(filtersPurchaseSignature);
      setFiltersPurchaseSignature(initialStatePurchseSignature);
      setErrorsFiltersPurchaseSignature({}); 
      setOpenEditFilterPurchaseSignature(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersPurchaseSignature = () => {
    setErrorsFiltersPurchaseSignature({});
    setFiltersPurchaseSignature(initialFiltersPurchaseSignature);
    setApplyFiltersPurchaseSignature(initialFiltersPurchaseSignature); 
    setOpenEditFilterPurchaseSignature(!openEditFilterPurchaseSignature);
  };

  const handleCancelFiltersPurchaseSignature = () => {
    setErrorsFiltersPurchaseSignature({});
    setFiltersPurchaseSignature(initialFiltersPurchaseSignature); 
    setOpenEditFilterPurchaseSignature(!openEditFilterPurchaseSignature);
  };
 
  const handleChangeSelectDatePurchaseSignature = (event) => {
    setFiltersPurchaseSignature(filtersPurchaseSignature => ({
      ...filtersPurchaseSignature,
      datepurchaseFrom: event!==null?new Date(event):null,
      datepurchaseTo:null
    }));
  };

  const handleBlurSelectDatePurchaseSignatureFrom = (e) => {
    handleChangeSelectDatePurchaseSignature(e);
    setErrorsFiltersPurchaseSignature(validateFormFilterPurchaseSignature());
  };

  const handleChangeSelectDatePurchaseSignatureTo = (event) => {
    setFiltersPurchaseSignature(filtersPurchaseSignature => ({
      ...filtersPurchaseSignature,
      datepurchaseTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDatePurchaseSignatureTo = (e) => {
    handleChangeSelectDatePurchaseSignatureTo(e);
    setErrorsFiltersPurchaseSignature(validateFormFilterPurchaseSignature());
  };
    
  const handleChangeFilterEditPurchaseSignature = (e) => {
    const { name, value } = e.target;
    setFiltersPurchaseSignature(filtersPurchaseSignature => ({
      ...filtersPurchaseSignature,
      [name]: value
    }));
  };

  const handleBlurFilterEditPurchaseSignature = (e) => {
    setErrorsFiltersPurchaseSignature(validateFormFilterPurchaseSignature());
    handleChangeFilterEditPurchaseSignature(e);
  };

  const toggleDrawerFilterEditPurchaseSignature = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditPurchaseSignature();
    }
  };
  
  const validateFormFilterPurchaseSignature = () => {

    let errorsFiltersPurchaseSignature = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersPurchaseSignature.idFrom!=="" & !regexNumber.test(filtersPurchaseSignature.idFrom)) {
      errorsFiltersPurchaseSignature.idFrom = 'Text_TextNoNumber';
    }
    if (filtersPurchaseSignature.idTo!=="" & !regexNumber.test(filtersPurchaseSignature.idTo)) {
      errorsFiltersPurchaseSignature.idTo = 'Text_TextNoNumber';
    }
    if (filtersPurchaseSignature.idFrom!=="" & !errorsFiltersPurchaseSignature.idFrom!==undefined){
      if (filtersPurchaseSignature.idTo!=="" & !errorsFiltersPurchaseSignature.idTo!==undefined){
        if (parseInt(filtersPurchaseSignature.idTo)<parseInt(filtersPurchaseSignature.idFrom)){
          errorsFiltersPurchaseSignature.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filtersPurchaseSignature.numberDIFROM!=="" & !regexNumber.test(filtersPurchaseSignature.numberDIFROM)) {
      errorsFiltersPurchaseSignature.numberDIFROM = 'Text_TextNoNumber';
    }
    if (filtersPurchaseSignature.numberDITO!=="" & !regexNumber.test(filtersPurchaseSignature.numberDITO)) {
      errorsFiltersPurchaseSignature.numberDITO = 'Text_TextNoNumber';
    }
    if (filtersPurchaseSignature.numberDIFROM!=="" & !errorsFiltersPurchaseSignature.numberDIFROM!==undefined){
      if (filtersPurchaseSignature.numberDITO!=="" & !errorsFiltersPurchaseSignature.numberDITO!==undefined){
        if (parseInt(filtersPurchaseSignature.numberDITO)<parseInt(filtersPurchaseSignature.numberDIFROM)){
          errorsFiltersPurchaseSignature.numberDITO = 'Text_TextGreater_FormDI';
        }
      }
    }
    
    if (regexInvalidCharacters.test(filtersPurchaseSignature.datepurchaseFrom)) {
      errorsFiltersPurchaseSignature.datepurchaseFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPurchaseSignature.datepurchaseTo)) {
      errorsFiltersPurchaseSignature.datepurchaseTo = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(filtersPurchaseSignature.vehiclestore_licenseplateFrom)) {
      errorsFiltersPurchaseSignature.vehiclestore_licenseplateFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPurchaseSignature.vehiclestore_licenseplateTo)) {
      errorsFiltersPurchaseSignature.vehiclestore_licenseplateTo = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(filtersPurchaseSignature.ori_tras_workplaceFrom)) {
      errorsFiltersPurchaseSignature.ori_tras_workplaceFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPurchaseSignature.ori_tras_workplaceTo)) {
      errorsFiltersPurchaseSignature.ori_tras_workplaceTo = 'Text_TextNoValid';
    }
   
    if (filtersPurchaseSignature.defaultStoreIdTo!=="" & filtersPurchaseSignature.defaultStoreIdTo<filtersPurchaseSignature.defaultStoreIdFrom){
      errorsFiltersPurchaseSignature.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersPurchaseSignature.useraccountIdTo!=="" & filtersPurchaseSignature.useraccountIdTo<filtersPurchaseSignature.useraccountIdFrom){
      errorsFiltersPurchaseSignature.useraccountIdTo = 'Text_TextGreater_UserAccount';
    } 

    if (filtersPurchaseSignature.vehiclestore_licenseplateTo!=="" & filtersPurchaseSignature.vehiclestore_licenseplateTo<filtersPurchaseSignature.vehiclestore_licenseplateFrom){
      errorsFiltersPurchaseSignature.vehiclestore_licenseplateTo = 'Text_TextGreater_vehicles';
    }

    if (filtersPurchaseSignature.ori_tras_workplaceTo!=="" & filtersPurchaseSignature.ori_tras_workplaceTo<filtersPurchaseSignature.ori_tras_workplaceFrom){
      errorsFiltersPurchaseSignature.ori_tras_workplaceTo = 'Text_TextGreater_workplace';
    }
    
    return errorsFiltersPurchaseSignature;
  }  

  const filteredDataPurchaseSignature = dataPurchaseSignature.filter((el) => {
    if (inputTextPurchaseSignature === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.purchasenote || '') +
        String(el.vehiclestore_licenseplate || '') +
        String(el.rel_trans_tradename || '') +
        String(el.ori_tras_tradename || '');
      let result = dataTranslate.toLowerCase().includes(inputTextPurchaseSignature.toLowerCase());
      return result;
    }
  })
 
  let inputHandlerPurchaseSignature  = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextPurchaseSignature(lowerCase);
  };
  
  const handleCloseDataEditPurchaseSignature = () => { 
    setOpenEditRowPurchaseSignature(false);
    setRowEditPurchaseSignature(openEditRowPurchaseSignature?rowEditPurchaseSignature:initialStatePurchseSignature[0]);
    setIsLoadingPurchaseSignature(false); 
    toggleDrawerFilterEditPurchaseSignature(false); 
    setRefeshData(true); 
	};
  
  useEffect(() => {
    if (numTotRegPurchaseSignature > Number(LIMITPAGESIZE)) {
      setActiveOffsetPurchaseSignature(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegPurchaseSignature; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetPurchaseSignature(newRangeOffset);
    } else {
      setCurrencyOffsetPurchaseSignature(0);
      setActiveOffsetPurchaseSignature(false);
      setRangeOffsetPurchaseSignature([{}]);
    }
  }, [numTotRegPurchaseSignature, LIMITPAGESIZE]);

  useEffect(() => {
    if (showModalPurchasesSignatureSettings){
      setFormPurchaseSignature(formPurchaseSignature => ({
        ...formPurchaseSignature,
        id : rowEditPurchaseSignature.id!==null?rowEditPurchaseSignature.id:"",
        defaultStore_id : rowEditPurchaseSignature.defaultStore_id!==null?rowEditPurchaseSignature.defaultStore_id:0,
        tradename :rowEditPurchaseSignature.tradename!==null?rowEditPurchaseSignature.tradename:"",
        useraccount_id :rowEditPurchaseSignature.useraccount_id!==null?rowEditPurchaseSignature.useraccount_id:0,
        username :rowEditPurchaseSignature.username!==null?rowEditPurchaseSignature.username:"",
        defaultformatnumber :rowEditPurchaseSignature.defaultformatnumber!==null?rowEditPurchaseSignature.defaultformatnumber:0,
        purchasenote :rowEditPurchaseSignature.purchasenote!==null?rowEditPurchaseSignature.purchasenote:"",
        vehiclestore_licenseplate :rowEditPurchaseSignature.vehiclestore_licenseplate!==null?rowEditPurchaseSignature.vehiclestore_licenseplate:"",
        workplace :rowEditPurchaseSignature.workplace!==null?rowEditPurchaseSignature.workplace:"",
        datepurchase :rowEditPurchaseSignature.datepurchase!==null?rowEditPurchaseSignature.datepurchase:"",
        rel_trans_tradename :rowEditPurchaseSignature.rel_trans_tradename!==null?rowEditPurchaseSignature.rel_trans_tradename:"",
        ori_tras_tradename :rowEditPurchaseSignature.ori_tras_tradename!==null?rowEditPurchaseSignature.ori_tras_tradename:"",
        totalpurchase :rowEditPurchaseSignature.totalpurchase!==null?rowEditPurchaseSignature.totalpurchase:0,
        payment_id:rowEditPurchaseSignature.payment_id!==null?rowEditPurchaseSignature.payment_id:0,
        payment_code:rowEditPurchaseSignature.payment_code!==null?rowEditPurchaseSignature.payment_code:"",
        payment_namees:rowEditPurchaseSignature.payment_namees!==null?rowEditPurchaseSignature.payment_namees:"",
        payment_nameen:rowEditPurchaseSignature.payment_nameen!==null?rowEditPurchaseSignature.payment_nameen:"",
      }));
    }else{
      setFormPurchaseSignature(initialStatePurchseSignature[0]);
    }
  },[rowEditPurchaseSignature,showModalPurchasesSignatureSettings]);


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;

    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`purchasesignature/storesorderbytradename`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataUserAccount = async () => {
      try {
        const getData =(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
            await helpHttp().get2(ENDPOINT,`purchasesignature/useraccountsorderbyusername/`,token,"-1"):
            await helpHttp().get2(ENDPOINT,`purchasesignature/useraccountsorderbyusername/`,token,defaultStore.id);
      if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setUserAccountsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataTransporter = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `purchasesignature/getlisttransporterpurchasesignature/`, token,false);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTrasnporterToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataPayment = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPaymenetTypeSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataTradename = async () => {
      try {
        const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
          await helpHttp().get7(ENDPOINT,`purchasesignature/getlisttradenamepurchasesignature`,token,"-1","-1",false):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master')))?
          await helpHttp().get7(ENDPOINT,`purchasesignature/getlisttradenamepurchasesignature`,token,"-1",useraccount_id,false):
          await helpHttp().get7(ENDPOINT,`purchasesignature/getlisttradenamepurchasesignature`,token,defaultStore.id,useraccount_id,false);
 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTradenameToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };   

    if (showModalPurchasesSignatureSettings ) { 
      fetchDataTransporter(); 
      fetchDataTradename();
      fetchDataPayment();
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))){
        fetchDataStore();
      }
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master'))){
        fetchDataUserAccount();
      } 
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,applyFiltersPurchaseSignature,openEditFilterPurchaseSignature,showModalPurchasesSignatureSettings,defaultStore.id,useraccount_id]);
   
  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegPurchaseSignature = async () => {
      setIsLoadingPurchaseSignature(true);
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))
        ? await helpHttp().get2(ENDPOINT,`purchasesignature/getinformationpurchasesignature/`,token,false)
        : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master'))?
            await helpHttp().get8(ENDPOINT,`purchasesignature/getinformationpurchasesignaturewithstore`,token,defaultStore.id,false):
            await helpHttp().get7(ENDPOINT,`purchasesignature/getinformationpurchasesignaturewithstoreanduseraccount`,token,defaultStore.id,useraccount_id,false))
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataPurchaseSignature(initialStatePurchseSignature);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegPurchaseSignature(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingPurchaseSignature(false);
      }
    };

    const fetchnumTotRegFilterSignature = async () => {
      setIsLoadingPurchaseSignature(true);
      try {
        const getData = 
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
        await helpHttp().post4(ENDPOINT,`purchasesignature/getcountfilterpurchasesignature`,token,"-1","-1",false,applyFiltersPurchaseSignature):
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master')))?
        await helpHttp().post4(ENDPOINT,`purchasesignature/getcountfilterpurchasesignature`,token,"-1",useraccount_id,false,applyFiltersPurchaseSignature):
        await helpHttp().post4(ENDPOINT,`purchasesignature/getcountfilterpurchasesignature`,token,defaultStore.id,useraccount_id,false,applyFiltersPurchaseSignature);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataPurchaseSignature(initialStatePurchseSignature);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilterSignature(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingPurchaseSignature(false);
      } 
    }; 
    
    const fetchDataPurchaseDelivery = async () => {
      try {
        const getData = 
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
        await helpHttp().post6(ENDPOINT,`purchasesignature/getpurchasesignature`,token,currencyOffsetPurchaseSignature,LIMITPAGESIZE,"-1","-1",false,applyFiltersPurchaseSignature):
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master')))?
        await helpHttp().post6(ENDPOINT,`purchasesignature/getpurchasesignature`,token,currencyOffsetPurchaseSignature,LIMITPAGESIZE,"-1",useraccount_id,false,applyFiltersPurchaseSignature):
        await helpHttp().post6(ENDPOINT,`purchasesignature/getpurchasesignature`,token,defaultStore.id,useraccount_id,false,applyFiltersPurchaseSignature);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataPurchaseSignature(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    if (showModalPurchasesSignatureSettings || refeshData){
      fetchnumTotRegPurchaseSignature();
      fetchnumTotRegFilterSignature(); 
      fetchDataPurchaseDelivery();
      setIsLoadingPurchaseSignature(false);
      setRefeshData(false);
    }  
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,LIMITPAGESIZE,applyFiltersPurchaseSignature,showModalPurchasesSignatureSettings,currencyOffsetPurchaseSignature,defaultStore.id,useraccount_id ,setOpenEditRowPurchaseSignature,openEditRowPurchaseSignature,rowEditPurchaseSignature,setRowEditPurchaseSignature,setIsLoadingPurchaseSignature,defaultStore,refeshData ]);
     
   return (
    <>
       {showModalPurchasesSignatureSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={"hANewStyle mb-2"}>
              {isLoadingPurchaseSignature?<CircularProgress color={"primary"}/>:<i className="fas fa-pen-nib text-warning"></i>} {t('Text_PurchaseDelivery_Table_Sign')}</h1>
              <div>
                <button type="button" className="buttonBack" onClick={closeModalPurchaseSignature}>{t('Button_Back')}</button><> </>
              </div>    
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextPurchaseSignature}
              placeholder={t('Text_PurchaseDelivery_Search')} 
              onChange={inputHandlerPurchaseSignature }
              fullWidth
              label={t('Text_PurchaseDelivery_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseSignature}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseSignature.idFrom!=="" || applyFiltersPurchaseSignature.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Purchases_Store_Master"))&&
                  <>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseSignature}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseSignature.defaultStoreIdFrom!=="" || applyFiltersPurchaseSignature.defaultStoreIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Store')}</button><> </>
                  </>
                }
                 {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Purchases_User_Master"))&&
                  <>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseSignature}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseSignature.useraccountIdFrom!=="" || applyFiltersPurchaseSignature.useraccountIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_UserAccount')}</button><> </>
                  </>
                }
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseSignature}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseSignature.purchasenoteFrom!=="" || applyFiltersPurchaseSignature.purchasenoteTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NumberPurchase')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseSignature}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseSignature.datepurchaseFrom!==null|| applyFiltersPurchaseSignature.datepurchaseTo!==null)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Dates')}</button><> </>                 
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseSignature }><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseSignature.numberDIFROM!=="" || applyFiltersPurchaseSignature.numberDITO!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_FormDI')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseSignature }><i className="fas fa-solid fa-circle" style={{color:( 
                     applyFiltersPurchaseSignature.vehiclestore_licenseplateFrom
                      || applyFiltersPurchaseSignature.vehiclestore_licenseplateTo
                      || applyFiltersPurchaseSignature.rel_trans_store_idFrom
                      ||  applyFiltersPurchaseSignature.rel_trans_store_idTo!=="" 
                      || applyFiltersPurchaseSignature.ori_tras_store_idFrom!==""
                      || applyFiltersPurchaseSignature.ori_tras_store_idTo!==""
                      || applyFiltersPurchaseSignature.ori_tras_workplaceFrom!==""
                      || applyFiltersPurchaseSignature.ori_tras_workplaceTo!==""                      
                      || applyFiltersPurchaseSignature.paymentFromES!==""
                      || applyFiltersPurchaseSignature.paymentToES!==""
                      || applyFiltersPurchaseSignature.paymentFromEN!==""
                      || applyFiltersPurchaseSignature.paymentToEN!==""
                      )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Others_Filters')}</button><> </>
            </h1>
          </div>
          <br/>

          {!isLoadingPurchaseSignature ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingPurchaseDelivery
                    dataPurchaseSignature={filteredDataPurchaseSignature}
                    numTotRegFilterSignature={ numTotRegFilterSignature}
                    titleTable={t('Text_PurchaseDelivery_Table_Sign')}
                    setRowEditPurchaseSignature={setRowEditPurchaseSignature}
                    numTotRegPurchaseSignature={numTotRegPurchaseSignature}
                    activeOffsetPurchaseSignature={activeOffsetPurchaseSignature}
                    rangeOffsetPurchaseSignature={rangeOffsetPurchaseSignature}
                    currencyOffsetPurchaseSignature={currencyOffsetPurchaseSignature}
                    setCurrencyOffsetPurchaseSignature={setCurrencyOffsetPurchaseSignature} 
                    setViewPDF={setViewPDF} 
                  >
                  </TableSortingPurchaseDelivery>
                </div>             
              </div>
              
              {openEditFilterPurchaseSignature &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditPurchaseSignature(false)}
                 onOpen={toggleDrawerFilterEditPurchaseSignature(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfilterpurchasedelivery" onSubmit={handleCancelFiltersPurchaseSignature}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                               id="idFrom" 
                               name="idFrom"
                               value={filtersPurchaseSignature.idFrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersPurchaseSignature.idFrom!=null?t(errorsFiltersPurchaseSignature.idFrom):t('Text_Filter_Code_From')}
                               placeholder={t('PlaceHolder_Filter_Code_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditPurchaseSignature}
                               onChange={handleChangeFilterEditPurchaseSignature}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                               id="idTo" 
                               name="idTo"
                               value={filtersPurchaseSignature.idTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersPurchaseSignature.idTo!=null?t(errorsFiltersPurchaseSignature.idTo):t('Text_Filter_Code_To')}
                               placeholder={t('PlaceHolder_Filter_Code_to')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditPurchaseSignature}
                               onChange={handleChangeFilterEditPurchaseSignature}/>
                           </div>
                           {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="defaultStoreIdFrom"
                                      name="defaultStoreIdFrom"
                                      value={filtersPurchaseSignature.defaultStoreIdFrom}
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersPurchaseSignature.defaultStoreIdFrom!=null?t(errorsFiltersPurchaseSignature.defaultStoreIdFrom):t('Text_Filter_Stores_From')}
                                      placeholder={t('PlaceHolder_Filter_Store_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditPurchaseSignature}
                                      onChange={handleChangeFilterEditPurchaseSignature}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {storesToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                             {option.documentid+" - "+option.tradename}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="defaultStoreIdTo"
                                        name="defaultStoreIdTo"
                                        value={filtersPurchaseSignature.defaultStoreIdTo}  
                                        inputProps={{ style: {width: 382}, maxLength: 100 }}
                                        label={errorsFiltersPurchaseSignature.defaultStoreIdTo!=null?t(errorsFiltersPurchaseSignature.defaultStoreIdTo):t('Text_Filter_Stores_To')}
                                        placeholder={t('PlaceHolder_Filter_Store_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditPurchaseSignature}
                                        onChange={handleChangeFilterEditPurchaseSignature}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {storesToSelect.filter(item=>(item.id>=filtersPurchaseSignature.defaultStoreIdFrom)).map(option => (
                                          <option key={option.id} value={option.id}>
                                              {option.documentid+" - "+option.tradename}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           { roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="useraccountIdFrom"
                                      name="useraccountIdFrom"
                                      value={filtersPurchaseSignature.useraccountIdFrom}
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersPurchaseSignature.useraccountIdFrom!=null?t(errorsFiltersPurchaseSignature.useraccountIdFrom):t('Text_Filter_Useraccount_From')}
                                      placeholder={t('PlaceHolder_UserAccount_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditPurchaseSignature}
                                      onChange={handleChangeFilterEditPurchaseSignature}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {userAccountsToSelect.map((option) => (
                                          <option key={option.id} value={option.username}>
                                            {option.username+" - "+option.email}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="useraccountIdTo"
                                        name="useraccountIdTo"
                                        value={filtersPurchaseSignature.useraccountIdTo}  
                                        inputProps={{ style: {width: 382}, maxLength: 100 }}
                                        label={errorsFiltersPurchaseSignature.useraccountIdTo!=null?t(errorsFiltersPurchaseSignature.useraccountIdTo):t('Text_Filter_Useraccount_To')}
                                        placeholder={t('PlaceHolder_UserAccount_To')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditPurchaseSignature}
                                        onChange={handleChangeFilterEditPurchaseSignature}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {userAccountsToSelect.filter(item=>(item.username>=filtersPurchaseSignature.useraccountIdFrom)).map(option => (
                                          <option key={option.id} value={option.username}>
                                              {option.username+" - "+option.email}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           <div className="form-group">
                             <TextField
                               id="purchasenoteFrom" 
                               name="purchasenoteFrom"
                               value={filtersPurchaseSignature.purchasenoteFrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersPurchaseSignature.purchasenoteFrom!=null?t(errorsFiltersPurchaseSignature.purchasenoteFrom):t('Text_Filter_NumberPurchase_From')}
                               placeholder={t('PlaceHolder_Filter_NumberPurchase_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditPurchaseSignature}
                               onChange={handleChangeFilterEditPurchaseSignature}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                               id="purchasenoteTo" 
                               name="purchasenoteTo"
                               value={filtersPurchaseSignature.purchasenoteTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersPurchaseSignature.purchasenoteTo!=null?t(errorsFiltersPurchaseSignature.purchasenoteTo):t('Text_Filter_NumberPurchase_To')}
                               placeholder={t('PlaceHolder_Filter_NumberPurchase_To')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditPurchaseSignature}
                               onChange={handleChangeFilterEditPurchaseSignature}/>
                           </div>
                         
                          <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="datepurchaseFrom" 
                                  name="datepurchaseFrom"
                                  value={ filtersPurchaseSignature.datepurchaseFrom!== null ? new Date(filtersPurchaseSignature.datepurchaseFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersPurchaseSignature.datepurchaseFrom!=null?t(errorsFiltersPurchaseSignature.datepurchaseFrom):t('Text_Filter_DatePurchase_From')}
                                  onBlur={handleBlurSelectDatePurchaseSignatureFrom}
                                  onChange={handleChangeSelectDatePurchaseSignature}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="datepurchaseTo" 
                                  name="datepurchaseTo"
                                  minDate={filtersPurchaseSignature.datepurchaseFrom!== null ? new Date(filtersPurchaseSignature.datepurchaseFrom): null}
                                  value={ filtersPurchaseSignature.datepurchaseTo!== null ? new Date(filtersPurchaseSignature.datepurchaseTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersPurchaseSignature.datepurchaseTo!=null?t(errorsFiltersPurchaseSignature.datepurchaseTo):t('Text_Filter_DatePurchase_To')}
                                  onBlur={handleBlurSelectDatePurchaseSignatureTo}
                                  onChange={handleChangeSelectDatePurchaseSignatureTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                          </div>         
                          <div className="form-group">
                            <TextField
                              id="vehiclestore_licenseplateFrom" 
                              name="vehiclestore_licenseplateFrom"
                              value={filtersPurchaseSignature.vehiclestore_licenseplateFrom}
                              inputProps={{ style: {width: 400}, maxLength: 20 }}
                              label={errorsFiltersPurchaseSignature.vehiclestore_licenseplateFrom!=null?t(errorsFiltersPurchaseSignature.vehiclestore_licenseplateFrom):t('Text_Filter_LicensePlate_From')}
                              placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseSignature}
                              onChange={handleChangeFilterEditPurchaseSignature}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id="vehiclestore_licenseplateTo" 
                              name="vehiclestore_licenseplateTo"
                              value={filtersPurchaseSignature.vehiclestore_licenseplateTo}
                              inputProps={{ style: {width: 400}, maxLength: 20 }}
                              label={errorsFiltersPurchaseSignature.vehiclestore_licenseplateTo!=null?t(errorsFiltersPurchaseSignature.vehiclestore_licenseplateTo):t('Text_Filter_LicensePlate_To')}
                              placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseSignature}
                              onChange={handleChangeFilterEditPurchaseSignature}
                            />
                          </div>

                          <div className="form-group">
                            <TextField
                              id="ori_tras_workplaceFrom" 
                              name="ori_tras_workplaceFrom"
                              value={filtersPurchaseSignature.ori_tras_workplaceFrom}
                              inputProps={{ style: {width: 400}, maxLength: 200 }}
                              label={errorsFiltersPurchaseSignature.ori_tras_workplaceFrom!=null?t(errorsFiltersPurchaseSignature.ori_tras_workplaceFrom):t('Text_Filter_WorkPlace_From')}
                              placeholder={t('PlaceHolder_Filter_WorkPlace_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseSignature}
                              onChange={handleChangeFilterEditPurchaseSignature}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id="ori_tras_workplaceTo" 
                              name="ori_tras_workplaceTo"
                              value={filtersPurchaseSignature.ori_tras_workplaceTo}
                              inputProps={{ style: {width: 400}, maxLength: 200 }}
                              label={errorsFiltersPurchaseSignature.ori_tras_workplaceTo!=null?t(errorsFiltersPurchaseSignature.ori_tras_workplaceTo):t('Text_Filter_WorkPlace_To')}
                              placeholder={t('PlaceHolder_Filter_WorkPlace_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseSignature}
                              onChange={handleChangeFilterEditPurchaseSignature}
                            />
                          </div>

                          <div className="form-group">
                              <TextField
                                id="numberDIFROM" 
                                name="numberDIFROM"
                                value={filtersPurchaseSignature.numberDIFROM}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersPurchaseSignature.numberDIFROM!=null?t(errorsFiltersPurchaseSignature.numberDIFROM):t('Text_Filter_FormDI_From')}
                                placeholder={t('PlaceHolder_Filter_FormDI_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="numberDITO" 
                                name="numberDITO"
                                value={filtersPurchaseSignature.numberDITO}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersPurchaseSignature.numberDITO!=null?t(errorsFiltersPurchaseSignature.numberDITO):t('Text_Filter_FormDI_To')}
                                placeholder={t('PlaceHolder_Filter_FormDI_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature}
                              />
                            </div>

                          <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                            <div className="form-group">
                              <TextField
                                id="rel_trans_store_idFrom"
                                name="rel_trans_store_idFrom"
                                value={filtersPurchaseSignature.rel_trans_store_idFrom}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={errorsFiltersPurchaseSignature.rel_trans_store_idFrom!=null?t(errorsFiltersPurchaseSignature.rel_trans_store_idFrom):t('Text_Filter_Trans_From')}
                                placeholder={t('PlaceHolder_Filter_Trans_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {trasnporterToSelect.map((option) => (
                                    <option key={option.id} value={option.tradename}>
                                        {option.documentid+" - "+option.tradename}
                                    </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="rel_trans_store_idTo"
                                name="rel_trans_store_idTo"
                                value={filtersPurchaseSignature.rel_trans_store_idTo}  
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={errorsFiltersPurchaseSignature.rel_trans_store_idTo!=null?t(errorsFiltersPurchaseSignature.rel_trans_store_idTo):t('Text_Filter_Trans_To')}
                                placeholder={t('PlaceHolder_Filter_Trans_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                  <option key={0} value=""/>
                                {trasnporterToSelect.filter(item=>(item.tradename>=filtersPurchaseSignature.rel_trans_store_idFrom)).map(option => (
                                  <option key={option.id} value={option.tradename}>
                                      {option.documentid+" - "+option.tradename}
                                  </option>
                                ))}
                              </TextField>
                            </div>
                            <div className="form-group">
                              <TextField
                                id="ori_tras_store_idFrom"
                                name="ori_tras_store_idFrom"
                                value={filtersPurchaseSignature.ori_tras_store_idFrom}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={errorsFiltersPurchaseSignature.ori_tras_store_idFrom!=null?t(errorsFiltersPurchaseSignature.ori_tras_store_idFrom):t('Text_Filter_TradeName_From')}
                                placeholder={t('PlaceHolder_Filter_TradeName_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {tradenameToSelect.map((option) => (
                                    <option key={option.id} value={option.tradename}>
                                        {option.documentid+" - "+option.tradename}
                                    </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="ori_tras_store_idTo"
                                name="ori_tras_store_idTo"
                                value={filtersPurchaseSignature.ori_tras_store_idTo}  
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={errorsFiltersPurchaseSignature.ori_tras_store_idTo!=null?t(errorsFiltersPurchaseSignature.ori_tras_store_idTo):t('Text_Filter_TradeName_To')}
                                placeholder={t('PlaceHolder_Filter_TradeName_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                  <option key={0} value=""/>
                                {tradenameToSelect.filter(item=>(item.tradename>=filtersPurchaseSignature.ori_tras_store_idFrom)).map(option => (
                                  <option key={option.id} value={option.tradename}>
                                      {option.documentid+" - "+option.tradename}
                                  </option>
                                ))}
                              </TextField>
                            </div>

                            <div className="form-group">
                              <TextField
                                id={i18n.language==="es"?"paymentFromES":"paymentFromEN"}
                                name={i18n.language==="es"?"paymentFromES":"paymentFromEN"}
                                value={i18n.language==="es"?filtersPurchaseSignature.paymentFromES:filtersPurchaseSignature.paymentFromEN}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFiltersPurchaseSignature.paymentFromES!=null?t(errorsFiltersPurchaseSignature.paymentFromES):t('Text_Filter_payment_From'):errorsFiltersPurchaseSignature.paymentFromEN!=null?t(errorsFiltersPurchaseSignature.paymentFromEN):t('Text_Filter_payment_From')}
                                placeholder={t('PlaceHolder_Filter_payment_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {paymenetTypeSelect.map((option) => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id={i18n.language==="es"?"paymentToES":"paymentToEN"}
                                name={i18n.language==="es"?"paymentToES":"paymentToEN"}
                                value={i18n.language==="es"?filtersPurchaseSignature.paymentToES:filtersPurchaseSignature.paymentToEN}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFiltersPurchaseSignature.paymentToES!=null?t(errorsFiltersPurchaseSignature.paymentToES):t('Text_Filter_payment_To'):errorsFiltersPurchaseSignature.paymentToEN!=null?t(errorsFiltersPurchaseSignature.paymentToEN):t('Text_Filter_payment_To')}
                                placeholder={t('PlaceHolder_Filter_payment_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {paymenetTypeSelect.filter(item=>(i18n.language==="es"?item.namees>=filtersPurchaseSignature.paymentFromES:item.nameen>=filtersPurchaseSignature.paymentFromEN)).map(option => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                                </TextField>
                            </div>

                            </Suspense>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditPurchaseSignature}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersPurchaseSignature}>{t('Button_Cancel_Filters')}</button>
                        </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
              </div>
              }  
              <PurchaseSignaturePDF 
                rowEditPurchaseSignature={rowEditPurchaseSignature} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />

              {/* End Content Row */}
          </>:
            <Loading isLoadingData={isLoadingPurchaseSignature}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

PurchaseSignatureFilter.propTypes = {
  applyFiltersPurchaseSignature: PropTypes.object.isRequired,
  setApplyFiltersPurchaseSignature: PropTypes.func.isRequired,
  showModalPurchasesSignatureSettings: PropTypes.bool.isRequired,
  setShowModalPurchasesSignatureSettings: PropTypes.func.isRequired 
};

export default PurchaseSignatureFilter;

