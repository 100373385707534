import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box'; 
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import useTheme from '@mui/material/styles/useTheme';
import VisibilityIcon from '@mui/icons-material/Visibility';
import visuallyHidden from '@mui/utils/visuallyHidden'; 
import {useTranslation} from "react-i18next"

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t, headCells,showDeleteButton } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 14}}>
            {t('Button_Edit')}
          </Typography>
        </TableCell>
        {showDeleteButton&&
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 14}}>
            {t('Button_Delete')}
          </Typography>
        </TableCell>} 
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
    const { textHead, textReg, numTotReg, numTotRegFilter, textOf } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead} 
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotReg).toLocaleString('de-DE')}
        </Typography>
      </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataGeneralSettings']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingSendEmail =(props)=>{
  const { data, headCells,numTotRegFilter, titleTable,setOpenDeleteRow,setOpenEditRow,setRowEdit,showDeleteButton,numTotReg,activeOffset,rangeOffset,currencyOffset,setCurrencyOffset} = props;
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0);
  const [countData,setCountData] = useState(data.length);

  useEffect(
    () => {
      if (data.length!==countData || data.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(data.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0);  
    },
    [data,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffset(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  const openModalDeleteRowDepartment = (row) => {
    setRowEdit(row);
    setOpenDeleteRow(true);
  };

  const openModalEditRow = (row) => {
    setRowEdit(row);
    setOpenEditRow(true);
  };

  const listItems= (
  stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
   
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {row.id}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >  
          {row.createdate !== null &&  row.createdate !== ""
            ? new Date(row.createdate).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US") 
            : ""} 
            </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >  
            {row.defaultStore_email}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >  
          {row.subjectEmail}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >  
          {i18n.language==='es'?row.status_namees:row.status_nameen}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >  
          {i18n.language==='es'?row.reason_namees:row.reason_nameen}
          </Typography>
        </TableCell> 
        <TableCell align="center">
          <IconButton  size="large" style={{color:"#4e73df"}} onClick={()=>openModalEditRow(row)}>
            <VisibilityIcon />
            <Typography style={{color:"#4e73df", fontSize: 14}} >
            </Typography>
          </IconButton>
        </TableCell>

        { (showDeleteButton  && row.id!=="" && row.id!==null )&&
        <TableCell align="center">
          <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalDeleteRowDepartment(row)}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>} 
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotReg={numTotReg} numTotRegFilter={numTotRegFilter} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} headCells={headCells} showDeleteButton={showDeleteButton}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={18} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffset && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffset"
                            name="currencyOffset"
                            value={currencyOffset}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffset
                            }}>
                            {rangeOffset.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={18}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingSendEmail.propTypes = {
  data: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  setOpenDeleteRow: PropTypes.func.isRequired,  
  setOpenEditRow: PropTypes.func.isRequired, 
  setRowEdit: PropTypes.func.isRequired,  
  showDeleteButton: PropTypes.bool.isRequired,  
  numTotReg: PropTypes.number.isRequired,
  activeOffset: PropTypes.bool.isRequired,
  rangeOffset: PropTypes.array.isRequired,
  currencyOffset: PropTypes.number.isRequired,
  setCurrencyOffset: PropTypes.func.isRequired
};
  
export default TableSortingSendEmail;