import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom';  
import Box from '@mui/material/Box'; 
import CircularProgress from '@mui/material/CircularProgress';  
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";  
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css";   
import SignatureCanvas from 'react-signature-canvas';
import ReportPurchaseSignature from "pages/purchaseSignature/purchaseSignatureSign/ReportPurchaseSignature";
import PropTypes from 'prop-types';  

const initialStatePurchaseSignature = {
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isfinished":false, 
  "purchasenote":0, 
  "numberticket":0, 
  "ownnumberpurchase":"", 
  "datepurchase": null, 
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,
  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_nima": "",
  "rel_trans_pygr": "",
  "rel_trans_grnp":"",
  "rel_trans_opt": 0,
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_nima": "",
  "ori_tras_pygr": "",
  "ori_tras_grnp":"",
  "ori_tras_opt": 0,
  "ori_tras_workplace": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "ori_tras_email": "",

  "observations": "",
  "totalpurchasedelivery": 0,
  "totaldiscountpurchasedelivery": 0,
  "totaltaxespurchasedelivery":0,
  "totalNetoPurchasedelivery":0,
  "enabled":true,
  "subownnumberpurchase":0,
  "payment_id" : 0 ,
  "payment_code": "",
  "payment_namees": "",
  "payment_nameen": "",
  "totalWeigthNeto":0,
  "signature_dispatched_by":"",
  "signature_client":"",
  "linespurchasedelivery":[]
}; 

const PurchaseDeliverySign = ({id, showModalPurchasesSignatureSettings}) => {
  const {t} = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token);   
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [formPurchaseSignature, setFormPurchaseSignature] = useState(initialStatePurchaseSignature);
  const [isLoadingData, setIsLoadingData] = useState(true);  
  const [pdfBlob, setPdfBlob] = useState(null); 
  const [signDispatchedCanvas, setSignDispatchedCanvas]= useState(); 
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalPurchasesSignatureSettings) {
        setFormPurchaseSignature(formPurchaseSignature => ({
          ...formPurchaseSignature,
          linespurchasedelivery: [],
        }));
        setFormPurchaseSignature(initialStatePurchaseSignature);
        setIsLoadingData(false);  
        setPdfBlob(null);
        setSignDispatchedCanvas();   
        navigate("/purchasesignature");
      }
    },
    [
      setFormPurchaseSignature,
      setIsLoadingData,  
      setPdfBlob,   
      setSignDispatchedCanvas,
      showModalPurchasesSignatureSettings,
      navigate]
  );
  
  const handleCloseDataEditPurchaseSignature = () => {
    setFormPurchaseSignature(formPurchaseSignature => ({
      ...formPurchaseSignature,
      linespurchasedelivery: [],
    }));
    setFormPurchaseSignature(initialStatePurchaseSignature);
    setIsLoadingData(false);    
    setPdfBlob(null);  
    setSignDispatchedCanvas();
    navigate("/purchasesignature");
	};

  const handleCancelSignDispatched = () => {
    signDispatchedCanvas.clear();  
    handleCloseDataEditPurchaseSignature();
  };
      
  const handleDeleteSignDispatched = () => {
    signDispatchedCanvas.clear();
    setFormPurchaseSignature(formPurchaseSignature => ({
      ...formPurchaseSignature,
      signature_client: "",
    })); 
  };

  const handleViewSignDispatched = () => {
    const dataURL = signDispatchedCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormPurchaseSignature(formPurchaseSignature => ({
      ...formPurchaseSignature,
      signature_client:dataURL
    }));  
  };
 
  const handleSubmitSign = () => {
    if (formPurchaseSignature && formPurchaseSignature.id) {
      const fetchData = async () => {
        setIsLoadingData(true);
        let isMounted = true;
        try {
          const getData = await helpHttp().put(ENDPOINT,`purchasesignature/updatepurchasesignature`,token,formPurchaseSignature); 
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
          } else {
            if (isMounted) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
          handleCloseDataEditPurchaseSignature();
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
        }
        return () => {
          isMounted = false;
        };
      };
      fetchData();
      setIsLoadingData(false);
    } else {
      dispatch(warningErrorAction(t('Text_IncorrectData')));
    }
  };  
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

       
  useEffect(() => {
    let isMounted = true;
    const fetchDataPurchaseDelivery = async () => {
      setIsLoadingData(true);
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`purchasesignature/getpurchasesignaturebyid/`,token,id);
        if (getData.err) {
          setFormPurchaseSignature(initialStatePurchaseSignature); 
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setFormPurchaseSignature(getData);
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingData(false);
      }  
    };

    if (id!=="0"){
      if (formPurchaseSignature.id===""){
        fetchDataPurchaseDelivery(); 
        setIsLoadingData(false);
      }
    }else{
      setFormPurchaseSignature(initialStatePurchaseSignature);
      setFormPurchaseSignature(formPurchaseSignature => ({
        ...formPurchaseSignature,
        linespurchasedelivery:[]
      }));
      setIsLoadingData(false);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,dispatch,id,t,token,pdfBlob,formPurchaseSignature.id,defaultStore.id,useraccount_id]);  

 return (
      <>
        {showModalPurchasesSignatureSettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff', width: '100%'}}>
              <form onSubmit={handleCloseDataEditPurchaseSignature}>  
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className="h9NewStyle mb-2">
                                  {(isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className="fas fa-regular fa-clipboard text-warning"></i>)} 
                                  &nbsp;&nbsp;{t('Text_NumberPurchase_Sign')+': '+formPurchaseSignature.purchasenote}
                              </h1>
                              <div>
                              {isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <>
                                    <button type="button" className="buttonCreate" onClick={handleSubmitSign}>{t('Button_Update')}</button><> </>
                                    <button type="button" className="buttonBack" onClick={handleCloseDataEditPurchaseSignature}>{t('Button_Cancel')}</button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isLoadingData ?
                <>
                  <div className="col-xl-12 mb-4">
                    <div className="rowpdf">
                      <div className="cardpdf border-info shadow">
                        <div className="cardpdf-body">
                          <div className="rowpdf no-gutters align-items-center">
                            <div className="formgrouppdf" style={{ height: '100%' }}>
                              {pdfBlob && (
                                <iframe
                                  src={URL.createObjectURL(pdfBlob)}
                                  title="PDF Preview"
                                  style={{ width: '100%', height: '100%', border: 'none' }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>:
                  <Loading isLoadingData={isLoadingData}/>
                }
                 <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                           <div  style={{border:'2px solid #4e73df', width: '100%', height: 150 }}>
                              <SignatureCanvas
                                penColor="black"
                                canvasProps={{
                                  style: { 
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'transparent',
                                  },
                                }}
                                ref={data=>setSignDispatchedCanvas(data)}
                              />
                            </div>
                           
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                              <button className="btn btn-secondary btn-lg " type="button" onClick={handleCancelSignDispatched}>{t('Button_Cancel_Sign')}</button><> </>
                              <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteSignDispatched}>{t('Button_Delete_Sign')}</button>
                              <button className="btn btn-primary btn-lg" type="button" onClick={handleViewSignDispatched}>{t('Button_View_Sign')}</button> 
                            </div> 
 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </form>
            </Box>
            <ReportPurchaseSignature 
              formPurchaseSignature={formPurchaseSignature}  
              setPdfBlob={setPdfBlob} 
            />

            <br/>
          </div>
        //* End Page Content *//
      ) :  null }
    </>
  )    
}

PurchaseDeliverySign.propTypes = {
  id: PropTypes.string.isRequired,
  showModalPurchasesSignatureSettings: PropTypes.bool.isRequired
};

export default PurchaseDeliverySign;