import React, {  useEffect, useState, useCallback } from 'react';  
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'; 
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';    
import Button from '@mui/material/Button'; 
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid'; 
import MenuItem  from "@mui/material/MenuItem"; 
import Paper from '@mui/material/Paper'; 
import SearchIcon from '@mui/icons-material/Search';
import ScaleIcon from '@mui/icons-material/Scale';
import TextField from '@mui/material/TextField';    
import Typography from '@mui/material/Typography'; 
import { experimentalStyled as styled } from '@mui/material/styles'; 
import { helpHttp } from "service/HelpHttp";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css";
import TicketsSearchStores from "pages/tickets/ticketsEdit/TicketsSearchStores";
import TicketsSearchStoresAddresses from "pages/tickets/ticketsEdit/TicketsSearchStoresAddress";
import TicketsSearchVehicles from "pages/tickets/ticketsEdit/TicketsSearchVehicles"; 
import InvoiceTickets from "pages/tickets/ticketsEdit/invoiceTicketsEdit/InvoiceTickets";
import TicketsSearchArticles from "pages/tickets/ticketsEdit/TicketsSearchArticles";
import TicketsSelectArticle from "pages/tickets/ticketsEdit/TicketsSelectArticle";  
import TicketsSelectArticleWithOutWeight from "pages/tickets/ticketsEdit/TicketsSelectArticlesWithOutWeight";  
import TicketsSearchDI from "pages/tickets/ticketsEdit/TicketsSearchDI";  
import TicketsCreateVehicles from "pages/tickets/ticketsVehicle/TicketsNewVehicle";
import signFrancisco from "components/photos/signs/Francisco.png"; 
import signGonzalo from "components/photos/signs/Gonzalo.png"; 
import signGuillermo from "components/photos/signs/Guillermo.png"; 
import signJorge from "components/photos/signs/Jorge.png"; 

import PropTypes from 'prop-types';

const initialState = [{
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isfinished":false, 
  "numberticket":0, 
  "purchasenote":0, 
  "ownnumbertickes":0, 
  "start_date_ticket": "",
  "end_date_ticket": "", 
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_tara": 0,
  "vehiclestore_pma": 0,
  "vehiclestore_checkpma": false,
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,
  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "rel_trans_grnp": "",

  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "ori_tras_email": "",

  "observations": "",
  "totalstartweight": 0,
  "totalendweight": 0,
  "newweightreading":0,
  "enabled":true,
  "signature_dispatched_by":"",

  "linestickets":[],
  "linesticketsWithOutWeight":[]
}];

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialPostalCodesToSelect = [{
  "id":"",
  "postalcode":"",
  "name":"",
  "postalcodename":"",
  "enabled":true,
  "province_id":30
}];

const initialArticlesToSelect = [{
  "id": 0,
  "indexrowarticle":0, 
  "tickets_id":0,
  "article_id":0,
  "article_code": "",
  "article_namees": "",
  "article_nameen": "",
  "start_date_ticket": "",
  "end_date_ticket": "",
  "firstweight":0,
  "secondweight":0, 
  "estimatedprice":0, 
  "discount":0, 
  "tax":0, 
  "taxincluded":false ,
  "ticketweight":true,
  "formdi_id":0,
  "ownformdi_id":0
}];
 
const initialVehicleToSelect = [{
  "id": "",
  "vehiclestore_licenseplate": "" 
}];

const initialWorkPlaceToSelect = [{
  "id": "",
  "workplace": "" 
}];

const initialDocumentIdToSelect = [{
  "id": "",
  "documentId": "",
  "tradename": ""
}];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const TicketsEditOrCreate = ({showModalTicketsSettings}) => {
  const {t,i18n} = useTranslation(['listDataTickets']); 
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles); 
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);  
  const ENDPOINTPRINTER = useSelector((state) => state.loginUser.defaultticketsformat.endpoinrprinter);
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const ENDPOINTWEIGHT = useSelector((state) => state.loginUser.defaultticketsformat.endpointweight);
  const LIMITSARTICLESPERWEIGHT = useSelector((state) => state.loginUser.defaultticketsformat.limitsarticleperweight);
  const scale_protocol = useSelector((state) => state.loginUser.defaultticketsformat.scale_protocol); 
  const scale_com = useSelector((state) => state.loginUser.defaultticketsformat.scale_com); 
  const scale_delay = useSelector((state) => state.loginUser.defaultticketsformat.scale_delay); 
  const scale_baud_rate = useSelector((state) => state.loginUser.defaultticketsformat.scale_baud_rate); 
  const scale_parity = useSelector((state) => state.loginUser.defaultticketsformat.scale_parity); 
  const scale_data_bits = useSelector((state) => state.loginUser.defaultticketsformat.scale_data_bits); 
  const scale_stop_bits = useSelector((state) => state.loginUser.defaultticketsformat.scale_stop_bits); 
  const scale_DTR = useSelector((state) => state.loginUser.defaultticketsformat.scale_DTR); 
  const scale_RTS = useSelector((state) => state.loginUser.defaultticketsformat.scale_RTS); 
  const scale_answer = useSelector((state) => state.loginUser.defaultticketsformat.scale_answer); 
  const scale_get = useSelector((state) => state.loginUser.defaultticketsformat.scale_get); 
  const numberoftickets = useSelector((state) => state.loginUser.defaultticketsformat.numberoftickets); 
  const printerticket_operatingsystem = useSelector((state) => state.loginUser.defaultticketsformat.operatingsystem); 
  const printerticket_communicationtype = useSelector((state) => state.loginUser.defaultticketsformat.communicationtype);  
  const headerPrinter = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinter);
  const printerticket_type = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_type);
  const printerticket_interface = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_interface);
  const printerticket_driver = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_driver);
  const articlesButtonsList = useSelector((state) => state.loginUser.defaultticketsformat.articlesButtonsList); 
  const isUserdefault = useSelector((state) => state.userDefault.isUserdefault);
     
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const [form, setForm] = useState(initialState[0]);
  const [errorsForm, setErrorsForm] = useState({});
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [postalCodeToSelect,setPostalCodeToSelect]= useState(initialPostalCodesToSelect);
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
  const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false);  
  const [showModalSearchDI, setShowModalSearchDI] = useState(false);  
  const [tetxtSearch, setTetxtSearch] = useState("Rel_Trans");   
  const [showModalSearchArticles, setShowModalSearchArticles] = useState(false); 
  const [editRowArticles, setEditRowArticles] = useState(initialArticlesToSelect);   
  const [unlockData,setUnlockData]=useState(false);  
  const [unlockDataChangeState,setUnlockDataChangeState]=useState(false);  
  const [submitTickets,setSubmitTickets]=useState(false);
  const [openEditRowVehicles,setOpenEditRowVehicles]=useState(false);
  const [vehiclesToSelect,setVehiclesToSelect]= useState(initialVehicleToSelect); 
  const [filteredVehicle, setFilteredVehicle] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicle, setHighlightedIndexVehicle] = useState(-1); // Índice de la opción resaltada 
  const [documentIdToSelect,setDocumentIdToSelect]= useState(initialDocumentIdToSelect);  
  const [filteredDocumentId, setFilteredDocumentId] = useState([]); // Opciones filtradas
  const [highlightedIndexDocumentId, setHighlightedIndexDocumentId] = useState(-1); // Índice de la opción resaltada 
  const [tradenameToSelect,setTradenameToSelect]= useState(initialDocumentIdToSelect);  
  const [filteredTradename, setFilteredTradename] = useState([]); // Opciones filtradas
  const [highlightedIndexTradename, setHighlightedIndexTradename] = useState(-1); // Índice de la opción resaltada 
  const [documentIdOriToSelect,setDocumentIdOriToSelect]= useState(initialDocumentIdToSelect);  
  const [filteredDocumentIdOri, setFilteredDocumentIdOri] = useState([]); // Opciones filtradas
  const [highlightedIndexDocumentIdOri, setHighlightedIndexDocumentIdOri] = useState(-1); // Índice de la opción resaltada 
  const [tradenameOriToSelect,setTradenameOriToSelect]= useState(initialDocumentIdToSelect);  
  const [filteredTradenamedOri, setFilteredTradenamedOri] = useState([]); // Opciones filtradas
  const [highlightedIndexTradenamedOri, setHighlightedIndexTradenamedOri] = useState(-1); // Índice de la opción resaltada 
  const [workPlaceToSelect,setWorkPlaceToSelect]= useState(initialWorkPlaceToSelect);  
  const [filteredWorkPlace, setFilteredWorkPlace] = useState([]); // Opciones filtradas
  const [highlightedIndexWorkPlace, setHighlightedIndexWorkPlace] = useState(-1); // Índice de la opción resaltada 
       
  const [refeshData,setRefeshData]=useState(false);
  
  const [configPrinter, setConfigPrinter] = useState({
    protocol: scale_protocol,
    comunication: scale_com,
    delay: scale_delay,
    baudRate: isNaN(scale_baud_rate) ? 9600 : parseInt(scale_baud_rate, 10),
    parity: scale_parity,
    dataBits: scale_data_bits,
    stopBits: scale_stop_bits,
    dtr: scale_DTR,
    rts: scale_RTS,
    answer: scale_answer,
    getfetch: scale_get,
    printerticket_communicationtype:printerticket_communicationtype
  });
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalTicketsSettings) { 
        setErrorsForm({});
        setForm(form => ({
          ...form,
          linestickets: [],
          linesticketsWithOutWeight:[]

        }));
        setForm(initialState[0]);
        setProvinceToSelect(initialProvincesToSelect);
        setPostalCodeToSelect(initialPostalCodesToSelect);
        setShowModalSearchStores(false);
        setShowModalSearchStoresAddress(false);
        setShowModalSearchVehicles(false);
        setShowModalSearchDI(false);
        setTetxtSearch("Rel_Trans ");   
        setEditRowArticles(initialArticlesToSelect); 
        setSubmitTickets(false); 
        setRefeshData(false);
        setUnlockData(false);
        setShowModalSearchArticles(false);
        setUnlockDataChangeState(false); 
        setOpenEditRowVehicles(false);
        setVehiclesToSelect(initialVehicleToSelect); 
        setDocumentIdToSelect(initialDocumentIdToSelect);
        setTradenameToSelect(initialDocumentIdToSelect); 
        setDocumentIdOriToSelect(initialDocumentIdToSelect); 
        setTradenameOriToSelect(initialDocumentIdToSelect); 
        setWorkPlaceToSelect(initialWorkPlaceToSelect);
        setFilteredVehicle([]);
        setHighlightedIndexVehicle(-1); 
        setFilteredDocumentId([]);
        setHighlightedIndexDocumentId(-1); 
        setFilteredTradename([]);
        setHighlightedIndexTradename(-1); 
        setFilteredDocumentIdOri([]);
        setHighlightedIndexDocumentIdOri(-1); 
        setFilteredTradenamedOri([]);
        setHighlightedIndexTradenamedOri(-1); 
        setFilteredWorkPlace([]);
        setHighlightedIndexWorkPlace(-1); 
        setConfigPrinter({});
        navigate("/home") 
        return () => {};
      }
    },
    [
      setForm,
      setErrorsForm,
      setProvinceToSelect,
      setPostalCodeToSelect,
      setShowModalSearchStores,
      setShowModalSearchStoresAddress,
      setShowModalSearchVehicles,
      setShowModalSearchDI,
      setTetxtSearch,  
      setEditRowArticles, 
      setUnlockData, 
      setShowModalSearchArticles,
      setUnlockDataChangeState,
      setConfigPrinter,
      setOpenEditRowVehicles,
      setVehiclesToSelect, 
      setDocumentIdToSelect,
      setTradenameToSelect, 
      setDocumentIdOriToSelect, 
      setTradenameOriToSelect,
      setWorkPlaceToSelect,
      setFilteredVehicle,
      setHighlightedIndexVehicle,
      setFilteredDocumentId, 
      setHighlightedIndexDocumentId,
      setFilteredTradename, 
      setHighlightedIndexTradename,
      setFilteredDocumentIdOri,
      setHighlightedIndexDocumentIdOri,
      setFilteredTradenamedOri,
      setHighlightedIndexTradenamedOri,
      setFilteredWorkPlace,
      setHighlightedIndexWorkPlace,
      showModalTicketsSettings,  
      setSubmitTickets,
      setRefeshData,
      navigate]
  );
  
  const handleCloseDataEditTickets = () => {
    setErrorsForm({});
    setForm(form => ({
      ...form,
      linestickets: [],
      linesticketsWithOutWeight:[]
    }));
    setForm(initialState[0]);
    setProvinceToSelect(initialProvincesToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    setShowModalSearchStores(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchVehicles(false);
    setShowModalSearchDI(false);
    setTetxtSearch("Rel_Trans");   
    setEditRowArticles(initialArticlesToSelect);   
    setUnlockData(false); 
    setShowModalSearchArticles(false);
    setUnlockDataChangeState(false);
    setOpenEditRowVehicles(false);
    setConfigPrinter({});
    setSubmitTickets(false); 
    setVehiclesToSelect(initialVehicleToSelect); 
    setDocumentIdToSelect(initialDocumentIdToSelect);
    setTradenameToSelect(initialDocumentIdToSelect);
    setDocumentIdOriToSelect(initialDocumentIdToSelect); 
    setTradenameOriToSelect(initialDocumentIdToSelect); 
    setFilteredVehicle([]);
    setHighlightedIndexVehicle(-1); 
    setFilteredDocumentId([]);
    setHighlightedIndexDocumentId(-1); 
    setFilteredTradename([]);
    setHighlightedIndexTradename(-1); 
    setFilteredDocumentIdOri([]);
    setHighlightedIndexDocumentIdOri(-1); 
    setFilteredTradenamedOri([]);
    setHighlightedIndexTradenamedOri(-1); 
    setFilteredWorkPlace([]);
    setHighlightedIndexWorkPlace(-1); 
    setRefeshData(false);
    navigate("/home")  
	};
  
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchVehicleFrom = (event) => {
    const searchVehicle = event.target.value.toUpperCase();
    setForm(form => ({
      ...form,
      vehiclestore_licenseplate: searchVehicle,
    })); 
    if (searchVehicle.trim() === "") {
      setFilteredVehicle([]);
      setHighlightedIndexVehicle(-1);
      return;
    }  
    const filtered = vehiclesToSelect.filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      const searchNormalizedVehicle = searchVehicle.toUpperCase().replace(/[.\-/\\]/g, "");
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.\-/\\]/g, "");
      return normalizedVehicle.includes(searchNormalizedVehicle);
    });
    setFilteredVehicle(filtered);
    setHighlightedIndexVehicle(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleFrom = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setForm(form => ({
      ...form,
      vehiclestore_licenseplate: (vehicle.vehiclestore_licenseplate || "") 
    }));
    setFilteredVehicle([]);
    setHighlightedIndexVehicle(-1);
  }; 
  const handleKeyDownVehicleFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicle.length === 0 && form.vehiclestore_licenseplate.trim() === "") {
        setFilteredVehicle(vehiclesToSelect);
        setHighlightedIndexVehicle(0);
      } else {
        setHighlightedIndexVehicle((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicle.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicle((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexVehicle >= 0) {
      if (highlightedIndexVehicle >= 0 && highlightedIndexVehicle < filteredVehicle.length) {
        handleSelectVehicleFrom(filteredVehicle[highlightedIndexVehicle]);
      }
    } else if (event.key === "Escape") {
      const isValid = vehiclesToSelect.some((store) => {
        if (!store || !store.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = store.vehiclestore_licenseplate.toUpperCase().replace(/[.\-/\\]/g, "");
        const normalizedInputVehicle = form.vehiclestore_licenseplate.toUpperCase().replace(/[.\-/\\]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setForm((form) => ({
          ...form,
          vehiclestore_licenseplate: "",
        }));
      } 
      setFilteredVehicle([]);
      setHighlightedIndexVehicle(-1);
    }
  }; 
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchDocumentId = (event) => {
    const searchDocumentId = event.target.value;
    setForm(form => ({
      ...form,
      rel_trans_documentid: searchDocumentId,
    })); 
    if (searchDocumentId.trim() === "") {
      setFilteredDocumentId([]);
      setHighlightedIndexDocumentId(-1);
      return;
    } 
    const filtered = documentIdToSelect.filter((store) => {
      if (!store || !store.documentId) return false;
      const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.-]/g, "");
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.-]/g, "");
      return normalizedDocumentId.includes(searchNormailzedDocumentId);
    });
    setFilteredDocumentId(filtered);
    setHighlightedIndexDocumentId(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectDocumentId = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setForm((form) => ({
      ...form,
      rel_trans_documentid: store.documentId || "",
    }));
    setFilteredDocumentId([]);
    setHighlightedIndexDocumentId(-1);
  }; 
  const handleKeyDownDocumentId = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredDocumentId.length === 0 && form.rel_trans_documentid.trim() === "") {
        setFilteredDocumentId(documentIdToSelect);
        setHighlightedIndexDocumentId(0);
      } else {
        setHighlightedIndexDocumentId((prevIndex) => {
          const nextIndex = prevIndex < filteredDocumentId.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexDocumentId((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexDocumentId >= 0) {
      if (highlightedIndexDocumentId >= 0 && highlightedIndexDocumentId < filteredDocumentId.length) {
        handleSelectDocumentId(filteredDocumentId[highlightedIndexDocumentId]);
      }
    } else if (event.key === "Escape") {
      const isValid = documentIdToSelect.some((store) => {
        if (!store || !store.documentId) return false; 
        const normalizedStoreId = store.documentId.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputId = form.rel_trans_documentid.toUpperCase();
        return normalizedStoreId === normalizedInputId;
      }); 
      if (!isValid) {
        setForm((form) => ({
          ...form,
          rel_trans_documentid: "",
        }));
      } 
      setFilteredDocumentId([]);
      setHighlightedIndexDocumentId(-1);
    }
  }; 
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTradename = (event) => {
    const searchTradename = event.target.value.toUpperCase();
    setForm(form => ({
      ...form,
      rel_trans_tradename: searchTradename,
    })); 
    if (searchTradename.trim() === "") {
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
      return;
    } 
    const filtered = tradenameToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      return normalizedTradeName.includes(searchTradename);
    });
    setFilteredTradename(filtered);
    setHighlightedIndexTradename(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTradeName = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setForm((form) => ({
      ...form,
      rel_trans_tradename: store.tradename || "",
      rel_trans_documentid: store.documentId || form.rel_trans_documentid,
      
    }));
    setFilteredTradename([]);
    setHighlightedIndexTradename(-1);
  }; 
  const handleKeyDownTradename = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTradename.length === 0 && form.rel_trans_tradename.trim() === "") {
        setFilteredTradename(tradenameToSelect);
        setHighlightedIndexTradename(0);
      } else {
        setHighlightedIndexTradename((prevIndex) => {
          const nextIndex = prevIndex < filteredTradename.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTradename((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexTradename >= 0) {
      if (highlightedIndexTradename >= 0 && highlightedIndexTradename < filteredTradename.length) {
        handleSelectTradeName(filteredTradename[highlightedIndexTradename]);
      }
    } else if (event.key === "Escape") {
      const isValid = tradenameToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = form.rel_trans_tradename.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setForm((form) => ({
          ...form,
          rel_trans_tradename: "",
        }));
      } 
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
    }
  }; 
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchDocumentIdOri = (event) => {
    const searchDocumentIdOri = event.target.value;
    setForm(form => ({
      ...form,
      ori_tras_documentid: searchDocumentIdOri,
    })); 
    if (searchDocumentIdOri.trim() === "") {
      setFilteredDocumentIdOri([]);
      setHighlightedIndexDocumentIdOri(-1);
      return;
    } 
    const filtered = documentIdOriToSelect.filter((store) => {
      if (!store || !store.documentId) return false;
      const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.\-/\\]/g, "");
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.\-/\\]/g, "");
      return normalizedDocumentId.includes(searchNormailzedDocumentId);
    });
    setFilteredDocumentIdOri(filtered);
    setHighlightedIndexDocumentIdOri(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectDocumentIdOri = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setForm((form) => ({
      ...form,
      ori_tras_documentid: store.documentId || "",
    }));
    setFilteredDocumentIdOri([]);
    setHighlightedIndexDocumentIdOri(-1);
  }; 
  const handleKeyDownDocumentIdOri = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredDocumentIdOri.length === 0 && form.ori_tras_documentid.trim() === "") {
        setFilteredDocumentIdOri(documentIdOriToSelect);
        setHighlightedIndexDocumentIdOri(0);
      } else {
        setHighlightedIndexDocumentIdOri((prevIndex) => {
          const nextIndex = prevIndex < filteredDocumentIdOri.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexDocumentIdOri((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexDocumentIdOri >= 0) {
      if (highlightedIndexDocumentIdOri >= 0 && highlightedIndexDocumentIdOri < filteredDocumentIdOri.length) {
        handleSelectDocumentIdOri(filteredDocumentIdOri[highlightedIndexDocumentIdOri]);
      }
    } else if (event.key === "Escape") {
      const isValid = documentIdOriToSelect.some((store) => {
        if (!store || !store.documentId) return false; 
        const normalizedStoreId = store.documentId.toUpperCase().replace(/[.\-/\\]/g, "");
        const normalizedInputId = form.ori_tras_documentid.toUpperCase().replace(/[.\-/\\]/g, "");
        return normalizedStoreId === normalizedInputId;
      }); 
      if (!isValid) {
        setForm((form) => ({
          ...form,
          ori_tras_documentid: "",
        }));
      } 
      setFilteredTradenamedOri([]);
      setHighlightedIndexDocumentIdOri(-1);
    }
  };
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTradenameOri = (event) => {
    const searchTradenameOri = event.target.value.toUpperCase();
    setForm(form => ({
      ...form,
      ori_tras_tradename: searchTradenameOri
    })); 
    if (searchTradenameOri.trim() === "") {
      setFilteredTradenamedOri([]);
      setHighlightedIndexTradenamedOri(-1);
      return;
    } 
    const filtered = tradenameOriToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      return normalizedTradeName.includes(searchTradenameOri);
    });
    setFilteredTradenamedOri(filtered);
    setHighlightedIndexTradenamedOri(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTradeNameOri = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setForm((form) => ({
      ...form,
      ori_tras_tradename: store.tradename || "",
      ori_tras_documentid: store.documentId || form.ori_tras_documentid,
      
    }));
    setFilteredTradenamedOri([]);
    setHighlightedIndexTradenamedOri(-1);
  }; 
  const handleKeyDownTradenameOri = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTradenamedOri.length === 0 && form.ori_tras_tradename.trim() === "") {
        setFilteredTradenamedOri(tradenameOriToSelect);
        setHighlightedIndexTradenamedOri(0);
      } else {
        setHighlightedIndexTradenamedOri((prevIndex) => {
          const nextIndex = prevIndex < filteredTradenamedOri.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTradenamedOri((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexTradenamedOri >= 0) {
      if (highlightedIndexTradenamedOri >= 0 && highlightedIndexTradenamedOri < filteredTradenamedOri.length) {
        handleSelectTradeNameOri(filteredTradenamedOri[highlightedIndexTradenamedOri]);
      }
    } else if (event.key === "Escape") {
      const isValid = tradenameOriToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = form.ori_tras_tradename.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setForm((form) => ({
          ...form,
          ori_tras_tradename: "",
        }));
      } 
      setFilteredTradenamedOri([]);
      setHighlightedIndexTradenamedOri(-1);
    }
  };
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchWorkPlace = (event) => {
    const searchWorkPlace = event.target.value.toUpperCase();
    setForm(form => ({
      ...form,
      ori_tras_street: searchWorkPlace,
    })); 
    if (searchWorkPlace.trim() === "") {
      setFilteredWorkPlace([]);
      setHighlightedIndexWorkPlace(-1);
      return;
    } 
    const filtered = workPlaceToSelect.filter((store) => {
      if (!store || !store.workplace) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedWorkPlace = store.workplace.toUpperCase().replace(/[.\-/\\]/g, "");
      return normalizedWorkPlace.includes(searchWorkPlace.replace(/[.\-/\\]/g, ""));
    });
    setFilteredWorkPlace(filtered);
    setHighlightedIndexWorkPlace(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectWorkPlace = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setForm((form) => ({
      ...form,
      ori_tras_street: store.workplace || ""
      
    }));
    setFilteredWorkPlace([]);
    setHighlightedIndexWorkPlace(-1);
  }; 

  const handleKeyDownWorkPlace = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredWorkPlace.length === 0 && form.ori_tras_street.trim() === "") {
        setFilteredWorkPlace(workPlaceToSelect);
        setHighlightedIndexWorkPlace(0);
      } else {
        setHighlightedIndexWorkPlace((prevIndex) => {
          const nextIndex = prevIndex < filteredWorkPlace.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexWorkPlace((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexWorkPlace >= 0) {
      if (highlightedIndexWorkPlace >= 0 && highlightedIndexWorkPlace < filteredWorkPlace.length) {
        handleSelectWorkPlace(filteredWorkPlace[highlightedIndexWorkPlace]);
      }
    } else if (event.key === "Escape") {
      const isValid = workPlaceToSelect.some((store) => {
        if (!store || !store.workplace) return false; 
        const normalizedWorkPlace = store.workplace.toUpperCase().replace(/[.\-/\\]/g, "");
        const normalizedInputWorkPlace = form.ori_tras_street.toUpperCase().replace(/[.\-/\\]/g, "");
        return normalizedWorkPlace === normalizedInputWorkPlace;
      }); 
      if (!isValid) {
        setForm((form) => ({
          ...form,
          ori_tras_street: "",
        }));
      } 
      setFilteredWorkPlace([]);
      handleSelectWorkPlace(-1);
    }
  };

  // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleBlur = (event) => {
    // Asegúrate de que no se cierra si haces clic en un elemento de la lista
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilteredVehicle([]);
      setHighlightedIndexVehicle(-1);
      setFilteredDocumentId([]);
      setHighlightedIndexDocumentId(-1);
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
      setFilteredDocumentIdOri([]);
      setHighlightedIndexDocumentIdOri(-1);
      setFilteredTradenamedOri([]);
      setHighlightedIndexTradenamedOri(-1);
      setFilteredWorkPlace([]);
      setHighlightedIndexWorkPlace(-1);
    }
  }; 

  const beginDataEditTickets = () => {
    setErrorsForm({});
    setForm(initialState[0]);
    setForm(form => ({
      ...form,
      id:"",
      defaultStore_id:defaultStore.id,
      useraccount_id:useraccount_id,
      isfinished:false,
      rel_trans_country_id: Number(DEFAULTCOUNTRY),
      rel_trans_province_id:  Number(DEFAULTPROVINCE),
      rel_trans_postalcode_id: 0,
      rel_trans_grnp: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id: Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
      signature_dispatched_by:isUserdefault===1?signFrancisco: isUserdefault===2?signGonzalo:isUserdefault===3?signGuillermo:signJorge,
      linestickets: [],
      linesticketsWithOutWeight:[]
    }));
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      id:0,
      indexrowarticle:0,
      tickets_id:0,
      article_id: 0,
      article_code: "",
      article_namees: "",
      article_nameen: "",
      start_date_ticket: "",
      end_date_ticket: "",
      firstweight: 0,
      secondweight:0,
      estimatedprice:0,
      discount:0,
      tax:0,
      taxincluded:false,
      ticketweight:true,
      formdi_id:0,
      ownformdi_id:0
    }));   
    setUnlockData(false);
    setUnlockDataChangeState(false);
    setShowModalSearchStores(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchVehicles(false);
    setShowModalSearchDI(false);
    setShowModalSearchArticles(false);
    setTetxtSearch("Rel_Trans");
    setSubmitTickets(true); 
    setRefeshData(true);
	};

  const formatNumber = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) + ' Kg';
  };

  const formatNumberEURO = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' €';
  };

  const formatNumberWithOutDecimal = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
  };
  
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 1000000) return true;
    return false;
  };

  const handleChangeTickets = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value,
    }));
  };

  const handleBlurTickets = (e) => {
    handleChangeTickets(e);
    setErrorsForm(validateTickets());
  };

  const searchVehicles = (event) => { 
    setShowModalSearchVehicles(true);
  };

  const handleAddTara = (event) => { 
    setOpenEditRowVehicles(true);
  }; 
  
  const deleteDataVehicles = (event) => {
    setForm(form => ({
      ...form,
      vehiclestore_id:0,
      vehiclestore_licenseplate: "",
      vehiclestore_tara:0,
      vehiclestore_pma: 0,
      vehiclestore_checkpma: false,
      vehiclestore_defaultdriver: "",
      vehiclestore_store_id: 0,
      totalstartweight:0
    })); 
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      article_id: 0,
      article_code: "",
      article_namees: "",
      article_nameen: "",
      start_date_ticket: "",
      end_date_ticket: "",
      firstweight: 0,
      secondweight:0 , 
      estimatedprice:0,
      discount:0,
      tax:0,
      taxincluded:false,
      ticketweight:true,
      formdi_id:0,
      ownformdi_id:0
    })); 
    setUnlockData(false);
    setUnlockDataChangeState(false);
    setErrorsForm(validateTickets());
  };

  const searchStoreRelTrans = (event) => {
    setTetxtSearch("Rel_Trans")
    setShowModalSearchStores(true);
  };

  const searchStoreOriTras = (event) => {
    setTetxtSearch("Ori_Tras")
    setShowModalSearchStores(true);
  };

  const deleteDataStoreRelTrans = (event) => {
    setForm(form => ({
      ...form,
      rel_trans_store_id: 0,
      rel_trans_documnettype_id: 0,
      rel_trans_documnettype_name: "",
      rel_trans_documentid: "",
      rel_trans_tradename: "",
      rel_trans_name: "",
      rel_trans_contacperson: "",
      rel_trans_roadtype_id: 0,
      rel_trans_street: "",
      rel_trans_country_id: Number(DEFAULTCOUNTRY),
      rel_trans_province_id:  Number(DEFAULTPROVINCE),
      rel_trans_postalcode_id: 0,
      rel_trans_grnp: "",
    }));  
    setErrorsForm(validateTickets());
  };

  const deleteDataStoreOriTras = (event) => {
    setForm(form => ({
      ...form,
      ori_tras_store_id: 0,
      ori_tras_documnettype_id: 0,
      ori_tras_documnettype_name: "",
      ori_tras_documentid: "",
      ori_tras_tradename: "",
      ori_tras_name: "",
      ori_tras_contacperson: "",
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
      ori_tras_email: ""
    }));  
    setErrorsForm(validateTickets());
  };

  const deleteDataStoreAddressOriTras = (event) => {
    setForm(form => ({
      ...form,
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
    }));  
    setErrorsForm(validateTickets());
  };

  const searchStoreAddress = (event) => {
    setShowModalSearchStoresAddress(true);
  };
 
  const selectArticle = (post) => {
    if (post.button_article_ticketweight){
      setEditRowArticles(editRowArticles => ({
        ...editRowArticles,
        article_id: post.button_article_id,
        article_code: post.button_article_code,
        article_namees: post.button_article_namees,
        article_nameen: post.button_article_nameen,
        start_date_ticket: "",
        end_date_ticket: "",
        secondweight:unlockData?form.vehiclestore_tara:0,
        estimatedprice: post.button_article_estimatedprice ,
        discount: 0,
        tax: post.button_article_tax ,
        taxincluded: post.button_article_taxincluded,
        ticketweight:post.button_article_ticketweight,
        formdi_id:0,
        ownformdi_id:0
      }));
      setForm(form => ({
        ...form,
        totalendweight:unlockData?form.vehiclestore_tara:0
      }))
    }else{
      if(form.linesticketsWithOutWeight.length>=LIMITSARTICLESPERWEIGHT){ 
        dispatch(warningErrorAction(t('Text_LIMITARTICESPERWEIGHT')));
      }else {
        let articleToAdd={
          id:"",
          indexrowarticle:form.linesticketsWithOutWeight.length>0?form.linesticketsWithOutWeight[form.linesticketsWithOutWeight.length-1].indexrowarticle+1:1,  
          tickets_id:0,
          article_id: post.button_article_id,
          article_code: post.button_article_code,
          article_namees: post.button_article_namees,
          article_nameen: post.button_article_nameen,
          start_date_ticket: "",
          end_date_ticket: "",
          firstweight: 1,
          secondweight:0,
          estimatedprice: post.button_article_estimatedprice ,
          discount: 0,
          tax: post.button_article_tax ,
          taxincluded: post.button_article_taxincluded,
          ticketweight:post.button_article_ticketweight,
          formdi_id:0,
          ownformdi_id:0
        }
        form.linesticketsWithOutWeight.push(articleToAdd);
        setForm(form => ({
          ...form
        }))
      }
    }
  };
  
  const validateTickets = () => {
    let errorsForm = {};
    //let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (regexInvalidCharacters.test(form.rel_trans_documentid)) {
      errorsForm.rel_trans_documentid = 'Text_TextNoValid';
    }
    
    if (regexInvalidCharacters.test(form.ori_tras_documentid)) {
      errorsForm.ori_tras_documentid = 'Text_TextNoValid';
    } 
    if ( !form.ori_tras_street ||  form.ori_tras_street==="") {
      errorsForm.ori_tras_street = 'Text_FieldVehicleStoreStreetRequired';
    } else {
      if (regexInvalidCharacters.test(form.ori_tras_street)) {
        errorsForm.ori_tras_street = 'Text_TextNoValid';
      }  
    }

    if (regexInvalidCharacters.test(form.observations)) {
      errorsForm.observations = 'Text_TextNoValid';
    } 

    if ( !form.vehiclestore_licenseplate ||  form.vehiclestore_licenseplate==="") {
      errorsForm.vehiclestore_licenseplate = 'Text_FieldVehicleStoreLicensePlateRequired';
    } else {
      if (regexInvalidCharacters.test(form.vehiclestore_licenseplate)) {
        errorsForm.vehiclestore_licenseplate = 'Text_TextNoValid';
      }  
    }

    if (!form.rel_trans_tradename ||  form.rel_trans_tradename==="") {
      errorsForm.rel_trans_tradename = 'Text_FieldRelTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(form.rel_trans_tradename)) {
        errorsForm.rel_trans_tradename = 'Text_TextNoValid';
      }  
    }

    if (regexInvalidCharacters.test(form.rel_trans_grnp)) {
      errorsForm.rel_trans_grnp = 'Text_TextNoValid';
    }  

    if (regexInvalidCharacters.test(form.ori_tras_email)) {
      errorsForm.ori_tras_email = 'Text_TextNoValid';
    } 

    if (!form.ori_tras_tradename || form.ori_tras_tradename==="") {
      errorsForm.ori_tras_tradename = 'Text_FieldOriTrasTradenameRequired';
    } else {
      if (regexInvalidCharacters.test(form.ori_tras_tradename)) {
        errorsForm.ori_tras_tradename = 'Text_TextNoValid';
      }  
    }

    if (editRowArticles.article_code===undefined  || editRowArticles.article_code==="") {
        errorsForm.linestickets = 'Text_FieldArticlesRequired';
    }

    if (editRowArticles.article_id===undefined  || editRowArticles.article_id==="" || editRowArticles.article_id==="0" || editRowArticles.article_id===0) {
      errorsForm.linestickets = 'Text_FieldArticlesRequired';
  }

    if (form.totalstartweight===undefined  || form.totalstartweight===0) {
      errorsForm.totalstartweight = 'Text_FieldTtotalIsNull';
    }

     return errorsForm;
  };
 
  const openExternalWeigh = () => {
    // CÓDIGO LECTURA EXTERNA PESA
    const fetchData = async () => {
      try {
        const getData = await helpHttp().postWeigt(ENDPOINTWEIGHT,`api/config`,configPrinter);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_ERROR_WEIGHT'))); 
          handleChangeArticleWeight(getData.message); 
        } else {

          if (getData.message!==undefined && getData.message!==null & getData.message!==""){
            handleChangeArticleWeight(getData.message); 
          }
        }       
      } catch (error) { 
        dispatch(warningErrorAction(t('Text_ERROR_WEIGHT'))); 
        handleChangeArticleWeight(0);  
      }
    };  
    if (configPrinter.printerticket_communicationtype && configPrinter.printerticket_communicationtype === "SOCKET") {
      let isMounted = true;
      // Crear la conexión WebSocket
      const socket = new WebSocket('ws://localhost:8080');
      // Manejar la apertura de la conexión WebSocket
      socket.addEventListener('open', () => { 
        socket.send(JSON.stringify({ type: 'read-scale', content: configPrinter }));
      });
      // Manejar los mensajes recibidos
      socket.addEventListener('message', (event) => {
        if (!isMounted) return;
        try {
          const response = JSON.parse(event.data);
          if (response.status === 'success') { 
            handleChangeArticleWeight(response.message);  
          } else {
            dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));
          }
        } catch (error) { 
          dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));
        }
      }); 
      // Manejar errores en el WebSocket
      socket.addEventListener('error', (error) => { 
        if (isMounted) {
          dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));
        }
      }); 
      // Manejar el cierre de la conexión WebSocket
      socket.addEventListener('close', (event) => { 
        if (!event.wasClean) { 
          if (isMounted) {
            dispatch(warningErrorAction(t('Text_ERROR_WEIGHT')));
          }
        }
      });
      // Limpiar el WebSocket cuando el componente se desmonte
      return () => {
        isMounted = false;
        if (socket.readyState === WebSocket.OPEN) {
          socket.close();
        }
      }; 
    } else {
      // Si no es comunicación por socket, realizar la lógica fetch
      fetchData();
    } 
  }; 

  const handleChangeArticleWeight = (value) => {
    let newValue= value
    if (isNaN(newValue)){
      newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    }
    setEditRowArticles(editRowArticles => ({
      ...editRowArticles,
      firstweight: newValue
    })); 
    setForm(form => ({
     ...form,
     totalstartweight:newValue
    }));
  };

  const handleSubmitDataEditTickets = () => {
    const errors = validateTickets();
    setErrorsForm(errors);
    if ((Object.keys(errors).length === 0) 
        && form.vehiclestore_licenseplate!=='' 
        && form.rel_trans_tradename!=='' 
        && form.totalstartweight!==0
        && form.ori_tras_street!==""
        && editRowArticles.article_id!==0
        && editRowArticles.article_code!==""
        && form.ori_tras_tradename!==''   ) {

      const fetchDataPrintTicket = async (dataToPrint) => {
        let isMounted = true; 
        let respondeData={
          "headerPrinter":headerPrinter,
          "numberoftickets":numberoftickets,
          "printerticket_type":printerticket_type,
          "printerticket_interface":printerticket_interface,
          "printerticket_driver":printerticket_driver,
          "printerticket_operatingsystem":printerticket_operatingsystem,
          "start_date_ticket":dataToPrint.start_date_ticket,
          "numberticket":(dataToPrint.numberticket.toString() || ''),
          "ori_tras_tradename": (dataToPrint.ori_tras_tradename || '') ,
          "rel_trans_tradename": (dataToPrint.rel_trans_tradename || ''),
          "vehiclestore_licenseplate":(dataToPrint.vehiclestore_licenseplate || ''), 
          "ori_tras_street":(dataToPrint.ori_tras_street || ''), 
          "linestickets": [],
          "linesticketsWithOutWeight":[]
        } 
        dataToPrint.linestickets.forEach((line) => {
          let formattedFirstWeight = formatNumber(line.firstweight);
          let formattedSecondWeight = line.secondweight!==0?formatNumber(line.secondweight):"";
          let formattedNetoWeight = line.secondweight!==0?formatNumber(Math.abs(line.firstweight - line.secondweight)):"";
          let lineToAdd={
            "article_code":(line.article_code || ''),
            "article_namees":(line.article_namees || ''),
            "start_date_ticket":line.start_date_ticket,
            "end_date_ticket":line.end_date_ticket,
            "firstweight":formattedFirstWeight,
            "secondweight": formattedSecondWeight,
            "totalweight": formattedNetoWeight
          }
          respondeData.linestickets.push(lineToAdd); 
        });
        dataToPrint.linesticketsWithOutWeight.forEach((line) => {
          let formattedFirstWeight = formatNumberWithOutDecimal(line.firstweight);
          let formattedSecondWeight = line.estimatedprice!==0?formatNumberEURO(line.estimatedprice):"";
          let formattedNetoWeight = line.firstweight!==0 && line.estimatedprice!==0?formatNumberEURO(Math.abs(line.firstweight * line.estimatedprice)):"";
          let lineToAdd={
            "article_code":(line.article_code.substring(0, 33) || ''),
            "article_namees":(line.article_namees.substring(0, 33) || ''),
            "firstweight":formattedFirstWeight,
            "secondweight": formattedSecondWeight,
            "totalweight":formattedNetoWeight
          }
          respondeData.linesticketsWithOutWeight.push(lineToAdd); 
        }); 
        if (printerticket_communicationtype && printerticket_communicationtype==="SOCKET"){
          // WebSocket connection
          const socket = new WebSocket('ws://localhost:8080'); 
          socket.addEventListener('open', () => {
            socket.send(JSON.stringify({ type: 'print-ticket', content: respondeData }));
          }); 
          socket.addEventListener('message', (event) => {
            try {
              const response = JSON.parse(event.data);
              if (response.status === 'success') {
                dispatch(successErrorAction(t('Text_PRINTER_SEND')));
              } else {
                if (isMounted) {
                  dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
                }
              }
            } catch (error) {
              if (isMounted) {
                dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
              }
            }
          }); 
          socket.addEventListener('error', (error) => { 
            if (isMounted) {
              dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
            }
          }); 
          socket.addEventListener('close', (event) => { 
            if (!event.wasClean) {
              if (isMounted) {
                dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
              }
            }
          }); 
        } else{
          try {
            const getData = await helpHttp().sendTickets(ENDPOINTPRINTER, `api/print-ticket`, { respondeData });
            if (getData.message === "OK") {
              dispatch(successErrorAction(t('Text_PRINTER_SEND')));
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
              }
            }
          } catch (error) { 
            if (isMounted) {
              dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
            }
          } 
        }  
        return () => { isMounted = false;};
      }; 

      const fetchData = async () => {
        let isMounted = true;
        try {
          const getData = (form.id === undefined || form.id === "" || form.id === "0" || form.id === 0)
            && await helpHttp().post(ENDPOINT, `tickets/saveticket`, token,form);
          if (getData.status === "OK") {
            setRefeshData(true);
            dispatch(successErrorAction(t('Text_SaveData'))); 
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };

      const fetchData2 = async () => {
        let isMounted = true;
        try {
          const getData = (form.id === undefined || form.id === "" || form.id === "0" || form.id === 0)
            && await helpHttp().post(ENDPOINT, `tickets/saveticketnotcheckpma`, token,form);
          if (getData.status === "OK") {
            if (getData.response ){
              fetchDataPrintTicket(getData.response);
            }
            setRefeshData(true);
            dispatch(successErrorAction(t('Text_SaveData')));
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
      if(unlockDataChangeState && editRowArticles.firstweight<form.totalendweight){
        dispatch(warningErrorAction(t('Text_FieldTtotalIsless'))); 
      }else{
        let newArticleToAdd={
          "id": 0,
          "indexrowarticle":0, 
          "tickets_id":0,
          "article_id":editRowArticles.article_id,
          "article_code": editRowArticles.article_code,
          "article_namees": editRowArticles.article_namees,
          "article_nameen": editRowArticles.article_nameen,
          "start_date_ticket": "",
          "end_date_ticket": "",
          "firstweight":editRowArticles.firstweight,
          "secondweight":!unlockDataChangeState?0:editRowArticles.secondweight,
          "estimatedprice": editRowArticles.estimatedprice ,
          "discount": editRowArticles.discount ,
          "tax": editRowArticles.tax ,
          "taxincluded": editRowArticles.taxincluded,
          "ticketweight":editRowArticles.ticketweight,
          "formdi_id":editRowArticles.formdi_id,
          "ownformdi_id":editRowArticles.ownformdi_id
        }
        form.linestickets.push(newArticleToAdd);

        if(!unlockDataChangeState){
          setForm(form => ({
            ...form,
            totalendweight:0,
            signature_dispatched_by:isUserdefault===1?signFrancisco: isUserdefault===2?signGonzalo:isUserdefault===3?signGuillermo:signJorge
          }));
          fetchData();
        }else{
          setForm(form => ({
            ...form,
            totalendweight:form.totalendweight,
            signature_dispatched_by:isUserdefault===1?signFrancisco: isUserdefault===2?signGonzalo:isUserdefault===3?signGuillermo:signJorge
          }));
          fetchData2();
        }
        form.linestickets.splice(1);
        beginDataEditTickets();
      }
      
    } else {
      if (errors.vehiclestore_licenseplate){
        dispatch(warningErrorAction(t(errors.vehiclestore_licenseplate)));
      }else{
        if (errors.rel_trans_tradename){
          dispatch(warningErrorAction(t(errors.rel_trans_tradename)));
        }else{
          if (errors.ori_tras_tradename){
            dispatch(warningErrorAction(t(errors.ori_tras_tradename)));
          }else{
            if(errors.ori_tras_street){
              dispatch(warningErrorAction(t(errors.ori_tras_street)));
            } else{
              if (errors.linestickets){
                dispatch(warningErrorAction(t(errors.linestickets)));
              }else{
                if (errors.totalstartweight){
                  dispatch(warningErrorAction(t(errors.totalstartweight)));
                }else{
                  dispatch(warningErrorAction(t('Text_IncorrectData')));
                } 
              }
            } 
          }
        }   
      }
    }
  };
                
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentRelTrans = async () => {
      try { 
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getstorebynumberdocument/`,token,form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim());
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==form.rel_trans_store_id){
            fetchStoreNumberDocumentRelTrans();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchStoreNumberDocumentRelTrans = async () => {
      try { 
          const getData = await helpHttp().get5(ENDPOINT,`ticketsgetdata/getstorebynumberdocumentpriority`,token,form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim(),6);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                  setForm(form => ({
                      ...form,
                      rel_trans_store_id: getData.rel_trans_store_id,
                      rel_trans_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      rel_trans_documentid: getData.rel_trans_documentid,
                      rel_trans_documnettype_name: getData.rel_trans_documnettype_name,
                      rel_trans_tradename: getData.rel_trans_tradename,
                      rel_trans_name: getData.rel_trans_name,
                      rel_trans_grnp: getData.rel_trans_grnp,
                      rel_trans_contacperson: getData.rel_trans_contacperson,
                      rel_trans_roadtype_id: (getData.rel_trans_roadtype_id===undefined 
                                            || getData.rel_trans_roadtype_id===null  
                                            || getData.rel_trans_roadtype_id===""
                                            || getData.rel_trans_roadtype_id==="0"
                                            || getData.rel_trans_roadtype_id===0)?
                                            0:getData.rel_trans_roadtype_id,
                      rel_trans_street: getData.rel_trans_street,
                      rel_trans_country_id: (getData.rel_trans_country_id===undefined 
                                            || getData.rel_trans_country_id===null  
                                            || getData.rel_trans_country_id===""
                                            || getData.rel_trans_country_id==="0"
                                            || getData.rel_trans_country_id===0)?
                                            Number(DEFAULTCOUNTRY):getData.rel_trans_country_id,
                      rel_trans_province_id: (getData.rel_trans_province_id===undefined 
                                            || getData.rel_trans_province_id===null  
                                            || getData.rel_trans_province_id===""
                                            || getData.rel_trans_province_id==="0"
                                            || getData.rel_trans_province_id===0)?
                                            Number(DEFAULTPROVINCE):getData.rel_trans_province_id,
                      rel_trans_postalcode_id: (getData.rel_trans_postalcode_id===undefined 
                                                || getData.rel_trans_postalcode_id===null  
                                                || getData.rel_trans_postalcode_id===""
                                                || getData.rel_trans_postalcode_id==="0"
                                                || getData.rel_trans_postalcode_id===0)?
                                                "":getData.rel_trans_postalcode_id 
                  }));
              }  
          }         
        } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
    };
    if (form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim()!==""){
      fetchExistNumberDocumentRelTrans();
    } 
    return () => { isMounted = false };
}, [ENDPOINT,t,token,dispatch,form.rel_trans_store_id,form.rel_trans_documentid,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentOriTras = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getstorebynumberdocument/`,token,form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim());
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==form.ori_tras_documentid){
            fetchStoreNumberDocumentOriTras();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentOriTras = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`ticketsgetdata/getstorebynumberdocumentpriority`,token,form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim(),2);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!=="" && getData.rel_trans_store_id!==form.ori_tras_store_id){
                  setForm(form => ({
                      ...form,
                      ori_tras_store_id: getData.rel_trans_store_id,
                      ori_tras_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      ori_tras_documnettype_name: getData.rel_trans_documnettype_name,
                      ori_tras_documentid: getData.rel_trans_documentid,
                      ori_tras_tradename: getData.rel_trans_tradename,
                      ori_tras_name: getData.rel_trans_name,
                      ori_tras_contacperson: getData.rel_trans_contacperson,
                      ori_tras_email: getData.rel_trans_email
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (form.ori_tras_documentid && form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim()!==""){
      fetchExistNumberDocumentOriTras();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,form.ori_tras_documentid,form.ori_tras_tradename,form.ori_tras_store_id,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistLicensePlate = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getexistvehicleylicenseplate/`,token,form.vehiclestore_licenseplate.replace(/[.\-/\\]/g, "").trim());
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0){
            fetchLicensePlate();
          }else{
            setForm(setForm => ({
              ...form,
              vehiclestore_id:0,
              vehiclestore_tara: 0,
              vehiclestore_pma:  0,
              vehiclestore_checkpma: false,
              vehiclestore_defaultdriver: "",
              vehiclestore_store_id:0,
              totalendweight:0
            }));
            setEditRowArticles(editRowArticles => ({
              ...editRowArticles,
              secondweight:0,
              formdi_id:0,
              ownformdi_id:0
            }));
            setUnlockData(false);
            setUnlockDataChangeState(false);
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchLicensePlate = async () => {
      try {
          const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/getvehiclebylicenseplate/`,token,form.vehiclestore_licenseplate.replace(/[.\-/\\]/g, "").trim());
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setForm(form => ({
                  ...form,
                  vehiclestore_id: getData.id,
                  vehiclestore_tara: getData.tara,
                  vehiclestore_pma:  getData.pma,
                  vehiclestore_checkpma: getData.checkpma,
                  vehiclestore_defaultdriver: getData.defaultdriver,
                  vehiclestore_store_id: (getData.store_id===undefined 
                                        || getData.store_id===null  
                                        || getData.store_id===""
                                        || getData.store_id==="0"
                                        || getData.store_id===0)?
                                        0:getData.store_id,
                  totalendweight:getData.checkpma?0:getData.tara
              }));
              if (!getData.checkpma){
                setEditRowArticles(editRowArticles => ({
                  ...editRowArticles,
                  secondweight:0,
                  formdi_id:0,
                  ownformdi_id:0
                }));
                setUnlockData(false); 
                setUnlockDataChangeState(false);
              }else{
                setEditRowArticles(editRowArticles => ({
                  ...editRowArticles,
                  secondweight:getData.tara,
                  formdi_id:0,
                  ownformdi_id:0
                }));
                setUnlockData(true);
                setUnlockDataChangeState(true);
              }
              if (form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="" ){
                if (form.rel_trans_tradename===undefined || form.rel_trans_tradename===null || form.rel_trans_tradename===""){
                  setForm(form => ({
                    ...form,
                    rel_trans_documentid: getData.store_documentid 
                  }));
                }
              } 
              setForm(form => ({
                ...form,
              }))
            }else{ 
              setUnlockData(false); 
              setUnlockDataChangeState(false); 
            }
          }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (form.vehiclestore_licenseplate && form.vehiclestore_licenseplate.replace(/[.\-/\\]/g, "").trim()!==""){
      fetchExistLicensePlate();
    } 
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,form,form.vehiclestore_licenseplate,form.rel_trans_documentid,form.rel_trans_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setForm,form.vehiclestore_checkpma,form.linestickets]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsgetdata/simpleprovincesbycountrybyname/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalTicketsSettings){
      fetchDataProvince();
    }

    return () => { isMounted = false };     
  }, [ENDPOINT,t,token,dispatch,i18n,showModalTicketsSettings,DEFAULTCOUNTRY]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = form.ori_tras_province_id!==""
            ? await helpHttp().get2(ENDPOINT,`ticketsgetdata/simplepostalcodesbyprovincebyname/`,token,form.ori_tras_province_id)
            : await helpHttp().get2(ENDPOINT,`ticketsgetdata/simplepostalcodesbycountry/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPostalCodeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalTicketsSettings){
      fetchDataPostalCode(); 
    }
    if (form.id === "") { 
      setForm(form => ({
        ...form,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        isfinished:false,
        rel_trans_country_id: Number(DEFAULTCOUNTRY),
        rel_trans_province_id:  Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id: 0,
        rel_trans_grnp:"",
        ori_tras_country_id: Number(DEFAULTCOUNTRY),
        ori_tras_province_id: Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id: 0,
        ori_tras_email:"",
        signature_dispatched_by:isUserdefault===1?signFrancisco: isUserdefault===2?signGonzalo:isUserdefault===3?signGuillermo:signJorge,
        linestickets: [] ,
        linesticketsWithOutWeight:[]
      }));
    }
    return () => { isMounted = false };  
  }, [ENDPOINT,t,token,dispatch,i18n,showModalTicketsSettings,form.ori_tras_province_id,DEFAULTCOUNTRY,DEFAULTPROVINCE,isUserdefault,form.id,defaultStore.id,useraccount_id]);
 
  useEffect(() => {
    let isMounted = true; 
    const fetchDataVehicles = async () => {
      try {
        const getData = await helpHttp().get8(ENDPOINT,`ticketsdetails/getlistvehiclesticketswithoutfilter`,token,
          (form.vehiclestore_licenseplate!==undefined && form.vehiclestore_licenseplate!==null && form.vehiclestore_licenseplate.replace(/[.\-/\\]/g, "").trim()!=="")?form.vehiclestore_licenseplate.replace(/[.\-/\\]/g, "").trim():"-1",
          (form.rel_trans_documentid!==undefined && form.rel_trans_documentid!==null && form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim()!=="")?form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim():"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setVehiclesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    
    if (showModalTicketsSettings && ((form.vehiclestore_licenseplate && form.vehiclestore_licenseplate.replace(/[.\-/\\]/g, "").trim()!=="") || (form.rel_trans_documentid && form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim()!==""))) { 
        fetchDataVehicles();
    }else{
      setVehiclesToSelect(initialVehicleToSelect);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,form.vehiclestore_licenseplate,showModalTicketsSettings,form.rel_trans_documentid]);
  
  useEffect(() => {
    let isMounted = true; 
    const fetchDataDocumentId = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsdetails/getlistdocumentidticketswithoutfilter/`,token,
          (form.rel_trans_documentid!==undefined && form.rel_trans_documentid!==null && form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim()!=="")?form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim():"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDocumentIdToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    
    if (showModalTicketsSettings && form.rel_trans_documentid && form.rel_trans_documentid.replace(/[.\-/\\]/g, "").trim()!=="") { 
      fetchDataDocumentId();
    }else{
      setDocumentIdToSelect(initialDocumentIdToSelect);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,showModalTicketsSettings,form.rel_trans_documentid]);

  useEffect(() => {
    let isMounted = true; 
    const fetchDataDocumentIdOri = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsdetails/getlistdocumentidticketswithoutfilter/`,token,
          (form.ori_tras_documentid!==undefined && form.ori_tras_documentid!==null && form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim()!=="")?form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim():"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDocumentIdOriToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    
    if (showModalTicketsSettings && form.ori_tras_documentid && form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim()!=="") { 
      fetchDataDocumentIdOri();
    }else{
      setDocumentIdOriToSelect(initialDocumentIdToSelect);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,showModalTicketsSettings,form.ori_tras_documentid]);

  useEffect(() => {
    let isMounted = true; 
    const fetchDataTradename = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsdetails/getlisttradenameticketswithoutfilter/`,token,
          (form.rel_trans_tradename!==undefined && form.rel_trans_tradename!==null && form.rel_trans_tradename.replace(/[.\-/\\]/g, "").trim()!=="")?form.rel_trans_tradename.replace(/[.\-/\\]/g, ""):"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTradenameToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    
    if (showModalTicketsSettings && form.rel_trans_tradename && form.rel_trans_tradename.replace(/[.\-/\\]/g, "").trim()!=="") { 
      fetchDataTradename();
    }else{
      setTradenameToSelect(initialDocumentIdToSelect);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,showModalTicketsSettings,form.rel_trans_tradename]);

  useEffect(() => {
    let isMounted = true; 
    const fetchDataTradenameOri = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsdetails/getlisttradenameticketswithoutfilter/`,token,
          (form.ori_tras_tradename!==undefined && form.ori_tras_tradename!==null && form.ori_tras_tradename.replace(/[.\-/\\]/g, "").trim()!=="")?form.ori_tras_tradename.replace(/[.\-/\\]/g, "").trim():"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTradenameOriToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    
    if (showModalTicketsSettings && form.ori_tras_tradename && form.ori_tras_tradename.replace(/[.\-/\\]/g, "").trim()!=="") { 
      fetchDataTradenameOri();
    }else{
      setTradenameOriToSelect(initialWorkPlaceToSelect);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,showModalTicketsSettings,form.ori_tras_tradename]);

  useEffect(() => {
    let isMounted = true; 
    const fetchDataWorkPlace = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`ticketsdetails/getlistworkplacetickets/`,token,
          (form.ori_tras_documentid!==undefined && form.ori_tras_documentid!==null && form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim()!=="")?form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim():"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setWorkPlaceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    
    if (showModalTicketsSettings && form.ori_tras_documentid && form.ori_tras_documentid.replace(/[.\-/\\]/g, "").trim()!=="") { 
      fetchDataWorkPlace();
    }else{
      setWorkPlaceToSelect(initialWorkPlaceToSelect);
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,showModalTicketsSettings,form.ori_tras_documentid]);

  return (
    <div>
      {showModalTicketsSettings && (
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff',width: '1810px'}}>
            <form className="createtickets" onSubmit={handleCloseDataEditTickets}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className="card border-info shadow">
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">
                            <h1 className="h1NewStyle mb-2">
                              <i className="fas fa-solid fa-weight text-primary"></i> {t('Text_TICKETS_TITLE')}
                            </h1>
                            <div>
                              <button type="button" className="buttonBack" onClick={handleCloseDataEditTickets}>
                                {t('Button_Exit')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>

              <div className="row">
                <div className="col-xl-12 mb-4"> 
                  <div className="card border-info shadow">
                    <div className="card-body">
                      <div className="row justify-content-center">
                        {/* SELECT */}
                        <div className="col-6 mb-2">
                          <div className="card border-info shadow">
                            <div className="card-header 2 bg-primary text-white text-center text-bold">
                              <h3><i className="fas fa-duotone fa-truck text-warning"></i> {t('Text_TICKETS_TITLE')}</h3>
                            </div>
                            <div className="card-body">
                              <div className="container">
                                <div className="row p-0"> 
                                  <div className="form-group">  
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_LICENSEPLATE').toUpperCase()} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                      <TextField
                                        id="vehiclestore_licenseplate"
                                        name="vehiclestore_licenseplate"
                                        value={form.vehiclestore_licenseplate}
                                        onChange={handleSearchVehicleFrom}
                                        onKeyDown={handleKeyDownVehicleFrom}
                                        label={errorsForm.vehiclestore_licenseplateFrom!=null?t(errorsForm.vehiclestore_licenseplateFrom):t('Text_TICKETS_LICENSEPLATE')}
                                        placeholder={t('Text_TICKETS_LICENSEPLATE')}
                                        variant="outlined"
                                        inputProps={{ style: {width: i18n.language==='es'?125:115,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      />
                                      {/* Desplegable dinámico */}
                                      {filteredVehicle.length > 0 && (
                                        <div
                                          className="dropdown-class"
                                          style={{
                                            position: "absolute",
                                            left:'0px',
                                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                                            borderRadius: "4px",
                                            marginTop: "4px",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                            width: "100%",
                                            fontSize: '18px',
                                            backgroundColor: "#f8f9fa", // Fondo gris claro
                                            zIndex: 1000,
                                          }}
                                        >
                                          {filteredVehicle.map((vehicle, index) => (
                                            <MenuItem
                                                key={vehicle.id}
                                                onClick={() => handleSelectVehicleFrom(vehicle)}
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "8px",
                                                    borderBottom: "1px solid #eee",
                                                    fontSize: '18px',
                                                    backgroundColor:
                                                    index === highlightedIndexVehicle ? "#4e73df" : "transparent",
                                                    color: index === highlightedIndexVehicle ? "white" : "black",
                                                }}
                                            >
                                              {vehicle.vehiclestore_licenseplate}
                                            </MenuItem>
                                          ))}
                                        </div>
                                      )}
                                    </span> 
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b"}} onClick={deleteDataVehicles}>
                                        <DeleteIcon style={{fontSize: 24}}/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                      <IconButton  size="small"  style={{color:"#4e73df",}} onClick={searchVehicles}>
                                        <SearchIcon style={{fontSize: 24}}/>
                                      </IconButton>
                                    }
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_CIF_TRANS_SHORT').toUpperCase()} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                      <TextField
                                        id="rel_trans_documentid"
                                        name="rel_trans_documentid"
                                        value={form.rel_trans_documentid}
                                        onChange={handleSearchDocumentId}
                                        onKeyDown={handleKeyDownDocumentId}
                                        label={errorsForm.rel_trans_documentid!=null?t(errorsForm.rel_trans_documentid):t('Text_TICKETS_CIF_TRANS_SHORT')}
                                        placeholder={t('Text_TICKETS_CIF_TRANS_SHORT')}
                                        variant="outlined"
                                        inputProps={{ style: {width: i18n.language==='es'?135:124,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      />
                                      {/* Desplegable dinámico */}
                                      {filteredDocumentId.length > 0 && (
                                        <div
                                          className="dropdown-class"
                                          style={{
                                            position: "absolute",
                                            left:'0px',
                                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                                            borderRadius: "4px",
                                            marginTop: "4px",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                            width: "350%",
                                            fontSize: '18px',
                                            backgroundColor: "#f8f9fa", // Fondo gris claro
                                            zIndex: 1000,
                                          }}
                                        >
                                          {filteredDocumentId.map((store, index) => (
                                            <MenuItem
                                                key={store.id}
                                                onClick={() => handleSelectDocumentId(store)}
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "8px",
                                                    borderBottom: "1px solid #eee",
                                                    fontSize: '18px',
                                                    backgroundColor:
                                                    index === highlightedIndexDocumentId ? "#4e73df" : "transparent",
                                                    color: index === highlightedIndexDocumentId ? "white" : "black",
                                                }}
                                            >
                                              {store.documentId} - {store.tradename}
                                            </MenuItem>
                                          ))}
                                        </div>
                                      )}
                                    </span>  
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreRelTrans}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                      <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreRelTrans}>
                                        <SearchIcon/>
                                      </IconButton>
                                    } 
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                      <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_TRADENAME_TRANS').toUpperCase()} :</label> 
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                        <TextField
                                          id="rel_trans_tradename"
                                          name="rel_trans_tradename"
                                          value={form.rel_trans_tradename}
                                          onChange={handleSearchTradename}
                                          onKeyDown={handleKeyDownTradename}
                                          label={errorsForm.rel_trans_tradename!=null?t(errorsForm.rel_trans_tradename):t('Text_TICKETS_TRADENAME_TRANS')}
                                          placeholder={t('Text_TICKETS_TRADENAME_TRANS')}
                                          variant="standard"
                                          inputProps={{ style: {width: i18n.language==='es'?628:642,  fontSize: '18px', }, maxLength: 100 }}
                                          InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        />
                                        {/* Desplegable dinámico */}
                                        {filteredTradename.length > 0 && (
                                          <div
                                            className="dropdown-class"
                                            style={{
                                              position: "absolute",
                                              left:'0px',
                                              border: "2px solid #4e73df", // Borde azul (#4e73df)
                                              borderRadius: "4px",
                                              marginTop: "4px",
                                              maxHeight: "200px",
                                              overflowY: "auto",
                                              width: "150%",
                                              fontSize: '18px',
                                              backgroundColor: "#f8f9fa", // Fondo gris claro
                                              zIndex: 1000,
                                            }}
                                          >
                                            {filteredTradename.map((store, index) => (
                                              <MenuItem
                                                  key={store.id}
                                                  onClick={() => handleSelectTradeName(store)}
                                                  style={{
                                                      cursor: "pointer",
                                                      padding: "8px",
                                                      borderBottom: "1px solid #eee",
                                                      fontSize: '18px',
                                                      backgroundColor:
                                                      index === highlightedIndexTradename ? "#4e73df" : "transparent",
                                                      color: index === highlightedIndexTradename ? "white" : "black",
                                                  }}
                                              >
                                                {store.tradename} - {store.documentId}
                                              </MenuItem>
                                            ))}
                                          </div>
                                        )}
                                      </span>   
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                      <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_GRNP_TRANS').toUpperCase()} :</label> 
                                      &nbsp;&nbsp;&nbsp;&nbsp; 
                                      <TextField
                                        id="rel_trans_grnp" 
                                        name="rel_trans_grnp"
                                        value={form.rel_trans_grnp} 
                                        inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 50 }}
                                        style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        label={errorsForm.rel_trans_grnp!=null?t(errorsForm.rel_trans_grnp):t('Text_TICKETS_GRNP_TRANS')} 
                                        placeholder={t('Text_TICKETS_GRNP_TRANS')}
                                        variant="standard"
                                        onBlur={handleBlurTickets}
                                        onChange={handleChangeTickets}
                                      />
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                      <button className="btn btn-danger btn-lg " type="button"  onClick={handleAddTara}>{t('Button_Add_TARA')}</button><> </>  
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <Typography style={{color:"#067330", fontSize: 20}} >  
                                      {t('Text_TICKETS_TRADENAME_STORE')} 
                                  </Typography>
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="row p-0"> 
                                  <div className="form-group">  
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_CIF_TRANS_SHORT').toUpperCase()} :</label>  
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                      <TextField
                                        id="ori_tras_documentid"
                                        name="ori_tras_documentid"
                                        value={form.ori_tras_documentid}
                                        onChange={handleSearchDocumentIdOri}
                                        onKeyDown={handleKeyDownDocumentIdOri}
                                        label={errorsForm.ori_tras_documentid!=null?t(errorsForm.ori_tras_documentid):t('Text_TICKETS_CIF_TRANS_SHORT')}
                                        placeholder={t('Text_TICKETS_CIF_TRANS_SHORT')}
                                        variant="outlined"
                                        inputProps={{ style: {width: i18n.language==='es'?150:150,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      />
                                      {/* Desplegable dinámico */}
                                      {filteredDocumentIdOri.length > 0 && (
                                        <div
                                          className="dropdown-class"
                                          style={{
                                            position: "absolute",
                                            left:'0px',
                                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                                            borderRadius: "4px",
                                            marginTop: "4px",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                            width: "350%",
                                            fontSize: '18px',
                                            backgroundColor: "#f8f9fa", // Fondo gris claro
                                            zIndex: 1000,
                                          }}
                                        >
                                          {filteredDocumentIdOri.map((store, index) => (
                                            <MenuItem
                                                key={store.id}
                                                onClick={() => handleSelectDocumentIdOri(store)}
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "8px",
                                                    borderBottom: "1px solid #eee",
                                                    fontSize: '18px',
                                                    backgroundColor:
                                                    index === highlightedIndexDocumentIdOri ? "#4e73df" : "transparent",
                                                    color: index === highlightedIndexDocumentIdOri ? "white" : "black",
                                                }}
                                            >
                                              {store.documentId} - {store.tradename}
                                            </MenuItem>
                                          ))}
                                        </div>
                                      )}
                                    </span>    
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b"}} onClick={deleteDataStoreOriTras}>
                                      <DeleteIcon style={{fontSize: 24}}/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                      <IconButton  size="small"  style={{color:"#4e73df"}} onClick={searchStoreOriTras}>
                                        <SearchIcon style={{fontSize: 24}}/>
                                      </IconButton>
                                    } 
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_email').toUpperCase()} :</label>   
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="ori_tras_email" 
                                      name="ori_tras_email"
                                      value={form.ori_tras_email}
                                      inputProps={{ style: {width: i18n.language==='es'?272:286,  fontSize: '18px', }, maxLength: 50 }}
                                      style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsForm.ori_tras_email!=null?t(errorsForm.ori_tras_email):t('Placeholder_TICKETS_EMAIL_STORE')}
                                      placeholder={t('Placeholder_TICKETS_EMAIL_STORE')}
                                      variant="standard"
                                      onBlur={handleBlurTickets}
                                      onChange={handleChangeTickets}
                                    />  
                                  </div>
                                </div> 
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_NAME_STORE').toUpperCase()} :</label>   
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                      <TextField
                                        id="ori_tras_tradename"
                                        name="ori_tras_tradename"
                                        value={form.ori_tras_tradename}
                                        onChange={handleSearchTradenameOri}
                                        onKeyDown={handleKeyDownTradenameOri}
                                        label={errorsForm.ori_tras_tradename!=null?t(errorsForm.ori_tras_tradename):t('Text_TICKETS_NAME_STORE')}
                                        placeholder={t('Text_TICKETS_NAME_STORE')}
                                        variant="standard"
                                        inputProps={{ style: {width: i18n.language==='es'?644:665,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      />
                                      {/* Desplegable dinámico */}
                                      {filteredTradenamedOri.length > 0 && (
                                        <div
                                          className="dropdown-class"
                                          style={{
                                            position: "absolute",
                                            left:'0px',
                                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                                            borderRadius: "4px",
                                            marginTop: "4px",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                            width: "100%",
                                            fontSize: '18px',
                                            backgroundColor: "#f8f9fa", // Fondo gris claro
                                            zIndex: 1000,
                                          }}
                                        >
                                          {filteredTradenamedOri.map((store, index) => (
                                            <MenuItem
                                                key={store.id}
                                                onClick={() => handleSelectTradeNameOri(store)}
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "8px",
                                                    borderBottom: "1px solid #eee",
                                                    fontSize: '18px',
                                                    backgroundColor:
                                                    index === highlightedIndexTradenamedOri ? "#4e73df" : "transparent",
                                                    color: index === highlightedIndexTradenamedOri ? "white" : "black",
                                                }}
                                            >
                                              {store.tradename} - {store.documentId}
                                            </MenuItem>
                                          ))}
                                        </div>
                                      )}
                                    </span>
                                  </div>                              
                                </div>  
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_TRADENAME_STORE_ADDRESS').toUpperCase()} :</label>    
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                      <TextField
                                        id="ori_tras_street"
                                        name="ori_tras_street"
                                        value={form.ori_tras_street}
                                        onChange={handleSearchWorkPlace}
                                        onKeyDown={handleKeyDownWorkPlace}
                                        label={errorsForm.ori_tras_street!=null?t(errorsForm.ori_tras_street):t('Placeholder_TICKETS_TRADENAME_STORE_ADDRESS')}
                                        placeholder={t('Placeholder_TICKETS_TRADENAME_STORE_ADDRESS')}
                                        variant="standard"
                                        inputProps={{ style: {width: i18n.language==='es'?641:547,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      />
                                      {/* Desplegable dinámico */}
                                      {filteredWorkPlace.length > 0 && (
                                        <div
                                          className="dropdown-class"
                                          style={{
                                            position: "absolute",
                                            left:'0px',
                                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                                            borderRadius: "4px",
                                            marginTop: "4px",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                            width: "100%",
                                            fontSize: '18px',
                                            backgroundColor: "#f8f9fa", // Fondo gris claro
                                            zIndex: 1000,
                                          }}
                                        >
                                          {filteredWorkPlace.map((store, index) => (
                                            <MenuItem
                                                key={store.id}
                                                onClick={() => handleSelectWorkPlace(store)}
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "8px",
                                                    borderBottom: "1px solid #eee",
                                                    fontSize: '18px',
                                                    backgroundColor:
                                                    index === highlightedIndexWorkPlace ? "#4e73df" : "transparent",
                                                    color: index === highlightedIndexWorkPlace ? "white" : "black",
                                                }}
                                            >
                                              {store.workplace}
                                            </MenuItem>
                                          ))}
                                        </div>
                                      )}
                                    </span> 
                                    &nbsp;&nbsp;
                                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreAddressOriTras}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    &nbsp;&nbsp;
                                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                      <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreAddress}>
                                        <SearchIcon/>
                                      </IconButton>
                                    } 
                                  </div>                              
                                </div>  
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_TRADENAME_STORE_PROVINCE').toUpperCase()} :</label>   
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="ori_tras_province_id"
                                      name="ori_tras_province_id"
                                      value={ (form.ori_tras_province_id === undefined || form.ori_tras_province_id ===null || form.ori_tras_province_id ===""
                                          || form.ori_tras_province_id ==="0" || form.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE):form.ori_tras_province_id}
                                      inputProps={{ style: {width: i18n.language==='es'?150:150,  fontSize: '18px', }, maxLength: 100 }}
                                      style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                      variant="outlined"
                                      onBlur={handleBlurTickets}
                                      onChange={handleChangeTickets}
                                      helperText={errorsForm.ori_tras_province_id!=null && errorsForm.ori_tras_province_id!==""?t(errorsForm.ori_tras_province_id):""}
                                      select
                                      SelectProps={{
                                          native: true,
                                          value: (form.ori_tras_province_id === undefined || form.ori_tras_province_id ===null || form.ori_tras_province_id ===""
                                              || form.ori_tras_province_id ==="0" || form.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE): form.ori_tras_province_id
                                      }}>
                                      <option key={0} value=""/> 
                                      {provinceToSelect.map((option) => (
                                      <option key={option.id} value={option.id}>
                                          {option.name}
                                      </option>
                                      ))}
                                  </TextField>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_TRADENAME_STORE_POSTALCODE').toUpperCase()} :</label>   
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <TextField
                                    id="ori_tras_postalcode_id"
                                    name="ori_tras_postalcode_id"
                                    value={ (form.ori_tras_postalcode_id === undefined || form.ori_tras_postalcode_id ===null || form.ori_tras_postalcode_id ===""
                                        || form.ori_tras_postalcode_id ==="0" || form.ori_tras_postalcode_id ===0)?"":form.ori_tras_postalcode_id}
                                    inputProps={{ style: {width: i18n.language==='es'?226:252}, maxLength: 100 }}
                                    variant="outlined"
                                    onBlur={handleBlurTickets}
                                    onChange={handleChangeTickets}
                                    helperText={errorsForm.ori_tras_postalcode_id!=null && errorsForm.ori_tras_postalcode_id!==""?t(errorsForm.ori_tras_postalcode_id):""}
                                    select
                                    SelectProps={{
                                    native: true,
                                    value: (form.ori_tras_postalcode_id === undefined || form.ori_tras_postalcode_id ===null || form.ori_tras_postalcode_id ===""
                                        || form.ori_tras_postalcode_id ==="0" || form.ori_tras_postalcode_id ===0)?"": form.ori_tras_postalcode_id
                                    }}>
                                    <option key={0} value=""/>
                                    {postalCodeToSelect.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.postalcodename}
                                    </option>
                                    ))}
                                  </TextField>
                                  </div>
                                </div>
                                <div className="row p-0"> 
                                  <div className="form-group">   
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_TICKETS_TRADENAME_STORE_OTHERS').toUpperCase()} :</label>   
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                      id="observations" 
                                      name="observations"
                                      value={form.observations}
                                      inputProps={{ style: {width: i18n.language==='es'?623:639,fontSize: '18px', }, maxLength: 100 }}
                                      style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsForm.observations!=null?t(errorsForm.observations):t('Placeholder_TICKETS_TRADENAME_STORE_OTHERS')}
                                      placeholder={t('Placeholder_TICKETS_TRADENAME_STORE_OTHERS')}
                                      variant="standard"
                                      onBlur={handleBlurTickets}
                                      onChange={handleChangeTickets}
                                    />  
                                  </div>                              
                                </div>  
                                <TicketsSelectArticle
                                   editRowArticles={editRowArticles}
                                   setEditRowArticles={setEditRowArticles}
                                   form={form}
                                   setForm={setForm}
                                   setErrorsForm={setErrorsForm}
                                   validateTickets={validateTickets}
                                   unlockData={unlockData}
                                   unlockDataChangeState={unlockDataChangeState}
                                   setUnlockDataChangeState={setUnlockDataChangeState}
                                   setShowModalSearchArticles={setShowModalSearchArticles}
                                   configPrinter={configPrinter}
                                   setShowModalSearchDI={setShowModalSearchDI}
                                />
                                {(form.linesticketsWithOutWeight!==undefined && form.linesticketsWithOutWeight.length>0) &&
                                  <TicketsSelectArticleWithOutWeight
                                    dataListTicketsDetails={form.linesticketsWithOutWeight}
                                    form={form}
                                    setForm={setForm}
                                  />
                                }
                                <hr className="sidebar-divider d-none d-md-block"/>                                
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg custom-btn" type="button"  onClick={handleSubmitDataEditTickets}>{t('Button_Add')}</button><> </>
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {articlesButtonsList.map((post, index) => (
                                      <Grid item xs={2} sm={4} md={4} key={index}>
                                        <Item>
                                          <Button variant="contained" style={{color:"#ffffff", background:post.button_color,fontSize: 18}} onClick={()=>selectArticle(post)}>
                                            {i18n.language==='es'?post.button_namees:post.button_nameen}
                                          </Button>
                                        </Item>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* END SELECT */}
                        {/* SCALE */}
                        <div className="col-6 mb-2">
                          <div className="card border-info shadow">
                            <div className="card-header 2 bg-primary text-white text-center text-bold">
                              <h3>{t('Text_TICKETS_SCALES')}</h3>
                            </div>
                            <div className="card-body">
                              <div className="row align-items-center">
                                <div className="col-3 mb-2">
                                </div>
                                <div className="col-5 mb-2">
                                  <NumericFormat 
                                    thousandSeparator={','} 
                                    decimalSeparator={'.'} 
                                    decimalScale= {0}
                                    fixedDecimalScale= {true}
                                    allowNegative= {false}
                                    isAllowed={withValueCap}
                                    id="firstweight" 
                                    name="firstweight"
                                    value={editRowArticles.firstweight}
                                    style={{width:300, color:"red", background:"black",fontSize: 38, textAlign:'right', paddingRight:10}} 
                                    disabled
                                  /> 
                                  </div>
                                  <div className="col-2 mb-2">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <IconButton 
                                      style={{color:"#ffffff", background:"#4e73df"}} 
                                      aria-label="weight vehicles" onClick={openExternalWeigh}>
                                      <ScaleIcon />
                                    </IconButton>
                                </div>
                                <div className="col-2 mb-2">
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <InvoiceTickets 
                            showModalTicketsSettings={showModalTicketsSettings}
                            submitTickets={submitTickets}
                            setSubmitTickets={setSubmitTickets}
                            configPrinter={configPrinter}
                            refeshData={refeshData}
                            setRefeshData={setRefeshData}
                          />
                        </div>
                        {/* END SCALE */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Content Row */}
            </form>
          </Box>
          <br/> 
          <TicketsCreateVehicles
            openEditRowVehicles={openEditRowVehicles}
            setOpenEditRowVehicles={setOpenEditRowVehicles} 
            setForm={setForm}
            form={form}
            setUnlockData={setUnlockData}
            setUnlockDataChangeState={setUnlockDataChangeState}
            setEditRowArticles={setEditRowArticles}
          />
          
          <TicketsSearchStores
            showModalSearchStores={showModalSearchStores}
            setShowModalSearchStores={setShowModalSearchStores}
            selectStores={tetxtSearch}
            setForm={setForm}
          />
          <TicketsSearchStoresAddresses
            showModalSearchStoresAddress={showModalSearchStoresAddress}
            setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
            setForm={setForm}
            titleTable={form.ori_tras_tradename}
            store_id={form.ori_tras_store_id}
            priority={2}
          />
          <TicketsSearchVehicles
            showModalSearchVehicles={showModalSearchVehicles}
            setShowModalSearchVehicles={setShowModalSearchVehicles}
            setForm={setForm}
            form={form}
          /> 
          <TicketsSearchArticles
            showModalSearchArticles={showModalSearchArticles}
            setShowModalSearchArticles={setShowModalSearchArticles}
            setEditRowArticles={setEditRowArticles}
            form={form}
            setForm={setForm}
            LIMITSARTICLESPERWEIGHT={LIMITSARTICLESPERWEIGHT}
          /> 
          <TicketsSearchDI
            showModalSearchDI={showModalSearchDI}
            setShowModalSearchDI={setShowModalSearchDI}
            editRowArticles={editRowArticles}
            setEditRowArticles={setEditRowArticles}
            form={form} 
          />  
        </div> 
      )}
    </div>
  );
};

TicketsEditOrCreate.propTypes = { 
  showModalTicketsSettings: PropTypes.bool.isRequired 
};

export default TicketsEditOrCreate;