import React, {  Suspense,useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading"; 
import PropTypes from 'prop-types'; 
import TableSortingContract from "pages/contracts/filterContract/SortingTablesContract";
import ContractPDF from "pages/contracts/generatePDF/ContractPDF";

const initialFiltersContracts= {
  "idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "useraccountIdFrom":"",
  "useraccountIdTo":"",
  "numberNTrom":"",
  "numberNTTo":"",
  "dateContractFrom":null,
  "dateContractTo":null,
};


const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": "",
  "name": ""
}];

const initialUserAccountToSelect = [{
  "id": "",
  "username":"",
  "firstName": "",
  "lastName1": "",
  "lastName2": "",
  "email": "",
  "aliasuser": ""
}];

const initialStateContract = [{
  "id" : 0,
  "owncontract_id":0,
  "defaultStore_id" : 0,
  "tradename" : "",
  "useraccount_id" : 0,
  "username" :"",
  "defaultformatnumber": 1,
  "number_nt":"",
  "date_contract" : "",
  "opt_tras_store_id" : 0,
  "opt_tras_tradename" : "",
  "opt_tras_shared" : false,
  "sendbyemail_opt_tras" : false,
  
  "ori_tras_store_id" : 0,
  "ori_tras_tradename" : "",
  "ori_tras_shared" : false,
  "sendbyemail_ori_tras" : false,
  
  "aut_tras_store_id" : 0,
  "aut_tras_tradename" : "",
  "aut_tras_shared" : false,
  "sendbyemail_aut_tras" : false,
  
  "dest_tras_store_id" : 0,
  "dest_tras_tradename" : "",
  "dest_tras_shared" : false,
  "sendbyemail_dest_tras" : false,
  
  "aut_dest_store_id" : 0,
  "aut_dest_tradename" : "",
  "aut_dest_shared" : false,
  "sendbyemail_aut_dest" : false,
  
  "sharedStored":"",
  "emailStored":""
    
}];
            
const ContractFilter = ({ applyFiltersContract,setApplyFiltersContract,showModalContractSettings,setShowModalContractSettings}) => {
  const {t,i18n} = useTranslation(['listDataContracts']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_FORMDI = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const [dataContract, setDataContract] = useState(initialStateContract); 
  const [filtersContract, setFiltersContract]= useState(applyFiltersContract);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegContract,setNumTotRegContract]= useState(0);
  const [errorsFiltersContract, setErrorsFiltersContract] = useState({});
  const [inputTextContract, setInputTextContract] = useState("");  
  const [openDeleteRowContract,setOpenDeleteRowContract]= useState(false); 
  const [openEditRowContract,setOpenEditRowContract]= useState(false);
  const [openEditFilterContract,setOpenEditFilterContract]= useState(false);
  const [rowEditContract,setRowEditContract]= useState(initialStateContract[0]);
  const [isLoadingContract, setIsLoadingContract] = useState(true);
  const [isLoadingErrorContract, setIsLoadingErrorContract] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const [viewPDF,setViewPDF]=useState(false);  

  const dispatch = useDispatch();
  const [activeOffsetContract, setActiveOffsetContract]= useState(false);
  const [currencyOffsetContract, setCurrencyOffsetContract] = useState(0);
  const [rangeOffsetContract, setRangeOffsetContract] = useState([{}]);
  const [storesToSelect,setStoresToSelect]= useState(initialStoresToSelect);
  const [userAccountsToSelect,setUserAccountsToSelect]= useState(initialUserAccountToSelect); 

  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalContractSettings) {
        setDataContract(initialStateContract); 
        setFiltersContract(initialFiltersContracts);
        setNumTotRegFilter(0);
        setNumTotRegContract(0);
        setErrorsFiltersContract({});
        setInputTextContract(""); 
        setOpenDeleteRowContract(false); 
        setOpenEditRowContract(false);
        setOpenEditFilterContract(false);
        setRowEditContract(initialStateContract[0]);
        setIsLoadingContract(false);
        setIsLoadingErrorContract(false);
        setIdCodeDelete(DELETE_CODE);
        setViewPDF(false);
        setActiveOffsetContract(false);
        setCurrencyOffsetContract(0);
        setRangeOffsetContract([{}]);
        setStoresToSelect(initialStoresToSelect);
        setUserAccountsToSelect(initialUserAccountToSelect); 
        setShowModalContractSettings(false);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataContract,  
      setFiltersContract,
      setNumTotRegFilter,
      setNumTotRegContract,
      setErrorsFiltersContract,
      setInputTextContract, 
      setOpenDeleteRowContract, 
      setOpenEditRowContract,
      setOpenEditFilterContract,
      setRowEditContract,
      setIsLoadingContract,
      setIsLoadingErrorContract,
      setIdCodeDelete,
      setViewPDF,
      setActiveOffsetContract,
      setCurrencyOffsetContract,
      setRangeOffsetContract,
      setStoresToSelect,
      setUserAccountsToSelect, 
      setShowModalContractSettings,
      showModalContractSettings, 
      navigate,
      DELETE_CODE]
  );

  const closeModalContract = () => {    
    setDataContract(initialStateContract); 
    setFiltersContract(initialFiltersContracts);
    setNumTotRegFilter(0);
    setNumTotRegContract(0);
    setErrorsFiltersContract({});
    setInputTextContract(""); 
    setOpenDeleteRowContract(false); 
    setOpenEditRowContract(false);
    setOpenEditFilterContract(false);
    setRowEditContract(initialStateContract[0]);
    setIsLoadingContract(false);
    setIsLoadingErrorContract(false);
    setIdCodeDelete(DELETE_CODE);
    setViewPDF(false);
    setActiveOffsetContract(false);
    setCurrencyOffsetContract(0);
    setRangeOffsetContract([{}]);
    setStoresToSelect(initialStoresToSelect);
    setUserAccountsToSelect(initialUserAccountToSelect); 
    setShowModalContractSettings(false);
    navigate("/home");
    return () => {};
  };
 
  const openCreateOrEditDataContract = () => {
    setInputTextContract(""); 
    setOpenEditFilterContract(!openEditFilterContract);
    navigate("/contractdetail/0");
  };

   
  const openEditFiltersContract = () => {
      setFiltersContract(applyFiltersContract);
      setOpenEditFilterContract(!openEditFilterContract);
  };

  const handleSubmitFilterEditContract = () => {
    setErrorsFiltersContract(validateFormFilterContract());
    if (Object.keys(errorsFiltersContract).length === 0) {
      setApplyFiltersContract(filtersContract);
      setFiltersContract(initialStateContract);
      setErrorsFiltersContract({}); 
      setOpenEditFilterContract(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersContract = () => {
    setErrorsFiltersContract({});
    setFiltersContract(initialFiltersContracts);
    setApplyFiltersContract(initialFiltersContracts); 
    setOpenEditFilterContract(!openEditFilterContract);
  };

  const handleCancelFiltersContract = () => {
    setErrorsFiltersContract({});
    setFiltersContract(initialFiltersContracts); 
    setOpenEditFilterContract(!openEditFilterContract);
  };
 
  const handleChangeSelectDateContract = (event) => {
    setFiltersContract(filtersContract => ({
      ...filtersContract,
      dateContractFrom: event!==null?new Date(event):null,
      dateContractTo:null
    }));
  };

  const handleBlurSelectDateContractFrom = (e) => {
    handleChangeSelectDateContract(e);
    setErrorsFiltersContract(validateFormFilterContract());
  };

  const handleChangeSelectDateContractTo = (event) => {
    setFiltersContract(filtersContract => ({
      ...filtersContract,
      dateContractTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateContractTo = (e) => {
    handleChangeSelectDateContractTo(e);
    setErrorsFiltersContract(validateFormFilterContract());
  };
    
  const handleChangeFilterEditContract = (e) => {
    const { name, value } = e.target;
    setFiltersContract(filtersContract => ({
      ...filtersContract,
      [name]: value
    }));
  };

  const handleBlurFilterEditContract = (e) => {
    setErrorsFiltersContract(validateFormFilterContract());
    handleChangeFilterEditContract(e);
  };

  const toggleDrawerFilterEditContract = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditContract();
    }
  };

  const validateFormFilterContract = () => {

    let errorsFiltersContract = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersContract.idFrom!=="" & !regexNumber.test(filtersContract.idFrom)) {
      errorsFiltersContract.idFrom = 'Text_TextNoNumber';
    }
    if (filtersContract.idTo!=="" & !regexNumber.test(filtersContract.idTo)) {
      errorsFiltersContract.idTo = 'Text_TextNoNumber';
    }
    if (filtersContract.idFrom!=="" & !errorsFiltersContract.idFrom!==undefined){
      if (filtersContract.idTo!=="" & !errorsFiltersContract.idTo!==undefined){
        if (parseInt(filtersContract.idTo)<parseInt(filtersContract.idFrom)){
          errorsFiltersContract.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filtersContract.defaultStoreIdTo!=="" & filtersContract.defaultStoreIdTo<filtersContract.defaultStoreIdFrom){
      errorsFiltersContract.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersContract.useraccountIdTo!=="" & filtersContract.useraccountIdTo<filtersContract.useraccountIdFrom){
      errorsFiltersContract.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }
  

    if (regexInvalidCharacters.test(filtersContract.numberNTrom)) {
      errorsFiltersContract.numberNTrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersContract.numberNTTo)) {
      errorsFiltersContract.numberNTTo = 'Text_TextNoValid';
    }

    if (filtersContract.numberNTTo!=="" & filtersContract.numberNTTo<filtersContract.numberNTrom){
      errorsFiltersContract.numberNTTo = 'Text_TextGreater_numberNTTo';
    }

    if (filtersContract.dateContractTo!==null & filtersContract.dateContractTo<filtersContract.dateContractFrom){
      errorsFiltersContract.dateContractTo = 'Text_TextGreater_Date';
    }
    return errorsFiltersContract;
  }  

  const filteredDataContract = dataContract.filter((el) => {
    if (inputTextContract === '') {
        return el;
    }
    else {
        let dataTranslate=el.id+el.number_nt;
        return dataTranslate.toLowerCase().includes(inputTextContract);
    }
  })

  let inputHandlerContract = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextContract(lowerCase);
  };

  const handleChangeDeleteRowContract = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowContract = () => {
    setRowEditContract(initialStateContract[0]);
    setIsLoadingErrorContract(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowContract(false);
  };

  const handleCloseDeleteRowFormDI = (e) => {
    if (idCodeDelete !== DELETE_CODE_FORMDI) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditContract.id!=='') {      
        const fetchData = async () => {
          setIsLoadingContract(true);
          try {
            const getData = await helpHttp().del(ENDPOINT,`contract/deletecontract  `,token,rowEditContract);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
                handleCloseDataEditContract();
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingContract(false);
          } 
          if (isMounted) {
            setIsLoadingContract(false);
          } 
        };
        fetchData();
      
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowContract(false);
    setIsLoadingErrorContract(false);
    handleCloseDataEditContract(); 
  };
 
  const handleCloseDataEditContract = () => { 
    setOpenEditRowContract(false);
    setRowEditContract(openEditRowContract?rowEditContract:initialStateContract[0]);
    setIsLoadingContract(false);
    setIsLoadingErrorContract(false);
    toggleDrawerFilterEditContract(false);  
	};
  
  useEffect(() => {
    if (numTotRegContract > Number(LIMITPAGESIZE)) {
      setActiveOffsetContract(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegContract; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetContract(newRangeOffset);
    } else {
      setCurrencyOffsetContract(0);
      setActiveOffsetContract(false);
      setRangeOffsetContract([{}]);
    }
  }, [numTotRegContract, LIMITPAGESIZE]);
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegContract = async () => {
      setIsLoadingContract(true);
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_Store_Master')))
        ? await helpHttp().get(ENDPOINT,`contract/getinformationtablecontract`,token)
        : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_User_Master'))?
            await helpHttp().get2(ENDPOINT,`contract/getinformationtablecontractwithstore/`,token,defaultStore.id):
            await helpHttp().get8(ENDPOINT,`contract/getinformationtablecontractwithstoreanduseraccount`,token,defaultStore.id,useraccount_id))
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataContract(initialStateContract);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegContract(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingContract(false);
      }  
      if (isMounted) {
        setIsLoadingContract(false);
      }
    };

    const fetchnumTotRegFilter = async () => {
      setIsLoadingContract(true);
      try {
        const getData = 
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_Store_Master')))?
        await helpHttp().get3(ENDPOINT,`contract/getcountfiltercontract`,token,"-1","-1",applyFiltersContract):
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_User_Master')))?
        await helpHttp().get3(ENDPOINT,`contract/getcountfiltercontract`,token,"-1",useraccount_id,applyFiltersContract):
        await helpHttp().get3(ENDPOINT,`contract/getcountfiltercontract`,token,defaultStore.id,useraccount_id,applyFiltersContract);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataContract(initialStateContract);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingContract(false);
      }
      if (isMounted) {
        setIsLoadingContract(false);
      }
    }; 
 
    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`contract/storesorderbytradename`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataUserAccount = async () => {
      try {
        const getData =(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_Store_Master')))?
            await helpHttp().get2(ENDPOINT,`contract/useraccountsorderbyusername/`,token,"-1"):
            await helpHttp().get2(ENDPOINT,`contract/useraccountsorderbyusername/`,token,defaultStore.id);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setUserAccountsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataContract = async () => {
      try {
        const getData = 
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_Store_Master')))?
        await helpHttp().post7(ENDPOINT,`contract/getcontract`,token,currencyOffsetContract,LIMITPAGESIZE,"-1","-1",applyFiltersContract):
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_User_Master')))?
        await helpHttp().post7(ENDPOINT,`contract/getcontract`,token,currencyOffsetContract,LIMITPAGESIZE,"-1",useraccount_id,applyFiltersContract):
        await helpHttp().post7(ENDPOINT,`contract/getcontract`,token,defaultStore.id,useraccount_id,applyFiltersContract);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataContract(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    if (showModalContractSettings){
      fetchnumTotRegContract();
      fetchnumTotRegFilter(); 
      fetchDataContract();
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_Store_Master'))){
        fetchDataStore();
      }
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_User_Master'))){
        fetchDataUserAccount();
      } 
    }  
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,LIMITPAGESIZE,applyFiltersContract,showModalContractSettings,currencyOffsetContract,defaultStore.id,useraccount_id ,openDeleteRowContract,setOpenDeleteRowContract,setOpenEditRowContract,openEditRowContract,rowEditContract,setRowEditContract,setIsLoadingContract,defaultStore ]);
     
   return (
    <>
       {showModalContractSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={"hANewStyle mb-2"}>
            {isLoadingErrorContract?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingContract?<CircularProgress color={openDeleteRowContract?"warning":"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_Contracts_Table')}</h1>
              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Contact_Create')) ?
                <div>
                  <button type="button" className="buttonCreate" onClick={openCreateOrEditDataContract}>{t('Button_Create')}</button><> </>
                  <button type="button" className="buttonBack" onClick={closeModalContract}>{t('Button_Back')}</button><> </>
                </div>:
                <div>
                  <button type="button" className="buttonBack" onClick={closeModalContract}>{t('Button_Back')}</button><> </>
                </div>
              }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextContract}
              placeholder={t('Text_CONTRACT_Search')} 
              onChange={inputHandlerContract}
              fullWidth
              label={t('Text_CONTRACT_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFiltersContract}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersContract.idFrom!=="" || applyFiltersContract.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_Store_Master"))&&
                  <>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersContract}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersContract.defaultStoreIdFrom!=="" || applyFiltersContract.defaultStoreIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Store')}</button><> </>
                  </>
                }
                 {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_User_Master"))&&
                  <>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersContract}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersContract.useraccountIdFrom!=="" || applyFiltersContract.useraccountIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_UserAccount')}</button><> </>
                  </>
                }
                <button type="button" className="buttonFilter" onClick={openEditFiltersContract}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersContract.numberNTrom!=="" || applyFiltersContract.numberNTTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_CONTRACT_NT_CONTRACT')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersContract}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersContract.dateContractFrom!==null|| applyFiltersContract.dateContractTo!==null)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Date')}</button><> </>                 
            </h1>
          </div>
          <br/>

          {!isLoadingContract ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingContract
                      dataContract={filteredDataContract} 
                      numTotRegFilter={ numTotRegFilter}
                      titleTable={t('Text_CONTRACT_Table')}
                      isLoadingContract={isLoadingContract}
                      setIsLoadingContract={setIsLoadingContract}
                      setOpenDeleteRowContract={setOpenDeleteRowContract}
                      setRowEditContract={setRowEditContract}
                      showEditButtonContract={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Contact_Edit'))?true:false}
                      showPrintButtonContract={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Contact_Print'))?true:false}
                      showDeleteButtonContract={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Contact_Delete'))?true:false}
                      showStoreContract={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Contact_View_Stores'))?true:false}
                      showUserAccountContract={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_User_Master'))?true:false}
                      numTotRegContract={numTotRegContract}
                      activeOffsetContract={activeOffsetContract}
                      rangeOffsetContract={rangeOffsetContract}
                      currencyOffsetContract={currencyOffsetContract}
                      setCurrencyOffsetContract={setCurrencyOffsetContract} 
                      setViewPDF={setViewPDF} 
                    > 
                    </TableSortingContract>
                </div>             
              </div>
              
              {openEditFilterContract &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditContract(false)}
                 onOpen={toggleDrawerFilterEditContract(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfiltercontract" onSubmit={handleCancelFiltersContract}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                               id="idFrom" 
                               name="idFrom"
                               value={filtersContract.idFrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersContract.idFrom!=null?t(errorsFiltersContract.idFrom):t('Text_Filter_Code_From')}
                               placeholder={t('PlaceHolder_Filter_Code_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditContract}
                               onChange={handleChangeFilterEditContract}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                               id="idTo" 
                               name="idTo"
                               value={filtersContract.idTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersContract.idTo!=null?t(errorsFiltersContract.idTo):t('Text_Filter_Code_To')}
                               placeholder={t('PlaceHolder_Filter_Code_to')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditContract}
                               onChange={handleChangeFilterEditContract}/>
                           </div>
                           {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_Store_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="defaultStoreIdFrom"
                                      name="defaultStoreIdFrom"
                                      value={filtersContract.defaultStoreIdFrom}
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersContract.defaultStoreIdFrom!=null?t(errorsFiltersContract.defaultStoreIdFrom):t('Text_Filter_Store_From')}
                                      placeholder={t('PlaceHolder_Filter_Store_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditContract}
                                      onChange={handleChangeFilterEditContract}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {storesToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                             {option.documentid+" - "+option.tradename}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="defaultStoreIdTo"
                                        name="defaultStoreIdTo"
                                        value={filtersContract.defaultStoreIdTo}  
                                        inputProps={{ style: {width: 382}, maxLength: 100 }}
                                        label={errorsFiltersContract.defaultStoreIdTo!=null?t(errorsFiltersContract.defaultStoreIdTo):t('Text_Filter_Store_To')}
                                        placeholder={t('PlaceHolder_Filter_Store_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditContract}
                                        onChange={handleChangeFilterEditContract}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {storesToSelect.filter(item=>(item.id>=filtersContract.defaultStoreIdFrom)).map(option => (
                                          <option key={option.id} value={option.id}>
                                              {option.documentid+" - "+option.tradename}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           { roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Contacts_User_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="useraccountIdFrom"
                                      name="useraccountIdFrom"
                                      value={filtersContract.useraccountIdFrom}
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersContract.useraccountIdFrom!=null?t(errorsFiltersContract.useraccountIdFrom):t('Text_Filter_UserAccount_From')}
                                      placeholder={t('PlaceHolder_UserAccount_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditContract}
                                      onChange={handleChangeFilterEditContract}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {userAccountsToSelect.map((option) => (
                                          <option key={option.id} value={option.username}>
                                            {option.username+" - "+option.email}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="useraccountIdTo"
                                        name="useraccountIdTo"
                                        value={filtersContract.useraccountIdTo}  
                                        inputProps={{ style: {width: 382}, maxLength: 100 }}
                                        label={errorsFiltersContract.useraccountIdTo!=null?t(errorsFiltersContract.useraccountIdTo):t('Text_Filter_UserAccount_To')}
                                        placeholder={t('PlaceHolder_UserAccount_To')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditContract}
                                        onChange={handleChangeFilterEditContract}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {userAccountsToSelect.filter(item=>(item.username>=filtersContract.useraccountIdFrom)).map(option => (
                                          <option key={option.id} value={option.username}>
                                              {option.username+" - "+option.email}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           <div className="form-group">
                             <TextField
                               id="numberNTrom" 
                               name="numberNTrom"
                               value={filtersContract.numberNTrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersContract.numberNTrom!=null?t(errorsFiltersContract.numberNTrom):t('Text_Filter_NumberNT_From')}
                               placeholder={t('PlaceHolder_Filter_NumberNT_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditContract}
                               onChange={handleChangeFilterEditContract}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                               id="numberNTTo" 
                               name="numberNTTo"
                               value={filtersContract.numberNTTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersContract.numberNTTo!=null?t(errorsFiltersContract.numberNTTo):t('Text_Filter_NumberNT_To')}
                               placeholder={t('PlaceHolder_Filter_NumberNT_To')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditContract}
                               onChange={handleChangeFilterEditContract}/>
                           </div>
                         
                          <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                              <DesktopDatePicker
                                  id="dateContractFrom" 
                                  name="dateContractFrom"
                                  value={ filtersContract.dateContractFrom!== null ? new Date(filtersContract.dateContractFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersContract.dateContractFrom!=null?t(errorsFiltersContract.dateContractFrom):t('PlaceHolder_Filter_DateSheet_From')}
                                  onBlur={handleBlurSelectDateContractFrom}
                                  onChange={handleChangeSelectDateContract}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="dateContractTo" 
                                  name="dateContractTo"
                                  minDate={filtersContract.dateContractFrom!== null ? new Date(filtersContract.dateContractFrom): null}
                                  value={ filtersContract.dateContractTo!== null ? new Date(filtersContract.dateContractTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersContract.dateContractTo!=null?t(errorsFiltersContract.dateContractTo):t('PlaceHolder_Filter_DateSheet_To')}
                                  onBlur={handleBlurSelectDateContractTo}
                                  onChange={handleChangeSelectDateContractTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                          </div>                    
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditContract}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersContract}>{t('Button_Cancel_Filters')}</button>
                        </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
              </div>
              }  
              <ContractPDF 
                rowEditContract={rowEditContract} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />
              <Dialog open={openDeleteRowContract} onClose={handleCancelDeleteRowContract}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditContract.id}  - {rowEditContract.number_nt} - {rowEditContract.opt_tras_tradename} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowContract}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowContract}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowFormDI}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 
              {/* End Content Row */}
          </>:
            <Loading isLoadingData={isLoadingContract}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

ContractFilter.propTypes = {
  applyFiltersContract: PropTypes.object.isRequired,
  setApplyFiltersContract: PropTypes.func.isRequired,
  showModalContractSettings: PropTypes.bool.isRequired,
  setShowModalContractSettings: PropTypes.func.isRequired
};

export default ContractFilter;

