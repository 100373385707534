import React  from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from "react-i18next";
import { Player } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/material'; 
import workerAnimation from 'components/animations/workerAnimation.json';

export const LoadingNews = () => {
const { t } = useTranslation(['listDataListMenu']);
const isLoadingData = useSelector((state) => state.loading.isLoading);
 
  return (
    <Dialog open={isLoadingData}>
      <DialogContent>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Player
            autoplay
            loop
            src={workerAnimation} // Animación local
            style={{ height: '50px', width: '50px' }}
          />
          {t('Text_LOADING')}
        </Box>
      </DialogContent>
    </Dialog>
  );
};