import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setFilters, resetFilters} from 'reducers/filtersPurchaseSignatureSlice';
import { resetPagination } from 'reducers/paginationPurchaseSignatureSlice';
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box';  
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container'; 
import MenuItem  from "@mui/material/MenuItem"; 
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp"; 
import { NumericFormat } from 'react-number-format'; 
import PropTypes from 'prop-types';  
import TableSortingPurchaseDelivery from "pages/purchaseSignature/purchaseSignatureFilter/SortingTablesPurchaseSignature"; 
import PurchaseSignaturePDF from "pages/purchaseSignature/generatePDF/PurchaseSignaturePDF";

const initialFiltersPurchaseSignature= {
  idFrom: "",
  idTo: "",
  defaultStoreIdFrom: "",
  defaultStoreIdTo: "",
  useraccountIdFrom: "",
  useraccountIdTo: "",
  purchasenoteFrom: "",
  purchasenoteTo: "",
  datepurchaseFrom: null,
  datepurchaseTo: null,
  vehiclestore_licenseplateFrom: "",
  vehiclestore_licenseplateTo: "",
  ori_tras_workplaceFrom: "",
  ori_tras_workplaceTo: "",
  rel_trans_store_idFrom: "", 
  rel_trans_store_idTo: "", 
  ori_tras_store_idFrom: "", 
  ori_tras_store_idTo: "", 
  numberDIFROM: "",
  numberDITO: "",
  paymentFromES: "",
  paymentToES: "",
  paymentFromEN: "",
  paymentToEN: "",
};
 
const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": ""
}];

const initialPaymentToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": "",
}];

const initialVehicleToSelect = [{
  "id": "",
  "vehiclestore_licenseplate": "" 
}];

const initialWorkPlaceToSelect = [{
  "id": "",
  "workplace": "" 
}];
 
const initialStatePurchseSignature = [{
  "id" : "",
  "defaultStore_id": 0,
  "tradename": "",
  "useraccount_id": 0,
  "username": "",
  "defaultformatnumber": 1,
  "isPending": true,
  "isFinished" : false,
  "purchasenote":0, 
  "ownnumberpurchase":"", 
  "numberticket":0, 
  "vehiclestore_licenseplate" : "",
  "workplace": "",
  "datepurchase" : null, 
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalpurchase" : 0 ,
  "enabled":"",
  "formDIAssociates": "",
  "payment_id" : 0 ,
  "payment_code": "",
  "payment_namees": "",
  "payment_nameen": ""
}];
            
const PurchaseSignatureFilter = ({ showModalPurchasesSignatureSettings,setShowModalPurchasesSignatureSettings}) => {
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const [dataPurchaseSignature, setDataPurchaseSignature] = useState(initialStatePurchseSignature); 
  const [formPurchaseSignature, setFormPurchaseSignature] = useState(initialStatePurchseSignature[0]);
  const filtersPurchaseSignature = useSelector((state) => state.filtersPurchaseSignature);
  const [filterPurchase, setFilterPurchase]= useState(filtersPurchaseSignature);
  const [numTotRegFilterSignature,setNumTotRegFilterSignature]= useState(0);
  const [numTotRegPurchaseSignature,setNumTotRegPurchaseSignature]= useState(0);
  const [errorsFiltersPurchaseSignature, setErrorsFiltersPurchaseSignature] = useState({});
  const [inputTextPurchaseSignature, setInputTextPurchaseSignature] = useState("");  
  const [openEditFilterPurchaseSignature,setOpenEditFilterPurchaseSignature]= useState(false);
  const [openEditRowPurchaseSignature,setOpenEditRowPurchaseSignature]= useState(false);
  const [rowEditPurchaseSignature,setRowEditPurchaseSignature]= useState(initialStatePurchseSignature[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [viewPDF,setViewPDF]=useState(false); 
  const dispatch = useDispatch();
  const [activeOffsetPurchaseSignature, setActiveOffsetPurchaseSignature]= useState(false);
  const [currencyOffsetPurchaseSignature, setCurrencyOffsetPurchaseSignature] = useState(0);
  const [rangeOffsetPurchaseSignature, setRangeOffsetPurchaseSignature] = useState([{}]);
  const [tradenameToSelect,setTradenameToSelect]= useState(initialStoresToSelect); 
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoresToSelect); 
  const [vehiclesToSelect,setVehiclesToSelect]= useState(initialVehicleToSelect); 
  const [workplaceToSelect,setWorkplaceToSelect]= useState(initialWorkPlaceToSelect); 
  const [paymenetTypeSelect,setPaymenetTypeSelect]= useState(initialPaymentToSelect); 
  const [refeshData,setRefeshData]=useState(false);
  const [filteredStoreFrom, setFilteredStoreFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexStoreFrom, setHighlightedIndexStoreFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredStoreTo, setFilteredStoreTo] = useState([]); // Opciones filtradas
  const [highlightedIndexStoreTo, setHighlightedIndexStoreTo] = useState(-1); // Índice de la opción resaltada
  const [filteredTransFrom, setFilteredTransFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexTransFrom, setHighlightedIndexTransFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredTransTo, setFilteredTransTo] = useState([]); // Opciones filtradas
  const [highlightedIndexTransTo, setHighlightedIndexTransTo] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleFrom, setFilteredVehicleFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleFrom, setHighlightedIndexVehicleFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleTo, setFilteredVehicleTo] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleTo, setHighlightedIndexVehicleTo] = useState(-1); // Índice de la opción resaltada 
  const [filteredWorkPlaceFrom, setFilteredWorkPlaceFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexWorkPlaceFrom, setHighlightedIndexWorkPlaceFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredWorkPlaceTo, setFilteredWorkPlaceTo] = useState([]); // Opciones filtradas
  const [highlightedIndexWorkPlaceTo, setHighlightedIndexWorkPlaceTo] = useState(-1); // Índice de la opción resaltada 

  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalPurchasesSignatureSettings) {
        setDataPurchaseSignature(initialStatePurchseSignature); 
        setFormPurchaseSignature(formPurchaseSignature);
        setFormPurchaseSignature(initialStatePurchseSignature[0]);
        setNumTotRegFilterSignature(0);
        setNumTotRegPurchaseSignature(0);
        setErrorsFiltersPurchaseSignature({});
        setInputTextPurchaseSignature("");   
        setOpenEditFilterPurchaseSignature(false);
        setOpenEditRowPurchaseSignature(false);
        setRowEditPurchaseSignature(initialStatePurchseSignature[0]);
        setRefeshData(false);
        setViewPDF(false);
        setActiveOffsetPurchaseSignature(false);
        setCurrencyOffsetPurchaseSignature(0);
        setRangeOffsetPurchaseSignature([{}]);
        setShowModalPurchasesSignatureSettings(false);
        setTrasnporterToSelect(initialStoresToSelect);
        setTradenameToSelect(initialStoresToSelect);
        setPaymenetTypeSelect(initialPaymentToSelect); 
        setVehiclesToSelect(initialVehicleToSelect);
        setWorkplaceToSelect(initialWorkPlaceToSelect); 
        setFilteredStoreFrom([]);
        setHighlightedIndexStoreFrom(-1); 
        setFilteredStoreTo([]);  
        setHighlightedIndexStoreTo(-1);  
        setFilteredTransFrom([]);
        setHighlightedIndexTransFrom(-1);  
        setFilteredTransTo([]);
        setHighlightedIndexTransTo(-1);  
        setFilteredVehicleFrom([]);
        setHighlightedIndexVehicleFrom(-1);  
        setFilteredVehicleTo([]);
        setHighlightedIndexVehicleTo(-1);   
        setFilteredWorkPlaceFrom([]);
        setHighlightedIndexWorkPlaceFrom(-1);  
        setFilteredWorkPlaceTo([]);  
        setHighlightedIndexWorkPlaceTo(-1);
        dispatch(setLoading(false));
        dispatch(resetPagination());
        dispatch(resetFilters());
        setFilterPurchase(filtersPurchaseSignature);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataPurchaseSignature,   
      formPurchaseSignature,
      setFormPurchaseSignature,
      filtersPurchaseSignature,
      setFilterPurchase,
      setNumTotRegFilterSignature,
      setNumTotRegPurchaseSignature,
      setErrorsFiltersPurchaseSignature,
      setInputTextPurchaseSignature,   
      setOpenEditFilterPurchaseSignature,
      setOpenEditRowPurchaseSignature,
      setRowEditPurchaseSignature,   
      setRefeshData,
      setViewPDF,
      setActiveOffsetPurchaseSignature,
      setCurrencyOffsetPurchaseSignature,
      setRangeOffsetPurchaseSignature, 
      setTrasnporterToSelect,
      setTradenameToSelect,
      setVehiclesToSelect,
      setWorkplaceToSelect,
      setPaymenetTypeSelect, 
      setFilteredStoreFrom,
      setHighlightedIndexStoreFrom, 
      setFilteredStoreTo,
      setHighlightedIndexStoreTo, 
      setFilteredTransFrom,
      setHighlightedIndexTransFrom, 
      setFilteredTransTo,
      setHighlightedIndexTransTo,  
      setFilteredVehicleFrom,
      setHighlightedIndexVehicleFrom, 
      setFilteredVehicleTo,
      setHighlightedIndexVehicleTo,   
      setFilteredWorkPlaceFrom,
      setHighlightedIndexWorkPlaceFrom, 
      setFilteredWorkPlaceTo,
      setHighlightedIndexWorkPlaceTo,   
      setShowModalPurchasesSignatureSettings,
      showModalPurchasesSignatureSettings, 
      dispatch,
      navigate]
  );

  const closeModalPurchaseSignature = () => {    
    setDataPurchaseSignature(initialStatePurchseSignature); 
    setFormPurchaseSignature(formPurchaseSignature);
    setFormPurchaseSignature(initialStatePurchseSignature[0]);
    setNumTotRegFilterSignature(0);
    setNumTotRegPurchaseSignature(0);
    setErrorsFiltersPurchaseSignature({});
    setInputTextPurchaseSignature("");   
    setOpenEditFilterPurchaseSignature(false);
    setOpenEditRowPurchaseSignature(false);
    setRowEditPurchaseSignature(initialStatePurchseSignature[0]);
    setRefeshData(false);
    setViewPDF(false);
    setActiveOffsetPurchaseSignature(false);
    setCurrencyOffsetPurchaseSignature(0);
    setRangeOffsetPurchaseSignature([{}]);
    setShowModalPurchasesSignatureSettings(false);
    setTrasnporterToSelect(initialStoresToSelect);
    setTradenameToSelect(initialStoresToSelect);
    setPaymenetTypeSelect(initialPaymentToSelect); 
    setVehiclesToSelect(initialVehicleToSelect);
    setWorkplaceToSelect(initialWorkPlaceToSelect); 
    setFilteredStoreFrom([]);
    setHighlightedIndexStoreFrom(-1); 
    setFilteredStoreTo([]);  
    setHighlightedIndexStoreTo(-1);  
    setFilteredTransFrom([]);
    setHighlightedIndexTransFrom(-1);  
    setFilteredTransTo([]);
    setHighlightedIndexTransTo(-1);  
    setFilteredVehicleFrom([]);
    setHighlightedIndexVehicleFrom(-1);  
    setFilteredVehicleTo([]);
    setHighlightedIndexVehicleTo(-1);   
    setFilteredWorkPlaceFrom([]);
    setHighlightedIndexWorkPlaceFrom(-1);  
    setFilteredWorkPlaceTo([]);  
    setHighlightedIndexWorkPlaceTo(-1);
    dispatch(setLoading(false));
    dispatch(resetPagination());
    dispatch(resetFilters());
    setFilterPurchase(filtersPurchaseSignature);
    navigate("/home");
    return () => {};
  };
 
  const openEditFiltersPurchaseSignature = () => {
      setOpenEditFilterPurchaseSignature(!openEditFilterPurchaseSignature);
  };

  const handleSubmitFilterEditPurchaseSignature = () => {
    setErrorsFiltersPurchaseSignature(validateFormFilterPurchaseSignature());
    if (Object.keys(errorsFiltersPurchaseSignature).length === 0) {
      setErrorsFiltersPurchaseSignature({}); 
      dispatch(resetPagination());
      dispatch(setFilters(filterPurchase));  
      setOpenEditFilterPurchaseSignature(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersPurchaseSignature = () => {
    setErrorsFiltersPurchaseSignature({});
    dispatch(resetPagination());
    dispatch(resetFilters()); 
    setFilterPurchase(initialFiltersPurchaseSignature);
    setOpenEditFilterPurchaseSignature(!openEditFilterPurchaseSignature);
  };

  const handleCancelFiltersPurchaseSignature = () => {
    setErrorsFiltersPurchaseSignature({});
    setOpenEditFilterPurchaseSignature(!openEditFilterPurchaseSignature);
  };
 
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100000000) return true;
    return false;
  };
  const handleChangeSelectDatePurchaseSignature = (event) => {
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      datepurchaseFrom: event!==null?new Date(event):null,
      datepurchaseTo:null
    }));
  };

  const handleBlurSelectDatePurchaseSignatureFrom = (e) => {
    handleChangeSelectDatePurchaseSignature(e);
    setErrorsFiltersPurchaseSignature(validateFormFilterPurchaseSignature());
  };

  const handleChangeSelectDatePurchaseSignatureTo = (event) => {
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      datepurchaseTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDatePurchaseSignatureTo = (e) => {
    handleChangeSelectDatePurchaseSignatureTo(e);
    setErrorsFiltersPurchaseSignature(validateFormFilterPurchaseSignature());
  };
    
  const handleChangeFilterEditPurchaseSignature = (e) => {
    const { name, value } = e.target;
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      [name]: value
    }));
  };

  const handleBlurFilterEditPurchaseSignature = (e) => {
    setErrorsFiltersPurchaseSignature(validateFormFilterPurchaseSignature());
    handleChangeFilterEditPurchaseSignature(e);
  };

  const toggleDrawerFilterEditPurchaseSignature = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditPurchaseSignature();
    }
  };
  
  const validateFormFilterPurchaseSignature = () => {

    let errorsFiltersPurchaseSignature = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filterPurchase.idFrom!=="" & !regexNumber.test(filterPurchase.idFrom)) {
      errorsFiltersPurchaseSignature.idFrom = 'Text_TextNoNumber';
    }
    if (filterPurchase.idTo!=="" & !regexNumber.test(filterPurchase.idTo)) {
      errorsFiltersPurchaseSignature.idTo = 'Text_TextNoNumber';
    }
    if (filterPurchase.idFrom!=="" & !errorsFiltersPurchaseSignature.idFrom!==undefined){
      if (filterPurchase.idTo!=="" & !errorsFiltersPurchaseSignature.idTo!==undefined){
        if (parseInt(filterPurchase.idTo)<parseInt(filterPurchase.idFrom)){
          errorsFiltersPurchaseSignature.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filterPurchase.numberDIFROM!=="" & !regexNumber.test(filterPurchase.numberDIFROM)) {
      errorsFiltersPurchaseSignature.numberDIFROM = 'Text_TextNoNumber';
    }
    if (filterPurchase.numberDITO!=="" & !regexNumber.test(filterPurchase.numberDITO)) {
      errorsFiltersPurchaseSignature.numberDITO = 'Text_TextNoNumber';
    }
    if (filterPurchase.numberDIFROM!=="" & !errorsFiltersPurchaseSignature.numberDIFROM!==undefined){
      if (filterPurchase.numberDITO!=="" & !errorsFiltersPurchaseSignature.numberDITO!==undefined){
        if (parseInt(filterPurchase.numberDITO)<parseInt(filterPurchase.numberDIFROM)){
          errorsFiltersPurchaseSignature.numberDITO = 'Text_TextGreater_FormDI';
        }
      }
    }
    
    if (regexInvalidCharacters.test(filterPurchase.datepurchaseFrom)) {
      errorsFiltersPurchaseSignature.datepurchaseFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filterPurchase.datepurchaseTo)) {
      errorsFiltersPurchaseSignature.datepurchaseTo = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(filterPurchase.vehiclestore_licenseplateFrom)) {
      errorsFiltersPurchaseSignature.vehiclestore_licenseplateFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filterPurchase.vehiclestore_licenseplateTo)) {
      errorsFiltersPurchaseSignature.vehiclestore_licenseplateTo = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(filterPurchase.ori_tras_workplaceFrom)) {
      errorsFiltersPurchaseSignature.ori_tras_workplaceFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filterPurchase.ori_tras_workplaceTo)) {
      errorsFiltersPurchaseSignature.ori_tras_workplaceTo = 'Text_TextNoValid';
    }
   
    if (filterPurchase.defaultStoreIdTo!=="" & filterPurchase.defaultStoreIdTo<filterPurchase.defaultStoreIdFrom){
      errorsFiltersPurchaseSignature.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filterPurchase.useraccountIdTo!=="" & filterPurchase.useraccountIdTo<filterPurchase.useraccountIdFrom){
      errorsFiltersPurchaseSignature.useraccountIdTo = 'Text_TextGreater_UserAccount';
    } 

    if (filterPurchase.vehiclestore_licenseplateTo!=="" & filterPurchase.vehiclestore_licenseplateTo<filterPurchase.vehiclestore_licenseplateFrom){
      errorsFiltersPurchaseSignature.vehiclestore_licenseplateTo = 'Text_TextGreater_vehicles';
    }

    if (filterPurchase.ori_tras_workplaceTo!=="" & filterPurchase.ori_tras_workplaceTo<filterPurchase.ori_tras_workplaceFrom){
      errorsFiltersPurchaseSignature.ori_tras_workplaceTo = 'Text_TextGreater_workplace';
    }
    
    return errorsFiltersPurchaseSignature;
  }  

  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchStoreFrom = (event) => {
    const searchStoreFrom = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_store_idFrom: searchStoreFrom,
    }));
    if (searchStoreFrom.trim() === "") {
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1);
      return;
    } 
    const filtered = tradenameToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchStoreFrom) ||
        normalizedDocumentId.includes(searchStoreFrom)
      );
    });
    setFilteredStoreFrom(filtered);
    setHighlightedIndexStoreFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectStoreFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_store_idFrom: store.tradename || "",
      ori_tras_store_idTo: store.tradename || filterPurchase.ori_tras_store_idTo,
      ori_tras_workplaceFrom: "",
      ori_tras_workplaceTo: "",
    }));
    setFilteredStoreFrom([]);
    setHighlightedIndexStoreFrom(-1);
  }; 
  const handleKeyDownStoreFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredStoreFrom.length === 0 && filterPurchase.ori_tras_store_idFrom.trim() === "") {
        setFilteredStoreFrom(tradenameToSelect);
        setHighlightedIndexStoreFrom(0);
      } else {
        setHighlightedIndexStoreFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredStoreFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexStoreFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexStoreFrom >= 0) {
      if (highlightedIndexStoreFrom >= 0 && highlightedIndexStoreFrom < filteredStoreFrom.length) {
        handleSelectStoreFrom(filteredStoreFrom[highlightedIndexStoreFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = tradenameToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterPurchase.ori_tras_store_idFrom.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          ori_tras_store_idFrom: ""
        }));
      }
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1);
    }
  };
 // Manejo del texto de búsqueda y filtrado dinámico
   const handleSearchStoreTo = (event) => {
     const searchStoreTo = event.target.value.toUpperCase();
     setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_store_idTo: searchStoreTo,
      ori_tras_workplaceFrom:"",
      ori_tras_workplaceTo:"" 
    }));
    if (searchStoreTo.trim() === "") {
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);
      return;
    } 
     const filtered = tradenameToSelect
     .filter(item=>(item.tradename>=filterPurchase.ori_tras_store_idFrom))
     .filter((store) => {
       if (!store || !store.tradename) return false;
       // Comparar después de eliminar puntos y guiones de ambos
       const normalizedTradeName = store.tradename.toUpperCase();
       const normalizedDocumentId = store.documentid.toUpperCase();
       return (
         normalizedTradeName.includes(searchStoreTo) ||
         normalizedDocumentId.includes(searchStoreTo)
       );
     });
     setFilteredStoreTo(filtered);
     setHighlightedIndexStoreTo(0);
   };
   // Manejo de la selección de una tienda
   const handleSelectStoreTo = (store) => {
     if (!store) return; // Validación adicional para evitar errores
     setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_store_idTo:  store.tradename || ""
    }));
    setFilteredStoreTo([]);
    setHighlightedIndexStoreTo(-1);
   }; 
   const handleKeyDownStoreTo = (event) => {
     if (event.key === "ArrowDown") {
       if (filteredStoreTo.length === 0 && filterPurchase.ori_tras_store_idTo.trim() === "") {
         const filtered = tradenameToSelect.filter(item=>(item.tradename>=filterPurchase.ori_tras_store_idFrom));
         setFilteredStoreTo(filtered);
         setHighlightedIndexStoreTo(0);
       } else {
         setHighlightedIndexStoreTo((prevIndex) => {
           const nextIndex = prevIndex < filteredStoreTo.length - 1 ? prevIndex + 1 : prevIndex;
           scrollToElement(nextIndex);
           return nextIndex;
         });
       }
     } else if (event.key === "ArrowUp") {
       setHighlightedIndexStoreTo((prevIndex) => {
         const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
         scrollToElement(prevIndexValid);
         return prevIndexValid;
       });
     } else if (event.key === "Enter" && highlightedIndexStoreTo >= 0) {
       if (highlightedIndexStoreTo >= 0 && highlightedIndexStoreTo < filteredStoreTo.length) {
         handleSelectStoreTo(filteredStoreTo[highlightedIndexStoreTo]);
       }
     } else if (event.key === "Escape" || event.key === "Tab") {
       const isValid = tradenameToSelect
       .filter(item=>(item.tradename>=filterPurchase.ori_tras_store_idFrom))
       .some((store) => {
        if (!store || !store.tradename) return false; 
          const normalizedTradeName = store.tradename.toUpperCase();
          const normalizedInputTradeName = filterPurchase.ori_tras_store_idTo.toUpperCase();
          return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          ori_tras_store_idTo:  ""
        }));
      }
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);
     }
   };    
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTransFrom = (event) => {
    const searchTransFrom = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      rel_trans_store_idFrom:  searchTransFrom
    })); 
    if (searchTransFrom.trim() === "") {
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1);
      return;
    } 
    const filtered = trasnporterToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchTransFrom) ||
        normalizedDocumentId.includes(searchTransFrom)
      );
    });
    setFilteredTransFrom(filtered);
    setHighlightedIndexTransFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTransFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      rel_trans_store_idFrom: store.tradename || "",
      rel_trans_store_idTo: store.tradename || filterPurchase.rel_trans_store_idTo,
    })); 
    setFilteredTransFrom([]);
    setHighlightedIndexTransFrom(-1);
  }; 
  const handleKeyDownTransFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTransFrom.length === 0 && filterPurchase.rel_trans_store_idFrom.trim() === "") {
        setFilteredTransFrom(trasnporterToSelect);
        setHighlightedIndexTransFrom(0);
      } else {
        setHighlightedIndexTransFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredTransFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTransFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexTransFrom >= 0) {
      if (highlightedIndexTransFrom >= 0 && highlightedIndexTransFrom < filteredTransFrom.length) {
        handleSelectTransFrom(filteredTransFrom[highlightedIndexTransFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = trasnporterToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterPurchase.rel_trans_store_idFrom.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          rel_trans_store_idFrom: "" 
        })); 
      }
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1);
    }
  }; 
  const handleSearchTransTo = (event) => {
    const searchTransTo = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      rel_trans_store_idTo:searchTransTo,
    })); 
    if (searchTransTo.trim() === "") {
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1);
      return;
    } 
    const filtered = trasnporterToSelect
    .filter(item=>(item.tradename>=filterPurchase.rel_trans_store_idFrom))
    .filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchTransTo) ||
        normalizedDocumentId.includes(searchTransTo)
      );
    });
    setFilteredTransTo(filtered);
    setHighlightedIndexTransTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTransTo = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      rel_trans_store_idTo:store.tradename || ""
    })); 
    setFilteredTransTo([]);
    setHighlightedIndexTransTo(-1);
  }; 
  const handleKeyDownTransTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTransTo.length === 0 && filterPurchase.rel_trans_store_idTo.trim() === "") {
        const filtered = trasnporterToSelect.filter(item=>(item.tradename>=filterPurchase.rel_trans_store_idFrom));
        setFilteredTransTo(filtered);
        setHighlightedIndexTransTo(0);
      } else {
        setHighlightedIndexTransTo((prevIndex) => {
          const nextIndex = prevIndex < filteredTransTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTransTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexTransTo >= 0) {
      if (highlightedIndexTransTo >= 0 && highlightedIndexTransTo < filteredTransTo.length) {
        handleSelectTransTo(filteredTransTo[highlightedIndexTransTo]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = trasnporterToSelect
      .filter(item=>(item.tradename>=filterPurchase.rel_trans_store_idFrom))
      .some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterPurchase.rel_trans_store_idTo.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          rel_trans_store_idTo: ""
        }));
      }
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1);
    }
  };  
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchVehicleFrom = (event) => {
    const searchVehicleFrom = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      vehiclestore_licenseplateFrom: searchVehicleFrom,
    }));
    if (searchVehicleFrom.trim() === "") {
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      return;
    } 
    const filtered = vehiclesToSelect.filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleFrom.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return ( normalizedVehicle.includes(searchNormailzedVehicled) );
    });
    setFilteredVehicleFrom(filtered);
    setHighlightedIndexVehicleFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleFrom = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      vehiclestore_licenseplateFrom: vehicle.vehiclestore_licenseplate || "",
      vehiclestore_licenseplateTo: vehicle.vehiclestore_licenseplate || filterPurchase.vehiclestore_licenseplateTo,
    }));
    setFilteredVehicleFrom([]);
    setHighlightedIndexVehicleFrom(-1);
  }; 
  const handleKeyDownVehicleFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleFrom.length === 0 && filterPurchase.vehiclestore_licenseplateFrom.trim() === "") {
        setFilteredVehicleFrom(vehiclesToSelect);
        setHighlightedIndexVehicleFrom(0);
      } else {
        setHighlightedIndexVehicleFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexVehicleFrom >= 0) {
      if (highlightedIndexVehicleFrom >= 0 && highlightedIndexVehicleFrom < filteredVehicleFrom.length) {
        handleSelectVehicleFrom(filteredVehicleFrom[highlightedIndexVehicleFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = vehiclesToSelect.some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filterPurchase.vehiclestore_licenseplateFrom.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          vehiclestore_licenseplateFrom: ""
        }));
      }
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
    }
  };
  const handleSearchVehicleTo = (event) => {
    const searchVehicleTo = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      vehiclestore_licenseplateTo:searchVehicleTo,
    }));
    if (searchVehicleTo.trim() === "") {
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
      return;
    } 
    const filtered = vehiclesToSelect
    .filter(item=>(item.vehiclestore_licenseplate>=filterPurchase.vehiclestore_licenseplateFrom))
    .filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleTo.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return (normalizedVehicle.includes(searchNormailzedVehicled));
    });
    setFilteredVehicleTo(filtered);
    setHighlightedIndexVehicleTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleTo = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      vehiclestore_licenseplateTo: vehicle.vehiclestore_licenseplate || ""
    })); 
    setFilteredVehicleTo([]);
    setHighlightedIndexVehicleTo(-1);
  }; 
  const handleKeyDownVehicleTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleTo.length === 0 && filterPurchase.vehiclestore_licenseplateTo.trim() === "") {
        const filtered = vehiclesToSelect.filter(item=>(item.vehiclestore_licenseplate>=filterPurchase.vehiclestore_licenseplateFrom));
        setFilteredVehicleTo(filtered);
        setHighlightedIndexVehicleTo(0);
      } else {
        setHighlightedIndexVehicleTo((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexVehicleTo >= 0) {
      if (highlightedIndexVehicleTo >= 0 && highlightedIndexVehicleTo < filteredVehicleTo.length) {
        handleSelectVehicleTo(filteredVehicleTo[highlightedIndexVehicleTo]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = vehiclesToSelect
      .filter(item=>(item.vehiclestore_licenseplate>=filterPurchase.vehiclestore_licenseplateFrom))
      .some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filterPurchase.vehiclestore_licenseplateTo.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
           vehiclestore_licenseplateTo: ""
        }));   
      }
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
    }
  }; 
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchWorkPlaceFrom = (event) => {
    const searchWorkPlaceFrom = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_workplaceFrom:  searchWorkPlaceFrom
    })); 
    if (searchWorkPlaceFrom.trim() === "") {
      setFilteredWorkPlaceFrom([]);
      setHighlightedIndexWorkPlaceFrom(-1);
      return;
    } 
    const filtered = workplaceToSelect.filter((store) => {
      if (!store || !store.workplace) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedWorkPlace = store.workplace.toUpperCase(); 
      return (normalizedWorkPlace.includes(searchWorkPlaceFrom));
    });
    setFilteredWorkPlaceFrom(filtered);
    setHighlightedIndexWorkPlaceFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectWorkPlaceFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_workplaceFrom: store.workplace || "",
      ori_tras_workplaceTo: store.workplace || filterPurchase.ori_tras_workplaceTo,
    })); 
    setFilteredWorkPlaceFrom([]);
    setHighlightedIndexWorkPlaceFrom(-1);
  }; 
  const handleKeyDownWorkPlaceFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredWorkPlaceFrom.length === 0 && filterPurchase.ori_tras_workplaceFrom.trim() === "") {
        setFilteredWorkPlaceFrom(workplaceToSelect);
        setHighlightedIndexWorkPlaceFrom(0);
      } else {
        setHighlightedIndexWorkPlaceFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredWorkPlaceFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexWorkPlaceFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexWorkPlaceFrom >= 0) {
      if (highlightedIndexWorkPlaceFrom >= 0 && highlightedIndexWorkPlaceFrom < filteredWorkPlaceFrom.length) {
        handleSelectWorkPlaceFrom(filteredWorkPlaceFrom[highlightedIndexWorkPlaceFrom]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = workplaceToSelect.some((store) => {
        if (!store || !store.workplace) return false; 
        const normalizedWorkPlace = store.workplace.toUpperCase();
        const normalizedInputWorkPlace = filterPurchase.ori_tras_workplaceFrom.toUpperCase();
        return normalizedWorkPlace === normalizedInputWorkPlace;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          ori_tras_workplaceFrom: "" 
        })); 
      }
      setFilteredWorkPlaceFrom([]);
      setHighlightedIndexWorkPlaceFrom(-1);
    }
  }; 
  const handleSearchWorkPlaceTo = (event) => {
    const searchWorkPlaceTo = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_workplaceTo:searchWorkPlaceTo,
    })); 
    if (searchWorkPlaceTo.trim() === "") {
      setFilteredWorkPlaceTo([]);
      setHighlightedIndexWorkPlaceTo(-1);
      return;
    } 
    const filtered = workplaceToSelect
    .filter(item=>(item.workplace>=filterPurchase.ori_tras_workplaceFrom))
    .filter((store) => {
      if (!store || !store.workplace) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedWorkPlace = store.workplace.toUpperCase(); 
      return ( normalizedWorkPlace.includes(searchWorkPlaceTo));
    });
    setFilteredWorkPlaceTo(filtered);
    setHighlightedIndexWorkPlaceTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectWorkPlaceTo = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_workplaceTo: store.workplace || ""
    })); 
    setFilteredWorkPlaceTo([]);
    setHighlightedIndexWorkPlaceTo(-1);
  }; 
  const handleKeyDownWorkPlaceTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredWorkPlaceTo.length === 0 && filterPurchase.ori_tras_workplaceTo.trim() === "") {
        const filtered = workplaceToSelect.filter(item=>(item.workplace>=filterPurchase.ori_tras_workplaceFrom));
        setFilteredWorkPlaceTo(filtered);
        setHighlightedIndexWorkPlaceTo(0);
      } else {
        setHighlightedIndexWorkPlaceTo((prevIndex) => {
          const nextIndex = prevIndex < filteredWorkPlaceTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexWorkPlaceTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if (event.key === "Enter" && highlightedIndexWorkPlaceTo >= 0) {
      if (highlightedIndexWorkPlaceTo >= 0 && highlightedIndexWorkPlaceTo < filteredWorkPlaceTo.length) {
        handleSelectWorkPlaceTo(filteredWorkPlaceTo[highlightedIndexWorkPlaceTo]);
      }
    } else if (event.key === "Escape" || event.key === "Tab") {
      const isValid = workplaceToSelect
      .filter(item=>(item.workplace>=filterPurchase.ori_tras_workplaceFrom))
      .some((store) => {
        if (!store || !store.workplace) return false; 
        const normalizedWorkPlace = store.workplace.toUpperCase();
        const normalizedInputWorkPlace = filterPurchase.ori_tras_workplaceTo.toUpperCase();
        return normalizedWorkPlace === normalizedInputWorkPlace;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
           ori_tras_workplaceTo: ""
        })); 
      }
      setFilteredWorkPlaceTo([]);
      setHighlightedIndexWorkPlaceTo(-1);
    }
  }; 
    
  // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleBlur = (event) => {
    // Asegúrate de que no se cierra si haces clic en un elemento de la lista
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1); 
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1); 
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1); 
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1); 
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
      setFilteredWorkPlaceFrom([]);
      setHighlightedIndexWorkPlaceFrom(-1);
      setHighlightedIndexWorkPlaceTo(-1);
    }
  };

  const filteredDataPurchaseSignature = dataPurchaseSignature.filter((el) => {
    if (inputTextPurchaseSignature === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.purchasenote || '') +
        String(el.vehiclestore_licenseplate || '') +
        String(el.rel_trans_tradename || '') +
        String(el.ori_tras_tradename || '') +
        String(el.workplace || '');
      let result = dataTranslate.toLowerCase().includes(inputTextPurchaseSignature.toLowerCase());
      return result;
    }
  })
 
  let inputHandlerPurchaseSignature  = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextPurchaseSignature(lowerCase);
  };
  
  const handleCloseDataEditPurchaseSignature = () => { 
    setOpenEditRowPurchaseSignature(false);
    setRowEditPurchaseSignature(openEditRowPurchaseSignature?rowEditPurchaseSignature:initialStatePurchseSignature[0]);
    dispatch(setLoading(false));
    toggleDrawerFilterEditPurchaseSignature(false); 
    setRefeshData(true); 
	};
  
  useEffect(() => {
    if (numTotRegPurchaseSignature > Number(LIMITPAGESIZE)) {
      setActiveOffsetPurchaseSignature(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegPurchaseSignature; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetPurchaseSignature(newRangeOffset);
    } else {
      setCurrencyOffsetPurchaseSignature(0);
      setActiveOffsetPurchaseSignature(false);
      setRangeOffsetPurchaseSignature([{}]);
    }
  }, [numTotRegPurchaseSignature, LIMITPAGESIZE]);

  useEffect(() => {
    if (showModalPurchasesSignatureSettings){
      setFormPurchaseSignature(formPurchaseSignature => ({
        ...formPurchaseSignature,
        id : rowEditPurchaseSignature.id!==null?rowEditPurchaseSignature.id:"",
        defaultStore_id : rowEditPurchaseSignature.defaultStore_id!==null?rowEditPurchaseSignature.defaultStore_id:defaultStore.id,
        tradename :rowEditPurchaseSignature.tradename!==null?rowEditPurchaseSignature.tradename:defaultStore.tradename,
        useraccount_id :rowEditPurchaseSignature.useraccount_id!==null?rowEditPurchaseSignature.useraccount_id:useraccount_id,
        username :rowEditPurchaseSignature.username!==null?rowEditPurchaseSignature.username:"",
        defaultformatnumber :rowEditPurchaseSignature.defaultformatnumber!==null?rowEditPurchaseSignature.defaultformatnumber:0,
        purchasenote :rowEditPurchaseSignature.purchasenote!==null?rowEditPurchaseSignature.purchasenote:"",
        vehiclestore_licenseplate :rowEditPurchaseSignature.vehiclestore_licenseplate!==null?rowEditPurchaseSignature.vehiclestore_licenseplate:"",
        workplace :rowEditPurchaseSignature.workplace!==null?rowEditPurchaseSignature.workplace:"",
        datepurchase :rowEditPurchaseSignature.datepurchase!==null?rowEditPurchaseSignature.datepurchase:"",
        rel_trans_tradename :rowEditPurchaseSignature.rel_trans_tradename!==null?rowEditPurchaseSignature.rel_trans_tradename:"",
        ori_tras_tradename :rowEditPurchaseSignature.ori_tras_tradename!==null?rowEditPurchaseSignature.ori_tras_tradename:"",
        totalpurchase :rowEditPurchaseSignature.totalpurchase!==null?rowEditPurchaseSignature.totalpurchase:0,
        payment_id:rowEditPurchaseSignature.payment_id!==null?rowEditPurchaseSignature.payment_id:0,
        payment_code:rowEditPurchaseSignature.payment_code!==null?rowEditPurchaseSignature.payment_code:"",
        payment_namees:rowEditPurchaseSignature.payment_namees!==null?rowEditPurchaseSignature.payment_namees:"",
        payment_nameen:rowEditPurchaseSignature.payment_nameen!==null?rowEditPurchaseSignature.payment_nameen:"",
      }));
    }else{
      setFormPurchaseSignature(initialStatePurchseSignature[0]);
    }
  },[rowEditPurchaseSignature,showModalPurchasesSignatureSettings,defaultStore.id,defaultStore.tradename,useraccount_id]);


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true; 
    const fetchDataTransporter = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().get2(ENDPOINT, `purchasedelivery/getlisttransporterpurchasedelivery/`, token,false);
        if (getData.err) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setTrasnporterToSelect(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    const fetchDataPayment = async () => {
      dispatch(setLoading(true));
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynameen`, token);
        if (getData.err) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setPaymenetTypeSelect(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataTradename = async () => {
      try {
        dispatch(setLoading(true));
        const getData = await helpHttp().get7(ENDPOINT,`purchasedelivery/getlisttradenamepurchasedelivery`,token,"-1","-1",false);
        if (getData.err) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setTradenameToSelect(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };   

    if (showModalPurchasesSignatureSettings ) { 
      fetchDataTransporter(); 
      fetchDataTradename();
      fetchDataPayment(); 
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,filtersPurchaseSignature,openEditFilterPurchaseSignature,showModalPurchasesSignatureSettings]);

  useEffect(() => {
    let isMounted = true; 
    const fetchDataVehicles = async () => {
      try {
        const getData = await helpHttp().get7(ENDPOINT,`purchasedelivery/getlistvehiclespurchasedelivery`,token,
          filterPurchase.rel_trans_store_idFrom!==undefined && filterPurchase.rel_trans_store_idFrom!==null && filterPurchase.rel_trans_store_idFrom.replace(/[.\-/\\]/g, "").trim()!==""?filterPurchase.rel_trans_store_idFrom.replace(/[.\-/\\]/g, ""):"-1",
          filterPurchase.rel_trans_store_idTo!==undefined && filterPurchase.rel_trans_store_idTo!==null && filterPurchase.rel_trans_store_idTo.replace(/[.\-/\\]/g, "").trim()!==""?filterPurchase.rel_trans_store_idTo.replace(/[.\-/\\]/g, ""):"-1",
          false); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setVehiclesToSelect(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  

    if (showModalPurchasesSignatureSettings ) { 
      fetchDataVehicles();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,roles,filterPurchase,openEditFilterPurchaseSignature,showModalPurchasesSignatureSettings]);
    
  useEffect(() => {
    let isMounted = true; 
    const fetchDataVehicles = async () => {
      try {
        const getData = await helpHttp().get7(ENDPOINT,`purchasedelivery/getlistworkplacepurchasedelivery`,token,
          filterPurchase.ori_tras_store_idFrom!==undefined && filterPurchase.ori_tras_store_idFrom!==null && filterPurchase.ori_tras_store_idFrom.replace(/[.\-/\\]/g, "").trim()!==""?filterPurchase.ori_tras_store_idFrom.replace(/[.\-/\\]/g, ""):"-1",
          filterPurchase.ori_tras_store_idTo!==undefined && filterPurchase.ori_tras_store_idTo!==null && filterPurchase.ori_tras_store_idTo.replace(/[.\-/\\]/g, "").trim()!==""?filterPurchase.ori_tras_store_idTo.replace(/[.\-/\\]/g, ""):"-1",
          false); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setWorkplaceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };   
    if (showModalPurchasesSignatureSettings ) { 
      fetchDataVehicles();
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,filterPurchase,openEditFilterPurchaseSignature,showModalPurchasesSignatureSettings]);
    
   
  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegPurchaseSignature = async () => {
      dispatch(setLoading(true));
      try {
        const getData  = await helpHttp().get2(ENDPOINT,`purchasesignature/getinformationpurchasesignature/`,token,false);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataPurchaseSignature(initialStatePurchseSignature);
            dispatch(setLoading(false));
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegPurchaseSignature(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchnumTotRegFilterSignature = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().post4(ENDPOINT,`purchasesignature/getcountfilterpurchasesignature`,token,"-1","-1",false,filtersPurchaseSignature);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataPurchaseSignature(initialStatePurchseSignature);
            dispatch(setLoading(false));
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilterSignature(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    }; 
    
    const fetchDataPurchaseDelivery = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().post6(ENDPOINT,`purchasesignature/getpurchasesignature`,token,currencyOffsetPurchaseSignature,LIMITPAGESIZE,"-1","-1",false,filtersPurchaseSignature);
        if (getData.err) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataPurchaseSignature(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    if (showModalPurchasesSignatureSettings || refeshData){
      fetchnumTotRegPurchaseSignature();
      fetchnumTotRegFilterSignature(); 
      fetchDataPurchaseDelivery(); 
      setRefeshData(false);
    }  
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,LIMITPAGESIZE,filtersPurchaseSignature,showModalPurchasesSignatureSettings,currencyOffsetPurchaseSignature ,setOpenEditRowPurchaseSignature,openEditRowPurchaseSignature,rowEditPurchaseSignature,setRowEditPurchaseSignature,defaultStore,refeshData ]);
     
   return (
    <>
       {showModalPurchasesSignatureSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={"hANewStyle mb-2"}>
              {isLoadingData?<CircularProgress color={"primary"}/>:<i className="fas fa-pen-nib text-warning"></i>} {t('Text_PurchaseDelivery_Table_Sign')}</h1>
              <div>
                <button type="button" className="buttonBack" onClick={closeModalPurchaseSignature}>{t('Button_Back')}</button><> </>
              </div>    
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextPurchaseSignature}
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              placeholder={t('Text_PurchaseDelivery_Search')} 
              onChange={inputHandlerPurchaseSignature }
              fullWidth
              label={t('Text_PurchaseDelivery_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersPurchaseSignature}><i className="fas fa-solid fa-circle" style={{color:(filtersPurchaseSignature.idFrom!=="" || filtersPurchaseSignature.idTo!=="" ||filtersPurchaseSignature.purchasenoteFrom!=="" || filtersPurchaseSignature.purchasenoteTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_NumberPurchase')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersPurchaseSignature}><i className="fas fa-solid fa-circle" style={{color:(filtersPurchaseSignature.datepurchaseFrom!==null|| filtersPurchaseSignature.datepurchaseTo!==null)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Dates')}</button><> </>                 
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersPurchaseSignature }><i className="fas fa-solid fa-circle" style={{color:(filtersPurchaseSignature.numberDIFROM!=="" || filtersPurchaseSignature.numberDITO!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Filter_FormDI')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersPurchaseSignature }><i className="fas fa-solid fa-circle" style={{color:( 
                     filtersPurchaseSignature.vehiclestore_licenseplateFrom
                      || filtersPurchaseSignature.vehiclestore_licenseplateTo
                      || filtersPurchaseSignature.rel_trans_store_idFrom
                      || filtersPurchaseSignature.rel_trans_store_idTo!=="" 
                      || filtersPurchaseSignature.ori_tras_store_idFrom!==""
                      || filtersPurchaseSignature.ori_tras_store_idTo!==""
                      || filtersPurchaseSignature.ori_tras_workplaceFrom!==""
                      || filtersPurchaseSignature.ori_tras_workplaceTo!==""                      
                      || filtersPurchaseSignature.paymentFromES!==""
                      || filtersPurchaseSignature.paymentToES!==""
                      || filtersPurchaseSignature.paymentFromEN!==""
                      || filtersPurchaseSignature.paymentToEN!==""
                      )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Others_Filters')}</button><> </>
            </h1>
          </div>
          <br/>

          {!isLoadingData ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingPurchaseDelivery
                    dataPurchaseSignature={filteredDataPurchaseSignature}
                    numTotRegFilterSignature={ numTotRegFilterSignature}
                    titleTable={t('Text_PurchaseDelivery_Table_Sign')}
                    setRowEditPurchaseSignature={setRowEditPurchaseSignature}
                    numTotRegPurchaseSignature={numTotRegPurchaseSignature}
                    activeOffsetPurchaseSignature={activeOffsetPurchaseSignature}
                    rangeOffsetPurchaseSignature={rangeOffsetPurchaseSignature}
                    currencyOffsetPurchaseSignature={currencyOffsetPurchaseSignature}
                    setCurrencyOffsetPurchaseSignature={setCurrencyOffsetPurchaseSignature} 
                    setViewPDF={setViewPDF} 
                  >
                  </TableSortingPurchaseDelivery>
                </div>             
              </div>
              
              {openEditFilterPurchaseSignature &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditPurchaseSignature(false)}
                 onOpen={toggleDrawerFilterEditPurchaseSignature(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfilterpurchasedelivery" onSubmit={handleCancelFiltersPurchaseSignature}>  
                         <div className="form-floating mb-3 px-4 primary">
                         <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="idFrom" 
                                name="idFrom" 
                                value={filterPurchase.idFrom}
                                label={errorsFiltersPurchaseSignature.idFrom!=null?t(errorsFiltersPurchaseSignature.idFrom):t('Text_Filter_Code_From')}
                                placeholder={t('PlaceHolder_Filter_Code_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature} 
                              /> 
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="idTo" 
                                name="idTo" 
                                value={filterPurchase.idTo}
                                label={errorsFiltersPurchaseSignature.idTo!=null?t(errorsFiltersPurchaseSignature.idTo):t('Text_Filter_Code_To')}
                                placeholder={t('PlaceHolder_Filter_Code_to')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEditPurchaseSignature}
                                onChange={handleChangeFilterEditPurchaseSignature} 
                              />  
                          </div> 
                          <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="datepurchaseFrom" 
                                  name="datepurchaseFrom"
                                  value={ filterPurchase.datepurchaseFrom!== null ? new Date(filterPurchase.datepurchaseFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersPurchaseSignature.datepurchaseFrom!=null?t(errorsFiltersPurchaseSignature.datepurchaseFrom):t('Text_Filter_DatePurchase_From')}
                                  onBlur={handleBlurSelectDatePurchaseSignatureFrom}
                                  onChange={handleChangeSelectDatePurchaseSignature}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px'
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="datepurchaseTo" 
                                  name="datepurchaseTo"
                                  minDate={filterPurchase.datepurchaseFrom!== null ? new Date(filterPurchase.datepurchaseFrom): null}
                                  value={ filterPurchase.datepurchaseTo!== null ? new Date(filterPurchase.datepurchaseTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersPurchaseSignature.datepurchaseTo!=null?t(errorsFiltersPurchaseSignature.datepurchaseTo):t('Text_Filter_DatePurchase_To')}
                                  onBlur={handleBlurSelectDatePurchaseSignatureTo}
                                  onChange={handleChangeSelectDatePurchaseSignatureTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px'
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                          </div>         
                          <div className="form-group">
                            {/* Campo de texto para escribir y filtrar */}
                            <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="ori_tras_store_idFrom"
                                name="ori_tras_store_idFrom"
                                value={filterPurchase.ori_tras_store_idFrom}
                                onChange={handleSearchStoreFrom}
                                onKeyDown={handleKeyDownStoreFrom}
                                label={errorsFiltersPurchaseSignature.ori_tras_store_idFrom!=null?t(errorsFiltersPurchaseSignature.ori_tras_store_idFrom):t('Text_Filter_TradeName_From')}
                                placeholder={t('PlaceHolder_Filter_tradename_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined" 
                              />
                              {/* Desplegable dinámico */}
                              {filteredStoreFrom.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                >
                                  {filteredStoreFrom.map((store, index) => (
                                    <MenuItem
                                        key={store.id}
                                        onClick={() => handleSelectStoreFrom(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexStoreFrom ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexStoreFrom ? "white" : "black",
                                        }}
                                    >
                                      {store.documentid} - {store.tradename}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="ori_tras_store_idTo"
                                name="ori_tras_store_idTo"
                                value={filterPurchase.ori_tras_store_idTo}
                                onChange={handleSearchStoreTo}
                                onKeyDown={handleKeyDownStoreTo}
                                label={errorsFiltersPurchaseSignature.ori_tras_store_idTo!=null?t(errorsFiltersPurchaseSignature.ori_tras_store_idTo):t('Text_Filter_TradeName_To')}
                                placeholder={t('PlaceHolder_Filter_tradename_to')}
                                variant="outlined"
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                              />
                              {/* Desplegable dinámico */}
                              {filteredStoreTo.length > 0 && ( 
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                >
                                  {filteredStoreTo.map((store, index) => (
                                    <MenuItem
                                      key={store.id}
                                        onClick={() => handleSelectStoreTo(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexStoreTo ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexStoreTo ? "white" : "black",
                                        }}
                                      >
                                        {store.documentid} - {store.tradename}
                                      </MenuItem>
                                  ))}
                                </div>
                            )}    
                            </span>
                          </div> 

                          <div className="form-group">
                            {/* Campo de texto para escribir y filtrar */}
                            <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="ori_tras_workplaceFrom"
                                name="ori_tras_workplaceFrom"
                                value={filterPurchase.ori_tras_workplaceFrom}
                                onChange={handleSearchWorkPlaceFrom}
                                onKeyDown={handleKeyDownWorkPlaceFrom}
                                label={errorsFiltersPurchaseSignature.ori_tras_workplaceFrom!=null?t(errorsFiltersPurchaseSignature.ori_tras_workplaceFrom):t('Text_Filter_WorkPlace_From')}
                                placeholder={t('PlaceHolder_Filter_WorkPlace_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined" 
                              />
                              {/* Desplegable dinámico */}
                              {filteredWorkPlaceFrom.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                >
                                  {filteredWorkPlaceFrom.map((store, index) => (
                                    <MenuItem
                                        key={store.id}
                                        onClick={() => handleSelectWorkPlaceFrom(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexWorkPlaceFrom ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexWorkPlaceFrom ? "white" : "black",
                                        }}
                                    >
                                      {store.workplace}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="ori_tras_workplaceTo"
                                name="ori_tras_workplaceTo"
                                value={filterPurchase.ori_tras_workplaceTo}
                                onChange={handleSearchWorkPlaceTo}
                                onKeyDown={handleKeyDownWorkPlaceTo}
                                label={errorsFiltersPurchaseSignature.ori_tras_workplaceTo!=null?t(errorsFiltersPurchaseSignature.ori_tras_workplaceTo):t('Text_Filter_WorkPlace_To')}
                                placeholder={t('PlaceHolder_Filter_WorkPlace_To')}
                                variant="outlined"
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                              />
                              {/* Desplegable dinámico */}
                              {filteredWorkPlaceTo.length > 0 && ( 
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                >
                                  {filteredWorkPlaceTo.map((store, index) => (
                                    <MenuItem
                                      key={store.id}
                                        onClick={() => handleSelectWorkPlaceTo(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexWorkPlaceTo ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexWorkPlaceTo ? "white" : "black",
                                        }}
                                      >
                                        {store.workplace}
                                      </MenuItem>
                                  ))}
                                </div>
                            )}    
                            </span>
                          </div> 

                          <div className="form-group">
                            <span style={{ position: "relative" }}  onBlur={handleBlur}>
                            {/* Campo de texto para escribir y filtrar */}
                              <TextField
                                id="rel_trans_store_idFrom"
                                name="rel_trans_store_idFrom"
                                value={filterPurchase.rel_trans_store_idFrom}
                                onChange={handleSearchTransFrom}
                                onKeyDown={handleKeyDownTransFrom}
                                label={errorsFiltersPurchaseSignature.rel_trans_store_idFrom!=null?t(errorsFiltersPurchaseSignature.rel_trans_store_idFrom):t('Text_Filter_Trans_From')}
                                placeholder={t('PlaceHolder_Filter_Trans_From')}
                                variant="outlined"
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              />
                              {/* Desplegable dinámico */}
                              {filteredTransFrom.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                >
                                  {filteredTransFrom.map((store, index) => (
                                    <MenuItem
                                        key={store.id}
                                        onClick={() => handleSelectTransFrom(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexTransFrom ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexTransFrom ? "white" : "black",
                                        }}
                                    >
                                      {store.documentid} - {store.tradename}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="rel_trans_store_idTo"
                                  name="rel_trans_store_idTo"
                                  value={filterPurchase.rel_trans_store_idTo}
                                  onChange={handleSearchTransTo}
                                  onKeyDown={handleKeyDownTransTo}
                                  label={errorsFiltersPurchaseSignature.rel_trans_store_idTo!=null?t(errorsFiltersPurchaseSignature.rel_trans_store_idTo):t('Text_Filter_Trans_To')}
                                  placeholder={t('PlaceHolder_Filter_Trans_To')}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  variant="outlined"
                                />
                                {/* Desplegable dinámico */}
                                {filteredTransTo.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                  >
                                  {filteredTransTo.map((store, index) => (
                                    <MenuItem
                                      key={store.id}
                                        onClick={() => handleSelectTransTo(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexTransTo ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexTransTo ? "white" : "black",
                                        }}
                                      >
                                        {store.documentid} - {store.tradename}
                                      </MenuItem>
                                  ))}
                                </div>
                              )}
                            </span>  
                          </div> 

                          <div className="form-group">
                            {/* Campo de texto para escribir y filtrar */}
                            <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="vehiclestore_licenseplateFrom"
                                name="vehiclestore_licenseplateFrom"
                                value={filterPurchase.vehiclestore_licenseplateFrom}
                                onChange={handleSearchVehicleFrom}
                                onKeyDown={handleKeyDownVehicleFrom}
                                label={errorsFiltersPurchaseSignature.vehiclestore_licenseplateFrom!=null?t(errorsFiltersPurchaseSignature.vehiclestore_licenseplateFrom):t('Text_Filter_LicensePlate_From')}
                                placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined"
                              />
                              {/* Desplegable dinámico */}
                              {filteredVehicleFrom.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                >
                                  {filteredVehicleFrom.map((vehicle, index) => (
                                    <MenuItem
                                        key={vehicle.id}
                                        onClick={() => handleSelectVehicleFrom(vehicle)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexVehicleFrom ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexVehicleFrom ? "white" : "black",
                                        }}
                                    >
                                      {vehicle.vehiclestore_licenseplate}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                              </span>  
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="vehiclestore_licenseplateTo"
                                  name="vehiclestore_licenseplateTo"
                                  value={filterPurchase.vehiclestore_licenseplateTo}
                                  onChange={handleSearchVehicleTo}
                                  onKeyDown={handleKeyDownVehicleTo}
                                  label={errorsFiltersPurchaseSignature.vehiclestore_licenseplateTo!=null?t(errorsFiltersPurchaseSignature.vehiclestore_licenseplateTo):t('Text_Filter_LicensePlate_To')}
                                  placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  variant="outlined"
                                />
                                {/* Desplegable dinámico */}
                                {filteredVehicleTo.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                >
                                  {filteredVehicleTo.map((vehicle, index) => (
                                    <MenuItem
                                      key={vehicle.id}
                                        onClick={() => handleSelectVehicleTo(vehicle)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexVehicleTo ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexVehicleTo ? "white" : "black",
                                        }}
                                      >
                                        {vehicle.vehiclestore_licenseplate}
                                      </MenuItem>
                                  ))}
                                </div>
                              )}    
                            </span>  
                          </div>   
                          <div className="form-group">
                            <TextField
                              id={i18n.language==="es"?"paymentFromES":"paymentFromEN"}
                              name={i18n.language==="es"?"paymentFromES":"paymentFromEN"}
                              value={i18n.language==="es"?filterPurchase.paymentFromES:filterPurchase.paymentFromEN}
                              inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={i18n.language==="es"?errorsFiltersPurchaseSignature.paymentFromES!=null?t(errorsFiltersPurchaseSignature.paymentFromES):t('Text_Filter_payment_From'):errorsFiltersPurchaseSignature.paymentFromEN!=null?t(errorsFiltersPurchaseSignature.paymentFromEN):t('Text_Filter_payment_From')}
                              placeholder={t('PlaceHolder_Filter_payment_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseSignature}
                              onChange={handleChangeFilterEditPurchaseSignature}
                              select
                              SelectProps={{
                              native: true,
                              }}>
                              <option key={0} value=""/>
                              {paymenetTypeSelect.map((option) => (
                              <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                  {i18n.language==="es"?option.namees:option.nameen}
                              </option>
                              ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id={i18n.language==="es"?"paymentToES":"paymentToEN"}
                              name={i18n.language==="es"?"paymentToES":"paymentToEN"}
                              value={i18n.language==="es"?filterPurchase.paymentToES:filterPurchase.paymentToEN}
                              inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={i18n.language==="es"?errorsFiltersPurchaseSignature.paymentToES!=null?t(errorsFiltersPurchaseSignature.paymentToES):t('Text_Filter_payment_To'):errorsFiltersPurchaseSignature.paymentToEN!=null?t(errorsFiltersPurchaseSignature.paymentToEN):t('Text_Filter_payment_To')}
                              placeholder={t('PlaceHolder_Filter_payment_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseSignature}
                              onChange={handleChangeFilterEditPurchaseSignature}
                              select
                              SelectProps={{
                              native: true,
                              }}>
                              <option key={0} value=""/>
                              {paymenetTypeSelect.filter(item=>(i18n.language==="es"?item.namees>=filterPurchase.paymentFromES:item.nameen>=filterPurchase.paymentFromEN)).map(option => (
                              <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                  {i18n.language==="es"?option.namees:option.nameen}
                              </option>
                              ))}
                              </TextField>
                          </div>  
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditPurchaseSignature}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersPurchaseSignature}>{t('Button_Cancel_Filters')}</button>
                        </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
              </div>
              }  
              <PurchaseSignaturePDF 
                rowEditPurchaseSignature={rowEditPurchaseSignature} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />

              {/* End Content Row */}
          </>:
            <LoadingNews/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

PurchaseSignatureFilter.propTypes = {
  showModalPurchasesSignatureSettings: PropTypes.bool.isRequired,
  setShowModalPurchasesSignatureSettings: PropTypes.func.isRequired 
};

export default PurchaseSignatureFilter;

