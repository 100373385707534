import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import TableSortingStore from 'pages/stores/SortingTablesStore'; 
import PropTypes from 'prop-types';

const initialFiltersStore= {
  "idFrom":"",
  "idTo":"",
  "documentIdFrom":"",
  "documentIdTo":"",
  "tradenameFrom":"",
  "tradenameTo":"",
  "nameFrom":"",
  "nameTo":"",
  "contacpersonFrom":"",
  "contacpersonTo":"", 
  "cnaeFrom":"",
  "cnaeTo":"",
  "accountingentryFrom":"",
  "accountingentryTo":"",
  "enabled":true,
  "notenabled":true,
  "vehicleStoreFrom":"",
  "vehicleStoreTo":"",
  "emailsFrom":"",
  "emailsTo":"",
  "phonesFrom":"",
  "phonesTo":""
};
 
const initialStateStore = [{
  "id":"",
  "documentid":"",
  "tradename":"",
  "name":"",
  "contacperson":"",
  "cnae":"",
  "accountingentry":"",
  "economicactivity":"",
  "createdate":"",
  "comments":"",
  "enabled" : true,
  "color_id":0,
  "color_codehex":"",
  "documnettype_id":0,
  "documnettype_name":"",
  "extphone_id":"",
  "emails" : [
    {
      "id":"",
      "email":"",
      "defaultemail":"",
    }
  ],
  "phones" : [
    {
      "id":"",
      "phone":"",
      "defaultemail":"",

    }
  ],
  "vehicles" : [
    {
      "id":"",
      "licenseplate":"",
      "defaultvehicle":"",
      "tara":0,
      "pma":"",
      "checkpma":true,
      "defauldriver":"",
      "enabled":true
    }
  ]
}];
             
const StoresFilter = ({ applyFiltersStore, setApplyFiltersStore, showModalStoreSettings, setShowModalStoreSettings}) => {  
  const {t,i18n} = useTranslation(['listDataStores']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const NON_ERASABLE_STORE_ID = useSelector((state) => state.variablesUser.NON_ERASABLE_STORE_ID); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_STORE = useSelector((state) => state.formatDIUser.delete_code_store); 
  const [dataStore, setDataStore] = useState(initialStateStore);
  const [filtersStore, setFiltersStore]= useState(applyFiltersStore);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegStore,setNumTotRegStore]= useState(0);
  const [errorsFiltersStore, setErrorsFiltersStore] = useState({});
  const [inputTextStore, setInputTextStore] = useState(""); 
  const [openCreateDataStore,setOpenCreateDataStore]= useState(false);
  const [openDeleteRowStore,setOpenDeleteRowStore]= useState(false);
  const [openEditRowStore,setOpenEditRowStore]= useState(false);
  const [openEditFilterStore,setOpenEditFilterStore]= useState(false);
  const [rowEditStore,setRowEditStore]= useState(initialStateStore[0]);
  const [isLoadingStore, setIsLoadingStore] = useState(true);  
  const [isLoadingErrorStore, setIsLoadingErrorStore] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffsetStore, setActiveOffsetStore]= useState(false);
  const [currencyOffsetStore, setCurrencyOffsetStore] = useState(0);
  const [rangeOffsetStore, setRangeOffsetStore] = useState([{}]);

  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalStoreSettings) {
        setDataStore(initialStateStore);
        setFiltersStore(initialFiltersStore);
        setNumTotRegFilter(0);
        setNumTotRegStore(0);
        setErrorsFiltersStore({});
        setInputTextStore(""); 
        setOpenCreateDataStore(false);
        setOpenDeleteRowStore(false);
        setOpenEditRowStore(false);
        setOpenEditFilterStore(false);
        setRowEditStore(initialStateStore[0]);
        setIsLoadingStore(false);
        setIsLoadingErrorStore(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffsetStore(false);
        setCurrencyOffsetStore(0);
        setRangeOffsetStore([{}]);
        setShowModalStoreSettings(false);
        setApplyFiltersStore(initialFiltersStore);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataStore,
      setFiltersStore,
      setNumTotRegFilter,
      setNumTotRegStore,
      setErrorsFiltersStore,
      setInputTextStore, 
      setOpenCreateDataStore,
      setOpenDeleteRowStore,
      setOpenEditRowStore,
      setOpenEditFilterStore,
      setRowEditStore,
      setIsLoadingStore,
      setIsLoadingErrorStore,
      setIdCodeDelete,
      setActiveOffsetStore,
      setCurrencyOffsetStore,
      setRangeOffsetStore,
      setApplyFiltersStore, 
      showModalStoreSettings, 
      setShowModalStoreSettings,
      DELETE_CODE,
      navigate]
  );
  
  const closeModalStores = () => {    
    setDataStore(initialStateStore);
    setFiltersStore(initialFiltersStore);
    setNumTotRegFilter(0);
    setNumTotRegStore(0);
    setErrorsFiltersStore({});
    setInputTextStore(""); 
    setOpenCreateDataStore(false);
    setOpenDeleteRowStore(false);
    setOpenEditRowStore(false);
    setOpenEditFilterStore(false);
    setRowEditStore(initialStateStore[0]);
    setIsLoadingStore(false);
    setIsLoadingErrorStore(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffsetStore(false);
    setCurrencyOffsetStore(0);
    setRangeOffsetStore([{}]);
    setShowModalStoreSettings(false);
    setApplyFiltersStore(initialFiltersStore);
    navigate("/home");
    return () => {};
  };

  const openCreateOrEditDataStore = () => {
    setInputTextStore(""); 
    setOpenCreateDataStore(true);
    navigate("/storedetail/0"); 
  };
  
  const openEditFiltersStore = () => {
    setFiltersStore(applyFiltersStore);
    setOpenEditFilterStore(!openEditFilterStore);
  };

  const handleSubmitFilterEditStore = () => {
    setErrorsFiltersStore(validateFormFilterStore());
    if (Object.keys(errorsFiltersStore).length === 0) {
      setApplyFiltersStore(filtersStore);
      setFiltersStore(initialFiltersStore);
      setErrorsFiltersStore({});
      setOpenEditFilterStore(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  const handleDeleteFiltersStore = () => {
    setErrorsFiltersStore({});
    setFiltersStore(initialFiltersStore);
    setApplyFiltersStore(initialFiltersStore);
    setOpenEditFilterStore(!openEditFilterStore);
  };

  const handleCancelFiltersStore = () => {
    setErrorsFiltersStore({});
    setFiltersStore(initialFiltersStore);
    setOpenEditFilterStore(!openEditFilterStore);
  };

  const handleChangeEnabledFilter = (e) => {
    setFiltersStore(filtersStore => ({
      ...filtersStore,
      enabled: !filtersStore.enabled
    }));
  };

  const handleChangeNotEnabledFilter = (e) => {
    setFiltersStore(filtersStore => ({
      ...filtersStore,
      notenabled: !filtersStore.notenabled
    }));
  };

  const handleChangeFilterEditStore = (e) => {
    const { name, value } = e.target;
    setFiltersStore(filtersStore => ({
      ...filtersStore,
      [name]: value
    }));
  };

  const handleBlurFilterEditStore = (e) => {
    setErrorsFiltersStore(validateFormFilterStore());
    handleChangeFilterEditStore(e);
  };

  const toggleDrawerFilterEditStore = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditStore();
    }
  };

  const validateFormFilterStore = () => {

    let errorsFiltersStore = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (filtersStore.idFrom!=="" & !regexNumber.test(filtersStore.idFrom)) {
      errorsFiltersStore.idFrom = 'Text_TextNoNumber';
    }
    if (filtersStore.idTo!=="" & !regexNumber.test(filtersStore.idTo)) {
      errorsFiltersStore.idTo = 'Text_TextNoNumber';
    }
    if (filtersStore.idFrom!=="" & !errorsFiltersStore.idFrom!==undefined){
      if (filtersStore.idTo!=="" & !errorsFiltersStore.idTo!==undefined){
        if (parseInt(filtersStore.idTo)<parseInt(filtersStore.idFrom)){
          errorsFiltersStore.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersStore.documentIdFrom)) {
      errorsFiltersStore.documentIdFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.documentIdTo)) {
      errorsFiltersStore.documentIdTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.tradenameFrom)) {
      errorsFiltersStore.tradenameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.tradenameTo)) {
      errorsFiltersStore.tradenameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.nameFrom)) {
      errorsFiltersStore.nameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.nameTo)) {
      errorsFiltersStore.nameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.contacpersonFrom)) {
      errorsFiltersStore.contacpersonFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.contacpersonTo)) {
      errorsFiltersStore.contacpersonTo = 'Text_TextNoValid';
    } 
  
    if (regexInvalidCharacters.test(filtersStore.cnaeFrom)) {
      errorsFiltersStore.cnaeFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.cnaeTo)) {
      errorsFiltersStore.cnaeTo = 'Text_TextNoValid';
    } 

    if (regexInvalidCharacters.test(filtersStore.accountingentryFrom)) {
      errorsFiltersStore.accountingentryFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.accountingentryTo)) {
      errorsFiltersStore.accountingentryTo = 'Text_TextNoValid';
    } 
  
    if (regexInvalidCharacters.test(filtersStore.vehicleStoreFrom)) {
      errorsFiltersStore.vehicleStoreFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.vehicleStoreTo)) {
      errorsFiltersStore.vehicleStoreTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.emailsFrom)) {
      errorsFiltersStore.emailsFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.emailsTo)) {
      errorsFiltersStore.emailsTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.phonesFrom)) {
      errorsFiltersStore.phonesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersStore.phonesTo)) {
      errorsFiltersStore.phonesTo = 'Text_TextNoValid';
    } 
    if (filtersStore.documentIdTo!=="" & filtersStore.documentIdTo<filtersStore.documentIdFrom){
      errorsFiltersStore.documentIdTo = 'Text_TextGreater_documentid';
    }
    if (filtersStore.tradenameTo!=="" & filtersStore.tradenameTo<filtersStore.tradenameFrom){
      errorsFiltersStore.tradenameTo = 'Text_TextGreater_tradename';
    }
    if (filtersStore.nameTo!=="" & filtersStore.nameTo<filtersStore.nameFrom){
      errorsFiltersStore.nameTo = 'Text_TextGreater_name';
    }
    if (filtersStore.contacpersonTo!=="" & filtersStore.contacpersonTo<filtersStore.contacpersonFrom){
      errorsFiltersStore.contacpersonTo = 'Text_TextGreater_contactName';
    }
   
    if (filtersStore.cnaeTo!=="" & filtersStore.cnaeTo<filtersStore.cnaeFrom){
      errorsFiltersStore.cnaeTo = 'Text_TextGreater_CNAE';
    }

    if (filtersStore.accountingentryTo!=="" & filtersStore.accountingentryTo<filtersStore.accountingentryFrom){
      errorsFiltersStore.accountingentryTo = 'Text_TextGreater_AccountingEntry';
    }
     
    if (filtersStore.vehicleStoreTo!=="" & filtersStore.vehicleStoreTo<filtersStore.vehicleStoreFrom){
      errorsFiltersStore.vehicleStoreTo = 'Text_TextGreater_vehicles';
    }

    if (filtersStore.emailsTo!=="" & filtersStore.emailsTo<filtersStore.emailsFrom){
      errorsFiltersStore.emailsTo = 'Text_TextGreater_emails';
    }

    if (filtersStore.phonesTo!=="" & filtersStore.phonesTo<filtersStore.phonesFrom){
      errorsFiltersStore.phonesTo = 'Text_TextGreater_phones';
    }
    return errorsFiltersStore;
  }  

  const filteredDataStore = dataStore.filter((el) => {
    if (inputTextStore === '') {
        return el;
    }
    else {
        let dataTranslate=el.id+el.documentid+el.tradename+el.contacperson+el.nima+el.cnae+el.pygr
        +(el.vehicles!==undefined && el.vehicles!==null && el.vehicles.length>0?el.vehicles.map(current => {return (current.licenseplate )}):"")
        +(el.emails!==undefined && el.emails!==null && el.emails.length>0?el.emails.map(current => {return (current.email )}):"")
        +(el.phones!==undefined && el.phones!==null && el.phones.length>0?el.phones.map(current => {return (current.phone )}):"");
        return dataTranslate.toLowerCase().includes(inputTextStore);
    }
  })

 
  let inputHandlerStore = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextStore(lowerCase);
  };
  
  const handleChangeDeleteRowStore = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };
  
  const handleCancelDeleteRowStore = () => {
    setRowEditStore(initialStateStore[0]);
    setIsLoadingStore(false);
    setIsLoadingErrorStore(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowStore(false);
  };

  const handleCloseDeleteRowStore = (e) => {
    if (idCodeDelete !== DELETE_CODE_STORE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditStore.id !== '') {
     
        const fetchData = async () => {
          setIsLoadingStore(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `store/deletestore`, token, rowEditStore);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingStore(false);
          } finally {
            if (isMounted && isLoadingStore!==undefined) {
              setIsLoadingStore(false);
            }
          }
        };
        
        fetchData();
      
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIsLoadingErrorStore(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowStore(false);
    handleCloseDataEditStore();
    return () => { isMounted = false;};
  };
 
  const handleCloseDataEditStore = () => { 
    setOpenCreateDataStore(false);
    setOpenEditRowStore(false);
    setRowEditStore(openEditRowStore?rowEditStore:initialStateStore[0]);
    setIsLoadingStore(false);
    setIsLoadingErrorStore(false);
    toggleDrawerFilterEditStore(false);
	};

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegStore > LIMITPAGESIZE) {
      setActiveOffsetStore(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegStore; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetStore(newRangeOffset);
    } else {
      setCurrencyOffsetStore(0);
      setActiveOffsetStore(false);
      setRangeOffsetStore([{}]);
    }
  }, [numTotRegStore, LIMITPAGESIZE]);

  useEffect(() => {
    let isMounted = true;
    const fetchnumTotReg = async () => {
      setIsLoadingStore(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `store/getinformationtablestore`, token);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataStore(initialStateStore);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setNumTotRegStore(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingStore(false);
      } finally {
        if (isMounted) {
          setIsLoadingStore(false);
        }
      }
    };

    const fetchnumTotRegFilter = async () => {
      setIsLoadingStore(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `store/getcountfilterstore`, token, applyFiltersStore);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataStore(initialStateStore);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingStore(false);
      } finally {
        if (isMounted) {
          setIsLoadingStore(false);
        }
      }
    };

    const fetchDataStore = async () => {
      setIsLoadingStore(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT, `store/simplestores`, token, currencyOffsetStore, LIMITPAGESIZE, applyFiltersStore);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataStore(initialStateStore);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setDataStore(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingStore(false);
      } finally {
        if (isMounted) {
          setIsLoadingStore(false);
        }
      }
    };

    if (showModalStoreSettings) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchDataStore();
    }
    return () => { isMounted = false; };

  }, [ENDPOINT,t,i18n,token,dispatch,openCreateDataStore,openDeleteRowStore,setOpenDeleteRowStore,rowEditStore,showModalStoreSettings,LIMITPAGESIZE,applyFiltersStore,currencyOffsetStore,setIsLoadingStore]);
 
  return (
    <>
      {showModalStoreSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h1NewStyle mb-2">
            {isLoadingErrorStore?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingStore?<CircularProgress color={openDeleteRowStore?"warning":"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_Stores_Table')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Stores_Create')) ?
              <div>
                <button type="button" className="buttonCreate" onClick={openCreateOrEditDataStore}>{t('Button_Create')}</button><> </>
                <button type="button" className="buttonBack" onClick={closeModalStores}>{t('Button_Back')}</button><> </>
              </div>:
              <div>
                <button type="button" className="buttonBack" onClick={closeModalStores}>{t('Button_Back')}</button><> </>
              </div>
             
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextStore}
              placeholder={t('Text_Store_Search')} 
              onChange={inputHandlerStore}
              fullWidth
              label={t('Text_Store_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.idFrom!=="" || applyFiltersStore.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.documentIdFrom!=="" || applyFiltersStore.documentIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_documentid')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.tradenameFrom!=="" || applyFiltersStore.tradenameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_tradename')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.nameFrom!=="" || applyFiltersStore.nameTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_name')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.contacpersonFrom!=="" || applyFiltersStore.contacpersonTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_contacperson')}</button><> </> 
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.cnaeFrom!=="" || applyFiltersStore.cnaeTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_CNAE')}</button><> </> 
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.enabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_enabled_Upper')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.notenabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_disabled')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersStore}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersStore.vehicleStoreFrom!==""
                      || applyFiltersStore.vehicleStoreTo!==""
                      || applyFiltersStore.emailsFrom!==""
                      || applyFiltersStore.emailsTo!==""
                      || applyFiltersStore.phonesFrom!==""
                      || applyFiltersStore.phonesTo!==""
                      )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Others_Filters')}</button><> </> 
                
            </h1>
          </div>
          <br/>

          {!isLoadingStore ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingStore
                    dataStore={filteredDataStore}
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={t('Text_Stores_Table')}
                    setOpenDeleteRowStore={setOpenDeleteRowStore}
                    setRowEditStore={setRowEditStore}
                    showEditButtonStore={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Stores_Edit'))?true:false}
                    showDeleteButtonStore={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Stores_Delete'))?true:false}
                    numTotRegStore={numTotRegStore}
                    activeOffsetStore={activeOffsetStore}
                    rangeOffsetStore={rangeOffsetStore}
                    currencyOffsetStore={currencyOffsetStore}
                    setCurrencyOffsetStore={setCurrencyOffsetStore}
                    minvalue={Number(NON_ERASABLE_STORE_ID)}>
                  </TableSortingStore>
                </div>             
              </div>
              {openEditFilterStore &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditStore(false)}
                 onOpen={toggleDrawerFilterEditStore(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfilteemployee" onSubmit={handleCancelFiltersStore}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                               id="idFrom" 
                               name="idFrom"
                               value={filtersStore.idFrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersStore.idFrom!=null?t(errorsFiltersStore.idFrom):t('Text_Filter_Code_From')}
                               placeholder={t('PlaceHolder_Filter_Code_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditStore}
                               onChange={handleChangeFilterEditStore}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                               id="idTo" 
                               name="idTo"
                               value={filtersStore.idTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersStore.idTo!=null?t(errorsFiltersStore.idTo):t('Text_Filter_Code_To')}
                               placeholder={t('PlaceHolder_Filter_Code_to')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditStore}
                               onChange={handleChangeFilterEditStore}/>
                           </div>
                           <div className="form-group">
                            <TextField
                              id="documentIdFrom" 
                              name="documentIdFrom"
                              value={filtersStore.documentIdFrom}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.documentIdFrom!=null?t(errorsFiltersStore.documentIdFrom):t('Text_Filter_documentId_From')}
                              placeholder={t('PlaceHolder_Filter_documentid_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="documentIdTo" 
                              name="documentIdTo"
                              value={filtersStore.documentIdTo}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.documentIdTo!=null?t(errorsFiltersStore.documentIdTo):t('Text_Filter_documentId_To')}
                              placeholder={t('PlaceHolder_Filter_documentid_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>
                           <div className="form-group">
                            <TextField
                              id="tradenameFrom" 
                              name="tradenameFrom"
                              value={filtersStore.tradenameFrom}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.tradenameFrom!=null?t(errorsFiltersStore.tradenameFrom):t('Text_Filter_tradename_From')}
                              placeholder={t('PlaceHolder_Filter_tradename_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id="tradenameTo" 
                              name="tradenameTo"
                              value={filtersStore.tradenameTo}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.tradenameTo!=null?t(errorsFiltersStore.tradenameTo):t('Text_Filter_tradename_To')}
                              placeholder={t('PlaceHolder_Filter_tradename_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>
                          <div className="form-group">
                            <TextField
                              id="nameFrom" 
                              name="nameFrom"
                              value={filtersStore.nameFrom}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.nameFrom!=null?t(errorsFiltersStore.nameFrom):t('Text_Filter_name_From')}
                              placeholder={t('PlaceHolder_Filter_name_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id="nameTo" 
                              name="nameTo"
                              value={filtersStore.nameTo}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.nameTo!=null?t(errorsFiltersStore.nameTo):t('Text_Filter_name_To')}
                              placeholder={t('PlaceHolder_Filter_name_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>
                          <div className="form-group">
                            <TextField
                              id="contacpersonFrom" 
                              name="contacpersonFrom"
                              value={filtersStore.contacpersonFrom}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.contacpersonFrom!=null?t(errorsFiltersStore.contacpersonFrom):t('Text_Filter_contactName_From')}
                              placeholder={t('PlaceHolder_Filter_contactName_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="contacpersonTo" 
                              name="contacpersonTo"
                              value={filtersStore.contacpersonTo}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.contacpersonTo!=null?t(errorsFiltersStore.contacpersonTo):t('Text_Filter_contactName_To')}
                              placeholder={t('PlaceHolder_Filter_contactName_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>
                          
                          <div className="form-group">
                            <TextField
                              id="cnaeFrom" 
                              name="cnaeFrom"
                              value={filtersStore.cnaeFrom}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.cnaeFrom!=null?t(errorsFiltersStore.cnaeFrom):t('Text_Filter_CNAE_From')}
                              placeholder={t('PlaceHolder_Filter_CNAE_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="cnaeTo" 
                              name="cnaeTo"
                              value={filtersStore.cnaeTo}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.cnaeTo!=null?t(errorsFiltersStore.cnaeTo):t('Text_Filter_CNAE_To')}
                              placeholder={t('PlaceHolder_Filter_CNAE_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>

                          <div className="form-group">
                            <TextField
                              id="accountingentryFrom" 
                              name="accountingentryFrom"
                              value={filtersStore.accountingentryFrom}
                              inputProps={{ style: {width: 400}, maxLength: 30 }}
                              label={errorsFiltersStore.accountingentryFrom!=null?t(errorsFiltersStore.cnaeFrom):t('Text_Filter_AccountingEntry_From')}
                              placeholder={t('PlaceHolder_Filter_AccountingEntry_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="accountingentryTo" 
                              name="accountingentryTo"
                              value={filtersStore.accountingentryTo}
                              inputProps={{ style: {width: 400}, maxLength: 30 }}
                              label={errorsFiltersStore.accountingentryTo!=null?t(errorsFiltersStore.accountingentryTo):t('Text_Filter_AccountingEntry_To')}
                              placeholder={t('PlaceHolder_Filter_AccountingEntry_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>

                          <div className="form-group">
                            <TextField
                              id="vehicleStoreFrom" 
                              name="vehicleStoreFrom"
                              value={filtersStore.vehicleStoreFrom}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.vehicleStoreFrom!=null?t(errorsFiltersStore.vehicleStoreFrom):t('PlaceHolder_Filter_vehicles_From')}
                              placeholder={t('PlaceHolder_Filter_vehicles_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="vehicleStoreTo" 
                              name="vehicleStoreTo"
                              value={filtersStore.vehicleStoreTo}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.vehicleStoreTo!=null?t(errorsFiltersStore.vehicleStoreTo):t('PlaceHolder_Filter_vehicles_to')}
                              placeholder={t('PlaceHolder_Filter_vehicles_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>
                          <div className="form-group">
                            <TextField
                              id="emailsFrom" 
                              name="emailsFrom"
                              value={filtersStore.emailsFrom}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.emailsFrom!=null?t(errorsFiltersStore.emailsFrom):t('Text_Filter_emails_From')}
                              placeholder={t('PlaceHolder_Filter_emails_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="emailsTo" 
                              name="emailsTo"
                              value={filtersStore.emailsTo}
                              inputProps={{ style: {width: 400}, maxLength: 100 }}
                              label={errorsFiltersStore.emailsTo!=null?t(errorsFiltersStore.emailsTo):t('Text_Filter_emails_To')}
                              placeholder={t('PlaceHolder_Filter_emails_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>
                          <div className="form-group">
                            <TextField
                              id="phonesFrom" 
                              name="phonesFrom"
                              value={filtersStore.phonesFrom}
                              inputProps={{ style: {width: 400}, maxLength: 30 }}
                              label={errorsFiltersStore.phonesFrom!=null?t(errorsFiltersStore.phonesFrom):t('Text_Filter_phones_From')}
                              placeholder={t('PlaceHolder_Filter_phones_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="phonesTo" 
                              name="phonesTo"
                              value={filtersStore.phonesTo}
                              inputProps={{ style: {width: 400}, maxLength: 30 }}
                              label={errorsFiltersStore.phonesTo!=null?t(errorsFiltersStore.phonesTo):t('Text_Filter_phones_To')}
                              placeholder={t('PlaceHolder_Filter_phones_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditStore}
                              onChange={handleChangeFilterEditStore}/>
                          </div>
                           
                          <div className="form-group">
                            <Checkbox
                              id="enabled" 
                              name="enabled"
                              checked={filtersStore.enabled}
                              onChange={handleChangeEnabledFilter}
                              variant="standard"/>
                            &nbsp;&nbsp;    
                            <label className="text-primary">{t('Text_Filter_Habilty')}</label>  
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Checkbox
                              id="notenabled" 
                              name="notenabled"
                              checked={filtersStore.notenabled}
                              onChange={handleChangeNotEnabledFilter}
                              variant="standard"/>
                            &nbsp;&nbsp;    
                            <label className="text-primary">{t('Text_Filter_Not_Habilty')}</label>    
                          </div>
                             
                         </div>
                         <hr className="sidebar-divider d-none d-md-block"/>
                         <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                           <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditStore}>{t('Button_Apply')}</button><> </>
                           <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersStore}>{t('Button_Cancel_Filters')}</button>
                         </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
             </div>
              }  
              <Dialog open={openDeleteRowStore} onClose={handleCancelDeleteRowStore}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditStore.id}  - {rowEditStore.tradename} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="idCode"
                      label={t('Text_Label')}
                      type="text"
                      fullWidth
                      variant="standard"
                      error
                      defaultValue={idCodeDelete}
                      onChange={handleChangeDeleteRowStore}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowStore}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowStore}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 
              {/* End Content Row */}
            </>:
              <Loading isLoadingData={isLoadingStore}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

StoresFilter.propTypes = {
  applyFiltersStore: PropTypes.object.isRequired,
  setApplyFiltersStore: PropTypes.func.isRequired,
  showModalStoreSettings: PropTypes.bool.isRequired,
  setShowModalStoreSettings: PropTypes.func.isRequired
};

export default StoresFilter;