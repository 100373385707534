import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  defaultStoreIdFrom:"",
  defaultStoreIdTo:"",
  useraccountIdFrom:"",
  useraccountIdTo:"",
  isFinished:false,
  isNotFinished:true,
  numberticketFrom:"",
  numberticketTo:"",
  start_date_ticketFrom:null,
	start_date_ticketTo:null,
  end_date_ticketFrom:null,
  end_date_tickettTo:null,
  vehiclestore_licenseplateFrom:"",
  vehiclestore_licenseplateTo:"",
  rel_trans_store_idFrom:"",
  rel_trans_store_idTo:"",
  ori_tras_store_idFrom:"",
  ori_tras_store_idTo:"",
  numberDIFROM:"",
  numberDITO:"",
};

const filtersTicketsSlice = createSlice({
  name: 'filtersTicket',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersTicketsSlice.actions;
export default filtersTicketsSlice.reducer;
