import React, {  useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box';    
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';    
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading";
import TableSortingTicketsFormDI from "pages/tickets/ticketsEdit/SortingTablesTicketsFormDI";
import PropTypes from 'prop-types';
 
const initialFiltersFormDI= {
  "idFrom":"",
  "idTo":"",
  "preiddocSheetFrom":"",
  "preiddocSheetTo":"",
  "sufiddocSheetFrom":"",
  "sufiddocSheetTo":"",
  "numberDTFrom":"",
  "numberDTTo":"",
  "dateSheetFrom":null,
  "dateSheetTo":null
};
     
const initialStateFormDI = [{
  "id" : 0, 
  "preiddoc_sheet" : "",
  "sufiddoc_sheet" : "", 
  "number_dt": "",
  "date_sheet" : "",
  "rel_trans_documentid": "",
  "rel_trans_tradename": "",
  "rel_trans_licenseplate":"",
  "codeler_code" : "",
  "codeler_namees" : "" ,
  "codeler_nameen" : "" 
}];  

const TicketsSearchDI = ({ showModalSearchDI, setShowModalSearchDI,editRowArticles,setEditRowArticles,form}) => {
  const {t,i18n} = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token); 
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const [dataFormDI, setDataFormDI] = useState(initialStateFormDI);
  const [isLoadingErrorFormDI, setIsLoadingErrorFormDI] = useState(false);
  const [isLoadingFormDI, setisLoadingFormDI] = useState(false);  
  const [inputTextFormDI, setInputTextFormDI] = useState("");
  const [applyFiltersFormiDI, setApplyFiltersFormiDI]= useState(initialFiltersFormDI);
  const [filtersFormDI, setFiltersFormDI]= useState(initialFiltersFormDI);
  const [openEditFilterFormDI,setOpenEditFilterFormDI]= useState(false);
  const [errorsfiltersFormDI, seterrorsfiltersFormDI] = useState({});
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegFormDI,setNumTotRegFormDI]= useState(0);
  const [activeOffsetFormDI, setActiveOffsetFormDI]= useState(false);
  const [currencyOffsetFormDI, setCurrencyOffsetFormDI] = useState(0);
  const [rangeOffsetFormDI, setRangeOffsetFormDI] = useState([{}]); 
  const dispatch = useDispatch(); 
   
  const handleCloseDataEditFormDI = () => {
    setDataFormDI(initialStateFormDI);
    setIsLoadingErrorFormDI(false);
    setisLoadingFormDI(false);
    setInputTextFormDI("");
    setApplyFiltersFormiDI(initialFiltersFormDI);
    setFiltersFormDI(initialFiltersFormDI);
    setOpenEditFilterFormDI(false);
    seterrorsfiltersFormDI({});
    setNumTotRegFilter(0);
    setNumTotRegFormDI(0);
    setActiveOffsetFormDI(false);
    setCurrencyOffsetFormDI(0);
    setRangeOffsetFormDI([{}]); 
    setShowModalSearchDI(false)
	};

  const toggleDrawerSearchFormDI  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditFormDI();
    }
  };

  let inputHandlerFormDI = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextFormDI(lowerCase);
  };

  const openEditfiltersFormDI = () => {
    setFiltersFormDI(applyFiltersFormiDI);
    setOpenEditFilterFormDI(!openEditFilterFormDI);
  };

  const toggleDrawerFilterEditFormDI = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditFormDI();
    }
  };

  const handleCancelfiltersFormDI = () => {
    seterrorsfiltersFormDI({});
    setFiltersFormDI(initialFiltersFormDI);
    setOpenEditFilterFormDI(!openEditFilterFormDI);
  };

  const handleChangeFilterEditFormDi = (e) => {
    const { name, value } = e.target;
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      [name]: value
    }));
  };

  const handleBlurFilterEditFormDI = (e) => {
    seterrorsfiltersFormDI(validateFormFilterFormDI());
    handleChangeFilterEditFormDi(e);
  };
 
  const handleSubmitFilterEditFormDI = () => {
    seterrorsfiltersFormDI(validateFormFilterFormDI());
    if (Object.keys(errorsfiltersFormDI).length === 0) {
      setApplyFiltersFormiDI(filtersFormDI);
      setFiltersFormDI(initialFiltersFormDI);
      seterrorsfiltersFormDI({});
      setOpenEditFilterFormDI(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  const handleDeletefiltersFormDI = () => {
    seterrorsfiltersFormDI({});
    setFiltersFormDI(initialFiltersFormDI);
    setApplyFiltersFormiDI(initialFiltersFormDI);
    setOpenEditFilterFormDI(!openEditFilterFormDI);
  };

  const handleChangeSelectDateSheetFrom = (event) => {
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      dateSheetFrom: event!==null?new Date(event):null,
      dateSheetTo:null
    }));
  };
 
  const handleBlurSelectDateSheetFrom = (e) => {
    handleChangeSelectDateSheetFrom(e);
    seterrorsfiltersFormDI(validateFormFilterFormDI());
  };

  const handleChangeSelectDateSheetTo = (event) => {
    setFiltersFormDI(filtersFormDI => ({
      ...filtersFormDI,
      dateSheetTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateSheetTo = (e) => {
    handleChangeSelectDateSheetTo(e);
    seterrorsfiltersFormDI(validateFormFilterFormDI());
  };

  const validateFormFilterFormDI = () => {

    let errorsfiltersFormDI = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersFormDI.idFrom!=="" & !regexNumber.test(filtersFormDI.idFrom)) {
      errorsfiltersFormDI.idFrom = 'Text_TextNoNumber';
    }
    if (filtersFormDI.idTo!=="" & !regexNumber.test(filtersFormDI.idTo)) {
      errorsfiltersFormDI.idTo = 'Text_TextNoNumber';
    }
    if (filtersFormDI.idFrom!=="" & !errorsfiltersFormDI.idFrom!==undefined){
      if (filtersFormDI.idTo!=="" & !errorsfiltersFormDI.idTo!==undefined){
        if (parseInt(filtersFormDI.idTo)<parseInt(filtersFormDI.idFrom)){
          errorsfiltersFormDI.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersFormDI.preiddocSheetFrom)) {
      errorsfiltersFormDI.preiddocSheetFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersFormDI.preiddocSheetTo)) {
      errorsfiltersFormDI.preiddocSheetTo = 'Text_TextNoValid';
    }
    
    if (filtersFormDI.sufiddocSheetFrom!=="" & !regexNumber.test(filtersFormDI.sufiddocSheetFrom)) {
      errorsfiltersFormDI.sufiddocSheetFrom = 'Text_TextNoNumber';
    }
    if (filtersFormDI.sufiddocSheetTo!=="" & !regexNumber.test(filtersFormDI.sufiddocSheetTo)) {
      errorsfiltersFormDI.sufiddocSheetTo = 'Text_TextNoNumber';
    } 

    if (regexInvalidCharacters.test(filtersFormDI.numberDTFrom)) {
      errorsfiltersFormDI.numberDTFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersFormDI.numberDTTo)) {
      errorsfiltersFormDI.numberDTTo = 'Text_TextNoValid';
    }

    if (filtersFormDI.preiddocSheetTo!=="" & filtersFormDI.preiddocSheetTo<filtersFormDI.preiddocSheetFrom){
      errorsfiltersFormDI.preiddocSheetTo = 'Text_TextGreater_PreiddocSheet';
    }

    if (filtersFormDI.sufiddocSheetTo!=="" & filtersFormDI.sufiddocSheetTo<filtersFormDI.sufiddocSheetFrom){
      errorsfiltersFormDI.sufiddocSheetTo = 'Text_TextGreater_SufiddocSheet';
    }

    if (filtersFormDI.numberDTTo!=="" & filtersFormDI.numberDTTo<filtersFormDI.numberDTFrom){
      errorsfiltersFormDI.numberDTTo = 'Text_TextGreater_numberDTTo';
    }
    return errorsfiltersFormDI;
  }   

  const filtereddataFormDI = dataFormDI.filter((el) => {
    if (inputTextFormDI === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.preiddoc_sheet || '') +
        String(el.sufiddoc_sheet || '');
      let result = dataTranslate.toLowerCase().includes(inputTextFormDI.toLowerCase());
      return result; 
    }
  })

  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegFormDI = async () => {
      try {
        setisLoadingFormDI(true);
        const getData = await helpHttp().get9(ENDPOINT,`ticketsgetdata/getinformationtableformdi`,token,defaultStore.id,form.rel_trans_store_id,form.vehiclestore_licenseplate,editRowArticles.article_id);
        if (getData.err) {
          setDataFormDI(initialStateFormDI);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegFormDI(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setisLoadingFormDI(false);
      }finally {
        setisLoadingFormDI(false);
      }
    };

    const fetchnumTotRegFilter = async () => {
      setisLoadingFormDI(true);
      try {
        const getData = await helpHttp().post9(ENDPOINT,`ticketsgetdata/getcountfilterformdi`,token,defaultStore.id,form.rel_trans_store_id,form.vehiclestore_licenseplate,editRowArticles.article_id,applyFiltersFormiDI);
        if (getData.err || getData.message === "Load failed") {
          setDataFormDI(initialStateFormDI);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setisLoadingFormDI(false);
      } finally {
        setisLoadingFormDI(false);
      }
    };

    const fetchdataFormDI = async () => {
      setisLoadingFormDI(true);
      try {
        const getData =  await helpHttp().post8(ENDPOINT,`ticketsgetdata/getformsdi`,token,currencyOffsetFormDI,LIMITPAGESIZE,defaultStore.id,form.rel_trans_store_id,form.vehiclestore_licenseplate,editRowArticles.article_id,applyFiltersFormiDI);
        if (getData.err || getData.message === "Load failed") {
          setDataFormDI(initialStateFormDI);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataFormDI(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setisLoadingFormDI(false);
      } finally {
        setisLoadingFormDI(false);
      }
    };
    
    if (showModalSearchDI){
      fetchnumTotRegFormDI();
      fetchnumTotRegFilter();
      fetchdataFormDI();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,i18n,token,dispatch,LIMITPAGESIZE,defaultStore.id,editRowArticles.article_id,form.rel_trans_store_id,form.vehiclestore_licenseplate,applyFiltersFormiDI,currencyOffsetFormDI,setisLoadingFormDI,showModalSearchDI]);

  return(
    <>
      {showModalSearchDI && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchFormDI(false)}
            onOpen={toggleDrawerSearchFormDI(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff', width: '1150px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  {isLoadingErrorFormDI?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingFormDI?<CircularProgress color={"primary"}/>:<i className="fas fa-regular fa-clipboard text-warning"></i>} {t('Text_DIs_Search')}</h1>
                    <div>
                      <button type="button" className="buttonCreate" onClick={handleCloseDataEditFormDI}>{t('Button_Exit')}</button><> </>
                    </div> 
                </div>  
                <br/>
                <div className="searchStyle">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{ style: {fontSize: '18px'} }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                    value={inputTextFormDI}
                    placeholder={t('Text_DI_Search')} 
                    onChange={inputHandlerFormDI}
                    fullWidth
                    label={t('Text_DI_Search')}>
                  </TextField>
                </div>
                <br/>
                <div id="myDIV">
                  <h1 className="h6NewStyleHigh mb-2">
                    {t('Text_Filter')}&nbsp;
                      <button type="button" className="buttonFilterHigh" onClick={openEditfiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersFormiDI.idFrom!=="" || applyFiltersFormiDI.idTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Code')}</button><> </>
                      <button type="button" className="buttonFilterHigh" onClick={openEditfiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersFormiDI.preiddocSheetFrom!=="" || applyFiltersFormiDI.preiddocSheetTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Prefix')}</button><> </>
                      <button type="button" className="buttonFilterHigh" onClick={openEditfiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersFormiDI.sufiddocSheetFrom!=="" || applyFiltersFormiDI.sufiddocSheetTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Sufix')}</button><> </> 
                      <button type="button" className="buttonFilterHigh" onClick={openEditfiltersFormDI}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersFormiDI.numberDTFrom!=="" || applyFiltersFormiDI.numberDTTo!=="" || applyFiltersFormiDI.dateSheetFrom!==null || applyFiltersFormiDI.dateSheetTo!==null)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Others_Filters')}</button><> </> 
                  </h1>
                </div>
                <br/>
                {!isLoadingFormDI ?
                  <>
                    <div className="card shadow mb-4">    
                      <div className="card-body">
                      <TableSortingTicketsFormDI
                        dataFormDI={filtereddataFormDI}
                        setEditRowArticles={setEditRowArticles}
                        numTotRegFilter={ numTotRegFilter}
                        titleTable={t('Text_DIs_AVAILABLE')}
                        numTotRegFormDI={numTotRegFormDI}
                        activeOffsetFormDI={activeOffsetFormDI}
                        rangeOffsetFormDI={rangeOffsetFormDI}
                        currencyOffsetFormDI={currencyOffsetFormDI}
                        setCurrencyOffsetFormDI={setCurrencyOffsetFormDI}
                        handleCloseDataEditFormDI={handleCloseDataEditFormDI}
                      >
                      </TableSortingTicketsFormDI>
                      </div>             
                    </div>
                    {openEditFilterFormDI &&
                      <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEditFormDI(false)}
                        onOpen={toggleDrawerFilterEditFormDI(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100' }}>
                            <br/>
                              <div id="myDIV">
                                <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                              </div>
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <form className="newfilterformstore" onSubmit={handleCancelfiltersFormDI}>  
                                <div className="form-floating mb-3 px-4 primary">
                                  <div className="form-group">
                                    <TextField
                                      id="idFrom" 
                                      name="idFrom"
                                      value={filtersFormDI.idFrom}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                      label={errorsfiltersFormDI.idFrom!=null?t(errorsfiltersFormDI.idFrom):t('Text_Filter_Code_From')}
                                      placeholder={t('PlaceHolder_Filter_Code_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDi}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="idTo" 
                                      name="idTo"
                                      value={filtersFormDI.idTo}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsfiltersFormDI.idTo!=null?t(errorsfiltersFormDI.idTo):t('Text_Filter_Code_To')}
                                      placeholder={t('PlaceHolder_Filter_Code_to')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDi}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="preiddocSheetFrom" 
                                      name="preiddocSheetFrom"
                                      value={filtersFormDI.preiddocSheetFrom}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                                      label={errorsfiltersFormDI.preiddocSheetFrom!=null?t(errorsfiltersFormDI.preiddocSheetFrom):t('Text_Filter_PreiddocSheet_From')}
                                      placeholder={t('PlaceHolder_Filter_PreiddocSheet_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDi}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="preiddocSheetTo" 
                                      name="preiddocSheetTo"
                                      value={filtersFormDI.preiddocSheetTo}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                      label={errorsfiltersFormDI.preiddocSheetTo!=null?t(errorsfiltersFormDI.preiddocSheetTo):t('Text_Filter_PreiddocSheet_To')}
                                      placeholder={t('PlaceHolder_Filter_PreiddocSheet_To')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDi}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="sufiddocSheetFrom" 
                                      name="sufiddocSheetFrom"
                                      value={filtersFormDI.sufiddocSheetFrom}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                      label={errorsfiltersFormDI.sufiddocSheetFrom!=null?t(errorsfiltersFormDI.sufiddocSheetFrom):t('Text_Filter_SufiddocSheet_From')}
                                      placeholder={t('PlaceHolder_Filter_SufiddocSheet_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDi}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="sufiddocSheetTo" 
                                      name="sufiddocSheetTo"
                                      value={filtersFormDI.sufiddocSheetTo}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                      label={errorsfiltersFormDI.sufiddocSheetTo!=null?t(errorsfiltersFormDI.sufiddocSheetTo):t('Text_Filter_SufiddocSheet_To')}
                                      placeholder={t('PlaceHolder_Filter_SufiddocSheet_To')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDi}/>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      id="numberDTFrom" 
                                      name="numberDTFrom"
                                      value={filtersFormDI.numberDTFrom}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}    
                                      label={errorsfiltersFormDI.numberDTFrom!=null?t(errorsfiltersFormDI.numberDTFrom):t('Text_Filter_NumberNT_From')}
                                      placeholder={t('PlaceHolder_Filter_NumberNT_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDi}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                      id="numberDTTo" 
                                      name="numberDTTo"
                                      value={filtersFormDI.numberDTTo}
                                      inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}    
                                      label={errorsfiltersFormDI.numberDTTo!=null?t(errorsfiltersFormDI.numberDTTo):t('Text_Filter_NumberNT_To')}
                                      placeholder={t('PlaceHolder_Filter_NumberNT_To')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditFormDI}
                                      onChange={handleChangeFilterEditFormDi}/>
                                  </div>

                                  <div className="form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                      <DesktopDatePicker
                                        id="dateSheetFrom" 
                                        name="dateSheetFrom"
                                        value={ filtersFormDI.dateSheetFrom!== null ? new Date(filtersFormDI.dateSheetFrom): null}
                                        format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                        label={errorsfiltersFormDI.dateSheetFrom!=null?t(errorsfiltersFormDI.dateSheetFrom):t('PlaceHolder_Filter_DateSheet_From')}
                                        onBlur={handleBlurSelectDateSheetFrom}
                                        onChange={handleChangeSelectDateSheetFrom}
                                        slotProps={{
                                          textField: {
                                            placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                            sx: {
                                              color: '#1565c0',
                                              maxLength: '100',
                                              width: 430,
                                              fontSize: '18px',
                                            },
                                            InputProps: {
                                              style: {
                                                fontSize: '18px',
                                              }
                                            },
                                            InputLabelProps:{ 
                                              style: {
                                                fontSize: '18px', 
                                                background: '#FFFFFF'
                                              }
                                            }, 
                                          },
                                        }}
                                      />
                                    </LocalizationProvider>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                      <DesktopDatePicker
                                        id="dateSheetTo" 
                                        name="dateSheetTo"
                                        minDate={filtersFormDI.dateSheetFrom!== null ? new Date(filtersFormDI.dateSheetFrom): null}
                                        value={ filtersFormDI.dateSheetTo!== null ? new Date(filtersFormDI.dateSheetTo): null}
                                        format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                        label={errorsfiltersFormDI.dateSheetTo!=null?t(errorsfiltersFormDI.dateSheetTo):t('PlaceHolder_Filter_DateSheet_To')}
                                        onBlur={handleBlurSelectDateSheetTo}
                                        onChange={handleChangeSelectDateSheetTo}
                                        slotProps={{
                                          textField: {
                                            placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                            sx: {
                                              color: '#1565c0',
                                              maxLength: '100',
                                              width: 430,
                                              fontSize: '18px', 
                                            },
                                            InputProps: {
                                              style: {
                                                fontSize: '18px',
                                              }
                                            },
                                            InputLabelProps:{ 
                                              style: {
                                                fontSize: '18px', 
                                                background: '#FFFFFF'
                                              }
                                            },
                                          },
                                        }}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                   
                                </div>
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditFormDI}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeletefiltersFormDI}>{t('Button_Cancel_Filters')}</button>
                                </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  
                  {/* End Content Row */}
                  </>:
                    <Loading isLoadingData={isLoadingFormDI}/>
                }
                
                
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

TicketsSearchDI.propTypes = {
  showModalSearchDI: PropTypes.bool.isRequired,
  setShowModalSearchDI: PropTypes.func.isRequired,
  setEditRowArticles: PropTypes.func.isRequired
};

export default TicketsSearchDI;

