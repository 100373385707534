import { useEffect } from 'react';
import { useSelector } from "react-redux";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the jsPDF autoTable plugin
import PropTypes from 'prop-types'; 
import RECICLED from "components/photos/purchaseDelivery/recicled.png";

const ReportPurchseDelivery = ({ dataPurchaseDelivery, initialStatePurchaseDelivery, setDataPurchaseDelivery, setViewPDF }) => {
  const headerPrinterPurchase = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinterPurchase);
 
  // Función para formatear el número
  function formatNumber(num) {
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  }

  function formatNumberEuro(num) {
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' €';
  }

  function formatNumberTN(num) {
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' Tn';
  }
  // Función para agrupar los impuestos y calcular el importe total por tipo de impuesto
  function groupTaxes(lines) {
    const taxGroups = [];
    
    lines.forEach((line) => {
      const { tax, amount, estimatedprice, discount, taxincluded } = line;
      const taxableAmount = amount * estimatedprice * (1 - discount / 100);
      let taxAmount = 0;

      if (tax!==0 && !taxincluded && taxableAmount!==0){
        taxAmount = taxableAmount * (tax / 100);
        const existingTaxGroup = taxGroups.find(group => group.taxRate === tax);
        if (existingTaxGroup) {
          existingTaxGroup.taxAmount += taxAmount;
        } else {
          taxGroups.push({ taxRate: tax, taxAmount });
        }
      }

     
    });

    return taxGroups;
  } 

  function getDateFormated(getDate) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const hoy = (getDate !==null && getDate !=="")? new Date(getDate):new Date();
    const dia = hoy.getDate();
    const mes = meses[hoy.getMonth()];
    const año = hoy.getFullYear();
    return `${dia} de ${mes} de ${año}`;
  }

  useEffect(() => {
    const generatePDF = () => {
      // Importa jsPDF
      const doc = new jsPDF({
        orientation: 'p', // 'p' para vertical, 'l' para horizontal
        unit: 'mm', // Unidad en milímetros
        format: [210, 297] // Ancho y alto en milímetros
      });  
      
      if(dataPurchaseDelivery.linespurchasedelivery && dataPurchaseDelivery.linespurchasedelivery.length > 0){
        // Agrupar los impuestos
        const taxGroups = groupTaxes(dataPurchaseDelivery.linespurchasedelivery);

        let numLinesPerPurchase=15;
        let totalPurchase=0;
        let totalTax=0;
        let numPages = Math.ceil(dataPurchaseDelivery.linespurchasedelivery.length / numLinesPerPurchase);
        if (numPages===0){
          numPages=1;
        } 
        for (let j = 0; j < numPages; j++) { 
          let yOffset = 12; // Margen superior para comenzar a imprimir
          doc.addImage(RECICLED, 'PNG', 37, 4, 5, 5);
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(16);
          doc.text("ALBARÁN", 150, yOffset-4, 'left');
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(14);
          const formatted_id = dataPurchaseDelivery.purchasenote.toString().padStart(8, '0');
          doc.text(`${formatted_id}`, 150, yOffset+2, 'left');
          doc.text(`${dataPurchaseDelivery.ownnumberpurchase.substr(0, 20)}`, 150, yOffset+8, 'left');
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(10);
          // Recorrer el contenido de headerPrinter
          if (headerPrinterPurchase && headerPrinterPurchase.length > 0) {
            // Dividimos en líneas
            let counter=0;
            headerPrinterPurchase.split('\n').forEach((line) => {
              if (counter===0){
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(8);
              }else{
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(7);
              }
              counter++;
              doc.text(line, 40, yOffset, 'center');
              yOffset += 2.5;
            });
          }
          yOffset = 38;
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(12);
          const newDate = (dataPurchaseDelivery.datepurchase!==undefined && dataPurchaseDelivery.datepurchase!==null && dataPurchaseDelivery.datepurchase!=="")?getDateFormated(dataPurchaseDelivery.datepurchase):"";
          doc.text(`${newDate}`, 195, yOffset-8, 'right');
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(10);
          doc.text("He recibido de D.", 15, yOffset, 'left');
          doc.text("Camión nº ", 15, yOffset+5, 'left');
          doc.text("GRNP N.º ", 77, yOffset+5, 'left');
          doc.text("Obra ", 15, yOffset+10, 'left');
          doc.text("Dirección ", 15, yOffset+15, 'left');
          doc.text("E-mail ", 15, yOffset+20, 'left');
          
         // Establecer el estilo de la línea punteada
          doc.setLineDash([0.2, 0.2], 0); // [2, 2] define el patrón de los puntos: 2px línea, 2px espacio
          // Dibujar la línea punteada
          doc.line(44, yOffset, 195, yOffset); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(34, yOffset+5, 75, yOffset+5); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(95, yOffset+5, 195, yOffset+5); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(25, yOffset+10, 195, yOffset+10); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(32, yOffset+15, 195, yOffset+15); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
          doc.line(26, yOffset+20, 195, yOffset+20); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)

          doc.text(`${dataPurchaseDelivery.ori_tras_tradename.substr(0, 78)}`, 44, yOffset-1, 'left');
          doc.text(`${dataPurchaseDelivery.vehiclestore_licenseplate.substr(0, 20)}`, 34, yOffset+4, 'left');
          doc.text(`${dataPurchaseDelivery.rel_trans_grnp.substr(0, 30)}`, 95, yOffset+4, 'left');
          doc.text(`${dataPurchaseDelivery.ori_tras_workplace.substr(0, 78)}`, 25, yOffset+9, 'left');
          doc.text(`${dataPurchaseDelivery.ori_tras_street.substr(0, 78)}`, 32, yOffset+14, 'left');
          doc.text(`${dataPurchaseDelivery.ori_tras_email.substr(0, 78)}`, 26, yOffset+19, 'left');


          doc.setLineDash([]);
          doc.setFillColor('#FFFFFF');
          doc.setLineWidth(0.3);  
          doc.rect(15, 60, 27, 6, 'FD');  
          doc.rect(42, 60, 120, 6, 'FD'); 
          doc.rect(155, 60, 20, 6, 'FD');  
          doc.rect(175, 60, 20, 6, 'FD');

          doc.setFont('helvetica', 'bold');
          doc.setFontSize(6);
          doc.text("CANTIDAD/TONELADAS", 16, 64, 'left');
          doc.setFontSize(10);
          doc.text("CONCEPTO", 98,64, 'center');
          doc.text("PRECIO", 165, 64, 'center');
          doc.text("IMPORTE",185, 64, 'center');
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(10);
          let startIndex = j * numLinesPerPurchase; // Índice inicial para esta página
          let endIndex = Math.min(startIndex + numLinesPerPurchase, dataPurchaseDelivery.linespurchasedelivery.length); // Índice final para esta página (máximo 10 líneas de RESIDUOS elementos)
          let addyOffset=0;
          yOffset=68;
          for (let i = startIndex; i < endIndex; i++) {
            if (dataPurchaseDelivery.linespurchasedelivery[i].article_namees.length>170){
              addyOffset=12;
            }else{
              if (dataPurchaseDelivery.linespurchasedelivery[i].article_namees.length>85){
                addyOffset=8;
              }else{
                addyOffset=4;
              } 
            }
           
            doc.setFillColor('#FFFFFF');
            doc.setLineWidth(0.2);  
            doc.rect(15, yOffset-3, 27, addyOffset, 'FD');  
            doc.rect(42, yOffset-3, 120, addyOffset, 'FD'); 
            doc.rect(155, yOffset-3, 20, addyOffset, 'FD');  
            doc.rect(175, yOffset-3, 20, addyOffset, 'FD');
           
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            if (dataPurchaseDelivery.linespurchasedelivery[i].ticketweight){
              doc.text(`${formatNumberTN(dataPurchaseDelivery.linespurchasedelivery[i].amount)}`, 41, yOffset, 'right');
            }else{
              doc.text(`${formatNumber(dataPurchaseDelivery.linespurchasedelivery[i].amount)}`, 41, yOffset, 'right');
            }
            totalPurchase+=dataPurchaseDelivery.linespurchasedelivery[i].amount*dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100));
            if (!dataPurchaseDelivery.linespurchasedelivery[i].taxincluded){
              totalTax+=(dataPurchaseDelivery.linespurchasedelivery[i].amount*dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))*(dataPurchaseDelivery.linespurchasedelivery[i].tax/100);
            }
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            if (dataPurchaseDelivery.linespurchasedelivery[i].article_namees.length>160){
              doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(0, 85)}`, 43, yOffset, 'left');
              doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(85,85)}`, 43, yOffset+4, 'left'); 
              doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(170)}`, 43, yOffset+8, 'left'); 
              doc.text(`${formatNumberEuro(dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))}`, 173, yOffset+8, 'right');
              doc.text(`${formatNumberEuro(dataPurchaseDelivery.linespurchasedelivery[i].amount*dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))}`, 193, yOffset+8, 'right');
            
            }else{
              if (dataPurchaseDelivery.linespurchasedelivery[i].article_namees.length>85){
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(0, 85)}`, 43, yOffset, 'left');
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(85)}`, 43, yOffset+4, 'left'); 
                doc.text(`${formatNumberEuro(dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))}`, 173, yOffset+4, 'right');
                doc.text(`${formatNumberEuro(dataPurchaseDelivery.linespurchasedelivery[i].amount*dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))}`, 193, yOffset+4, 'right');
              
              }else{
                doc.text(`${dataPurchaseDelivery.linespurchasedelivery[i].article_namees.substr(0, 85)}`, 43, yOffset, 'left'); 
                doc.text(`${formatNumberEuro(dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))}`, 173, yOffset, 'right');
                doc.text(`${formatNumberEuro(dataPurchaseDelivery.linespurchasedelivery[i].amount*dataPurchaseDelivery.linespurchasedelivery[i].estimatedprice*(1-(dataPurchaseDelivery.linespurchasedelivery[i].discount/100)))}`, 193, yOffset, 'right');
              } 
            } 
          
            yOffset+=addyOffset;  
          } 
          if (j===(numPages-1)){
            doc.setFillColor('#FFFFFF');
            doc.setLineWidth(0.3);  
            doc.line(15, yOffset-3, 195, yOffset-3);
            doc.rect(175, yOffset-3, 20, 4, 'FD');
            yOffset+=4;
            doc.rect(175, yOffset-3, 20, 4, 'FD');
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(9);
            doc.text("SUBTOTAL", 173, yOffset-4, 'right');
            doc.text(`${formatNumberEuro(totalPurchase)}`, 193, yOffset-4, 'right');
            yOffset+=4;
                
            if (taxGroups && taxGroups.length>0){
              taxGroups.forEach((group) => {
                doc.setFillColor('#FFFFFF');
                doc.setLineWidth(0.3);  
                doc.rect(175, yOffset-3, 20, 4, 'FD');
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(9);
                doc.text(`IGIC ${group.taxRate}%:`, 173, yOffset, 'right'); 
                doc.text(`${formatNumberEuro(group.taxAmount)}`, 193, yOffset, 'right');
                yOffset+=4;
                
              }); 
            }else{
              doc.setFillColor('#FFFFFF');
              doc.setLineWidth(0.3);  
              doc.rect(175, yOffset-3, 20, 4, 'FD');
              doc.setFont('helvetica', 'normal');
              doc.setFontSize(9);
              doc.text("IGIC", 173, yOffset, 'right');
              yOffset+=4;
            }

            doc.setFillColor('#FFFFFF');
            doc.setLineWidth(0.3);  
            doc.rect(175, yOffset-3, 20, 4, 'FD');
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(9);
            doc.text("TOTAL", 173, yOffset, 'right');
            let paymentData="FORMA DE PAGO: "+dataPurchaseDelivery.payment_namees;
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(9);
            //doc.text(`${formatNumberEuro(totalTax)}`, 193, yOffset, 'right');
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(9);
            doc.text(`${formatNumberEuro(totalPurchase+totalTax)}`, 193, yOffset, 'right');
            doc.text(`${paymentData}`, 195,yOffset+8, 'right');
            
            yOffset=115
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.text("DNI nº", 82, yOffset+16, 'left');
            doc.setLineDash([0.2, 0.2], 0); // [2, 2] define el patrón de los puntos: 2px línea, 2px espacio
            // Dibujar la línea punteada
            doc.line(92, yOffset+16, 120, yOffset+16); // Dibuja una línea punteada desde (10, 20) hasta (200, 20)
            doc.setLineDash([]);
            if (dataPurchaseDelivery.signature_dispatched_by!==undefined && dataPurchaseDelivery.signature_dispatched_by!==""){
              doc.addImage(`${dataPurchaseDelivery.signature_dispatched_by}`, 'PNG', 17, yOffset+2, 20, 20);
            } 
            if (dataPurchaseDelivery.signature_client!==undefined && dataPurchaseDelivery.signature_client!==""){
              doc.addImage(`${dataPurchaseDelivery.signature_client}`, 'PNG', 95, yOffset+2, 20, 20);
            }
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(8);
            doc.text("Despachado por", 18, yOffset+3, 'left');
            doc.text("Conforme Cliente", 100, yOffset+3, 'center');
          }
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(7);
          doc.text("*Este documento no tiene valor como  'Certificado Acreditativo de Gestión de Residuos de la Construcción'  según el art. 7.c. del Real Decreto 105/2008", 12, yOffset+24, 'left');
          if (j<numPages-1){
            doc.addPage();
          }
      } 
    }
    // Open PDF in a new tab
    const pdfBlob = doc.output('blob');
    URL.revokeObjectURL(pdfBlob);
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
    setViewPDF(false);
    setDataPurchaseDelivery(initialStatePurchaseDelivery);
      
  };

  if (dataPurchaseDelivery) {
    generatePDF();
  }
}, [dataPurchaseDelivery, setViewPDF, setDataPurchaseDelivery, initialStatePurchaseDelivery,headerPrinterPurchase]);
  return null; // This component does not render anything in the DOM
};

ReportPurchseDelivery.propTypes = {
  dataPurchaseDelivery: PropTypes.object.isRequired, 
  setDataPurchaseDelivery: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ReportPurchseDelivery;
