import jsPDF from "jspdf";

const exportToPDF = (data, fileName) => {
  const formatDate = (dateValue) => {
    if (!dateValue) return "";
    const date = new Date(dateValue);
    return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`;
  };

  function formatNumberEuro(num) {
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' €';
  }

  const entradasData = data.map(data => [
    data.purchasenote || "",
    formatDate(data.datepurchase) || "",
    data.vehiclestore_licenseplate || "",
    data.rel_trans_tradename || "",
    data.ori_tras_tradename || "",
    data.ori_tras_workplace || "",
    data.totalNetoPurchasedelivery || "" 
  ]);
 
  const doc = new jsPDF({
    orientation: 'l', // 'p' para vertical, 'l' para horizontal
    unit: 'mm', // Unidad en milímetros
    format: [297, 210] // Ancho y alto en milímetros
  });  
  let  yOffset = 10; // Posición inicial en el eje Y

  // Agrega encabezados al PDF
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(8); 
 

  doc.text("Nº ALBARÁN", 10, yOffset, 'left');
  doc.text("FECHA", 30, yOffset, 'left');
  doc.text("MATRÍCULA", 50, yOffset, 'left');
  doc.text("TRANSPORTISTA", 82, yOffset, 'left');
  doc.text("EMPRESA", 137, yOffset, 'left');
  doc.text("OBRA", 192, yOffset, 'left');
  doc.text("TOTAL", 270, yOffset, 'left');
    
       
  yOffset += 5;
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(8); 
  // Agrega datos al PDF
  entradasData.forEach((row) => {
    const formatted_id = row[0].toString().padStart(8, '0');
    doc.text(`${formatted_id}`, 28, yOffset, 'right');
    doc.text(`${row[1]}`, 30, yOffset, 'left');
    doc.text(`${row[2].substr(0, 20)}`, 50, yOffset, 'left');
    doc.text(`${row[3].substr(0, 30)}`, 82, yOffset, 'left');
    doc.text(`${row[4].substr(0, 30)}`, 137, yOffset, 'left');
    doc.text(`${row[5].substr(0, 45)}`, 192, yOffset, 'left');  
    doc.text(`${formatNumberEuro(row[6])}`, 290, yOffset, 'right');  
    yOffset += 5;
    if (yOffset >  200) {
      doc.addPage(); // Agrega nueva página si el contenido excede el límite
      yOffset = 10;
    }
  });

 
  // Abre el PDF en una nueva ventana
  const pdfBlob = doc.output("blob");
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl, "_blank");

  // Descarga automática (opcional)
  // doc.save(safeFileName);
};

export default exportToPDF;
