import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button'; 
import Container from '@mui/material/Container';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton'; 
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField  from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import PropTypes from 'prop-types'; 
import ContractSearchCodeLers  from "pages/contracts/editOrNewContract/ContractSearchCodeLers";
import TableSortingProcessTypes from "pages/contracts/editOrNewContract/SortingTablesProcess";
import ContractCreateProcessTypes from "pages/contracts/editOrNewContract/ContractNewProcessType";
import TableSortingTreatmentTypes from "pages/contracts/editOrNewContract/SortingTablesTreatment";
import ContractCreateTreatmentTypes from "pages/contracts/editOrNewContract/ContractNewTreatmentType";

const initialCodeLER = {
  "id": "",
  "indexrowcodeler":"",
  "codeler_id":"",
  "codeler_code": "",
  "codeler_namees": "",
  "codeler_nameen": "",
  "processtypes":[],
  "treatmenttypes":[]
};

const initialProcessTypeToSelect = [{
  "id": "",
  "indexrowprocesstypes":"",
  "processtype_id":"",
  "processtype_code": "",
  "processtype_namees": "",
  "processtype_nameen": ""
}];

const initialTreatmentTypeToSelect = [{
  "id": "",
  "indexrowtreatmenttypes":"",
  "treatmenttype_id":"",
  "treatmenttype_code": "",
  "treatmenttype_namees": "",
  "treatmenttype_nameen": ""
}];

 
const ContractCreateCodeLER = ({openEditRowCodeLERS,setOpenEditRowCodeLERS,openCreateEditRowCodeLERS,editRowCodeLER,formContract,setFormContract}) => {
  const {t,i18n} = useTranslation(['listDataContracts']);
  const token = useSelector((state) => state.loginUser.token); 
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const LIMITPROCESSTYPECONTRACT = useSelector((state) => state.loginUser.defaulttypesdi.limitprocesstypecontract);  
  const LIMITREATMENTTYPECONTRACT = useSelector((state) => state.loginUser.defaulttypesdi.limitreatmenttypecontract);  
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const [showModalSearchCodeLERS, setShowModalSearchCodeLERS] =  useState(false);
  
  const [valueTabs, setValueTabs] = useState("first");
  const [newCodeLER,setNewCodeLER]= useState(initialCodeLER);
  const [errorsCodeLER, setErrorsCodeLER] = useState({});
  const [openDeleteRowProcessTypes, setOpenDeleteRowProcessTypes] = useState(false);
  const [openEditRowProcessTypes, setOpenEditRowProcessTypes] = useState(false);
  const [editRowProcessType, setEditRowProcessType] = useState(initialProcessTypeToSelect);
  const [idCodeDeleteProcessType,setIdCodeDeleteProcessType]=useState(DELETE_CODE);
  const [openDeleteRowTreatmentTypes, setOpenDeleteRowTreatmentTypes] = useState(false);
  const [openEditRowTreatmentTypes, setOpenEditRowTreatmentTypes] = useState(false);
  const [editRowTreatmentType, setEditRowTreatmentType] = useState(initialTreatmentTypeToSelect);
  const [idCodeDeleteTreatmentType,setIdCodeDeleteTreatmentType]=useState(DELETE_CODE);
 
  const dispatch = useDispatch(); 
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowCodeLERS) {
        setShowModalSearchCodeLERS(false);
        setValueTabs("first");
        setNewCodeLER(initialCodeLER);
        setErrorsCodeLER({});
        setOpenDeleteRowProcessTypes(false);
        setOpenEditRowProcessTypes(false);
        setEditRowProcessType(initialProcessTypeToSelect);
        setIdCodeDeleteProcessType(DELETE_CODE);
        setOpenDeleteRowTreatmentTypes(false);
        setOpenEditRowTreatmentTypes(false);
        setEditRowTreatmentType(initialTreatmentTypeToSelect);
        setIdCodeDeleteTreatmentType(DELETE_CODE);
        setOpenEditRowCodeLERS(false);
      }
    },[
      setShowModalSearchCodeLERS,
      setValueTabs,
      setNewCodeLER,
      setErrorsCodeLER,
      setOpenDeleteRowProcessTypes,
      setOpenEditRowProcessTypes,
      setEditRowProcessType,
      setIdCodeDeleteProcessType,
      setOpenDeleteRowTreatmentTypes,
      setOpenEditRowTreatmentTypes,
      setEditRowTreatmentType,
      setIdCodeDeleteTreatmentType,
      openEditRowCodeLERS,
      setOpenEditRowCodeLERS,
      DELETE_CODE]
  );

  const handleCloseNewCodeLER = () => {
    setShowModalSearchCodeLERS(false);
    setValueTabs("first");
    setNewCodeLER(initialCodeLER);
    setErrorsCodeLER({});
    setOpenDeleteRowProcessTypes(false);
    setOpenEditRowProcessTypes(false);
    setEditRowProcessType(initialProcessTypeToSelect);
    setIdCodeDeleteProcessType(DELETE_CODE);
    setOpenDeleteRowTreatmentTypes(false);
    setOpenEditRowTreatmentTypes(false);
    setEditRowTreatmentType(initialTreatmentTypeToSelect);
    setIdCodeDeleteTreatmentType(DELETE_CODE);
    setOpenEditRowCodeLERS(false);
  };

  const handleChangeNewCodeLER = (e) => {
    const { name, value } = e.target;
    setNewCodeLER(newCodeLER => ({
      ...newCodeLER,
      [name]: value,
    }));
  };
  
  const handleBlurNewCodeLER = (e) => {
    handleChangeNewCodeLER(e);
    setErrorsCodeLER(validateFormCodeLER());
  };
  
  const searchFormCodeLer = () => {
    setShowModalSearchCodeLERS(true);
  };

  const handleChangeValueTabs = (event,newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  const handleCancelDeleteRowProcessType = () => {
    setIdCodeDeleteProcessType(DELETE_CODE);
    setOpenDeleteRowProcessTypes(false);
  };

  const handleChangeDeleteRowPorcessType = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteProcessType(value);
  };

  const handleCloseDeleteRowProcessType = (e) => {
    e.preventDefault();
    if (idCodeDeleteProcessType!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=newCodeLER.processtypes.findIndex(((item) => item.indexrowprocesstypes === editRowProcessType.indexrowprocesstypes));
      if ( indexDelete !== -1 ) {
        newCodeLER.processtypes.splice( indexDelete, 1 );
      }
      setFormContract(formContract => ({
        ...formContract,
      }))
      setIdCodeDeleteProcessType(DELETE_CODE);
      setOpenDeleteRowProcessTypes(false);
    }
  };

  const handleCancelDeleteRowTreatmentType = () => {
    setIdCodeDeleteTreatmentType(DELETE_CODE);
    setOpenDeleteRowTreatmentTypes(false);
  };

  const handleChangeDeleteRowTreatmentType = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteTreatmentType(value);
  };

  const handleCloseDeleteRowTreatmentType = (e) => {
    e.preventDefault();
    if (idCodeDeleteTreatmentType!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=newCodeLER.treatmenttypes.findIndex(((item) => item.indexrowtreatmenttypes === editRowTreatmentType.indexrowtreatmenttypes));
      if ( indexDelete !== -1 ) {
        newCodeLER.treatmenttypes.splice( indexDelete, 1 );
      }
      setFormContract(formContract => ({
        ...formContract,
      }))
      setIdCodeDeleteTreatmentType(DELETE_CODE);
      setOpenDeleteRowTreatmentTypes(false);
    }
  };
 
  const toggleDrawerNewCodeLER  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewCodeLER();
    }
  };

  const validateFormCodeLER = () => {
    let errorsCodeLER = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (newCodeLER.codeler_code===""){
      errorsCodeLER.codeler_code = 'Text_CODELER_code';
    }else {
      if (regexInvalidCharacters.test(newCodeLER.codeler_code)) {
        errorsCodeLER.codeler_code = 'Text_TextNoValid';
      }else{
        if (!openCreateEditRowCodeLERS){
          if (formContract.codelers.find((item) => {return (item.codeler_code.trim()=== newCodeLER.codeler_code.trim() ) })){
            errorsCodeLER.codeler_code = 'Text_CODELER_EXIST';
          }
        }   
      }
    }
  
    if (newCodeLER.codeler_namees===""){
        errorsCodeLER.codeler_namees = 'Text_CODELER_NOT_EXIST';
    }else {
      if (regexInvalidCharacters.test(newCodeLER.codeler_namees)) {
        errorsCodeLER.codeler_namees = 'Text_TextNoValid';
      }
    } 

    if (newCodeLER.codeler_nameen===""){
      errorsCodeLER.codeler_nameen = 'Text_CODELER_NOT_EXIST';
    }else {
      if (regexInvalidCharacters.test(newCodeLER.codeler_nameen)) {
        errorsCodeLER.codeler_nameen = 'Text_TextNoValid';
      }
    }   
    return errorsCodeLER;
  };

  const handleSubmitNewCodeLER = () => {  

    setErrorsCodeLER(validateFormCodeLER());
    let errorsCheckCodeLER=validateFormCodeLER();

    if ((Object.keys(errorsCheckCodeLER).length === 0) 
          && newCodeLER.codeler_id!==''  
          && newCodeLER.codeler_code!==''  
          && newCodeLER.codeler_namees!=='' 
          && newCodeLER.codeler_nameen!=='' ) {

      let codeLERToAdd={
        "id":"",
        "indexrowcodeler":openCreateEditRowCodeLERS?newCodeLER.indexrowcodeler:formContract.codelers.length>0?formContract.codelers[formContract.codelers.length-1].indexrowcodeler+1:1,       
        "codeler_id":newCodeLER.codeler_id,
        "codeler_code":newCodeLER.codeler_code,
        "codeler_namees":newCodeLER.codeler_namees,
        "codeler_nameen":newCodeLER.codeler_nameen,
        "processtypes":newCodeLER.processtypes,
        "treatmenttypes":newCodeLER.treatmenttypes
      }
      if (!openCreateEditRowCodeLERS){
        formContract.codelers.push(codeLERToAdd);
      }else{
        let idx=formContract.codelers.findIndex(((item) => item.indexrowcodeler === newCodeLER.indexrowcodeler));
        formContract.codelers[idx]=codeLERToAdd
      }

     
      setFormContract(formContract => ({
        ...formContract,
      }))
      handleCloseNewCodeLER();
    } else {
      if (errorsCheckCodeLER.codeler_code){
        dispatch(warningErrorAction(t(errorsCheckCodeLER.codeler_code)));
      }
      if (errorsCheckCodeLER.codeler_namees){
        dispatch(warningErrorAction(t(errorsCheckCodeLER.codeler_namees)));
      }
      if (errorsCheckCodeLER.codeler_nameen){
        dispatch(warningErrorAction(t(errorsCheckCodeLER.codeler_nameen)));
      }
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistCodeLer = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`contract/getexistcodelerbycode/`,token,newCodeLER.codeler_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials'))); 
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!=="" && getData>0){
            fetchCodeLerCode();
          }else{
            setNewCodeLER(newCodeLER => ({
              ...newCodeLER,
              id: "",
              indexrowcodeler:0,
              codeler_id:"",
              namees: "",
              nameen: "",
              processtypes:[],
              treatmenttypes:[]
            })); 
          }
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchCodeLerCode = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`contract/getformcodelerfindbycode/`,token,newCodeLER.codeler_code);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials'))); 
        } else if (isMounted) {
          if (getData!==undefined && getData!==null && getData!==""){
            setNewCodeLER(newCodeLER => ({
                ...newCodeLER,
                codeler_id: getData.codeler_id, 
                codeler_code: getData.codeler_code, 
                codeler_namees: getData.codeler_namees,
                codeler_nameen: getData.codeler_nameen ,
                processtypes:[],
                treatmenttypes:[]
              }));
        }  
        }  
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (!openCreateEditRowCodeLERS && newCodeLER.codeler_code!==""){
      fetchExistCodeLer();
    }
    
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,newCodeLER.codeler_code,setNewCodeLER,editRowCodeLER,openCreateEditRowCodeLERS]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && openCreateEditRowCodeLERS){
      setNewCodeLER(newCodeLER => ({
        ...newCodeLER,
        id:editRowCodeLER.id!==null?editRowCodeLER.id:"",
        indexrowcodeler:editRowCodeLER.indexrowcodeler!==null?editRowCodeLER.indexrowcodeler:"",
        codeler_id:editRowCodeLER.codeler_id!==null?editRowCodeLER.codeler_id:"",
        codeler_code:editRowCodeLER.codeler_code!==null?editRowCodeLER.codeler_code:"",
        codeler_namees:editRowCodeLER.codeler_namees!==null?editRowCodeLER.codeler_namees:"",
        codeler_nameen:editRowCodeLER.codeler_nameen!==null?editRowCodeLER.codeler_nameen:"",
        processtypes:editRowCodeLER.processtypes!==null?editRowCodeLER.processtypes:[],
        treatmenttypes:editRowCodeLER.treatmenttypes!==null?editRowCodeLER.treatmenttypes:[]
      }));
    }else{
      setNewCodeLER(initialCodeLER);
    }
    return()=>{isMounted=false;}  
  }, [editRowCodeLER,openCreateEditRowCodeLERS,openEditRowCodeLERS]);

  
  return(
    <>
      {openEditRowCodeLERS && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewCodeLER(false)}
            onOpen={toggleDrawerNewCodeLER(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1050px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_CODELER_NEW')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewCodeLER}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('Text_CONTRACT_CODE_LER')} :</label>
                    <div className="form-group">
                      <TextField
                        id="codeler_code" 
                        name="codeler_code"
                        value={newCodeLER.codeler_code}
                        inputProps={{ style: {width: 450}, maxLength: 100 }}
                        label={errorsCodeLER.codeler_code!=null?t(errorsCodeLER.codeler_code):t('PlaceHolder_CONTRACT_CODE_LER')}
                        placeholder={t('PlaceHolder_CONTRACT_CODE_LER')}
                        variant="standard"
                        onBlur={handleBlurNewCodeLER}
                        onChange={handleChangeNewCodeLER}
                        disabled={openCreateEditRowCodeLERS}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {!openCreateEditRowCodeLERS &&
                          <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormCodeLer}>
                              <SearchIcon/>
                          </IconButton>
                        }
                    </div>

                    <label className="text-primary">{t('Text_CONTRACT_CODE_LER_TITLEES')} :</label>
                    <div className="form-group">
                      <TextField
                        id="codeler_namees" 
                        name="codeler_namees"
                        value={newCodeLER.codeler_namees}
                        inputProps={{ style: {width: 1000}, maxLength: 255 }}
                        label={errorsCodeLER.codeler_namees!=null?t(errorsCodeLER.codeler_namees):t('Text_CONTRACT_CODE_LER_TITLEES')}
                        placeholder={t('Text_CONTRACT_CODE_LER_TITLEES')}
                        variant="standard"
                        onBlur={handleBlurNewCodeLER}
                        onChange={handleChangeNewCodeLER}
                        disabled/>
                    </div>

                    <label className="text-primary">{t('Text_CONTRACT_CODE_LER_TITLEEN')} :</label>
                    <div className="form-group">
                      <TextField
                        id="nameen" 
                        name="nameen"
                        value={newCodeLER.codeler_nameen}
                        inputProps={{ style: {width: 1000}, maxLength: 255 }}
                        label={errorsCodeLER.codeler_nameen!=null?t(errorsCodeLER.codeler_nameen):t('Text_CONTRACT_CODE_LER_TITLEEN')}
                        placeholder={t('Text_CONTRACT_CODE_LER_TITLEEN')}
                        variant="standard"
                        onBlur={handleBlurNewCodeLER}
                        onChange={handleChangeNewCodeLER}
                        disabled/>
                    </div>
                    <div className="row p-2"> 
                        <div className="form-group"> 
                          <Box sx={{ width: '100%' }}>
                            <Tabs
                              value={valueTabs}
                              onChange={handleChangeValueTabs}
                              style={{color:"#4e73df",width:"100%"}}
                              indicatorColor="primary"
                              variant="fullWidth"
                              aria-label="secondary tabs example">
                                <Tab value="first" label={t('Text_CONTRACT_CODE_TREATMENTS')} disabled={newCodeLER.codeler_code===undefined || newCodeLER.codeler_code===""}/>     
                                <Tab value="second" label={t('Text_CONTRACT_CODE_PROCESSES')} disabled={newCodeLER.codeler_code===undefined || newCodeLER.codeler_code===""}/>      
                            </Tabs>  
                          </Box>
                        </div>
                      </div>
                      {valueTabs==="first" && newCodeLER.processtypes!==undefined ?
                        <div className="row p-2"> 
                          <div className="form-group">
                            <TableSortingTreatmentTypes 
                                dataTreatmentTypes={newCodeLER.treatmenttypes} 
                                numTotRegTreatmentTypes={newCodeLER.treatmenttypes.length}
                                setOpenDeleteRowTreatmentTypes={setOpenDeleteRowTreatmentTypes}
                                setOpenEditRowTreatmentTypes={setOpenEditRowTreatmentTypes}
                                setEditRowTreatmentType={setEditRowTreatmentType} 
                                LIMITPAGESIZE={LIMITPAGESIZE}
                                LIMITREATMENTTYPECONTRACT={LIMITREATMENTTYPECONTRACT}
                                validateFormCodeLER={validateFormCodeLER}
                              />
                              <ContractCreateTreatmentTypes 
                                  openEditRowTreatmentTypes={openEditRowTreatmentTypes}
                                  setOpenEditRowTreatmentTypes={setOpenEditRowTreatmentTypes} 
                                  newCodeLER={newCodeLER} 
                                  setNewCodeLER={setNewCodeLER}
                              />
                          </div>
                        </div>
                        :valueTabs==="second" && newCodeLER.treatmenttypes!==undefined?
                        <div className="row p-2"> 
                          <div className="form-group">    
                            <TableSortingProcessTypes 
                              dataProcessTypes={newCodeLER.processtypes} 
                              numTotRegProcessTypes={newCodeLER.processtypes.length}
                              setOpenDeleteRowProcessTypes={setOpenDeleteRowProcessTypes}
                              setOpenEditRowProcessTypes={setOpenEditRowProcessTypes}
                              setEditRowProcessType={setEditRowProcessType} 
                              LIMITPAGESIZE={LIMITPAGESIZE}
                              LIMITPROCESSTYPECONTRACT={LIMITPROCESSTYPECONTRACT}
                              validateFormCodeLER={validateFormCodeLER}
                            />
                            <ContractCreateProcessTypes 
                              openEditRowProcessTypes={openEditRowProcessTypes}
                              setOpenEditRowProcessTypes={setOpenEditRowProcessTypes} 
                              newCodeLER={newCodeLER} 
                              setNewCodeLER={setNewCodeLER}
                            />
                          </div>
                        </div>
                      :<></>
                    }
                    <ContractSearchCodeLers
                      showModalSearchCodeLERS={showModalSearchCodeLERS}
                      setShowModalSearchCodeLERS={setShowModalSearchCodeLERS} 
                      setNewCodeLER={setNewCodeLER}
                    />
      
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitNewCodeLER}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseNewCodeLER}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
              <div>
                <Dialog open={openDeleteRowProcessTypes} onClose={handleCancelDeleteRowProcessType}>
                  <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowProcessType.processtype_code} - {i18n.language==='es'?editRowProcessType.processtype_namees:editRowProcessType.processtype_nameen} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="idCode"
                      label={t('Text_Label')}
                      type="text"
                      fullWidth
                      variant="standard"
                      error
                      defaultValue={idCodeDeleteProcessType}
                      onChange={handleChangeDeleteRowPorcessType}/>
                  </DialogContent>
                  <DialogActions>
                    <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowProcessType}>{t('Button_Cancel')}</Button>
                    <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowProcessType}>{t('Button_Delete')}</Button>
                  </DialogActions>
                </Dialog>
              </div>

              <div>
                <Dialog open={openDeleteRowTreatmentTypes} onClose={handleCancelDeleteRowTreatmentType}>
                  <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowTreatmentType.treatmenttype_code} - {i18n.language==='es'?editRowTreatmentType.treatmenttype_namees:editRowTreatmentType.treatmenttype_nameen} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="idCode"
                      label={t('Text_Label')}
                      type="text"
                      fullWidth
                      variant="standard"
                      error
                      defaultValue={idCodeDeleteTreatmentType}
                      onChange={handleChangeDeleteRowTreatmentType}/>
                  </DialogContent>
                  <DialogActions>
                    <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowTreatmentType}>{t('Button_Cancel')}</Button>
                    <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowTreatmentType}>{t('Button_Delete')}</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

ContractCreateCodeLER.propTypes = {
  openEditRowCodeLERS: PropTypes.bool.isRequired,
  setOpenEditRowCodeLERS: PropTypes.func.isRequired,  
  openCreateEditRowCodeLERS: PropTypes.bool.isRequired,
  formContract: PropTypes.object.isRequired,
  setFormContract: PropTypes.func.isRequired, 
};

export default ContractCreateCodeLER;