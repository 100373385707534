const exportToCSV = (data, fileName) => {
  const formatDate = (dateValue) => {
    if (!dateValue) return "null";
    const date = new Date(dateValue);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  // Manejar caracteres especiales y campos vacíos
  const safeField = (field) => {
    if (field == null || field === "") return "null";
    field = field.toString().replace(/"/g, '""'); // Escapa comillas dobles
    field = field.replace(/\r?\n|\r/g, " "); // Elimina saltos de línea
    return `"${field}"`; // Encierra en comillas dobles
  };

  const entradasData = data.flatMap(data => {
    const albaranDetails = [
      data.purchasenote || "null",
      data.ownnumberpurchase || "null",
      formatDate(data.datepurchase) || "null",
      data.vehiclestore_licenseplate || "null",
      data.rel_trans_documentid || "null",
      data.rel_trans_tradename || "null",
      data.vehiclestore_defaultdriver || "null",
      data.rel_trans_nima || "null",
      data.rel_trans_grnp || "null",
      data.ori_tras_documentid || "null",
      data.ori_tras_tradename || "null",
      data.ori_tras_workplace || "null",
      data.ori_tras_street || "null",
      data.ori_tras_province_name || "null",
      data.ori_tras_postalcode_name || "null",
      data.ori_tras_email || "null",
      data.totalpurchasedelivery || "null",
      data.totaldiscountpurchasedelivery || "null",
      data.totaltaxespurchasedelivery || "null",
      data.totalNetoPurchasedelivery || "null",
      data.payment_namees || "null"
    ]; 

    const lineEntries = (data.linespurchasedelivery || []).map(line => [
      ...albaranDetails,
      line.article_code || "null",
      line.article_namees || "null",
      line.amount || "null",
      line.estimatedprice || "null",
      line.discount || "null",
      line.tax || "null",
      line.taxincluded ? "SÍ" : "NO",
      line.total || "null"
    ]);
    return lineEntries;
  });

  const csvHeaders = [
    "Nº ALBARÁN",
    "REFERENCIA ALBARÁN",
    "FECHA",
    "MATRÍCULA",
    "Nº DOCUMENTO",
    "TRANSPORTISTA",
    "CONDUCTOR",
    "NIMA",
    "GRNP",
    "Nº DOCUMENTO",
    "EMPRESA",
    "OBRA",
    "DIRECCIÓN",
    "PROVINCIA",
    "CÓDIGO POSTAL",
    "EMAIL",
    "IMPORTE ALBARÁN",
    "DESCUENTOS",
    "IMPUESTOS",
    "TOTAL ALBARÁN",
    "FORMA DE PAGO",
    "CÓDIGO ARTÍCULO",
    "DESCRIPCIÓN ARTÍCULO",
    "CANTIDAD",
    "PRECIO ESTIMADO",
    "DESCUENTOS",
    "IMPUESTO",
    "INCLUIDO",
    "TOTAL"
  ];

  const csvContent = [csvHeaders, ...entradasData]
    .map(row => row.map(field => safeField(field)).join('*')) // Aplica el manejo de caracteres especiales
    .join('\n'); // Une las filas

  const blob = new Blob(["\uFEFF" + csvContent], { type: 'text/csv;charset=utf-8;' });
  const safeFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = safeFileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export default exportToCSV;
