import React, {useState, useEffect} from 'react' 
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage, setOrder, setOrderBy,setRowsPerPage} from 'reducers/paginationTicketsSlice';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';    
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';  
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage'; 
import Paper from '@mui/material/Paper';  
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel'; 
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import TextField from '@mui/material/TextField';
import useTheme from '@mui/material/styles/useTheme'; 
import visuallyHidden from '@mui/utils/visuallyHidden'
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const headCellsTicketsDetails = [
  { id: 'numberticket', numeric: false, disablePadding: false, label: 'Text_TICKETS_NUMBERTICKETS', canOrder: true },
  { id: 'start_date_ticket', numeric: false, disablePadding: false, label: 'Text_TICKETSDETAILS_FIRSTDATE_WEIGHTS', canOrder: true },
  { id: 'end_date_ticket', numeric: false, disablePadding: false, label: 'Text_TICKETSDETAILS_ENDDATE_WEIGHTS', canOrder: true },
  { id: 'ori_tras_tradename', numeric: false, disablePadding: false, label: 'Text_TICKETS_NAME_STORE', canOrder: true },
  { id: 'rel_trans_tradename', numeric: false, disablePadding: false, label: 'Text_TICKETS_TRADENAME_TRANS', canOrder: true },
  { id: 'vehiclestore_licenseplate', numeric: false, disablePadding: false, label: 'Text_TICKETS_LICENSEPLATE', canOrder: true },
  { id: 'totalstartweight', numeric: false, disablePadding: false, label: 'Text_TICKETSDETAILS_FIRST_WEIGHTS', canOrder: true },
  { id: 'totalendweight', numeric: false, disablePadding: false, label: 'Text_TICKETSDETAILS_END_WEIGHTS', canOrder: true },
  { id: 'formDIAssociates', numeric: false, disablePadding: false, label: 'Text_TICKETS_ASSOCIATES_DI', canOrder: true }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,showEditButtonTicketsDetails,showPrintButtonTicketsDetails,showDeleteButtonTicketsDetails} = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
      {showEditButtonTicketsDetails &&
          <TableCell
            key='edit'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 18}} >
              {t('Button_Edit')}
            </Typography>
          </TableCell>
        } 
        {showPrintButtonTicketsDetails &&
          <TableCell
            key='print'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#067330", fontSize: 18}} >
              {t('Button_Print')}
            </Typography>
          </TableCell>
        } 
        {showPrintButtonTicketsDetails &&
          <TableCell
            key='pdf'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 18}} >
              {t('Button_Print_PDF')}
            </Typography>
          </TableCell>
        } 
        {headCellsTicketsDetails.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 18}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 18}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))} 
        {showDeleteButtonTicketsDetails &&
          <TableCell
            key='delete'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 18}} > 
              {t('Button_Delete_Back')}
            </Typography>
          </TableCell>
        } 
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegTicketsDetails, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegTicketsDetails).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataTickets']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingTicketsDetails =(props)=>{
  const { 
    dataListTicketsDetails, 
    numTotRegFilter,
    titleTable,
    setOpenDeleteRowTicketsDetails,
    setRowEditTicketsDetails, 
    showEditButtonTicketsDetails,
    showPrintButtonTicketsDetails,
    showDeleteButtonTicketsDetails, 
    numTotRegTicketsDetails,
    activeOffsetTicketsDetails,
    rangeOffsetTicketsDetails,
    currencyOffsetTicketsDetails,
    setCurrencyOffsetTicketsDetails,
    setViewPDF,
    setViewPrint
  } = props;
  const {t,i18n} = useTranslation(['listDataTickets']);
  const dispatch = useDispatch();
  const { currentPage, order, orderBy,rowsPerPage } = useSelector((state) => state.paginationTickets);
  const [countData,setCountData] = useState(dataListTicketsDetails.length);
  const maxPage = Math.max(0, Math.ceil(countData / rowsPerPage) - 1);
  const page = Math.min(currentPage, maxPage);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataListTicketsDetails.length) : 0);
 
  
  useEffect(
    () => {
      if (dataListTicketsDetails.length!==countData || dataListTicketsDetails.length<=rowsPerPage){
       //dispatch(setCurrentPage(0));
      }  
      setCountData(dataListTicketsDetails.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataListTicketsDetails.length) : 0);  
    },
    [dataListTicketsDetails,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetTicketsDetails(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    dispatch(setOrder(isAsc ? 'desc' : 'asc')); // Actualiza Redux
    dispatch(setOrderBy(property)); // Actualiza Redux
  };  

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage(newPage)); // Guardar la página actual en Redux
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(setRowsPerPage(newRowsPerPage)); // Guardar el número de elementos por página actual en Redux
    dispatch(setCurrentPage(0)); // Reiniciar la página cuando cambian las filas por página
  };

  const openModalDeleteRowTickets = (row) => {
    setRowEditTicketsDetails(row);
    setOpenDeleteRowTicketsDetails(true);
  };

  const openModalTicketsPrint = async (row) => {
    setRowEditTicketsDetails(row);
    setViewPrint(true);
  }; 

  const openModalTicketsPDF = async (row) => {
    setRowEditTicketsDetails(row);
    setViewPDF(true);
  }; 

  const listItems = stableSort(dataListTicketsDetails, getComparator(order, orderBy))
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  .map((row, keyData) => {
      return (
      <TableRow hover tabIndex={-1} key={keyData}>
        {(setRowEditTicketsDetails && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <Link  to={`/ticketseditsdetails/${row.id}`} >
              <IconButton  size="large"  style={{color:"#4e73df"}} >
                <EditIcon style={{fontSize: 28}}/>
              </IconButton>
            </Link>
          </TableCell>
        }  
        {(showPrintButtonTicketsDetails && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#067330"}} onClick={()=>openModalTicketsPrint(row)}>
              <PrintIcon style={{fontSize: 28}}/>
            </IconButton>
          </TableCell>
        }

         {(showPrintButtonTicketsDetails && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#e74a3b"}} onClick={()=>openModalTicketsPDF(row)}>
              <PictureAsPdfIcon style={{fontSize: 28}}/>
            </IconButton>
          </TableCell>
        } 
        <TableCell align="left">
         <Typography style={{color:"#4e73df", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.numberticket}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
          {row.start_date_ticket !== "" 
            ? new Date(row.start_date_ticket).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US") + 
              " " + 
              new Date(row.start_date_ticket).toLocaleTimeString(i18n.language === "es" ? "es-ES" : "en-US",{timeStyle: "short"})
            : ""}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.end_date_ticket !== "" 
              ? new Date(row.end_date_ticket).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US") + 
                " " + 
                new Date(row.end_date_ticket).toLocaleTimeString(i18n.language === "es" ? "es-ES" : "en-US",{timeStyle: "short"})
              : ""
            }
           </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.ori_tras_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.rel_trans_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.vehiclestore_licenseplate}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 18}} >
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {0}
              fixedDecimalScale= {true}
              allowNegative= {false}
              id="totalstartweight" 
              name="totalstartweight"
              value={row.totalstartweight}
              style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 18, textAlign:'right', paddingRight:5}} 
              disabled
            />
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography style={{color:"#000000", fontSize: 18}} >
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {0}
              fixedDecimalScale= {true}
              allowNegative= {false}
              id="totalstartweight" 
              name="totalstartweight"
              value={row.totalendweight}
              style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 18, textAlign:'right', paddingRight:5}} 
              disabled
            /> 
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography style={{color:"#000000", fontSize: 16, textDecorationLine: row.enabled?'':'line-through'}} >
            {(row.formDIAssociates===undefined || row.formDIAssociates===null || row.formDIAssociates==="")?t('Text_NOT_REPORTING_DI'): row.formDIAssociates}
          </Typography>
        </TableCell>
        {(showDeleteButtonTicketsDetails && row.id!=="" && row.id!==null) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:row.enabled?"#e74a3b":"#ffc107"}} onClick={()=>openModalDeleteRowTickets(row)}>
              {row.enabled?<DeleteIcon style={{fontSize: 28}}/>:<DeleteForeverIcon style={{fontSize: 28}}/>} 
            </IconButton>
          </TableCell>
        }
          
      </TableRow>
    );
  });
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegTicketsDetails={numTotRegTicketsDetails} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} showEditButtonTicketsDetails={showEditButtonTicketsDetails} showPrintButtonTicketsDetails={showPrintButtonTicketsDetails} showDeleteButtonTicketsDetails={showDeleteButtonTicketsDetails}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={16} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetTicketsDetails && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetTicketsDetails"
                            name="currencyOffsetTicketsDetails"
                            value={currencyOffsetTicketsDetails}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:18}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetTicketsDetails
                            }}>
                            {rangeOffsetTicketsDetails.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={16}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingTicketsDetails.propTypes = {
  dataListTicketsDetails: PropTypes.array.isRequired, 
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  setOpenDeleteRowTicketsDetails: PropTypes.func.isRequired, 
  setRowEditTicketsDetails: PropTypes.func.isRequired,   
  showEditButtonTicketsDetails: PropTypes.bool.isRequired,  
  showPrintButtonTicketsDetails: PropTypes.bool.isRequired,  
  showDeleteButtonTicketsDetails: PropTypes.bool.isRequired,
  activeOffsetTicketsDetails: PropTypes.bool.isRequired,
  rangeOffsetTicketsDetails: PropTypes.array.isRequired,
  currencyOffsetTicketsDetails: PropTypes.number.isRequired,
  setCurrencyOffsetTicketsDetails: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired,
  setViewPrint: PropTypes.func.isRequired
  
};
  
export default TableSortingTicketsDetails;
