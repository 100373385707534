import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'; 
import Button from '@mui/material/Button'; 
import Card from '@mui/material/Card'; 
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography'; 
import 'react-toastify/dist/ReactToastify.css'  
import "css/generalSettings.css";
import PropTypes from 'prop-types';
import image1 from "components/photos/form.jpg";
import image2 from "components/photos/finishedform.jpg";
import image3 from "components/photos/endform.jpg"; 
import image4 from "components/photos/convertpdf.png"; 
import image5 from "components/photos/Pesada.webp"; 
import image6 from "components/photos/purchase.png"; 
import image7 from "components/photos/purchaseended.jpeg"; 
import image8 from "components/photos/Sign.jpg"; 

const data = [{
  "id": 1,
  "title": "Text_TITLE_CREATE_FORM",
  "information":"Text_INFORMATION_CREATE_FORM",
  "icon":image1,
  "isEnabled":true
 
},{
  "id": 2,
  "title": "Text_TITLE_FINISHED_FORM",
  "information":"Text_INFORMATION_FINISHED_FORM",
  "icon":image2,
  "isEnabled":true
},{
  "id": 3,
  "title": "Text_TITLE_MODIFIED_FORM",
  "information":"Text_INFORMATION_MODIFIED_FORM",
  "icon":image3,
  "isEnabled":true
},{
  "id": 4,
  "title": "Text_TITLE_SCAN_FORM",
  "information":"Text_INFORMATION_SCAN_FORM",
  "icon":image4,
  "isEnabled":true
 },{
  "id": 5,
  "title": "Text_TITLE_WEIGHVEHICLES",
  "information":"Text_INFORMATION_SWEIGHVEHICLES",
  "icon":image5,
  "isEnabled":true
 },{
  "id": 6,
  "title": "Text_TITLE_PURCHASES",
  "information":"Text_INFORMATION_PURCHASES",
  "icon":image6,
  "isEnabled":true
 },{
  "id": 7,
  "title": "Text_TITLE_PURCHASES_ENDED",
  "information":"Text_INFORMATION_PURCHASES_ENDED",
  "icon":image7,
  "isEnabled":true
 },{
  "id": 8,
  "title": "Text_TITLE_PURCHASES_SIGN",
  "information":"Text_INFORMATION_PURCHASES_SIGN",
  "icon":image8,
  "isEnabled":true
 }]

const PrincipalMenuSettings = ({ 
  showPrincipalMenu,
  setIndexShowSettings,
  setShowModalGeneralSettings,
  setShowPrincipalMenu
}) => {

  const {t} = useTranslation(['listDataGeneralSettings']); 
  const roles = useSelector((state) => state.loginUser.roles); 
  data[0].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Create'))?true:false;
  data[1].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Edit'))?true:false;
  data[2].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Edit_Ended'))?true:false;
  data[3].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_PDF'))?false:false;
  data[4].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Tickets_Create'))?true:false;
  data[5].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Create'))?true:false;
  data[6].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_View_Ended'))?true:false;
  data[7].isEnabled=roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Signature_Desk'))?true:false;
  
  const navigate = useNavigate();

  const closeModal = (id) => {
    switch (id) {
      case 1:    
        navigate("/formdetail/0");  
        break;
      case 2:    
        navigate("/formdi");  
        break;
      case 3:    
        navigate("/formdiended");  
        break;
      case 4:    
        setIndexShowSettings(18);
        setShowModalGeneralSettings(false);
        setShowPrincipalMenu(false);
        break;
      case 5:    
        navigate("/tickets");  
        break; 
      case 6:    
        navigate("/purchasedeliverybegin");  
        break; 
      case 7:    
        navigate("/purchasedeliveryended");  
        break; 
        case 8:    
      navigate("/purchasesignature");  
        break; 
      default:
        break;
    }
   
  };
  const listData= (
    <>
      {data.map((post) => (
        post.isEnabled && 
          <Grid item xs={4} sm={4} md={4} key={post.id}>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                image={post.icon}
                title={t(post.title)}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" className='text-center'>
                  {t(post.title)}
                </Typography>
                <Typography variant="body2" color="text.secondary" className='text-justify'>
                  {t(post.information)}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={(e) => closeModal(post.id)}>{t('Text_Select')}</Button>
              </CardActions>
            </Card>
        </Grid>
      ))}
    </>    
  );

  return (
    <>
      {showPrincipalMenu ? (     
        //* Begin Page Content *//
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h1NewStyle mb-2"><SettingsIcon style={{color:"#ffc107", fontSize:56 }} /> {t('Text_MENU_PRINCIPAL')}</h1>
          </div>  
          <br/>        
          {/* Content Row */}
          <Grid
            container
            spacing={4}
            justify="center"
          >
            {listData}
          </Grid>
         {/* End Content Row */}
        </div>  
        //* End Page Content *//
      ) :  null }
    </>
  );
};

PrincipalMenuSettings.propTypes = { 
  showPrincipalMenu: PropTypes.bool.isRequired,
  setIndexShowSettings: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired,
  setShowPrincipalMenu: PropTypes.func.isRequired
};

export default PrincipalMenuSettings;
