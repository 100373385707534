import { combineReducers } from '@reduxjs/toolkit';
import { loginReducer } from "./LoginReducer";
import { errorReducer } from "./ErrorReducer";
import { variablesReducer } from "./VariablesReducer";
import { formatDIReducer } from "./FormatDIReducer"; 
import paginationPurchaseDeliveryReducer from './paginationPurchaseDeliverySlice';
import filtersPurchaseDeliveryReducer from './filtersPurchaseDeliverySlice'; 
import paginationTicketsReducer from './paginationTicketsSlice';
import filtersTicketsReducer from './filtersTicketsSlice.jsx'; 
import loadingReducer from './loadingSlice.jsx';

const rootReducer = combineReducers({
  loginUser: loginReducer,
  loadError: errorReducer,
  variablesUser: variablesReducer,
  formatDIUser:formatDIReducer,
  pagination: paginationPurchaseDeliveryReducer,
  filtersPurchaseDelivery: filtersPurchaseDeliveryReducer,
  paginationTickets: paginationTicketsReducer,
  filtersTickets: filtersTicketsReducer,
  loading: loadingReducer
});

export default rootReducer;
