import React, {useState, useEffect,useRef} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { NumericFormat } from 'react-number-format'; 
import  Toolbar from '@mui/material/Toolbar';
import  TableFooter from '@mui/material/TableFooter';
import  TablePagination from '@mui/material/TablePagination';
import  TableSortLabel from '@mui/material/TableSortLabel';
import  Table from '@mui/material/Table';
import  TableBody from '@mui/material/TableBody';
import  TableCell from '@mui/material/TableCell';
import  TableContainer from '@mui/material/TableContainer';
import  TableHead from '@mui/material/TableHead';
import  TableRow from '@mui/material/TableRow';
import  Paper from '@mui/material/Paper';
import  Typography  from '@mui/material/Typography';
import visuallyHidden from '@mui/utils/visuallyHidden';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField';
import Checkbox from  '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';

const headCellsArticles = [ 
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true
  },
  {
    id: 'namees',
    numeric: false,
    disablePadding: false,
    label: 'Text_Namees',
    canOrder:true
  },
  {
    id: 'nameen',
    numeric: false,
    disablePadding: false,
    label: 'Text_Nameen',
    canOrder:true
  },
  {
    id: 'saleprice',
    numeric: false,
    disablePadding: false,
    label: 'Text_SalePrice',
    canOrder:false
  },
  {
    id: 'typeinside',
    numeric: false,
    disablePadding: false,
    label: 'Text_Inside',
    canOrder:false
  },
  {
    id: 'typeoutside',
    numeric: false,
    disablePadding: false,
    label: 'Text_Outside',
    canOrder:false
  },
  {
    id: 'ticketweight',
    numeric: false,
    disablePadding: false,
    label: 'Text_Weight',
    canOrder:false
  },
  {
    id: 'codeler',
    numeric: false,
    disablePadding: false,
    label: 'Text_CodeLER',
    canOrder:true
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_enabled',
    canOrder:false
  } 
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,showEditButtonArticle,showDeleteButtonArticle } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsArticles.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        {showEditButtonArticle&&
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {t('Button_Edit')}
          </Typography>
        </TableCell>}
         {showDeleteButtonArticle&&
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 14}} > 
            {t('Button_Delete')}
          </Typography>
        </TableCell>}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegArticle, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegArticle).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataArticles']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingArticle =(props)=>{
  const {dataArticle,
    numTotRegFilter,
    titleTable,
    setOpenDeleteRowArticle,
    setRowEditArticle,
    showEditButtonArticle,
    showDeleteButtonArticle,
    numTotRegArticle,
    activeOffsetArticle,
    rangeOffsetArticle,
    currencyOffsetArticle,
    setCurrencyOffsetArticle,
    minvalue,
    setInitialRowIndex,
    initialRowIndex } = props;

  const {t} = useTranslation(['listDataArticles']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataArticle.length) : 0);
  const [countData,setCountData] = useState(dataArticle.length); 
  const rowRefs = useRef([]);

  useEffect(
    () => {
      if (dataArticle.length!==countData || dataArticle.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataArticle.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataArticle.length) : 0);  
    },
    [dataArticle,page,setEmptyRows,rowsPerPage,countData]
  );
    

  const handleChangeOffset = (event) => {
    setCurrencyOffsetArticle(event.target.value); 
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage); 
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  const openModalDeleteRowDepartment = (row,actualIndex) => {
    setRowEditArticle(row); 
    setInitialRowIndex(initialRowIndex);
    setInitialRowIndex(actualIndex);
    setOpenDeleteRowArticle(true);
  };
  
  const listItems= (
    stableSort(dataArticle, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, keyData) => {
    const actualIndex = page * rowsPerPage + keyData;
    return (
      <TableRow
        hover
        tabIndex={-1}
        key={keyData}
        ref={(el) => (rowRefs.current[actualIndex] = el)} // Asigna referencias
      >
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.code}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.namees}
          </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.nameen}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} > 
            <NumericFormat
              style={{color:"#000000", align:'right', fontSize: 14}}
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {2}
              suffix={" €"}
              fixedDecimalScale= {true}
              displayType="text"
              value={row.saleprice}
              disabled={true}
             />
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={row.typeinside} disabled/>
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={row.typeoutside} disabled/>
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={row.ticketweight} disabled/>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >  
            {row.codeler_code}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox checked={row.enabled} disabled/>
        </TableCell>
          
        {(showEditButtonArticle && row.id!=="" && row.id!==null ) &&
        <TableCell align="center">
          <Link  to={`/articledetail/${row.id}`} >
            <IconButton  size="large"  style={{color:"#4e73df", fontSize: 14}} >
              <EditIcon/>
            </IconButton>
          </Link>
        </TableCell>}
        {(showDeleteButtonArticle && row.id!=="" && row.id!==null) &&
        <TableCell align="center">
          <IconButton  size="large"  style={{ color:row.id>minvalue?"#e74a3b":"#858796", fontSize: 14}} onClick={row.id>minvalue?()=>openModalDeleteRowDepartment(row,actualIndex):() => {}}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>}
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegArticle={numTotRegArticle} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} showEditButtonArticle={showEditButtonArticle} showDeleteButtonArticle={showDeleteButtonArticle}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetArticle && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetArticle"
                            name="currencyOffsetArticle"
                            value={currencyOffsetArticle}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetArticle
                            }}>
                            {rangeOffsetArticle.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={12}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingArticle.propTypes = {
  dataArticle: PropTypes.array.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  setOpenDeleteRowArticle: PropTypes.func.isRequired,
  setRowEditArticle: PropTypes.func.isRequired,    
  showEditButtonArticle: PropTypes.bool.isRequired,  
  showDeleteButtonArticle: PropTypes.bool.isRequired,
  numTotRegArticle: PropTypes.number.isRequired,
  activeOffsetArticle: PropTypes.bool.isRequired,
  rangeOffsetArticle: PropTypes.array.isRequired,
  currencyOffsetArticle: PropTypes.number.isRequired,
  setCurrencyOffsetArticle: PropTypes.func.isRequired,
  minvalue: PropTypes.number.isRequired,
  setInitialRowIndex: PropTypes.func.isRequired,
  initialRowIndex: PropTypes.number.isRequired
};
  
export default TableSortingArticle;