import React, {useState} from 'react' 
import { useTranslation} from "react-i18next"  
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';     
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'; 
import Paper from '@mui/material/Paper';  
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead'; 
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';  
import Typography from '@mui/material/Typography';  
import visuallyHidden from '@mui/utils/visuallyHidden';
import PropTypes from 'prop-types';
const headCellsArticles = [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_CODE',
    canOrder:true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_ARTICLE_TITLE',
    canOrder:true,
  },
  {
    id: 'firstweight',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_ARTICLE_Amount',
    canOrder:false,
  },
  {
    id: 'estimatedprice',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_ARTICLE_Price',
    canOrder:false,
  }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsArticles.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 14}} > 
            {t('Button_Delete')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
const TicketsSelectArticleWithOutWeight =(props)=>{
  const { dataListTicketsDetails,form,setForm} = props;
  const {t,i18n} = useTranslation(['listDataTickets']);  
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
 
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 1000000) return true;
    return false;
  };

  const handleBlurArticlesWeight = (row, value) => {
    handleChangeArticleWeight(row, value);
  };

  const handleChangeArticleWeight = (row, value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll("kg","").replaceAll("€","").replaceAll("%","")):0;
    var i =  form.linesticketsWithOutWeight.indexOf( row );
    form.linesticketsWithOutWeight.map((row,index)=>{
      if ( index!==i ) {
        return row.firstweight
      }else{
        return row.firstweight=newValue
      }  
    })
    setForm(form => ({
     ...form
    }));
  };

  const handleBlurArticlesPrice = (row, value) => {
    handleChangeArticlePrice(row, value);
  };
 
  const handleChangeArticlePrice = (row, value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll("kg","").replaceAll("€","").replaceAll("%","")):0;
    var i =  form.linesticketsWithOutWeight.indexOf( row );
    form.linesticketsWithOutWeight.map((row,index)=>{
      if ( index!==i ) {
        return row.estimatedprice
      }else{
        return row.estimatedprice=newValue
      }  
    })
    setForm(form => ({
     ...form
    })) 
  };

  const openModalDeleteRowArticle = (row) => {
    let indexDelete=form.linesticketsWithOutWeight.findIndex(((item) => item.indexrowarticle === row.indexrowarticle));
    if ( indexDelete !== -1 ) {
      form.linesticketsWithOutWeight.splice( indexDelete, 1 );
    }
    setForm(form => ({
      ...form
    }));
  };
  const listItems= (
  stableSort(dataListTicketsDetails, getComparator(order, orderBy)).map((row, keydataListTicketsDetails) => {
    return (
      <TableRow hover tabIndex={-1} key={keydataListTicketsDetails}>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14}} >
            {row.article_code}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:"#4e73df", fontSize: 14}} >
            {i18n.language==="es"?row.article_namees:row.article_nameen}
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:"#4e73df", fontSize: 14}} >
          <NumericFormat 
            thousandSeparator={','} 
            decimalSeparator={'.'} 
            decimalScale= {0}
            fixedDecimalScale= {true}
            allowNegative= {false}
            isAllowed={withValueCap}
            id="firstweight" 
            name="firstweight"
            value={row.firstweight}
            style={{width:118, color:"red", background:"black",fontSize: 20,  paddingLeft:5}} 
            onBlur={(e) => handleBlurArticlesWeight(row,e.target.value)}
            onChange={(e) => handleChangeArticleWeight(row,e.target.value)}
          /> 
          </Typography>
        </TableCell>
        <TableCell align="left">
        <Typography style={{color:"#4e73df", fontSize: 14}} >
          <NumericFormat 
            thousandSeparator={','} 
            decimalSeparator={'.'} 
            decimalScale= {0}
            fixedDecimalScale= {true}
            allowNegative= {false}
            isAllowed={withValueCap}
            id="estimatedprice" 
            name="estimatedprice"
            prefix={"€ "}
            value={row.estimatedprice}
            style={{width:118, color:"red", background:"black",fontSize: 20, paddingLeft:5}} 
            onBlur={(e) => handleBlurArticlesPrice(row,e.target.value)}
            onChange={(e) => handleChangeArticlePrice(row,e.target.value)}
          />  
          </Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalDeleteRowArticle(row)}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t}/>
              <TableBody>    
                {listItems} 
              </TableBody>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TicketsSelectArticleWithOutWeight.propTypes = {
  dataListTicketsDetails: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired
};

export default TicketsSelectArticleWithOutWeight;