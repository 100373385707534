import React, { useState }from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedIcon from '@mui/icons-material/Feed';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom'; 
import { ToastContainer } from 'react-toastify'; 
import { TodayIs} from 'components/utils/TodayIs';
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages';
import RoadSettings from "pages/settings/RoadSettings";
import DocumentTypeSettings from "pages/settings/DocumentTypeSettings"; 
import CountrySettings from "pages/settings/CountrySettings"; 
import CommunitySettings from "pages/settings/CommunitySettings"
import CodeLerSettings from "pages/settings/CodeLerSettings";
import DescriptionSettings from "pages/settings/DescriptionSettings" 
import TreatmentTypeSettings from "pages/settings/TreatmentTypeSettings"
import ProcessTypeSettings from "pages/settings/ProcessTypeSettings"
import DangerousnessSettings from "pages/settings/DangerousnessSettings" 
import ContractFilter from "pages/contracts/filterContract/ContractFilter"

const initialFiltersContracts= {
    "idFrom":"",
    "idTo":"",
    "defaultStoreIdFrom":"",
    "defaultStoreIdTo":"",
    "useraccountIdFrom":"",
    "useraccountIdTo":"",
    "numberNTrom":"",
    "numberNTTo":"",
    "dateContractFrom":null,
    "dateContractTo":null,
  };

const HomeContract = () => {
    const {t,i18n} = useTranslation(['listDataContracts']);
    const state = useSelector((state) => state);
    const enabled = state.loginUser.enabled;
    const roles = state.loginUser.roles;
    const defaultStore = state.loginUser.defaultStore;
    const [applyFiltersContract, setApplyFiltersContract]= useState(initialFiltersContracts);
    const [showModalGeneralSettings, setShowModalGeneralSettings] = useState(false);
    const [indexShowSettings,setIndexShowSettings]=useState(0);
    const [showDocumentTypeSettings, setShowModalDocumentTypeSettings] = useState(false);
    const [showCommunitySettings, setShowCommunitySettings] = useState(false);
    const [showModalCodeLerSettings, setShowModalCodeLerSettings] = useState(false);
    const [showModalDescriptionSettings, setShowModalDescriptionSettings] = useState(false);
    const [showModalDangerousnessSettings, setShowModalDangerousnessSettings] = useState(false);
    const [showModalProcessTypeSettings, setShowModalProcessTypeSettings] = useState(false);
    const [showModalTreatmentTypeSettings, setShowModalTreatmentTypeSettings] = useState(false);
    const [showRoadSettings, setShowModalRoadSettings] = useState(false);
    const [showCountrySettings, setShowModalCountrySettings] = useState(false);
    const [showModalEmployeeSettings, setShowModalEmployeeSettings] = useState(false); 
    const [showModalStoreSettings, setShowModalStoreSettings] = useState(false); 
    const [showModalFormSettings, setShowModalFormSettings] = useState(false); 
    const [showModalTicketsSettings, setShowModalTicketsSettings] = useState(false); 
    const [showModalContractSettings, setShowModalContractSettings] = useState(true);  
    const [showModalArticlesSettings, setShowModalArticlesSettings] = useState(false); 
    const [showModalPurchasesDeliverySettings, setShowModalPurchasesDeliverySettings] = useState(false); 
    const [showModalShippingsSettings, setShowModalShippingsSettings] = useState(false); 
    const [showModalRegisterBookSettings, setShowModalRegisterBookSettings] = useState(false); 
   
    const scriptUrl = '/js/sb-admin-2.min.js'; 

    function changeSelectedLanguage(){
        i18n.language==="es"?i18n.changeLanguage("en"):i18n.changeLanguage("es");
    };

    const closeModalFormDI = () => {    
        setApplyFiltersContract(initialFiltersContracts);
        setIndexShowSettings(0);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(showModalEmployeeSettings);
        setShowModalStoreSettings(showModalStoreSettings); 
        setShowModalFormSettings(showModalFormSettings);
        setShowModalTicketsSettings(showModalTicketsSettings);
        setShowModalGeneralSettings(showModalGeneralSettings);
        setShowModalContractSettings(showModalContractSettings);
        setShowModalArticlesSettings(showModalArticlesSettings);
        setShowModalPurchasesDeliverySettings(showModalPurchasesDeliverySettings);
        setShowModalShippingsSettings(showModalShippingsSettings);
        setShowModalRegisterBookSettings(showModalRegisterBookSettings);
        return () => {};
    };
 
    function openModaDocuemntsSettings(){
        setIndexShowSettings(2);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(true);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }
    
    function openModaCountrySettings(){
        setIndexShowSettings(4);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(true);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaRoadSettings(){
        setIndexShowSettings(5);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(true);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaCommunitySettings(){
        setIndexShowSettings(6);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(true);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaCodeLerSettings(){
        setIndexShowSettings(8);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(true);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaDescriptionSettings(){
        setIndexShowSettings(9);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(true);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaTreatmentTypeSettings(){
        setIndexShowSettings(10);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(true); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaProcessTypeSettings(){
        setIndexShowSettings(11);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(false);
        setShowModalProcessTypeSettings(true);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaDangerousnessSettings(){
        setIndexShowSettings(12);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalCodeLerSettings(false);
        setShowModalDescriptionSettings(false);
        setShowModalDangerousnessSettings(true);
        setShowModalProcessTypeSettings(false);
        setShowModalTreatmentTypeSettings(false); 
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalFormSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalRegisterBookSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer/>
        {enabled===false ? <Navigate to="/"></Navigate>:
        <div id="page-top">
           
            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Sidebar - INFORMATION FILTROS APLICADOS */}
                    <li className="nav-item active">
                        <Link className="nav-link" to="/contract">
                            <FeedIcon/>
                            <span> {t('Text_Information')}</span>
                        </Link>
                    </li>
                    <div className={applyFiltersContract.idFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_From')}
                    </div>
                    <li className={applyFiltersContract.idFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersContract.idFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersContract.idFrom}
                    </li>
                    <div className={applyFiltersContract.idTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Code_To')}
                    </div>
                    <li className={applyFiltersContract.idTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersContract.idTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersContract.idTo}
                    </li>
                    
                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_Store_Master"))&&
                        <>
                            <div className={applyFiltersContract.defaultStoreIdFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                                {t('Text_Filter_Store_From')}
                            </div>
                            <li className={applyFiltersContract.defaultStoreIdFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersContract.defaultStoreIdFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersContract.defaultStoreIdFrom}
                            </li>
                            <div className={applyFiltersContract.defaultStoreIdTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                                {t('Text_Filter_Store_To')}
                            </div>
                            <li className={applyFiltersContract.defaultStoreIdTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersContract.defaultStoreIdTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersContract.defaultStoreIdTo}
                            </li>
                        </>
                    }
                    {(roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_Store_Master"))
                        || roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_User_Master"))) &&
                        <>
                            <div className={applyFiltersContract.useraccountIdFrom===''?"sidebar-heading":"sidebar-heading text-white"}> 
                                {t('Text_Filter_UserAccount_From')}
                            </div>
                            <li className={applyFiltersContract.useraccountIdFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersContract.useraccountIdFrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersContract.useraccountIdFrom}
                            </li>
                            <div className={applyFiltersContract.useraccountIdTo===''?"sidebar-heading":"sidebar-heading text-white"}> 
                                {t('Text_Filter_UserAccount_To')}
                            </div>
                            <li className={applyFiltersContract.useraccountIdTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                                { applyFiltersContract.useraccountIdTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersContract.useraccountIdTo}
                            </li>
                        </>
                    }

                    <div className={applyFiltersContract.numberNTrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_NumberNT_From')}
                    </div>
                    <li className={applyFiltersContract.numberNTrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersContract.numberNTrom==='' ? t('Text_Filter_Not_Apply'): applyFiltersContract.numberNTrom}
                    </li>
                    <div className={applyFiltersContract.numberNTTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_NumberNT_To')}
                    </div>
                    <li className={applyFiltersContract.numberNTTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersContract.numberNTTo==='' ? t('Text_Filter_Not_Apply'): applyFiltersContract.numberNTTo}
                    </li>

                    <div className={applyFiltersContract.dateContractFrom===null || applyFiltersContract.dateContractFrom===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Date_From')}
                    </div>
                    <li className={applyFiltersContract.dateContractFrom===null || applyFiltersContract.dateContractFrom===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        {applyFiltersContract.dateContractFrom===null || applyFiltersContract.dateContractFrom===''? t('Text_Filter_Not_Apply'): new Date(applyFiltersContract.dateContractFrom).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>
                    <div className={applyFiltersContract.dateContractTo===null || applyFiltersContract.dateContractTo===''?"sidebar-heading":"sidebar-heading text-white"}>   
                        {t('Text_Filter_Date_To')}
                    </div>
                    <li className={applyFiltersContract.dateContractTo===null || applyFiltersContract.dateContractTo===''?"sidebar-heading text-warning":"sidebar-heading text-warning"}>
                        { applyFiltersContract.dateContractTo===null || applyFiltersContract.dateContractTo===''? t('Text_Filter_Not_Apply'):new Date(applyFiltersContract.dateContractTo).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US')}
                    </li>
               
               
                    <li className="sidebar-heading text-warning">
                    &nbsp;
                    </li>
                    {/* Divider */}
                    <hr className="sidebar-divider"/>

                    {/* Heading */}
                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings"))&&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/contract" data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Docuemnts"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaDocuemntsSettings}>{t('SubMenu_Settings_Type_Documents')} </Link>}
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Locations"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaCountrySettings}>{t('SubMenu_Settings_Type_Locations')} </Link>}
                                   
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Roads"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaRoadSettings}>{t('SubMenu_Settings_Type_Roads')} </Link>}
                                 
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Community"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaCommunitySettings}>{t('SubMenu_Settings_Type_Community')} </Link>}
                                  
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_CodeLers"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaCodeLerSettings}>{t('SubMenu_Settings_Type_CodeLers')} </Link>}
                                  
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Desciption"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaDescriptionSettings}>{t('SubMenu_Settings_Type_Description')} </Link>}

                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_TreatmentType"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaTreatmentTypeSettings}>{t('SubMenu_Settings_Type_TreatmentType')} </Link>}

                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_ProcessType"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaProcessTypeSettings}>{t('SubMenu_Settings_Type_ProcessType')} </Link>}

                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Dangerousness"))&&
                                    <Link className="collapse-item"  to="/contract" onClick={openModaDangerousnessSettings}>{t('SubMenu_Settings_Type_Dangerousnes')} </Link>}
              
                                    <Link className="collapse-item" to="/contract" onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                   
                                </div>
                            </div>
                        </li>
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to="/home" onClick={closeModalFormDI} >
                            <LogoutIcon/>
                            <span>{t('Button_Back')}</span>
                        </Link>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block"/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

          
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>   
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}

                {/* Show Modals */}  
                <ContractFilter 
                    applyFiltersContract={applyFiltersContract}
                    setApplyFiltersContract={setApplyFiltersContract}
                    showModalContractSettings={showModalContractSettings}  
                    setShowModalContractSettings={setShowModalContractSettings}
                />
                <CodeLerSettings  
                    showModalCodeLerSettings={showModalCodeLerSettings}
                    setShowModalCodeLerSettings={setShowModalCodeLerSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalArticlesSettings={setShowModalArticlesSettings}
                    isArticle={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <DescriptionSettings  
                    showModalDescriptionSettings={showModalDescriptionSettings}
                    setShowModalDescriptionSettings={setShowModalDescriptionSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                 <TreatmentTypeSettings  
                    showModalTreatmentTypeSettings={showModalTreatmentTypeSettings}
                    setShowModalTreatmentTypeSettings={setShowModalTreatmentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <ProcessTypeSettings  
                    showModalProcessTypeSettings={showModalProcessTypeSettings}
                    setShowModalProcessTypeSettings={setShowModalProcessTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                 <DangerousnessSettings  
                    showModalDangerousnessSettings={showModalDangerousnessSettings}
                    setShowModalDangerousnessSettings={setShowModalDangerousnessSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalRegisterBookSettings={setShowModalRegisterBookSettings}
                    isRegisterBook={false}
                />
                <CommunitySettings  
                    showCommunitySettings={showCommunitySettings} 
                    setShowCommunitySettings={setShowCommunitySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <DocumentTypeSettings  
                    showDocumentTypeSettings={showDocumentTypeSettings}
                    setShowModalDocumentTypeSettings={setShowModalDocumentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
               
                <RoadSettings  
                    showRoadSettings={showRoadSettings} 
                    setShowModalRoadSettings={setShowModalRoadSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <CountrySettings  
                    showCountrySettings={showCountrySettings} 
                    setShowModalCountrySettings={setShowModalCountrySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={false}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                 
                </div>
                {/* End of Main Content */}

                {/* Footer */}
                <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}
         
      </div>}
      {/* End of Body */}

    </>
  )
};

export default HomeContract;
