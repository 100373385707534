import React, {useState }from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next" 
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';  
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; 
import { TodayIs } from 'components/utils/TodayIs';
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages';
import RoadSettings from "pages/settings/RoadSettings";
import DocumentTypeSettings from "pages/settings/DocumentTypeSettings";
import CountrySettings from "pages/settings/CountrySettings";
import CommunitySettings from "pages/settings/CommunitySettings"
import TicketsEditOrCreate from "pages/tickets/ticketsEdit/TicketsEdit";
import VehiclesSettings from "pages/vehicles/VehiclesSettings";

const HomeTickets = () => {
    const {t,i18n} = useTranslation(['listDataTickets']); 
    const enabled = useSelector((state) => state.loginUser.enabled);
    const roles = useSelector((state) => state.loginUser.roles);  
    const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
    const [showModalGeneralSettings, setShowModalGeneralSettings] = useState(false);
    const [indexShowSettings,setIndexShowSettings]=useState(0);
    const [showDocumentTypeSettings, setShowModalDocumentTypeSettings] = useState(false);
    const [showCommunitySettings, setShowCommunitySettings] = useState(false);
    const [showRoadSettings, setShowModalRoadSettings] = useState(false);
    const [showCountrySettings, setShowModalCountrySettings] = useState(false);
    const [showVehiclesSettings,setShowVehiclesSettings] = useState(false);
    const [showModalEmployeeSettings, setShowModalEmployeeSettings] = useState(false); 
    const [showModalStoreSettings, setShowModalStoreSettings] = useState(false);  
    const [showModalFormSettings, setShowModalFormSettings] = useState(false); 
    const [showModalTicketsSettings, setShowModalTicketsSettings] = useState(true); 
    const [showModalContractSettings, setShowModalContractSettings] = useState(false); 
    const [showModalArticlesSettings, setShowModalArticlesSettings] = useState(false); 
    const [showModalPurchasesDeliverySettings, setShowModalPurchasesDeliverySettings] = useState(false); 
    const [showModalShippingsSettings, setShowModalShippingsSettings] = useState(false); 
    const [showPrincipalMenu, setShowPrincipalMenu] = useState(false);

    const scriptUrl = '/js/sb-admin-2.min.js'; 
    const navigate = useNavigate();  
 
    const handleCloseTicketsEditData = () => {
        setIndexShowSettings(0);
        setShowPrincipalMenu(showPrincipalMenu);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowVehiclesSettings(false);
        setShowModalEmployeeSettings(showModalEmployeeSettings);
        setShowModalStoreSettings(showModalStoreSettings); 
        setShowModalFormSettings(showModalFormSettings); 
        setShowModalTicketsSettings(showModalTicketsSettings);
        setShowModalContractSettings(showModalContractSettings);
        setShowModalGeneralSettings(showModalGeneralSettings);
        setShowModalArticlesSettings(showModalArticlesSettings);
        setShowModalPurchasesDeliverySettings(showModalPurchasesDeliverySettings);
        setShowModalShippingsSettings(showModalShippingsSettings);
        navigate("/home");
        return () => {};
    };
    
    function changeSelectedLanguage(){
        i18n.language==="es"?i18n.changeLanguage("en"):i18n.changeLanguage("es");        
    };
    
    function openModaDocumentsSettings(){
        setIndexShowSettings(2);
        setShowPrincipalMenu(false);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(true);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowVehiclesSettings(false);
        setShowModalFormSettings(false); 
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaCountrySettings(){
        setIndexShowSettings(4);
        setShowPrincipalMenu(false);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(true);
        setShowModalEmployeeSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowVehiclesSettings(false);
        setShowModalFormSettings(false); 
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaRoadSettings(){
        setIndexShowSettings(5);
        setShowPrincipalMenu(false);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(true);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalTicketsSettings(false);
        setShowVehiclesSettings(false);
        setShowModalStoreSettings(false);
        setShowModalFormSettings(false); 
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModaCommunitySettings(){
        setIndexShowSettings(6);
        setShowPrincipalMenu(false);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(true);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalTicketsSettings(false);
        setShowModalStoreSettings(false);
        setShowVehiclesSettings(false);
        setShowModalFormSettings(false); 
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }

    function openModalVehicles(){
        setIndexShowSettings(0);
        setShowPrincipalMenu(false);
        setIndexShowSettings(indexShowSettings);
        setShowModalDocumentTypeSettings(false);
        setShowCommunitySettings(false);
        setShowModalRoadSettings(false);
        setShowModalCountrySettings(false);
        setShowModalEmployeeSettings(false);
        setShowModalTicketsSettings(false);
        setShowVehiclesSettings(true);
        setShowModalStoreSettings(false);
        setShowModalFormSettings(false); 
        setShowModalContractSettings(false);
        setShowModalArticlesSettings(false);
        setShowModalPurchasesDeliverySettings(false);
        setShowModalShippingsSettings(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
    }
     
    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer/>
        {enabled===false ? <Navigate to="/home"></Navigate>:
        <div id="page-top">

            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary toggled sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>
 
                    {/* Heading */}
                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings"))&&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to={`/tickets`} data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Docuemnts"))&&
                                    <Link className="collapse-item"  to="/tickets" onClick={openModaDocumentsSettings}>{t('SubMenu_Settings_Type_Documents')} </Link>}
                                    
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Locations"))&&
                                    <Link className="collapse-item"  to="/tickets" onClick={openModaCountrySettings}>{t('SubMenu_Settings_Type_Locations')} </Link>}
                                   
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Roads"))&&
                                    <Link className="collapse-item"  to="/tickets" onClick={openModaRoadSettings}>{t('SubMenu_Settings_Type_Roads')} </Link>}
                                 
                                    {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Community"))&&
                                    <Link className="collapse-item"  to="/tickets" onClick={openModaCommunitySettings}>{t('SubMenu_Settings_Type_Community')} </Link>}
                          
                                    <Link className="collapse-item" to="/tickets" onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                             
                                </div>
                            </div>
                        </li>
                    }

                    {/* Nav Item - VEHICLES Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Handle_Store_View_Vehicle")) &&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/tickets" data-toggle="collapse" data-target="#collapsevehicles"
                                aria-expanded="true" aria-controls="collapsevehicles">
                                <SettingsIcon/>
                                <span>{t('Menu_Vehicles')}</span>
                            </Link>
                            <div id="collapsevehicles" className="collapse" aria-labelledby="headingvehicles"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Vehicles')}</h6>
                                    <Link className="collapse-item"  to="/tickets" onClick={openModalVehicles}>{t('SubMenu_Vehicles_Filters')} </Link> 
                                </div>
                            </div>
                        </li> 
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to={`/home`} onClick={handleCloseTicketsEditData}>
                            <LogoutIcon/>
                            <span>{t('Button_Back')}</span>
                        </Link>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block"/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

          
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>      
                                
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}

                {/* Show Modals */}
                <TicketsEditOrCreate 
                    showModalTicketsSettings={showModalTicketsSettings}
                /> 
                <CommunitySettings  
                    showCommunitySettings={showCommunitySettings} 
                    setShowCommunitySettings={setShowCommunitySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false} 
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={true}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <DocumentTypeSettings  
                    showDocumentTypeSettings={showDocumentTypeSettings}
                    setShowModalDocumentTypeSettings={setShowModalDocumentTypeSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={true}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
               
                <RoadSettings  
                    showRoadSettings={showRoadSettings} 
                    setShowModalRoadSettings={setShowModalRoadSettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false}
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={true}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <CountrySettings  
                    showCountrySettings={showCountrySettings} 
                    setShowModalCountrySettings={setShowModalCountrySettings} 
                    setIndexShowSettings={setIndexShowSettings} 
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    setShowModalEmployeeSettings={setShowModalEmployeeSettings}
                    isEmployee={false}
                    setShowModalStoreSettings={setShowModalStoreSettings}
                    isStore={false} 
                    setShowModalFormSettings={setShowModalFormSettings}
                    isForm={false}
                    setShowModalContractSettings={setShowModalContractSettings}
                    isContract={false}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                    isWweigVehicles={true}
                    setShowModalPurchasesDeliverySettings={setShowModalPurchasesDeliverySettings}
                    isPurchasesDelivery={false}
                    setShowModalShippingsSettings={setShowModalShippingsSettings}
                    isShipping={false}
                />
                <VehiclesSettings  
                    setShowPrincipalMenu={setShowPrincipalMenu}
                    showVehiclesSettings={showVehiclesSettings} 
                    setShowVehiclesSettings={setShowVehiclesSettings}
                    setShowModalGeneralSettings={setShowModalGeneralSettings} 
                    showModalTicketsSettings={true}
                    setShowModalTicketsSettings={setShowModalTicketsSettings}
                />
            
                </div>
                {/* End of Main Content */}

                {/* Footer */}
                    <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}

      </div>}
      {/* End of Body */}

    </>
  )
};

export default HomeTickets;
