import React, { useEffect} from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css";  
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';

const TicketsPrint = ({ rowEditTicketsDetails, viewPrint, setViewPrint }) => {
  const { t, i18n } = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token);
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const ENDPOINTPRINTER = useSelector((state) => state.loginUser.defaultticketsformat.endpoinrprinter);
  const printerticket_operatingsystem = useSelector((state) => state.loginUser.defaultticketsformat.operatingsystem);  
  const printerticket_communicationtype = useSelector((state) => state.loginUser.defaultticketsformat.communicationtype);  
  const headerPrinter = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinter);
  const printerticket_type = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_type);
  const printerticket_interface = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_interface);
  const printerticket_driver = useSelector((state) => state.loginUser.defaultticketsformat.printerticket_driver);
  
  const dispatch = useDispatch();

  const formatNumberWithKg = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) + ' Kg';
  };

  const formatNumberEURO = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + ' €';
  };

  const formatNumberWithOutDecimal = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
  };

  useEffect(() => {
    let isMounted = true;
    const fetchDataPrintTicket = async (dataToPrint) => {
       
      let respondeData={
        "headerPrinter":headerPrinter,
        "numberoftickets":1,
        "printerticket_type":printerticket_type,
        "printerticket_interface":printerticket_interface,
        "printerticket_driver":printerticket_driver,
        "printerticket_operatingsystem":printerticket_operatingsystem,
        "start_date_ticket":dataToPrint.start_date_ticket,
        "numberticket":(dataToPrint.numberticket.toString() || ''),
        "ori_tras_tradename": (dataToPrint.ori_tras_tradename || '') ,
        "rel_trans_tradename": (dataToPrint.rel_trans_tradename || ''),
        "vehiclestore_licenseplate":(dataToPrint.vehiclestore_licenseplate || ''), 
        "ori_tras_street":(dataToPrint.ori_tras_street || ''), 
        "linestickets": [],
        "linesticketsWithOutWeight":[]
      } 
      dataToPrint.linestickets.forEach((line) => {
        let formattedFirstWeight = formatNumberWithKg(line.firstweight);
        let formattedSecondWeight = line.secondweight!==0?formatNumberWithKg(line.secondweight):"";
        let formattedNetoWeight = line.secondweight!==0?formatNumberWithKg(Math.abs(line.firstweight - line.secondweight)):"";
        let lineToAdd={
          "article_code":(line.article_code || ''),
          "article_namees":(line.article_namees || ''),
          "start_date_ticket":line.start_date_ticket,
          "end_date_ticket":line.end_date_ticket,
          "firstweight":formattedFirstWeight,
          "secondweight": formattedSecondWeight,
          "totalweight": formattedNetoWeight
        }
        respondeData.linestickets.push(lineToAdd); 
      });
      dataToPrint.linesticketsWithOutWeight.forEach((line) => {
        let formattedFirstWeight = formatNumberWithOutDecimal(line.firstweight);
        let formattedSecondWeight = line.estimatedprice!==0?formatNumberEURO(line.estimatedprice):"";
        let formattedNetoWeight = line.firstweight!==0 && line.estimatedprice!==0?formatNumberEURO(Math.abs(line.firstweight * line.estimatedprice)):"";
        let lineToAdd={
          "article_code":(line.article_code.substring(0, 33) || ''),
          "article_namees":(line.article_namees.substring(0, 33) || ''),
          "firstweight":formattedFirstWeight,
          "secondweight": formattedSecondWeight,
          "totalweight": formattedNetoWeight
        }
        respondeData.linesticketsWithOutWeight.push(lineToAdd); 
      }); 
      isMounted = true;
      if (printerticket_communicationtype && printerticket_communicationtype==="SOCKET"){
        // WebSocket connection
        const socket = new WebSocket('ws://localhost:8080'); 
        socket.addEventListener('open', () => {
          socket.send(JSON.stringify({ type: 'print-ticket', content: respondeData }));
        }); 
        socket.addEventListener('message', (event) => {
          try {
            const response = JSON.parse(event.data);
            if (response.status === 'success') {
              dispatch(successErrorAction(t('Text_PRINTER_SEND')));
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
              }
            }
          } catch (error) {
            if (isMounted) {
              dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
            }
          }
        }); 
        socket.addEventListener('error', (error) => {
          if (isMounted) {
            dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
          }
        }); 
        socket.addEventListener('close', (event) => {
          if (!event.wasClean) {
            if (isMounted) {
              dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
            }
          }
        }); 
      } else{
        try {
          const getData = await helpHttp().sendTickets(ENDPOINTPRINTER, `api/print-ticket`, { respondeData });
          if (getData.message === "OK") {
            dispatch(successErrorAction(t('Text_PRINTER_SEND')));
          } else {
            if (isMounted) {
              dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
            }
          }
        } catch (error) {
          if (isMounted) {
            dispatch(warningErrorAction(t('Text_PRINTER_ERROR_CONECT')));
          }
        } 
      }  
      return () => { isMounted = false;};
    }; 
    
    const fetchDataTickets = async () => { 
      try {
        const getData = await helpHttp().get2(ENDPOINT, `ticketsdetails/getticketbyid/`, token, rowEditTicketsDetails.id);
        if (getData.err) {
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else {
          if(getData!==undefined && getData!==null ){
            fetchDataPrintTicket(getData);  
          }
          
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (rowEditTicketsDetails!==undefined && rowEditTicketsDetails.id!==undefined  && rowEditTicketsDetails.id!==null  && rowEditTicketsDetails.id!=="" && rowEditTicketsDetails.id!=="0" && rowEditTicketsDetails.id!==0
      && defaulttypesdi!==undefined  && defaulttypesdi!==null  && defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0
    ) {
      if (viewPrint){
        fetchDataTickets();
        setViewPrint(false);
      } 
    } 
    return () => {isMounted = false;};
  }, [defaulttypesdi, rowEditTicketsDetails, ENDPOINT, dispatch, t, token,i18n.language,ENDPOINTPRINTER,headerPrinter,printerticket_operatingsystem,printerticket_driver,printerticket_interface,printerticket_communicationtype,printerticket_type,setViewPrint,viewPrint]);  
 
  return (
     <>
     </>
  );
};


TicketsPrint.propTypes = {
  rowEditTicketsDetails: PropTypes.object.isRequired,
  viewPrint: PropTypes.bool.isRequired,
  setViewPrint: PropTypes.func.isRequired
};

export default TicketsPrint;
